import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Tabs,
  Checkbox,
  Flex,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { DatePicker } from "antd/lib";
import vi from "dayjs/locale/vi";
import { SoQuyService } from "services/SoQuyService";
import TabPane from "antd/es/tabs/TabPane";
import { ToaNhaService } from "services/ToaNhaService";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import SelectCustom from "components/Forms/SelectCustom";
import InputCustom from "components/Forms/InputCustom";
import DebounceSelect from "components/DebounceSelect";
import FormDrawerUser from "components/FormMatBang";
import { EditOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { CategoryService } from "services/CategoryService";
import { DateTime } from "luxon";
import { Format_Date } from "layouts/ultis/common";
const { TextArea } = Input;

const FormAdd = (props, ref) => {
  const { onAfterSubmit, filterCondition, toanha } = props;
  const [api, contextHolder] = notification.useNotification();
  const defaulData = {
    TuNgay: new Date(),
    DenNgay: new Date(),
  };
  const [data, setData] = useState(defaulData);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loaiMB, setLoaiMb] = useState([]);

  const defaultErrors = {
    STT: null,
    DonGia: null,
  };

  const [errors, setErrors] = useState(defaultErrors);
  const filter = async (_filter) => {
    setLoading(true);
    let _resMB;

    _resMB = await CategoryService.DuAn.MatBang.get(_filter);
    if (_resMB.data) {
      setLoaiMb(_resMB.data);
    }
    setLoading(false);
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      filter(filterCondition);
      setData((pr) => ({ ...pr, MaTN: filterCondition.MaTN }));
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
  
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MaTN":
        const newFilterCondition = { ...filterCondition, MaTN: v };
        filter(newFilterCondition);
        _data[p] = v;
        break;
      case "TuNgay":
        const date = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString = date.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString;
        break;
      case "DenNgay":
        const date1 = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString1 = date1.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString1;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };

  const onSubmit = async (close) => {
    setLoading(true);

    let valid = validate();
    if (valid.length > 0) {
      setLoading(false);
      return;
    }

    let _data = _.cloneDeep(data);
    let _res = await CategoryService.DichVu.Nuoc.DinhMuc.add(_data);
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} loại xe thành công!`
      );
      setIsUpdate(true);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const renderThongTinChung = () => {
    return (
      <>
        <Row style={{ marginTop: "10px" }} gutter={[20, 12]}>
          <Col span={12}>
            <InputNumberCustom
              label="STT:"
              applyChange={applyChange}
              prop={"STT"}
              value={data?.STT}
              placeholder="0"
              errors={errors}
            />
          </Col>
          <Col span={12}>
            <SelectCustom
              label="Tòa nhà:"
              applyChange={applyChange}
              prop="MaTN"
              value={data?.MaTN}
              placeholder="Chọn tòa nhà"
              options={toanha}
              fieldNames={{ label: "TenTN", value: "MaTN" }}
              errors={errors}
            />
          </Col>
          <Col span={12}>
            <SelectCustom
              label="Loại mặt bằng:"
              applyChange={applyChange}
              prop="MaLMB"
              value={data?.MaLMB}
              placeholder="Chọn tòa nhà"
              options={loaiMB}
              fieldNames={{ label: "TenLMB", value: "MaLMB" }}
              errors={errors}
            />
          </Col>
          <Col span={12}>
            <InputNumberCustom
              label="Đơn giá:"
              applyChange={applyChange}
              prop={"DonGia"}
              value={data?.DonGia}
              placeholder="0"
              errors={errors}
            />
          </Col>
          {/* <Col span={12}>
            <SelectCustom
              label="Từ ngày:"
              applyChange={applyChange}
              prop="TuNgay"
              value={data?.TuNgay}
              placeholder="Chọn ngày từ"
              options={[]}
              //   fieldNames={{ label: "tenDA", value: "maDA" }}
            />
          </Col> */}
          <Col span={12}>
            <DatePickerCustom
              label="Từ ngày:"
              applyChange={applyChange}
              prop={"TuNgay"}
              value={data?.TuNgay}
              placeholder={"Chọn từ ngày"}
            />
          </Col>
          <Col span={12}>
            <InputNumberCustom
              label="Số định mức:"
              applyChange={applyChange}
              prop={"SoDM"}
              value={data?.SoDM}
              placeholder="0"
            />
          </Col>
          <Col span={12}>
            <DatePickerCustom
              label="đến ngày:"
              applyChange={applyChange}
              prop={"DenNgay"}
              value={data?.DenNgay}
              placeholder={"Chọn đến ngày"}
            />
          </Col>
          <Col span={12}>
            <InputNumberCustom
              label="Tiền BVMT:"
              applyChange={applyChange}
              prop={"TienBVMT"}
              value={data?.TienBVMT}
              placeholder="0"
            />
          </Col>
          <Col span={12}>
            <InputNumberCustom
              label="Tiền hao hụt:"
              applyChange={applyChange}
              prop={"TienHaoHut"}
              value={data?.TienHaoHut}
              placeholder="0"
            />
          </Col>
          <Col span={24}>
            <Flex>
              <p
                style={{
                  fontWeight: "600",
                  width: "20%",
                  borderBottom: "1px solid #d9d9d9",
                }}
              >
                Diễn giải:
              </p>
              <TextArea
                value={data?.DienGiai}
                onChange={(e) => applyChange("DienGiai", e.target.value)}
              />
            </Flex>
          </Col>
        </Row>
      </>
    );
  };
  return (
    <Drawer
      title={isupdate ? "Sửa định mực nước" : "Thêm định mức nước"}
      width={920}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined />Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {/* <FormDrawerUser
        className="vh-100"
        isEdit={isEdit}
        isOpen={modal}
        toggle={toggle}
        detail={staffForm}
      /> */}
      {contextHolder}
      {renderThongTinChung()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
