import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Tabs,
  Checkbox,
  Flex,
  Collapse,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { DatePicker } from "antd/lib";
import vi from "dayjs/locale/vi";
import { SoQuyService } from "services/SoQuyService";
import TabPane from "antd/es/tabs/TabPane";
import { ToaNhaService } from "services/ToaNhaService";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import InputCustom from "components/Forms/InputCustom";
import DebounceSelect from "components/DebounceSelect";
import SelectCustom from "components/Forms/SelectCustom";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import InputNumberCustom2 from "components/Forms/InputNumberCustom2";
import FormDrawerUser from "components/FormMatBang";
import { EditOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";
import { Format_Currency } from "layouts/ultis/common";
import { MatBangService } from "services/MatBangService";
const { TextArea } = Input;

const FormAdd = (props, ref) => {
  const { onAfterSubmit, filterCondition } = props;
  const [api, contextHolder] = notification.useNotification();
  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");
  const defaulData = {
    NgayThu: dateInHoChiMinh.toISO(),
    DotThu: dateInHoChiMinh.toISO(),
    ThanhToanBCK: false,
    TTMayPOS: false,
    ThanhToanVTT: false,
  };
  const [data, setData] = useState(defaulData);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = {
    // ThanhTien: null,
  };
  const [errors, setErrors] = useState(defaultErrors);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MaSoMB":
        const KH = JSON.parse(v.title);
        _data.KhachHang = KH.TenKH;
        _data[p] = v;
        break;
      case "NgayThu":
        const date = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString = date.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString;
        break;
      case "DotThu":
        const date2 = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString2 = date2.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString2;
        break;
      // case "NgayHH":
      //   const date3 = DateTime.fromJSDate(v.$d, { zone: "utc" });
      //   const isoString3 = date3.setZone("Asia/Ho_Chi_Minh").toISO();
      //   _data[p] = isoString3;
      //   break;
      // case "NgayTT":
      //   const date5 = DateTime.fromJSDate(v.$d, { zone: "utc" });
      //   const isoString5 = date5.setZone("Asia/Ho_Chi_Minh").toISO();
      //   _data[p] = isoString5;
      //   break;
      case "ThanhToanVTT":
        _data[p] = !v;
        break;
      case "ThanhToanBCK":
        _data[p] = !v;
        break;
      case "TTMayPOS":
        _data[p] = !v;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    // let valid = validate();
    // if (valid?.length > 0) {
    //   setLoading(false);
    //   return;
    // }
    let _res = await ToaNhaService.addToaNha(_data);
    if (!_data?.MaTN) {
      setData((pre) => {
        return { ...pre, MaTN: _res.data };
      });
    }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} phiếu thu thành công!`
      );
      setIsUpdate(true);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const dataSource = [
    {
      key: "1",
      stt: "1",
      dichVu: "Dịch vụ A",
      phaiThu: 1000000,
      dienGiai: "Thanh toán lần 1 cho dịch vụ A",
    },
    {
      key: "2",
      stt: "2",
      dichVu: "Dịch vụ B",
      phaiThu: 2000000,
      dienGiai: "Thanh toán lần 2 cho dịch vụ B",
    },
  ];

  const totalRows = dataSource.length;
  const totalPhaiThu = dataSource.reduce(
    (sum, record) => sum + record.phaiThu,
    0
  );

  const [rowState, setRowState] = useState(
    dataSource.map((item) => ({ checked: true, thucThu: item.thucThu }))
  );

  const [totalThucThu, setTotalThucThu] = useState(
    dataSource.reduce((sum, record) => sum + record.thucThu, 0)
  );

  useEffect(() => {
    let total = rowState.reduce((sum, row) => sum + row.thucThu, 0);
    setTotalThucThu(total);
    data.ThucThu = total;
    data.TienThua = -total;
  }, [rowState]);

  const onChange = (index, checked) => {
    setRowState((prevState) =>
      prevState.map((row, rowIndex) =>
        rowIndex === index
          ? {
              ...row,
              checked,
              thucThu: checked ? dataSource[index].thucThu : 0,
            }
          : row
      )
    );
  };
  const columns = [
    { title: "Dịch vụ" },
    { title: "Số tiền" },
    { title: "Diễn giải" },
  ];
  const items = [
    {
      key: "1",
      label: "Nợ cũ",
      children: (
        <>
          {dataSource.map((item, index) => (
            <Row
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #d9d9d9",
                marginBottom: "10px",
                paddingBottom: "10px",
              }}
            >
              <Col span={3}>
                <p>
                  <Checkbox
                    checked={rowState[index].checked}
                    onChange={(e) => onChange(index, e.target.checked)}
                  ></Checkbox>
                </p>
              </Col>
              <Col span={7}>
                <p>{item.dichVu}</p>
              </Col>
              <Col span={7}>
                <p>{Format_Currency(item.phaiThu)}</p>
              </Col>
              <Col span={7}>
                <p>{item.dienGiai}</p>
              </Col>
            </Row>
          ))}
          {/* <Row
            style={{
              textAlign: "center",
              fontWeight: "500",
            }}
          >
            <Col span={2}></Col>
            <Col span={4}>
              <p>{totalRows}</p>
            </Col>
            <Col span={4}></Col>
            <Col span={4}>
              <p>{Format_Currency(totalPhaiThu)}</p>
            </Col>
            <Col span={4}>
              <p>{Format_Currency(totalThucThu)}</p>
            </Col>
            <Col span={6}></Col>
          </Row> */}
        </>
      ),
    },
    {
      key: "2",
      label: "Nợ mới",
      children: <p>This is panel header 2</p>,
    },
  ];
  async function fetchMatBang(input) {
    return MatBangService.getMatBang({
      InputSearch: input,
      MaTN: filterCondition?.MaTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.MaSoMB}`,
        value: item.MaMB,
        title: JSON.stringify(item),
      }));
    });
  }

  const renderThongTinChung = () => {
    return (
      <>
        <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
          Thông tin chung
        </p>

        <Row gutter={[20, 12]}>
          <Col span={8}>
            <InputCustom
              label="Số phiếu thu:"
              applyChange={applyChange}
              prop="SoPhieuThu"
              value={data?.SoPhieuThu}
              placeholder="Nhập số phiếu thu"
            />
          </Col>
          <Col span={16}>
            <InputCustom
              label="Người nộp:"
              applyChange={applyChange}
              prop="NguoiNop"
              value={data?.NguoiNop}
              placeholder="Nhập tên người nhập"
            />
          </Col>
          <Col span={8}>
            <DatePickerCustom
              label="Ngày thu:"
              applyChange={applyChange}
              prop={"NgayThu"}
              value={data?.NgayThu}
              placeholder={"Chọn ngày thu"}
            />
          </Col>
          <Col span={16}>
            <InputCustom
              label="Địa chỉ:"
              applyChange={applyChange}
              prop="DiaChi"
              value={data?.DiaChi}
              placeholder="Nhập địa chỉ"
            />
          </Col>
          <Col span={8}>
            <DatePickerCustom
              label="Đợt thu:"
              applyChange={applyChange}
              prop={"DotThu"}
              value={data?.DotThu}
              placeholder={"Chọn đợt thu"}
            />
          </Col>

          <Col span={8}>
            <Flex style={{ borderBottom: "1px solid #d9d9d9" }}>
              <p
                style={{
                  fontWeight: "600",
                  width: "40% ",
                }}
              >
                Mặt bằng:
              </p>
              <DebounceSelect
                open={open}   
                value={data?.MaSoMB}
                placeholder="Tìm theo mã mặt bằng"
                fetchOptions={fetchMatBang}
                onChange={(v) => applyChange("MaSoMB", v)}
                style={{
                  width: "100%",
                }}
              />
            </Flex>
            {errors && (
              <p style={{ color: "red", textAlign: "center" }}>
                {errors && errors["MaSoMB"]}
              </p>
            )}
          </Col>
          <Col span={8}>
            <InputCustom
              label="Khách hàng:"
              applyChange={applyChange}
              prop="KhachHang"
              value={data?.KhachHang}
              placeholder="Chọn khách hàng"
              disabled={true}
            />
          </Col>
          <Col span={24}>
            <p
              style={{
                fontWeight: "600",
              }}
            >
              Lý do:
            </p>
            <TextArea
              value={data?.LyDo}
              onChange={(e) => applyChange("LyDo", e.target.value)}
            />
          </Col>
          <Col span={8}>
            <Checkbox
              value={data?.ThanhToanBCK}
              onChange={(e) => applyChange("ThanhToanBCK", e.target.value)}
              checked={data?.ThanhToanBCK}
            >
              {" "}
              Thanh toán bằng chuyển khoản
            </Checkbox>
          </Col>

          {/* <Col span={8}>
            <SelectCustom
              label="Khách hàng:"
              applyChange={applyChange}
              prop="KhachHang"
              value={data?.KhachHang}
              placeholder="Chọn khách hàng"
              options={[]}
              //   fieldNames={{ label: "tenDA", value: "maDA" }}
            />
          </Col>
          <Col span={8}></Col> */}
          <Col span={8}>
            <Checkbox
              checked={data?.TTMayPOS}
              value={data?.TTMayPOS}
              onChange={(e) => applyChange("TTMayPOS", e.target.value)}
            >
              {" "}
              Thanh toán máy POS
            </Checkbox>
          </Col>
          <Col span={8}>
            <Checkbox
              value={data?.ThanhToanVTT}
              onChange={(e) => applyChange("ThanhToanVTT", e.target.value)}
              checked={data?.ThanhToanVTT}
            >
              {" "}
              Thanh toán với tiền thừa
            </Checkbox>
          </Col>
        </Row>
        <Row
          style={{
            textAlign: "center",
            marginTop: "20px",
            fontWeight: "600",
            backgroundColor: "#E6F1FE",
            padding: "10px 0",
          }}
        >
          <Col span={2}></Col>
          <Col span={22}>
            <Row>
              {columns.map((item) => (
                <Col span={8} key={item.title}>
                  <p>{item.title}</p>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Row>
          <Collapse
            style={{
              width: "100%",
              borderTopRightRadius: "0px",
              borderTopLeftRadius: "0px",
            }}
            items={items}
            defaultActiveKey={["1"]}
            onChange={onChange}
          />
        </Row>
      </>
    );
  };
  return (
    <Drawer
      title="Phiếu thu trắng"
      width={920}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined />
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      {renderThongTinChung()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
