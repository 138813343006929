import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useRef,
} from "react";
import {
  Button,
  Input,
  Select,
  Row,
  Col,
  Drawer,
  notification,
  Table,
  Divider,
  Checkbox,
  Flex,
  Collapse,
} from "antd";
import "./index.scss";
import _, { cloneDeep } from "lodash";
import { YeuCauService } from "services/YeuCauService";
import { Format_Datetime } from "layouts/ultis/common";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import SelectCustom from "components/Forms/SelectCustom";
import InputCustom from "components/Forms/InputCustom";
import DebounceSelect from "components/DebounceSelect";
import InputNumberCustom2 from "components/Forms/InputNumberCustom2";
import { PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { Format_Currency } from "layouts/ultis/common";
import FormHoaDon from "./FormHoaDon";
import { DateTime } from "luxon";
import { CongNoService } from "services/CongNoService";
import { MatBangService } from "services/MatBangService";
import FormLapHoaDon from "./FormLapHoaDon";
import { FaFileInvoiceDollar } from "react-icons/fa6";
import { ToaNhaService } from "services/ToaNhaService";
import Mau1A5 from "views/admin/SoQuy/PhieuThu/FormPrint/Mau1A5";
import { useReactToPrint } from "react-to-print";
import MauChiTiet from "views/admin/SoQuy/PhieuThu/FormPrint/MauChiTiet";

const { TextArea } = Input;
const FormDetail = (props, ref) => {
  const refDetail = useRef(null);
  const componentRef = useRef();
  const { onAfterSubmit, options, filterCondition } = props;
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");

  const defaultData = { ChuyenKhoan: false, NgayThu: dateInHoChiMinh.toISO() };
  const [data, setData] = useState(defaultData);
  const [nhatky, setNhatky] = useState([]);
  const [open, setOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [pT, SetPT] = useState([]);
  const [iDMauPrint, setIDMauPrint] = useState();
  const [soHD, setSoHD] = useState("");

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  // const NoCu = dataSource.filter((item) => item.IsDebt === true);
  const [NoCu, setNoCu] = useState([]);
  const [PhatSinh, setPhatSinh] = useState([]);

  const totalRows = NoCu?.length;
  const totalRows2 = PhatSinh?.length;
  const totalPhaiThu = NoCu?.reduce((sum, record) => sum + record.PhaiThu, 0);
  const totalPhaiThu2 = PhatSinh?.reduce(
    (sum, record) => sum + record.PhaiThu,
    0
  );
  const TongThucThu = NoCu?.reduce((sum, record) => sum + record.SoTien, 0);
  const TongThucThu2 = PhatSinh?.reduce(
    (sum, record) => sum + record.SoTien,
    0
  );
  const [rowState, setRowState] = useState(
    dataSource?.map((item) => ({ checked: true, ThucThu: item.ThucThu }))
  );

  const [totalThucThu, setTotalThucThu] = useState(
    dataSource?.reduce((sum, record) => sum + record.ThucThu, 0)
  );

  useEffect(() => {
    let total = rowState.reduce((sum, row) => sum + row.ThucThu, 0);
    setTotalThucThu(total);
    data.ThucThu = total;
    data.TienThua = -total;
  }, [rowState]);

  const onChangeRowSate = (index, checked) => {
    setRowState((prevState) =>
      prevState.map((row, rowIndex) =>
        rowIndex === index
          ? {
              ...row,
              checked,
              thucThu: checked ? dataSource[index].thucThu : 0,
            }
          : row
      )
    );
  };
  const filterByMaMB = async (maMB) => {
    let _res = await CongNoService.TongHop.getTienThua({ MaMB: maMB });

    const _resPT = await ToaNhaService.DuAn.getPhieuThu({});
    if (_resPT.data) {
      const mauPT = options.ToaNha.find(
        (item) => item.MaTN === filterCondition.MaTN
      )?.MaMauPhieuThu;

      let mauPTParse = mauPT ? mauPT : [];

      SetPT(_resPT.data?.filter((item) => mauPTParse.includes(item.ID)));
      const newArrMau = _resPT.data?.filter((item) =>
        mauPTParse.includes(item.ID)
      );
      setIDMauPrint(
        newArrMau.find((item) => item.IsMacDinh === true)?.ID ||
          newArrMau[0]?.ID
      );
      setData((item) => ({
        ...item,
        ThuaThangTruoc: _res.data?.tienThuaThangTruoc,
        MauPhieuThu:
          newArrMau.find((item) => item.IsMacDinh === true)?.ID ||
          newArrMau[0]?.ID,
      }));
    }
  };

  const filterCongNo = async (item) => {
    const jsDate = new Date(filterCondition.DenNgay);
    const dateTime = DateTime.fromJSDate(jsDate);
    const payLoad = {
      MaMB: item.MaMB,
      month: dateTime.month,
      year: dateTime.year,
    };
    let _res = await CongNoService.GetCongNoTongHopChiTiet(payLoad);

    if (_res?.data) {
      const filteredData = _res.data;

      const NoCu = filteredData.filter((item) => item.IsDebt === true);
      const PhatSinh = filteredData.filter((item) => item.IsDebt !== true);

      const TongThucThuNC = NoCu.reduce(
        (sum, record) => sum + record.SoTien,
        0
      );
      const TongThucThuPS = PhatSinh.reduce(
        (sum, record) => sum + record.SoTien,
        0
      );

      setDataSource(filteredData);
      setNoCu(NoCu);
      setPhatSinh(PhatSinh);

      setData((prevData) => ({
        ...prevData,
        PhaiThu: TongThucThuNC + TongThucThuPS,
      }));
    }
  };
  useImperativeHandle(ref, () => ({
    show: async (item) => {
      setOpen(true);
      filterByMaMB(item?.MaMB);
      setData({
        ...item,
        YeuCauID: item.ID,
        MaTT: item.MaTT,
        ThucThu: totalThucThu,
        TienThua: -totalThucThu,
        // PhaiThu: TongThucThu + TongThucThu2,
        ChuyenKhoan: false,
        NguoiNop: options.KhachHang?.find((v) => v.MaKH === item.MaKH)?.TenKH,
        MatBang: {
          value: item?.MaMB,
          label: item?.MaSoMB,
        },
        IsThuThua: false,
        NgayThu: dateInHoChiMinh.toISO(),
      });
      if (filterCondition.DenNgay) {
        await filterCongNo(item);
      } else {
        // console.error("filterCondition.DenNgay is null or undefined");
      }
    },
  }));
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const onClose = () => {
    setOpen(false);
    setData({});
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "NgayThu":
        const date = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString = date.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString;
        break;
      case "MaHinhThucThuTien":
        _data[p] = v;
        break;
      case "CheckNC":
        const updatedNoCu = NoCu.map((item) => {
          if (item.ID === v.ID) {
            const soTien = item.IsCheck === true ? 0 : item?.PhaiThu;

            if (
              item?.PhaiThu > _data?.ThuaThangTruoc &&
              _data.IsThuThua === true
            ) {
              openNotification(
                "error",
                "topRight",
                "Tiền thanh toán lớn hơn tiền thu trước! vui lòng nhập lại!"
              );
              return { ...item, IsCheck: false, SoTien: 0 };
            } else {
              return { ...item, IsCheck: !item.IsCheck, SoTien: soTien };
            }
          }
          return item;
        });
        setNoCu(updatedNoCu);
        const TongThucThuNC2 = updatedNoCu.reduce(
          (sum, record) => sum + record.SoTien,
          0
        );
        const TongThucThuPS2 = PhatSinh.reduce(
          (sum, record) => sum + record.SoTien,
          0
        );
        _data.PhaiThu = TongThucThuNC2 + TongThucThuPS2;
        break;
      case "CheckPSTT":
        const updatedNPS = PhatSinh.map((item) => {
          if (item.ID === v.ID) {
            const soTien = item.IsCheck === true ? 0 : item?.PhaiThu;
            if (
              item?.PhaiThu > _data?.ThuaThangTruoc &&
              _data.IsThuThua === true
            ) {
              openNotification(
                "error",
                "topRight",
                "Tiền thanh toán lớn hơn tiền thu trước! vui lòng nhập lại!"
              );
              return { ...item, IsCheck: false, SoTien: 0 };
            } else {
              return { ...item, IsCheck: !item.IsCheck, SoTien: soTien };
            }
          }
          return item;
        });
        setPhatSinh(updatedNPS);
        const TongThucThuNC = NoCu.reduce(
          (sum, record) => sum + record.SoTien,
          0
        );
        const TongThucThuPS = updatedNPS.reduce(
          (sum, record) => sum + record.SoTien,
          0
        );
        _data.PhaiThu = TongThucThuNC + TongThucThuPS;
        break;
      case "IsThuThua":
        const TongThucThuNC3 = NoCu.reduce(
          (sum, record) => sum + record.SoTien,
          0
        );
        const TongThucThuPS3 = PhatSinh.reduce(
          (sum, record) => sum + record.SoTien,
          0
        );
        if (
          v === false &&
          _data?.ThuaThangTruoc < TongThucThuNC3 + TongThucThuPS3
        ) {
          const updatedNPS = PhatSinh.map((item) => {
            return { ...item, IsCheck: false, SoTien: 0 };
          });
          const updatedNC = NoCu.map((item) => {
            return { ...item, IsCheck: false, SoTien: 0 };
          });
          _data.PhaiThu = 0;
          setPhatSinh(updatedNPS);
          setNoCu(updatedNC);
          openNotification(
            "error",
            "topRight",
            "Tiền thanh toán lớn hơn tiền thu trước! vui lòng nhập lại!"
          );
        } else {
          const updatedNPS = PhatSinh.map((item) => {
            return { ...item, IsCheck: true, SoTien: item?.PhaiThu };
          });
          const updatedNC = NoCu.map((item) => {
            return { ...item, IsCheck: true, SoTien: item?.PhaiThu };
          });
          const totalAmount = [...updatedNPS, ...updatedNC].reduce(
            (accumulator, item) => {
              return accumulator + (item.SoTien || 0);
            },
            0
          );
          _data.PhaiThu = totalAmount;
          setPhatSinh(updatedNPS);
          setNoCu(updatedNC);
        }
        _data[p] = !v;
        break;
      case "MauPhieuThu":
        setIDMauPrint(v);
        _data[p] = v;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const onSubmit = async (close) => {
    setLoading(true);

    let _data = _.cloneDeep(data);

    const arrChiTiet = [
      ...NoCu.filter((item) => item.IsCheck === true),
      ...PhatSinh.filter((item) => item.IsCheck === true),
    ];

    // let valid = validate();
    // if (valid?.length > 0) {
    //   console.log(errors);
    //   setLoading(false);
    //   return;
    // }
    const payload = {
      ..._data,
      MaTN: filterCondition.MaTN,
      ChiTiet: arrChiTiet,
    };
    let _res = await CongNoService.TongHop.thanhToan(payload);
    setLoading(false);
    if (_res.status === 2000) {
      setSoHD(_res.data);
      handlePrint();
      openNotification("success", "topRight", `Thanh toán thành công!`);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  async function fetchMatBang(input) {
    return MatBangService.getMatBang({
      InputSearch: input,
      MaTN: filterCondition?.MaTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.MaSoMB}`,
        value: item.MaMB,
        title: JSON.stringify(item),
      }));
    });
  }

  const items = [
    {
      key: "1",
      label: "Nợ cũ",
      children: (
        <>
          {NoCu.map((item, index) => (
            <Row
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #d9d9d9",
                marginBottom: "10px",
                paddingBottom: "10px",
              }}
            >
              <Col span={2}>
                <p>
                  <Checkbox
                    onChange={(e) => applyChange("CheckNC", e.target.value)}
                    value={item}
                    checked={item?.IsCheck}
                  ></Checkbox>
                </p>
              </Col>
              <Col span={4}>
                <p>{item.Name}</p>
              </Col>
              <Col span={4}>
                <p>{Format_Datetime(item?.NgayThu)}</p>
              </Col>
              <Col span={4}>
                <p>{Format_Currency(item?.PhaiThu)}</p>
              </Col>
              <Col span={4}>
                <p>{Format_Currency(item?.SoTien)}</p>
              </Col>
              <Col span={6}>
                <p>{item.DienGiai}</p>
              </Col>
            </Row>
          ))}
          <Row
            style={{
              textAlign: "center",
              fontWeight: "500",
            }}
          >
            <Col span={2}></Col>
            <Col span={4}>
              <p>{totalRows}</p>
            </Col>
            <Col span={4}></Col>
            <Col span={4}>
              <p>{Format_Currency(totalPhaiThu)}</p>
            </Col>
            <Col span={4}>
              <p>{Format_Currency(TongThucThu)}</p>
            </Col>
            <Col span={6}></Col>
          </Row>
        </>
      ),
    },
    {
      key: "2",
      label: "Phát sinh trong tháng",
      children: (
        <>
          {PhatSinh.map((item, index) => (
            <Row
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #d9d9d9",
                marginBottom: "10px",
                paddingBottom: "10px",
              }}
            >
              <Col span={2}>
                <p>
                  <Checkbox
                    onChange={(e) => applyChange("CheckPSTT", e.target.value)}
                    value={item}
                    checked={item?.IsCheck}
                  ></Checkbox>
                </p>
              </Col>
              <Col span={4}>
                <p>{item.Name}</p>
              </Col>
              <Col span={4}>
                <p>{Format_Datetime(item?.NgayThu)}</p>
              </Col>
              <Col span={4}>
                <p>{Format_Currency(item?.PhaiThu)}</p>
              </Col>
              <Col span={4}>
                <p>{Format_Currency(item?.SoTien)}</p>
              </Col>
              <Col span={6}>
                <p>{item.DienGiai}</p>
              </Col>
            </Row>
          ))}
          <Row
            style={{
              textAlign: "center",
              fontWeight: "500",
            }}
          >
            <Col span={2}></Col>
            <Col span={4}>
              <p>{totalRows2}</p>
            </Col>
            <Col span={4}></Col>
            <Col span={4}>
              <p>{Format_Currency(totalPhaiThu2)}</p>
            </Col>
            <Col span={4}>
              <p>{Format_Currency(TongThucThu2)}</p>
            </Col>
            <Col span={6}></Col>
          </Row>
        </>
      ),
    },
  ];
  const columns = [
    {
      title: "Dịch vụ",
      dataIndex: "dichVu",
      key: "dichVu",
    },
    {
      title: "Đợt thanh toán",
      dataIndex: "dotThanhToan",
      key: "dotThanhToan",
    },
    {
      title: "Phải thu",
      dataIndex: "phaiThu",
      key: "phaiThu",
      // render: (text) => text,
    },
    {
      title: "Thực thu",
      dataIndex: "thucThu",
      key: "thucThu",
      // render: (text) => text,
    },
    {
      title: "Diễn giải",
      dataIndex: "dienGiai",
      key: "dienGiai",
    },
  ];
  const renderThongTinChung = () => {
    return (
      <>
        <Row style={{ paddingTop: "15px" }} gutter={[20, 12]}>
          <Col span={12}>
            <InputNumberCustom
              disabled={true}
              label="Thực thu:"
              applyChange={applyChange}
              prop={"PhaiThu"}
              value={data?.PhaiThu}
              placeholder="0"
            />
          </Col>
          <Col span={12}>
            <InputNumberCustom
              label="Thừa tháng trước:"
              applyChange={applyChange}
              prop={"ThuaThangTruoc"}
              value={data?.ThuaThangTruoc}
              placeholder="1"
            />
          </Col>
          {/* <Col span={12}>
            <InputNumberCustom
              label="Trả khách:"
              applyChange={applyChange}
              prop={"TraKhach"}
              value={data?.TraKhach}
              placeholder="0"
            />
          </Col>
          <Col span={12}>
            <InputNumberCustom
              label="Tiền thừa:"
              applyChange={applyChange}
              prop={"TienThua"}
              value={data?.TienThua}
              placeholder="0"
            />
          </Col> */}
          {data?.HinhThucTT === "ChuyenKhoan" && (
            <Col span={12}>
              {" "}
              <SelectCustom
                label="Tài khoản:"
                applyChange={applyChange}
                prop="TaiKhoan"
                value={data?.TaiKhoan}
                placeholder="Chọn tài khoản"
                options={[]}
                //   fieldNames={{ label: "tenDA", value: "maDA" }}
              />
            </Col>
          )}
          <Col span={12}>
            <InputNumberCustom
              label="Số dư:"
              applyChange={applyChange}
              prop={"SoDu"}
              value={data?.SoDu}
              placeholder="0"
            />
          </Col>
          {isVisible ? (
            <>
              {/* <Col span={12}>
                <InputCustom
                  label="Số phiếu:"
                  applyChange={applyChange}
                  prop="SoPhieu"
                  value={data?.SoPhieu}
                  placeholder="Nhập số phiếu"
                />
              </Col> */}
              <Col span={12}>
                <DatePickerCustom
                  label="Ngày thu:"
                  applyChange={applyChange}
                  prop="NgayThu"
                  value={data?.NgayThu}
                  placeholder="Chọn ngày thu"
                />
              </Col>
              <Col span={12}>
                <SelectCustom
                  label="Mẫu phiếu thu:"
                  applyChange={applyChange}
                  prop="MauPhieuThu"
                  value={data?.MauPhieuThu}
                  placeholder="Chọn mẫu phiếu thu"
                  options={pT}
                  fieldNames={{ label: "TenMauPT", value: "ID" }}
                />
              </Col>
              <Col span={12}>
                <InputCustom
                  label="Người nộp:"
                  applyChange={applyChange}
                  prop="NguoiNop"
                  value={data?.NguoiNop}
                  placeholder="Nhập tên người nộp"
                />
              </Col>
              <Col span={12}>
                <InputCustom
                  label="Địa chỉ:"
                  applyChange={applyChange}
                  prop="DiaChi"
                  value={data?.DiaChi}
                  placeholder="Nhập địa chỉ"
                />
              </Col>

              <Col span={12}>
                <Flex style={{ borderBottom: "1px solid #d9d9d9" }}>
                  <p
                    style={{
                      fontWeight: "600",
                      width: "30% ",
                    }}
                  >
                    Mặt bằng:
                  </p>
                  <DebounceSelect
                   open={open}
                    disabled={true}
                    value={data?.MatBang}
                    placeholder="Tìm theo mã mặt bằng"
                    fetchOptions={fetchMatBang}
                    onChange={(v) => applyChange("MatBang", v)}
                    style={{
                      width: "100%",
                    }}
                  />
                </Flex>
              </Col>
              <Col span={12}>
                <div style={{ display: "flex" }}>
                  <p
                    style={{
                      fontWeight: "600",
                      width: "20%",
                      borderBottom: "1px solid #d9d9d9",
                    }}
                  >
                    Diễn giải:
                  </p>
                  <TextArea
                    value={data?.DienGiai}
                    onChange={(e) => applyChange("DienGiai", e.target.value)}
                  />
                </div>
              </Col>
            </>
          ) : (
            ""
          )}
          <Col span={12}>
            {" "}
            <SelectCustom
              label="Hình thức TT:"
              applyChange={applyChange}
              prop="MaHinhThucThuTien"
              value={data?.MaHinhThucThuTien}
              placeholder="Chọn hình thức thanh toán"
              options={[
                { TenHTTT: "Tiền mặt", key: 1 },
                { TenHTTT: "Chuyển khoản ngân hàng", key: 2 },
                { TenHTTT: "Bù trừ PQL", key: 8 },
                { TenHTTT: "Bù trừ tiền cọc", key: 10 },
                { TenHTTT: "Thanh toán máy POS", key: 3 },
              ]}
              fieldNames={{ label: "TenHTTT", value: "key" }}
            />
          </Col>
          {/* <Col span={6}>
            <Checkbox
              style={{
                fontWeight: "500",
              }}
              value={data?.ChuyenKhoan}
              onChange={(e) => applyChange("ChuyenKhoan", e.target.value)}
            >
              Chuyển khoản
            </Checkbox>
          </Col>
          <Col span={6}>
            <Checkbox
              style={{
                fontWeight: "500",
              }}
              value={data?.BuTruPhiQL}
              onChange={(e) => applyChange("BuTruPhiQL", e.target.value)}
            >
              Bù trừ phí QL
            </Checkbox>
          </Col>
          <Col span={6}>
            <Checkbox
              style={{
                fontWeight: "500",
              }}
              value={data?.BuTruTienCoc}
              onChange={(e) => applyChange("BuTruTienCoc", e.target.value)}
            >
              Bù trừ tiền cọc
            </Checkbox>
          </Col>
          <Col span={6}>
            <Checkbox
              style={{
                fontWeight: "500",
              }}
              value={data?.ThanhToanMayPOS}
              onChange={(e) => applyChange("ThanhToanMayPOS", e.target.value)}
            >
              Thanh toán máy POS
            </Checkbox>
          </Col> */}
          <Col span={12}>
            <Checkbox
              style={{
                fontWeight: "500",
              }}
              value={data?.IsThuThua}
              checked={data?.IsThuThua}
              onChange={(e) => applyChange("IsThuThua", e.target.value)}
            >
              Thanh toán với số tiền thừa
            </Checkbox>
          </Col>
        </Row>
        <Row style={{ marginTop: "15px" }} gutter={[20, 12]}>
          <Col span={12}>
            <Button onClick={toggleVisibility}>
              {isVisible ? "Thu gọn" : "Mở rộng"}
            </Button>
          </Col>
          <Col span={12}></Col>
        </Row>
        <Row
          style={{
            textAlign: "center",
            marginTop: "20px",
            fontWeight: "600",
            backgroundColor: "#E6F1FE",
            padding: "10px 0",
          }}
        >
          <Col span={2}></Col>
          {columns.map((item) => (
            <Col span={4} key={item.title}>
              <p>{item.title}</p>
            </Col>
          ))}
        </Row>
        <Row>
          <Collapse
            style={{
              width: "100%",
              borderTopRightRadius: "0px",
              borderTopLeftRadius: "0px",
            }}
            items={items}
            defaultActiveKey={["1"]}
            onChange={onChangeRowSate}
          />
        </Row>
      </>
    );
  };

  return (
    <Drawer
      title="Thanh toán"
      width={960}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <>
          <p style={{ fontSize: 14, fontWeight: "bold", marginRight: 20 }}>
            {options?.ToaNha?.find((v) => v.MaTN === data?.MaTN)?.TenTN}
          </p>
          <Button
            style={{ marginRight: "15px" }}
            icon={
              <FaFileInvoiceDollar
                style={{
                  marginBottom: "-2px",
                  marginLeft: "-3px",
                  color: "orange",
                }}
              />
            }
            onClick={() => refDetail.current.show(data)}
            name="Lập hóa đơn"
          >
            Lập hóa đơn
          </Button>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined /> Lưu & xuất hóa đơn
          </Button>
        </>
      }
    >
      {contextHolder}
      {renderThongTinChung()}
      <FormLapHoaDon
        ref={refDetail}
        filterCongNo={filterCongNo}
        filterCondition={filterCondition}
      />
      {/* <div style={{ display: "none" }}>
        <div ref={componentRef}>
          {{
            1: <Mau1A5 />,
            2: <Mau1A5 songNgu={true} />,
            4: <Mau1A5 double={true} />,
            5: <Mau1A5 songNgu={true} double={true} />,
            6: <MauChiTiet />,
          }[iDMauPrint] || <MauChiTiet />}
        </div>
      </div> */}
      <div style={{ display: "none" }}>
        <div ref={componentRef}>
          {(() => {
            switch (iDMauPrint) {
              case 1:
                return (
                  <Mau1A5
                    songNgu={false}
                    double={false}
                    data={{ ...data, SoHD: soHD }}
                    dataTable={[
                      ...NoCu.filter((item) => item.IsCheck === true),
                      ...PhatSinh.filter((item) => item.IsCheck === true),
                    ]}
                    dataCT={options.ToaNha.find(
                      (item) => item.MaTN === filterCondition.MaTN
                    )}
                  />
                );
              case 2:
                return (
                  <Mau1A5
                    songNgu={true}
                    double={false}
                    data={{ ...data, SoHD: soHD }}
                    dataTable={[
                      ...NoCu.filter((item) => item.IsCheck === true),
                      ...PhatSinh.filter((item) => item.IsCheck === true),
                    ]}
                    dataCT={options.ToaNha.find(
                      (item) => item.MaTN === filterCondition.MaTN
                    )}
                  />
                );
              case 3:
                return (
                  <Mau1A5
                    songNgu={false}
                    double={true}
                    data={{ ...data, SoHD: soHD }}
                    dataTable={[
                      ...NoCu.filter((item) => item.IsCheck === true),
                      ...PhatSinh.filter((item) => item.IsCheck === true),
                    ]}
                    dataCT={options.ToaNha.find(
                      (item) => item.MaTN === filterCondition.MaTN
                    )}
                  />
                );
              case 4:
                return (
                  <Mau1A5
                    songNgu={true}
                    double={true}
                    data={{ ...data, SoHD: soHD }}
                    dataTable={[
                      ...NoCu.filter((item) => item.IsCheck === true),
                      ...PhatSinh.filter((item) => item.IsCheck === true),
                    ]}
                    dataCT={options.ToaNha.find(
                      (item) => item.MaTN === filterCondition.MaTN
                    )}
                  />
                );
              case 5:
              case 6:
              default:
                return (
                  <MauChiTiet
                  data={{ ...data, SoHD: soHD }}
                    dataTable={[
                      ...NoCu.filter((item) => item.IsCheck === true),
                      ...PhatSinh.filter((item) => item.IsCheck === true),
                    ]}
                    dataCT={options.ToaNha.find(
                      (item) => item.MaTN === filterCondition.MaTN
                    )}
                  />
                );
            }
          })()}
        </div>
      </div>
    </Drawer>
  );
};
export default forwardRef(FormDetail);
