import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Button,
  Input,
  Upload,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  message,
  Select,
  Checkbox,
  Flex,
  ColorPicker,
  InputNumber,
} from "antd";
import { CustomerService } from "services/CustomerService";
import _ from "lodash";
import { ToaNhaService } from "services/ToaNhaService";
import TextArea from "antd/es/input/TextArea";
import { CategoryService } from "services/CategoryService";
import { SaveOutlined } from "@ant-design/icons";
const FormAddMultiple = (props, ref) => {
  const { onAfterSubmit } = props;
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const defaultData = { ChoThue: false };
  const [data, setData] = useState(defaultData);
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("0");
  const [toanha, setToanha] = useState([]);
  const [khoinha, setKhoinha] = useState([]);

  const defaultErrors = {
    TienTo: null,
  };

  const [errors, setErrors] = useState(defaultErrors);

  const loadcategories = async () => {
    let _tn = await ToaNhaService.getToaNhaByNV();
    setToanha(_tn.data);
  };
  const loadKhoiNha = async (maTN) => {
    let _tn = await ToaNhaService.Categories.getKhoiNha({
      MaTN: maTN,
    });
    setKhoinha(_tn.data);
  };
  useImperativeHandle(ref, () => ({
    show: async (tab, filterCondition) => {
      setOpen(true);
      loadcategories();
      setActiveTab(tab);
      setData((prevData) => ({
        ...prevData,
        ChoThue: false,
        MaTN: filterCondition.MaTN,
        MaKN: filterCondition.MaKN,
      }));
    },
    update: async (data, tab) => {
      setOpen(true);
      loadcategories();
      loadKhoiNha(data.MaTN);
      setData(data);
      setActiveTab(tab);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
    setLoading(false);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MaTN":
        loadKhoiNha(v);
        _data.MaKN = null;
        _data[p] = v;
        break;
      case "ChoThue":
        _data[p] = !v;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = (propsErrors) => {
    let _errors = _.cloneDeep(propsErrors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);

    let _res = await ToaNhaService.Categories.AddNhieuTang(_data);

    setLoading(false);
    if (_res.status === 2000) {
      openNotification("success", "topRight", `Cập nhật thành công!`);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  return (
    <Drawer
      title="Danh mục"
      width={520}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          {/* <Button loading={loading} onClick={() => onSubmit()}>
              Lưu
            </Button> */}
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined /> Lưu & đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Row gutter={[24, 20]}>
        <>
          <Col span={24} style={{ marginTop: "10px" }}>
            <p style={{ fontWeight: "600" }}>Tiền tố</p>
            <Input
              value={data?.TienTo}
              placeholder="Nhập tiền tố"
              onChange={(e) => applyChange("TienTo", e.target.value)}
            />
            {errors && (
              <p style={{ color: "red", textAlign: "center" }}>
                {errors && errors["TienTo"]}
              </p>
            )}
              <p style={{ fontWeight: "600" }}>Số lượng</p>
              <InputNumber
                style={{ width: "100%" }}
                value={data?.SoLuong}
                placeholder="Nhập số lượng"
                onChange={(value) => applyChange("SoLuong", value)}
                min={0}
              />

            <p style={{ marginTop: 20, fontWeight: "600" }}>Dự án</p>
            <Select
              style={{
                width: "100%",
              }}
              value={data?.MaTN}
              placeholder="Chọn dự án"
              onChange={(v) => applyChange("MaTN", v)}
              options={toanha}
              fieldNames={{ label: "TenTN", value: "MaTN" }}
            />
            <p style={{ marginTop: 20, fontWeight: "600" }}>Khối nhà</p>
            <Select
              value={data.MaKN}
              placeholder="Chọn khối nhà"
              onChange={(v) => applyChange("MaKN", v)}
              style={{
                width: "100%",
              }}
              options={khoinha}
              fieldNames={{ label: "TenKN", value: "MaKN" }}
            />
          </Col>
        </>
      </Row>
    </Drawer>
  );
};
export default forwardRef(FormAddMultiple);
