import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Space,
  Drawer,
  notification,
  Table,
  Upload,
  Select,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import { UploadOutlined } from "@ant-design/icons";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { DichVuService } from "services/DichVuService";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import { ConvertExcelDateToJSDate } from "layouts/ultis/common";

const FormAdd = (props, ref) => {
  const { maTN, onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();

  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sheetExcel, setSheetExcel] = useState();
  const [workbook, setWorkbook] = useState(null);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
    },
    update: async (data) => {
      setOpen(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData([]);
    setSheetExcel();
    setCol(columns);
    setLoading(false);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };

  const columns = [
    { title: "MaMB", dataIndex: "MaSoMB", key: "MaSoMB" },
    { title: "MaKH", dataIndex: "MaSoKH", key: "MaSoKH" },
    { title: "Loại dịch vụ", dataIndex: "LoaiDichVu", key: "LoaiDichVu" },
    { title: "Số lượng", dataIndex: "SoLuong", key: "SoLuong" },
    { title: "Đơn giá", dataIndex: "DonGia", key: "DonGia" },
    { title: "Thành tiền", dataIndex: "ThanhTien", key: "ThanhTien" },
    { title: "Ngày thanh T", dataIndex: "NgayTT", key: "NgayTT" },
    { title: "Ngày tính phí", dataIndex: "NgayTinhPhi", key: "NgayTinhPhi" },
    {
      title: "Thanh toán TK",
      dataIndex: "ThanhToanTheoKy",
      key: "ThanhToanTheoKy",
      width: 140,
    },
  ];
  const [col, setCol] = useState(columns);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const sampleData = [
    {
      MaSoMB: "HH1-0301",
      MaSoKH: "KH001",
      LoaiDichVu: "Bảo hiểm",
      SoLuong: 10,
      DonGia: 50000,
      ThanhTien: 500000,
      NgayTT: "2024-01-15",
      NgayTinhPhi: "2024-01-10",
      ThanhToanTheoKy: "FALSE",
    },
    {
      MaSoMB: "HH1-0302",
      MaSoKH: "KH002",
      LoaiDichVu: "Dịch vụ B",
      SoLuong: 5,
      DonGia: 75000,
      ThanhTien: 375000,
      NgayTT: "2024-02-20",
      NgayTinhPhi: "2024-02-15",
      ThanhToanTheoKy: "TRUE",
    },
  ];
  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(sampleData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "File mẫu khac" + fileExtension);
  };
  const loadDataImport = (data) => {
    let res = [];
    data?.map((item, i) => {
      if (i > 0) {
        res.push({
          MaSoMB: item[0]?.toString() ?? null,
          MaSoKH: item[1]?.toString() ?? null,
          LoaiDichVu: item[2]?.toString() ?? null,
          SoLuong: parseFloat(item[3]),
          DonGia: parseFloat(item[4]),
          ThanhTien: parseFloat(item[5]),
          NgayTT:
            typeof item[6] === "number"
              ? ConvertExcelDateToJSDate(item[6])
              : item[6],
          NgayTinhPhi:
            typeof item[7] === "number"
              ? ConvertExcelDateToJSDate(item[7])
              : item[7],
          ThanhToanTheoKy: item[8],
        });
      }
    });

    setData(res);
  };
  const handleSheet = (value) => {
    const wsname = workbook.SheetNames[value];
    const ws = workbook.Sheets[wsname];
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
    loadDataImport(data);
  };

  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    dayjs.extend(customParseFormat);

    const formatDate = (dateString) => {
      if (dayjs(dateString, "DD/MM/YYYY", true).isValid()) {
        return dayjs(dateString, "DD/MM/YYYY").format("YYYY-MM-DD");
      } else if (dayjs(dateString, "YYYY/MM/DD", true).isValid()) {
        return dayjs(dateString, "YYYY/MM/DD").format("YYYY-MM-DD");
      } else if (dayjs(dateString, "DD-MM-YYYY", true).isValid()) {
        return dayjs(dateString, "DD-MM-YYYY").format("YYYY-MM-DD");
      } else {
        return dateString;
      }
    };

    const filterData = _data.map((item) => {
      return {
        ...item,
        ThanhToanTheoKy: item?.ThanhToanTheoKy?.toLowerCase() === "true",
        NgayTT: formatDate(item.NgayTT),
        NgayTinhPhi: formatDate(item.NgayTinhPhi),
      };
    });

    let _res = await DichVuService.DichVuKhac.importExcel({
      MaTN: maTN,
      List: filterData,
    });
    setLoading(false);

    if (_res.status !== 2000) {
      openNotification("error", "topRight", _res.message);
    } else if (_res.status === 2000 && _res.data.length === 0) {
      openNotification("success", "topRight", "Thao tác thành công");

      onClose();
    } else {
      const pushErrorData = _data.map((item) => {
        let updatedItem = { ...item };
        _res?.data?.forEach((i) => {
          if (item.MaSoMB === i.maSoMB) {
            updatedItem = { ...item, error: i.error };
          }
        });
        return updatedItem;
      });
      setData(pushErrorData.filter((item) => item.error));
      setCol([
        {
          title: "Error",
          dataIndex: "error",
          key: "error",
          width: 180,
          render: (item) => <p style={{ color: "red" }}>{item}</p>,
        },
        ...columns,
      ]);

      openNotification("success", "topRight", "Thao tác thành công");
    }
    onAfterSubmit();
  };
  return (
    <Drawer
      title="Excel"
      width={960}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          {sheetExcel && (
            <Select
              placeholder="Chọn Sheet"
              style={{
                width: 115,
                border: "1px solid #d9d9d9",
                borderBottom: "3px solid #d9d9d",
                borderRadius: "5px",
                padding: "0 2px 0 10px",
              }}
              onChange={handleSheet}
              options={sheetExcel}
            />
          )}
          <Upload
            accept=".txt, .csv, .xlsx, .xls"
            showUploadList={false}
            beforeUpload={(file) => {
              setWorkbook(null);
              setSheetExcel(null);
              const reader = new FileReader();
              const rABS = !!reader.readAsBinaryString;
              reader.onload = (e) => {
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
                const sheets = wb.SheetNames.map((item, index) => ({
                  value: index,
                  label: item,
                }));
                if (sheets.length === 1) {
                  const wsname = wb.SheetNames[0];
                  const ws = wb.Sheets[wsname];
                  const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
                  loadDataImport(data);
                  setCol(columns);
                } else {
                  setWorkbook(wb);
                  setSheetExcel(sheets);
                  setData();
                  setCol(columns);
                }
              };
              if (rABS) reader.readAsBinaryString(file);
              else reader.readAsArrayBuffer(file);
            }}
          >
            <Button icon={<UploadOutlined />}>Upload File</Button>
          </Upload>
          <Button onClick={() => exportToCSV()}>Tải file mẫu</Button>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Table
        style={{ margin: 0, marginTop: "20px" }}
        dataSource={data}
        columns={col}
        scroll={{
          y: window.innerHeight - 250,
          x: 1100,
        }}
      />
    </Drawer>
  );
};
export default forwardRef(FormAdd);
