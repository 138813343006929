import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import * as React from "react";
import { NhanVienService } from "services/NhanVienService";
import "./index.scss";
import { Flex } from "antd";
import _ from "lodash"
const HoaDonToPrint = (props, ref) => {
  const { data, data2, filter } = props;
  const [nhanvien, setNhanVien] = React.useState({});
  React.useEffect(() => {
    NhanVienService.getDetail().then((res) => {
      setNhanVien(res.data ?? []);
    });
  }, []);

  return (
    <div
      style={{
        justifyContent: "center",
        display: "flex",
      }}
    >
      <div
        ref={ref}
        className="print-report"
        style={{
          width: filter?.Horizontal ? "1123px" : "794px",
          // width: filter?.Horizontal ? "80%" : "60%",
          margin: "0",
          padding: "0 25px",
        }}
      >
        <div className="page-break" />
        {/* <div style={{ height: "98vh", margin: "0 10px", padding: "0" }}> */}
        <div style={{ margin: "0 10px", padding: "0" }}>
          <Flex justify="space-around" style={{ padding: "20px" }}>
            <img width={100} src={nhanvien.Logo} />
            <div style={{ textAlign: "center", padding: "15px 0px" }}>
              <p style={{ fontSize: 15, fontWeight: "bold" }}>
                {nhanvien?.TenCT}
              </p>
              <p>{nhanvien?.DiaChi}</p>
              <p>{nhanvien?.DienThoai}</p>
            </div>
          </Flex>
          <Flex justify="space-between">
            <div>
              <p>
                Từ ngày {Format_Date(filter.TuNgay)} Đến ngày{" "}
                {Format_Date(filter.DenNgay)}
              </p>
              <p>
                In lúc:{" "}
                {new Date().getHours() < 10
                  ? "0" + new Date().getHours()
                  : new Date().getHours()}
                :
                {new Date().getMinutes() < 10
                  ? "0" + new Date().getMinutes()
                  : new Date().getMinutes()}
              </p>
            </div>
            <p
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              CHI TIẾT HOẠT ĐỘNG TRONG NGÀY
            </p>
          </Flex>
          <p style={{ fontWeight: "bold" }}>Bán vé</p>
          <table style={{ width: "100%" }}>
            <tr
              style={{
                textAlign: "left",
                borderBottom: "2px solid black",
              }}
            >
              <th style={{ fontSize: 12, width: "30px", padding: "6px 0px" }}>
                STT
              </th>
              <th style={{ fontSize: 12 }}>Quầy vé</th>
              <th style={{ fontSize: 12 }}>Số HD</th>
              <th style={{ fontSize: 12 }}>Thu ngân</th>
              <th style={{ fontSize: 12 }}>Giờ TT</th>
              <th style={{ fontSize: 12 }}>Khách hàng</th>
              <th style={{ fontSize: 12 }}>Nguồn KH</th>
              <th style={{ fontSize: 12 }}>Tổng cộng</th>
              <th style={{ fontSize: 12 }}>Thanh toán</th>
              <th style={{ fontSize: 12 }}>Còn nợ</th>
              {/* <th style={{ fontSize: 12 }}>Chi tiết</th> */}
            </tr>
            {data?.map((item, index) => {
              return (
                <tr style={{ borderBottom: "1px dotted gray" }}>
                  <td
                    style={{ width: "20px", fontSize: 12, padding: "6px 0px" }}
                  >
                    {index + 1}
                  </td>
                  <td style={{ fontSize: 12 }}>{item.TenQuay}</td>
                  <td style={{ fontSize: 12 }}>{item.SoBH}</td>
                  <td style={{ fontSize: 12 }}>{item.HoTen}</td>
                  <td style={{ fontSize: 12 }}>
                    {new Date(item.NgayNhap).getHours()}:
                    {new Date(item.NgayNhap).getMinutes()}
                  </td>
                  <td style={{ fontSize: 12 }}>{item.TenCongTy}</td>
                  <td style={{ fontSize: 12 }}>{item.TenNguon}</td>
                  <td style={{ fontSize: 12 }}>
                    {Format_Currency(item.KhachTra)}
                  </td>
                  <td style={{ fontSize: 12 }}>
                    {Format_Currency(item.ThanhToan)}
                  </td>
                  <td style={{ fontSize: 12 }}>
                    {Format_Currency(item.KhachTra - item.ThanhToan)}
                  </td>
                </tr>
              );
            })}
            <tfoot
              style={{
                padding: "10px 0px",
                borderTop: "1px solid gray",
              }}
            >
              <tr>
                <td colSpan={7} style={{ fontSize: 12, fontWeight: "bold" }}>
                  Tổng cộng
                </td>
                <td style={{ fontSize: 12, fontWeight: "bold" }}>
                  {Format_Currency(_.sumBy(data, (v) => v.KhachTra))}
                </td>
                <td style={{ fontSize: 12, fontWeight: "bold" }}>
                  {Format_Currency(_.sumBy(data, (v) => v.ThanhToan))}
                </td>
                <td style={{ fontSize: 12, fontWeight: "bold" }}>
                  {Format_Currency(
                    _.sumBy(data, (v) => v.KhachTra - v.ThanhToan)
                  )}
                </td>
              </tr>
            </tfoot>
          </table>

          <p style={{ fontWeight: "bold", marginTop: 20 }}>Vé bán</p>
          <table style={{ width: "100%" }}>
            <tr
              style={{
                textAlign: "left",
                borderBottom: "2px solid black",
              }}
            >
              <th style={{ fontSize: 12, width: "30px", padding: "6px 0px" }}>
                STT
              </th>
              <th style={{ fontSize: 12 }}>Mã hàng</th>
              <th style={{ fontSize: 12 }}>Tên hàng</th>
              <th style={{ fontSize: 12 }}>Số lượng</th>
              <th style={{ fontSize: 12 }}>Đơn giá</th>
              <th style={{ fontSize: 12 }}>Tiền CK</th>
              <th style={{ fontSize: 12 }}>Thành tiền</th>
            </tr>
            {data2?.map((item, index) => {
              return (
                <tr style={{ borderBottom: "1px dotted gray" }}>
                  <td
                    style={{ width: "20px", fontSize: 12, padding: "6px 0px" }}
                  >
                    {index + 1}
                  </td>
                  <td style={{ fontSize: 12 }}>{item.KyHieu}</td>
                  <td style={{ fontSize: 12 }}>{item.TenSP}</td>
                  <td style={{ fontSize: 12 }}>{item.SoLuong}</td>
                  <td style={{ fontSize: 12 }}>
                    {Format_Currency(item.DonGia)}
                  </td>
                  <td style={{ fontSize: 12 }}>
                    {Format_Currency(item.TienCK)}
                  </td>
                  <td style={{ fontSize: 12 }}>
                    {Format_Currency(item.ThanhTien)}
                  </td>
                </tr>
              );
            })}
            <tfoot
              style={{
                padding: "10px 0px",
                borderTop: "1px solid gray",
              }}
            >
              <tr>
                <td colSpan={3} style={{ fontSize: 12, fontWeight: "bold" }}>
                  Tổng cộng
                </td>
                <td style={{ fontSize: 12, fontWeight: "bold" }}>
                  {Format_Currency(_.sumBy(data2, (v) => v.SoLuong))}
                </td>
                <td></td>
                <td style={{ fontSize: 12, fontWeight: "bold" }}>
                  {Format_Currency(_.sumBy(data2, (v) => v.TienCK))}
                </td>
                <td style={{ fontSize: 12, fontWeight: "bold" }}>
                  {Format_Currency(_.sumBy(data2, (v) => v.TongTien))}
                </td>
              </tr>
              <tr>
                <td colSpan={6} style={{ fontSize: 12, fontWeight: "bold" }}>
                  Chiết khấu
                </td>
                <td style={{ fontSize: 12, fontWeight: "bold" }}>
                  {Format_Currency(_.sumBy(data2, (v) => v.TongTienCK))}
                </td>
              </tr>
              <tr>
                <td colSpan={6} style={{ fontSize: 12, fontWeight: "bold" }}>
                  Tổng sau CK
                </td>
                <td style={{ fontSize: 12, fontWeight: "bold" }}>
                  {Format_Currency(_.sumBy(data2, (v) => v.TongSauCK))}
                </td>
              </tr>
            </tfoot>
          </table>
          <Flex justify="flex-end" style={{ padding: "30px 10%" }}>
            <div>
              <p style={{ textAlign: "center" }}>
                Ngày {new Date().getDate()} tháng {new Date().getMonth() + 1}{" "}
                năm {new Date().getFullYear()}
              </p>
              <p style={{ fontWeight: "bold", textAlign: "center" }}>
                Người lập
              </p>
              <p style={{ textAlign: "center", fontStyle: "italic" }}>
                (Ký , họ tên)
              </p>
            </div>
          </Flex>
          {/* <p
            style={{
              marginTop: 20,
              fontStyle: "italic",
              fontSize: 12,
              textAlign: "center",
            }}
          >
            Phiếu này có giá trị xuất hoá đơn đỏ trong ngày
          </p>
          <p
            style={{
              fontStyle: "italic",
              fontSize: 12,
              textAlign: "center",
            }}
          >
            Cảm ơn quý khách. Hẹn gặp lại!
          </p> */}
        </div>
      </div>
    </div>
  );
};
export default React.forwardRef(HoaDonToPrint);
