import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Select,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  message,
  DatePicker,
  Flex,
} from "antd";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import vi from "dayjs/locale/vi";
import _, { filter } from "lodash";
import DebounceSelect from "components/DebounceSelect";
import { MatBangService } from "services/MatBangService";
import { CuDanService } from "services/CuDanService";
import { YeuCauService } from "services/YeuCauService";
import SelectCustom from "components/Forms/SelectCustom";
import InputCustom from "components/Forms/InputCustom";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import { DateTime } from "luxon";
import { SaveOutlined } from "@ant-design/icons";
import UploadComponent from "components/UploadComponent";
import { URL_UPLOAD } from "services/UploadService";

const { TextArea } = Input;
const FormAdd = (props, ref) => {
  const { onAfterSubmit, options, filterCondition } = props;
  const [api, contextHolder] = notification.useNotification();
  const [messageApi, contextHolderMS] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");
  const defaultData = {
    NgayYC: dateInHoChiMinh.toISO(),
    HanXuLy: dateInHoChiMinh.toISO(),
    NgayHenKH: dateInHoChiMinh.toISO(),
    Images: [],
  };
  const [data, setData] = useState(defaultData);
  const [open, setOpen] = useState(false);
  const [cudan, setCudan] = useState([]);
  const defaultErrors = {
    MatBang: null,
    MaBP: null,
    MaLYC: null,
    MaTT: null,
    MaNTN: null,
  };
  const affterUpload = async (file) => {
    let _data = _.cloneDeep(data);
    if (file.length > 0) {
      if (!_data.Images) {
        _data.Images = [URL_UPLOAD + file[0]];
      } else {
        _data.Images.push(URL_UPLOAD + file[0]);
      }
      setData(_data);
    }
  };
  const onRemove = async (file) => {
    // let _file = files.find((v) => v.Link === file.url);
    // await VisaService.HoSo.deleteTaiLieu({
    //   ID: _file?.ID,
    // });
  };
  console.log(filterCondition, "test mã nhân viên");
  const getFirstNhanVien = (nhanVienString) => {
    const nhanVienArray = nhanVienString.slice(1, -1).split(",");
    return Number(nhanVienArray[0]);
  };
  const [errors, setErrors] = useState(defaultErrors);
  const [loaiyeucau, setLoaiyeucau] = useState([]);
  useImperativeHandle(ref, () => ({
    show: async (matn) => {
      setOpen(true);
      setData({
        ...data,
        MaTN: matn,
        MaNTN: getFirstNhanVien(filterCondition.NhanVien),
      });
    },
    update: async (data) => {
      setOpen(true);
      data.MatBang = { value: data.MaMB, label: data.MaSoMB };
      let _lyc = await YeuCauService.Categories.getLoaiYeuCau({
        MaPB: data.MaBP,
      });
      setLoaiyeucau(_lyc.data);
      let _res = await CuDanService.getCuDanByMaMB({ MaMB: data.MaMB });
      setCudan(_res.data);
      let _resImg = await YeuCauService.getImg({ ID: data.ID });
      const listImg = _.map(_resImg?.data, "DuongDan");
      setData({ ...data, Images: listImg });
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaultData);
    setErrors(defaultErrors);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MaMB":
        let _res = await CuDanService.getCuDanByMaMB({ MaMB: v });
        setCudan(_res.data);
        _data[p] = v;
        break;
      case "MaBP":
        let _lyc = await YeuCauService.Categories.getLoaiYeuCau({ MaPB: v });
        setLoaiyeucau(_lyc.data);
        _data[p] = v;
        break;
      case "NgayYC":
        const date = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString = date.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString;
        break;
      case "HanXuLy":
        const date2 = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString2 = date2.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString2;
        break;
      case "NgayHenKH":
        const date3 = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString3 = date3.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString3;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async () => {
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      return;
    }
    _data.MaMB = _data.MatBang?.value;
    setLoading(true);
    let _res = await YeuCauService.addYeuCau(_data);
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${_data.ID ? "Sửa" : "Thêm"} nhân viên thành công!`
      );
      onClose();
      onAfterSubmit();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  async function fetchMatBang(input) {
    return MatBangService.getMatBang({
      InputSearch: input,
      MaTN: data?.MaTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.MaSoMB}`,
        value: item.MaMB,
        title: JSON.stringify(item),
      }));
    });
  }

  return (
    <Drawer
      title="Yêu cầu khách hàng"
      width={1120}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <p style={{ fontSize: 14, fontWeight: "bold", marginRight: 20 }}>
            {options?.ToaNha?.find((v) => v.MaTN === data?.MaTN)?.TenTN}
          </p>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined />
            Lưu & đóng
          </Button>
        </Space>
      }
    >
      {contextHolderMS}
      {contextHolder}
      <Row gutter={[20, 12]} style={{ marginTop: 20 }}>
        <Col span={8}>
          <Flex style={{ borderBottom: "1px solid #d9d9d9" }}>
            <p
              style={{
                color: "red",
                fontWeight: "600",
                marginTop: "5px",
                width: "30%",
              }}
            >
              Căn hộ:
            </p>
            <DebounceSelect
              open={open}
              value={data?.MatBang}
              placeholder="Tìm theo mã mặt bằng"
              fetchOptions={fetchMatBang}
              onChange={(v) => applyChange("MatBang", v)}
              style={{
                width: "100%",
              }}
            />
          </Flex>{" "}
          {errors && (
            <p style={{ color: "red", textAlign: "center" }}>
              {errors && errors["MatBang"]}
            </p>
          )}
        </Col>
        <Col span={8}>
          {" "}
          <SelectCustom
            label="Cư dân:"
            applyChange={applyChange}
            prop="MaKH"
            value={data?.MaKH}
            placeholder="Chọn cư dân"
            options={cudan}
            fieldNames={{ label: "HoTenNK", value: "ID" }}
          />
        </Col>
        <Col span={8}>
          <InputCustom
            label="K/H phản ánh:"
            applyChange={applyChange}
            prop="NguoiGui"
            value={data?.NguoiGui}
            placeholder="Nhập tên khách phản ánh"
          />
        </Col>
        <Col span={8}>
          <InputCustom
            label="Di động:"
            applyChange={applyChange}
            prop="SoDienThoaiNguoiGui"
            value={data?.SoDienThoaiNguoiGui}
            placeholder="Nhập số điện thoại"
          />
        </Col>
        <Col span={8}>
          <InputCustom
            label="Tiêu đề:"
            applyChange={applyChange}
            prop="TieuDe"
            value={data?.TieuDe}
            placeholder="Nhập tiêu đề"
          />
        </Col>
        {/* <Col span={24} className="gutter-row">
          <p style={{ color: "red", fontWeight: "600" }}>Nội dung:</p>
          <TextArea
            value={data?.NoiDung}
            placeholder="Nhập nội dung"
            onChange={(e) => applyChange("NoiDung", e.target.value)}
          />
        </Col> */}
        <Col span={8}>
          {" "}
          <SelectCustom
            label="Độ ưu tiên:"
            applyChange={applyChange}
            prop="MaDoUuTien"
            value={data?.MaDoUuTien}
            placeholder="Chọn độ ưu tiên"
            options={options?.MucDo}
            fieldNames={{ label: "TenDoUuTien", value: "MaDoUuTien" }}
          />
        </Col>
        <Col span={8}>
          {" "}
          <SelectCustom
            required={true}
            label="Giửi đến:"
            applyChange={applyChange}
            prop="MaBP"
            value={data?.MaBP}
            placeholder="Chọn phòng ban"
            options={options?.PhongBan}
            fieldNames={{ label: "TenPB", value: "MaPB" }}
            errors={errors}
          />
        </Col>
        <Col span={8}>
          {" "}
          <SelectCustom
            required={true}
            label="Loại yêu cầu:"
            applyChange={applyChange}
            prop="MaLYC"
            value={data?.MaLYC}
            placeholder="Chọn loại yêu cầu"
            options={loaiyeucau}
            fieldNames={{ label: "TenLYC", value: "MaLYC" }}
            errors={errors}
          />
        </Col>
        <Col span={8}>
          <SelectCustom
            label="Hình thức:"
            applyChange={applyChange}
            prop="MaHinhThucNhanYC"
            value={data?.MaHinhThucNhanYC}
            placeholder="Chọn hình thức"
            options={options?.HinhThuc}
            fieldNames={{ label: "TenHT", value: "ID" }}
          />
        </Col>
        <Col span={8}>
          <DatePickerCustom
            label="Ngày yêu cầu:"
            applyChange={applyChange}
            prop={"NgayYC"}
            value={data?.NgayYC}
            placeholder={"Chọn ngày yêu cầu"}
          />
        </Col>
        <Col span={8}>
          <DatePickerCustom
            label="Hạn xử lý:"
            applyChange={applyChange}
            prop={"HanXuLy"}
            value={data?.HanXuLy}
            placeholder={"Chọn hạn ngày xử lý"}
          />
        </Col>
        <Col span={8}>
          <DatePickerCustom
            label="Ngày hẹn K/H:"
            applyChange={applyChange}
            prop={"NgayHenKH"}
            value={data?.NgayHenKH}
            placeholder={"Chọn ngày hẹn khách hàng"}
          />
        </Col>
        <Col span={8}>
          <SelectCustom
            required={true}
            label="Trạng thái:"
            applyChange={applyChange}
            prop="MaTT"
            value={data?.MaTT}
            placeholder="Chọn trạng thái"
            options={options?.TrangThai?.filter((v) => v.MaTT !== 0)}
            fieldNames={{ label: "TenTT", value: "MaTT" }}
            errors={errors}
          />
        </Col>
        <Col span={8}>
          <SelectCustom
            required={true}
            label="Nhân viên:"
            applyChange={applyChange}
            prop="MaNTN"
            value={data?.MaNTN}
            placeholder="Chọn nhân viên"
            options={options?.NhanVien}
            fieldNames={{ label: "HoTenNV", value: "MaNV" }}
            errors={errors}
          />
        </Col>
        <Col span={24} className="gutter-row">
          <p style={{ color: "red", fontWeight: "600" }}>Nội dung:</p>
          <TextArea
            value={data?.NoiDung}
            placeholder="Nhập nội dung"
            onChange={(e) => applyChange("NoiDung", e.target.value)}
          />
        </Col>
        <Col span={24}>
          <UploadComponent
            files={data?.Images ? data?.Images : []}
            multiple={true}
            affterUpload={affterUpload}
            onRemove={onRemove}
          />
        </Col>
      </Row>
    </Drawer>
  );
};

export default forwardRef(FormAdd);
