import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  ColorPicker,
  Flex,
  Select,
  Input,
  Checkbox,
} from "antd";
import _ from "lodash";
import { ToaNhaService } from "services/ToaNhaService";
import InputCustom from "components/Forms/InputCustom";
import { YeuCauService } from "services/YeuCauService";
import { SaveOutlined } from "@ant-design/icons";

const FormAdd = (props, ref) => {
  const { onAfterSubmit, toanha } = props;
  const [api, contextHolder] = notification.useNotification();
  const defaulData = {
    NgayThu: new Date(),
  };
  const [data, setData] = useState(defaulData);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = {
    ThanhTien: null,
  };
  const [errors, setErrors] = useState(defaultErrors);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    _data[p] = v;
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    // let valid = validate();
    // if (valid?.length > 0) {
    //   setLoading(false);
    //   return;
    // }
    let _res = await YeuCauService.Categories.addTrangThai(_data);

    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} trạng thái yêu cầu thành công!`
      );
      setIsUpdate(true);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const renderThongTinChung = () => {
    return (
      <>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <p style={{ fontWeight: "600", paddingTop: "15px" }}>Dự án</p>
            <Select
              style={{
                width: "100%",
              }}
              value={data?.MaTN}
              placeholder="Chọn dự án"
              onChange={(v) => applyChange("MaTN", v)}
              options={toanha}
              fieldNames={{ label: "TenTN", value: "MaTN" }}
            />
          </Col>
          <Col span={24}>
            <p style={{ fontWeight: "600" }}>Tên trạng thái</p>
            <Input
              value={data?.TenTT}
              placeholder="Nhập tên trạng thái"
              onChange={(e) => applyChange("TenTT", e.target.value)}
            />
          </Col>

          <Col span={12}>
            <Checkbox
              checked={data?.DefautCuDan === true}
              onChange={() => applyChange("DefautCuDan", !data?.DefautCuDan)}
            >
              Mặc định cư dân
            </Checkbox>
          </Col>
          <Col span={12}>
            <Checkbox
              checked={data?.isViewApp === true}
              onChange={() => applyChange("isViewApp", !data?.isViewApp)}
            >
              Hiển thị app bql
            </Checkbox>
          </Col>
          <Col span={12}>
            <Checkbox
              checked={data?.ViewAppCuDan === true}
              onChange={() => applyChange("ViewAppCuDan", !data?.ViewAppCuDan)}
            >
              Hiển thị app cư dân
            </Checkbox>
          </Col>
          <Col span={12}>
            <Checkbox
              checked={data?.isDanhGia === true}
              onChange={() => applyChange("isDanhGia", !data?.isDanhGia)}
            >
              Cư dân đánh giá
            </Checkbox>
          </Col>
          <Col span={12}>
            <Checkbox
              checked={data?.isNgayHoanThanh === true}
              onChange={() =>
                applyChange("isNgayHoanThanh", !data?.isNgayHoanThanh)
              }
            >
              Đánh dấu hoàn thành
            </Checkbox>
          </Col>
          <Col span={12}>
            <ColorPicker
              value={data?.Color}
              size="small"
              showText
              onChange={(v) => applyChange("Color", v.toHexString())}
            />
          </Col>
        </Row>
      </>
    );
  };
  return (
    <Drawer
      title={isupdate ? "Sửa trạng thái" : "Thêm trạng thái"}
      width={720}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined /> Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      {renderThongTinChung()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
