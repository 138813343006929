import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Flex,
  Popover,
  Row,
  Select,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import ActiveRoute from "components/ActiveRoute";
import { ToaNhaService } from "services/ToaNhaService";
import { NhanVienService } from "services/NhanVienService";
import { YeuCauService } from "services/YeuCauService";
import { FaSort } from "react-icons/fa6";
import FilterDate from "components/FilterDate";

const FilterModal = ({ ...props }) => {
  const { filterCondition, onChangeFilter, options } = props;
  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "NhanVien":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        break;
      case "MaTN":
        _filter[p] = v;
        localStorage.setItem("MaTN", v);
        break;
      default:
        _filter[p] = v;
        break;
    }
    onChangeFilter(_filter);
  };
  const onChangeDate = (v) => {
    let _filter = _.cloneDeep(filterCondition);
    _filter.TuNgay = v.TuNgay;
    _filter.DenNgay = v.DenNgay;
    onChangeFilter(_filter);
  };
  return (
    <div className="">
      <ActiveRoute />
      {/* <FilterDate onChangeDate={onChangeDate} /> */}
      <div className="box-search">
        {/* <p className="label">Dự án</p> */}
        <Select
          // mode="multiple"
          // defaultValue={localStorage.getItem("MaTN")}
          value={filterCondition.MaTN}
          placeholder="Chọn dự án"
          onChange={(v) => applyChangeFilter("MaTN", v)}
          style={{
            width: "100%",
          }}
          options={options?.ToaNha}
          fieldNames={{ label: "TenTN", value: "MaTN" }}
        />
      </div>
      {/* <div className="box-search">
        <p className="label">Nhân viên</p>
        <Select
          mode="multiple"
          // value={filterCondition.MaTN}
          placeholder="Chọn nhân viên"
          onChange={(v) => applyChangeFilter("NhanVien", v)}
          style={{
            width: "100%",
          }}
          options={options?.NhanVien}
          fieldNames={{ label: "HoTenNV", value: "MaNV" }}
        />
      </div> */}
    </div>
  );
};
export default FilterModal;
