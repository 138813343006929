import React from "react";
import { Format_Time } from "layouts/ultis/common";
import {
  notification,
  Row,
  Col,
  Flex,
  Select,
  Skeleton,
  Input,
  Image,
  Button,
  Tag,
} from "antd";
import { BsGrid1X2 } from "react-icons/bs";
import { GetRandomColor } from "layouts/ultis/common";
const MessageTop = ({ refInbox, members, setIsShowDetail, isShowDetail }) => {
  return (
    <Flex className="content-top" align="center" justify="space-between">
      <Flex gap={10} align="center">
        <Flex
          align="center"
          justify="center"
          style={{
            background: GetRandomColor(refInbox.current?.id),
            width: 30,
            height: 30,
            borderRadius: 50,
            fontWeight: "bold",
          }}
        >
          {refInbox?.current?.name_chat?.charAt(0)}
        </Flex>
        <div>
          <p style={{ fontWeight: "bold" }}>{refInbox?.current?.name_chat}</p>
          <p style={{ color: "rgb(112,117,121)", fontSize: 12 }}>
            {members?.length} thành viên
          </p>
        </div>
      </Flex>
      <BsGrid1X2
        size={20}
        style={{ cursor: "pointer" }}
        onClick={() => setIsShowDetail(!isShowDetail)}
        color={isShowDetail ? "#6ec9cb" : "black"}
      />
    </Flex>
  );
};

export default React.memo(MessageTop);
