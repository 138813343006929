import { Checkbox } from "antd";
import { Format_Currency } from "layouts/ultis/common";

export const columnsTenTienIch = [
  {
    title: "Tên tiện tích",
    ellipsis: true,
    dataIndex: "TenTienIch",
    key: "TenTienIch",
  },
  {
    title: "Tiền đặt cọc",
    ellipsis: true,
    dataIndex: "TienCoc",
    key: "TienCoc",
    render: (item) => (item ? Format_Currency(item) : ""),
  },
  {
    title: "Số lần/tuần",
    ellipsis: true,
    dataIndex: "SoLan1Tuan",
    key: "SoLan1Tuan",
  },
  { title: "Ghi chú", ellipsis: true, dataIndex: "GhiChu", key: "GhiChu" },
  {
    title: "Hiển thị",
    ellipsis: true,
    dataIndex: "isView",
    key: "isView",
    render: (item) => <Checkbox checked={item}> </Checkbox>,
  },
  { title: "Icon", ellipsis: true, dataIndex: "Icon", key: "Icon" },
];
export const columnsKhuTienIch = [
  { title: "Tên bàn", ellipsis: true, dataIndex: "TenBan", key: "TenBan" },
];

export const columnsKhungGioTienIch = [
  { title: "Từ giờ", ellipsis: true, dataIndex: "TuGio", key: "TuGio" },
  { title: "Đến giờ", ellipsis: true, dataIndex: "DenGio", key: "DenGio" },
  {
    title: "Khung giờ",
    ellipsis: true,
    dataIndex: "KhungGio",
    key: "KhungGio",
  },
];
export const columnsTrangThaiTienIch = [
  { title: "Tên trạng thái", ellipsis: true, dataIndex: "TenTT", key: "TenTT" },
];
