import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Flex,
  Image,
  Input,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Table,
  Tooltip,
  notification,
} from "antd";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { CiChat2, CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import { ToaNhaService } from "services/ToaNhaService";
import { MatBangService } from "services/MatBangService";
import { AiOutlineMenuFold } from "react-icons/ai";
import { MSBService } from "services/MSBService";
export default function Index() {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toanha, setToanha] = useState([]);
  const [khoinha, setKhoinha] = useState([]);
  const [filterCondition, setFilterCondition] = useState({
    MaTN: 0,
    PageIndex: 1,
    PageSize: 20,
    InputSearch: "",
  });
  const refDetail = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const loadCategories = async () => {
    let _tn = await ToaNhaService.getToaNhaByNV();
    setToanha(_tn.data);
    let _filter = _.cloneDeep(filterCondition);
    _filter.MaTN = _tn.data[0]?.MaTN;
    setFilterCondition(_filter);
    loadcategoriesByMaTN(_tn.data[0]?.MaTN);
    filter(_filter);
  };
  const loadcategoriesByMaTN = async (matn) => {
    let _tn = await ToaNhaService.Categories.getKhoiNha({
      MaTN: matn,
    });
    setKhoinha(_tn.data);
  };
  useEffect(() => {
    loadCategories();
  }, []);
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await MSBService.GetVirtualAccount(_filter);
    if (_res.data) setData(_res.data);
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () => {
    filter(filterCondition);
  };

  const columns = [
    {
      title: "Mã số MB",
      dataIndex: "MaSoMB",
      // ellipsis: true,
    },
    {
      title: "Tài khoản",
      dataIndex: "AccountNumber",
      // ellipsis: true,
    },
    {
      title: "Tên tài khoản",
      dataIndex: "Name",
      // ellipsis: true,
    },
    {
      title: "Điện thoại",
      dataIndex: "Phone",
      // ellipsis: true,
    },
    {
      title: "Thanh toán 1 lần",
      dataIndex: "PayType",
      // ellipsis: true,
      render: (item) => (item === "0" ? "Không" : "Có"),
    },
    {
      title: "Ngày tạo",
      dataIndex: "NgayTao",
      // ellipsis: true,
      render: (item) => (item ? Format_Date(item) : null),
    },
    {
      title: "",
      fixed: "right",
      width: "50px",
      render: (item) => {
        return (
          <div>
            {/* <Tooltip placement="topRight" title="Nhắn tin">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<CiChat2 />}
                onClick={() => createQR(item)}
              />
            </Tooltip> */}
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item)}
              />
            </Tooltip>
            <Popconfirm
              title="Xoá tài khoản"
              description="Bạn có chắc chắn muốn xoá tài khoản?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.ID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  // const createQR = async (item) => {
  //   let _login = await MSBService.Login();
  //   let _qr = {
  //     Token: JSON.parse(_login.data)?.access_token,
  //     ServiceCode: "ADL",
  //     Rows: [
  //       {
  //         AccountNumber: item.AccountNumber,
  //         Amount: "100000",
  //         Remark: "123",
  //       },
  //     ],
  //   };
  //   let _res = await MSBService.CreateQR(_qr);
  // };
  const remove = async (value) => {
    let _res = await MSBService.DeleteVirtualAccount({ ID: Number(value) });
    if (_res.status === 2000) {
      openNotification("success", "topRight", "Xoá thành công");
      filter(filterCondition);
    } else {
      openNotification("error", "topRight", "Xoá thất bại");
    }
  };
  const onHandleHide = () => {
    setCollapsed(!collapsed);
  };
  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        if (filterCondition.MaTN) filter(filterCondition);
      }, 500);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  const onChangePage = (page, size) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.PageIndex = page;
    _ft.PageSize = size;
    setFilterCondition(_ft);
    filter(_ft);
  };
  return (
    <Row gutter={15}>
      <Col span={collapsed ? 0 : 4} style={{ transition: "all 0.3s ease" }}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
          options={{
            ToaNha: toanha,
            KhoiNha: khoinha,
          }}
        />
      </Col>
      <Col span={collapsed ? 24 : 20}>
        <Toolbar
          left={() => (
            <Flex gap={5} align="center">
              <AiOutlineMenuFold
                size={20}
                color="primary"
                onClick={() => onHandleHide()}
              />
              <Input
                style={{
                  width: "300px",
                  padding: "4px 11px",
                  borderRadius: 10,
                }}
                placeholder="Tìm mã số mặt bằng, tài khoản"
                onChange={(e) => onSearch(e.target.value)}
              />
            </Flex>
          )}
          // right={() => (
          //   <Flex gap={10}>
          //     <Button
          //       loading={loading}
          //       type="primary"
          //       onClick={() => refDetail.current.show()}
          //     >
          //       Thêm
          //     </Button>
          //   </Flex>
          // )}
        />
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 250,
          }}
          bordered
          pagination={false}
          // pagination={{
          //   showSizeChanger: false,
          //   defaultPageSize: 20,
          //   pageSizeOptions: ["20", "50", "500", "1000", "10000"],
          //   position: ["bottomRight"],
          //   total: data.length > 0 && data[0].TotalRows,
          //   onChange: (v) => console.log(v),
          //   onShowSizeChange: (v) => console.log(v),
          // }}
          virtual={true}
          size="small"
          summary={(pageData) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={2}>
                    <p style={{ fontWeight: "bold" }}>
                      Tổng: {pageData?.length}
                    </p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
        <div style={{ textAlign: "right", paddingTop: "10px" }}>
          <Pagination
            current={filterCondition?.PageIndex}
            onChange={onChangePage}
            total={data.length > 0 && data[0]?.TotalRows}
            showTotal={(total) => `Tổng: ${total}`}
            pageSizeOptions={[10, 20, 50, 100, 1000]}
          />
        </div>
      </Col>
      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
      {contextHolder}
    </Row>
  );
}
