import { Format_Currency } from "layouts/ultis/common";
import {
  columnsQuanHeCuDan,
  columnsTrangThaiCuDan,
} from "./CuDan/ColumnsCuDan";
import {
  columnsKhungGioTienIch,
  columnsKhuTienIch,
  columnsTenTienIch,
  columnsTrangThaiTienIch,
} from "./TienIch/ColumnsTienIch";
import {
  columnsHinhThucYeuCau,
  columnsLoaiYeuCau,
  columnsMucDoYeuCau,
  columnsTrangThaiYeuCau,
} from "./YeuCau/ColumnsYeuCau";

const {
  EditTwoTone,
  DeleteOutlined,
  PlusOutlined,
} = require("@ant-design/icons");
const { Button, Tooltip, Popconfirm, ColorPicker, Checkbox } = require("antd");
const { Format_Date } = require("layouts/ultis/common");

const getColumns = (activeTab, secondaryOption, refs, remove, data) => {
  const {
    refTrangThaiCuDan,
    refQuanHeCuDan,
    refTenTienIch,
    refTrangThaiTienIch,
    refKhuTienIch,
    refKhungGioTienIch,
    refTrangThaiYC,
    refLoaiiYC,
    refMucDoYC,
    refHinhThucYC,
  } = refs;

  switch (activeTab) {
    case 1:
      switch (secondaryOption) {
        case 1:
          return [
            ...columnsTrangThaiCuDan,
            {
              title: (
                <div style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    onClick={() => refTrangThaiCuDan.current.show()}
                  >
                    <PlusOutlined
                      style={{ fontSize: "13px", margin: "0 -4px" }}
                    />{" "}
                    Thêm
                  </Button>
                </div>
              ),
              fixed: "right",
              width: "100px",
              render: (item) => (
                <div style={{ display: "flex" }}>
                  <Tooltip placement="topRight" title="Sửa">
                    <Button
                      style={{ marginLeft: 10 }}
                      type="primary"
                      ghost
                      icon={<EditTwoTone />}
                      onClick={() => refTrangThaiCuDan.current.update(item)}
                    />
                  </Tooltip>
                  <Popconfirm
                    title="Xoá danh mục"
                    description="Bạn có chắc chắn muốn xoá danh mục?"
                    okText="Đồng ý"
                    cancelText="Không"
                    onConfirm={() => remove(item.ID)}
                  >
                    <Tooltip placement="topRight" title="Xoá">
                      <Button
                        style={{ marginLeft: 10 }}
                        danger
                        ghost
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </div>
              ),
            },
          ];
        case 2:
          return [
            ...columnsQuanHeCuDan,
            {
              title: (
                <div style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    onClick={() => refQuanHeCuDan.current.show()}
                  >
                    <PlusOutlined
                      style={{ fontSize: "13px", margin: "0 -4px" }}
                    />{" "}
                    Thêm
                  </Button>
                </div>
              ),
              fixed: "right",
              width: "100px",
              render: (item) => (
                <div style={{ display: "flex" }}>
                  <Tooltip placement="topRight" title="Sửa">
                    <Button
                      style={{ marginLeft: 10 }}
                      type="primary"
                      ghost
                      icon={<EditTwoTone />}
                      onClick={() => refQuanHeCuDan.current.update(item)}
                    />
                  </Tooltip>
                  <Popconfirm
                    title="Xoá danh mục"
                    description="Bạn có chắc chắn muốn xoá danh mục?"
                    okText="Đồng ý"
                    cancelText="Không"
                    onConfirm={() => remove(item.ID)}
                  >
                    <Tooltip placement="topRight" title="Xoá">
                      <Button
                        style={{ marginLeft: 10 }}
                        danger
                        ghost
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </div>
              ),
            },
          ];
        default:
          break;
      }
      break;
    case 2:
      switch (secondaryOption) {
        case 1:
          return [
            ...columnsTenTienIch,
            {
              title: (
                <div style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    onClick={() => refTenTienIch.current.show()}
                  >
                    <PlusOutlined
                      style={{ fontSize: "13px", margin: "0 -4px" }}
                    />{" "}
                    Thêm
                  </Button>{" "}
                </div>
              ),
              fixed: "right",
              width: "100px",
              render: (item) => (
                <div style={{ display: "flex" }}>
                  <Tooltip placement="topRight" title="Sửa">
                    <Button
                      style={{ marginLeft: 10 }}
                      type="primary"
                      ghost
                      icon={<EditTwoTone />}
                      onClick={() => refTenTienIch.current.update(item)}
                    />
                  </Tooltip>
                  <Popconfirm
                    title="Xoá tiên tiện ích"
                    description="Bạn có chắc chắn muốn xoá tên tiện ích?"
                    okText="Đồng ý"
                    cancelText="Không"
                    onConfirm={() => remove(item.ID)}
                  >
                    <Tooltip placement="topRight" title="Xoá">
                      <Button
                        style={{ marginLeft: 10 }}
                        danger
                        ghost
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </div>
              ),
            },
          ];
        case 2:
          return [
            ...columnsKhuTienIch,
            {
              title: (
                <div style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    onClick={() => refKhuTienIch.current.show()}
                  >
                    <PlusOutlined
                      style={{ fontSize: "13px", margin: "0 -4px" }}
                    />{" "}
                    Thêm
                  </Button>{" "}
                </div>
              ),
              fixed: "right",
              width: "100px",
              render: (item) => (
                <div style={{ display: "flex" }}>
                  <Tooltip placement="topRight" title="Sửa">
                    <Button
                      style={{ marginLeft: 10 }}
                      type="primary"
                      ghost
                      icon={<EditTwoTone />}
                      onClick={() => refKhuTienIch.current.update(item)}
                    />
                  </Tooltip>
                  <Popconfirm
                    title="Xoá danh mục"
                    description="Bạn có chắc chắn muốn xoá danh mục?"
                    okText="Đồng ý"
                    cancelText="Không"
                    onConfirm={() => remove(item.ID)}
                  >
                    <Tooltip placement="topRight" title="Xoá">
                      <Button
                        style={{ marginLeft: 10 }}
                        danger
                        ghost
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </div>
              ),
            },
          ];
        case 3:
          return [
            ...columnsKhungGioTienIch,
            {
              title: (
                <div style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    onClick={() => refKhungGioTienIch.current.show()}
                  >
                    <PlusOutlined
                      style={{ fontSize: "13px", margin: "0 -4px" }}
                    />{" "}
                    Thêm
                  </Button>{" "}
                </div>
              ),
              fixed: "right",
              width: "100px",
              render: (item) => (
                <div style={{ display: "flex" }}>
                  <Tooltip placement="topRight" title="Sửa">
                    <Button
                      style={{ marginLeft: 10 }}
                      type="primary"
                      ghost
                      icon={<EditTwoTone />}
                      onClick={() => refKhungGioTienIch.current.update(item)}
                    />
                  </Tooltip>
                  <Popconfirm
                    title="Xoá danh mục"
                    description="Bạn có chắc chắn muốn xoá danh mục?"
                    okText="Đồng ý"
                    cancelText="Không"
                    onConfirm={() => remove(item.ID)}
                  >
                    <Tooltip placement="topRight" title="Xoá">
                      <Button
                        style={{ marginLeft: 10 }}
                        danger
                        ghost
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </div>
              ),
            },
          ];
        case 4:
          return [
            ...columnsTrangThaiTienIch,
            // {
            //   title: (
            //     <div style={{ textAlign: "center" }}>
            //       <Button
            //         type="primary"
            //         onClick={() => refTrangThaiTienIch.current.show()}
            //       >
            //        <PlusOutlined style={{ fontSize: "13px", margin: "0 -4px" }} /> Thêm
            //       </Button>{" "}
            //     </div>
            //   ),
            //   fixed: "right",
            //   width: "100px",
            //   render: (item) => (
            //       <div style={{ display: "flex" }}>
            //       <Tooltip placement="topRight" title="Sửa">
            //         <Button
            //           style={{ marginLeft: 10 }}
            //           type="primary"
            //           ghost
            //           icon={<EditTwoTone />}
            //           onClick={() => refTrangThaiTienIch.current.update(item)}
            //         />
            //       </Tooltip>
            //       <Popconfirm
            //         title="Xoá danh mục"
            //         description="Bạn có chắc chắn muốn xoá danh mục?"
            //         okText="Đồng ý"
            //         cancelText="Không"
            //         onConfirm={() => remove(item.ID)}
            //       >
            //         <Tooltip placement="topRight" title="Xoá">
            //           <Button
            //             style={{ marginLeft: 10 }}
            //             danger
            //             ghost
            //             icon={<DeleteOutlined />}
            //           />
            //         </Tooltip>
            //       </Popconfirm>
            //     </div>
            //   ),
            // },
          ];
        default:
          break;
      }
      break;
    case 3:
      switch (secondaryOption) {
        case 1:
          return [
            ...columnsTrangThaiYeuCau,
            {
              title: (
                <div style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    onClick={() => refTrangThaiYC.current.show()}
                  >
                    <PlusOutlined
                      style={{ fontSize: "13px", margin: "0 -4px" }}
                    />{" "}
                    Thêm
                  </Button>{" "}
                </div>
              ),
              fixed: "right",
              width: "100px",
              render: (item) => (
                <div style={{ display: "flex" }}>
                  <Tooltip placement="topRight" title="Sửa">
                    <Button
                      style={{ marginLeft: 10 }}
                      type="primary"
                      ghost
                      icon={<EditTwoTone />}
                      onClick={() => refTrangThaiYC.current.update(item)}
                    />
                  </Tooltip>
                  <Popconfirm
                    title="Xoá danh mục"
                    description="Bạn có chắc chắn muốn xoá danh mục?"
                    okText="Đồng ý"
                    cancelText="Không"
                    onConfirm={() => remove(item.MaTT)}
                  >
                    <Tooltip placement="topRight" title="Xoá">
                      <Button
                        style={{ marginLeft: 10 }}
                        danger
                        ghost
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </div>
              ),
            },
          ];
        case 2:
          return [
            ...columnsMucDoYeuCau,
            {
              title: (
                <div style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    onClick={() => refMucDoYC.current.show()}
                  >
                    <PlusOutlined
                      style={{ fontSize: "13px", margin: "0 -4px" }}
                    />{" "}
                    Thêm
                  </Button>{" "}
                </div>
              ),
              fixed: "right",
              width: "100px",
              render: (item) => (
                <div style={{ display: "flex" }}>
                  <Tooltip placement="topRight" title="Sửa">
                    <Button
                      style={{ marginLeft: 10 }}
                      type="primary"
                      ghost
                      icon={<EditTwoTone />}
                      onClick={() => refMucDoYC.current.update(item)}
                    />
                  </Tooltip>
                  <Popconfirm
                    title="Xoá danh mục"
                    description="Bạn có chắc chắn muốn xoá danh mục?"
                    okText="Đồng ý"
                    cancelText="Không"
                    onConfirm={() => remove(item.MaDoUuTien)}
                  >
                    <Tooltip placement="topRight" title="Xoá">
                      <Button
                        style={{ marginLeft: 10 }}
                        danger
                        ghost
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </div>
              ),
            },
          ];
        case 3:
          return [
            ...columnsHinhThucYeuCau,
            {
              title: (
                <div style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    onClick={() => refHinhThucYC.current.show()}
                  >
                    <PlusOutlined
                      style={{ fontSize: "13px", margin: "0 -4px" }}
                    />{" "}
                    Thêm
                  </Button>{" "}
                </div>
              ),
              fixed: "right",
              width: "100px",
              render: (item) => (
                <div style={{ display: "flex" }}>
                  <Tooltip placement="topRight" title="Sửa">
                    <Button
                      style={{ marginLeft: 10 }}
                      type="primary"
                      ghost
                      icon={<EditTwoTone />}
                      onClick={() => refHinhThucYC.current.update(item)}
                    />
                  </Tooltip>
                  <Popconfirm
                    title="Xoá danh mục"
                    description="Bạn có chắc chắn muốn xoá danh mục?"
                    okText="Đồng ý"
                    cancelText="Không"
                    onConfirm={() => remove(item.ID)}
                  >
                    <Tooltip placement="topRight" title="Xoá">
                      <Button
                        style={{ marginLeft: 10 }}
                        danger
                        ghost
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </div>
              ),
            },
          ];
        case 4:
          return [
            ...columnsLoaiYeuCau,
            {
              title: (
                <div style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    onClick={() => refLoaiiYC.current.show()}
                  >
                    <PlusOutlined
                      style={{ fontSize: "13px", margin: "0 -4px" }}
                    />{" "}
                    Thêm
                  </Button>{" "}
                </div>
              ),
              fixed: "right",
              width: "100px",
              render: (item) => (
                <div style={{ display: "flex" }}>
                  <Tooltip placement="topRight" title="Sửa">
                    <Button
                      style={{ marginLeft: 10 }}
                      type="primary"
                      ghost
                      icon={<EditTwoTone />}
                      onClick={() => refLoaiiYC.current.update(item)}
                    />
                  </Tooltip>
                  <Popconfirm
                    title="Xoá danh mục"
                    description="Bạn có chắc chắn muốn xoá danh mục?"
                    okText="Đồng ý"
                    cancelText="Không"
                    onConfirm={() => remove(item.MaLYC)}
                  >
                    <Tooltip placement="topRight" title="Xoá">
                      <Button
                        style={{ marginLeft: 10 }}
                        danger
                        ghost
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </div>
              ),
            },
          ];
        default:
          break;
      }
      break;
    default:
      break;
  }
};
export default getColumns;
