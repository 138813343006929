import React, { useEffect, useRef, useState } from "react";
import { HomeService } from "services/HomeService";
import {
  Button,
  Popconfirm,
  Table,
  Tooltip,
  notification,
  Dropdown,
  Space,
  Row,
  Col,
  DatePicker,
  Select,
  Flex,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import { BanHangService } from "services/BanHangService";
import Print from "./Print";
import { ReportService } from "services/ReportService";
import { NhanVienService } from "services/NhanVienService";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const { RangePicker } = DatePicker;

export default function Settings() {
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [loading, setLoading] = useState(false);
  const componentRef = useRef();
  const [quay, setQuay] = useState([]);
  const [nhanvien, setNhanvien] = useState([]);
  const [loaiHD, setLoaiHD] = useState([]);
  const [filterCondition, setFilterCondition] = useState({
    Horizontal: false,
    TuNgay: new Date(),
    DenNgay: new Date(),
    MaNhom: 2,
    QuayBan: ",,",
    NhanVien: ",,",
    LoaiHD: ",,",
  });
  useEffect(() => {
    filter(filterCondition);
    BanHangService.getQuayBan().then((res) => setQuay(res.data ?? []));
    BanHangService.Categories.getLoaiHD().then((res) =>
      setLoaiHD(res.data ?? [])
    );
    NhanVienService.getNhanVien().then((res) => setNhanvien(res.data ?? []));
  }, []);
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await ReportService.getBanHangTheoNgay(_filter);
    if (_res.data) setData(_res.data);
    let _res2 = await ReportService.getBanHangTheoSP(_filter);
    if (_res2.data) setData2(_res2.data);
    setLoading(false);
  };

  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "QuayBan":
      case "LoaiHD":
      case "NhanVien":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        break;
      case "TuNgay":
        _filter[p] = new Date(v);
        break;
      case "DenNgay":
        _filter[p] = new Date(v);
        break;
      default:
        _filter[p] = v;
        break;
    }
    setFilterCondition(_filter);
    filter(_filter);
    filter(_filter);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const reportTemplateRef = useRef(null);

  const handleGeneratePdf = () => {
    const input = componentRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      // const pdf = new jsPDF({
      //   orientation: "portrait",
      //   unit: "px",
      //   format: "letter", userUnit: "px"
      // });
      const pdf = new jsPDF('p','mm','a4',true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth/imgWidth,pdfHeight/imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;
      // const height = (canvas.height * width) / canvas.width;
      pdf.addImage(imgData, "PNG", imgX, imgY, imgWidth * ratio, imgHeight * ratio);
      pdf.save("download.pdf");

    
    });
  };
  return (
    <Row gutter={15}>
      <Col span={24}>
        <div className="box-search" style={{ padding: "8px 60px" }}>
          <Row justify="space-between" align="middle">
            <Col span={16}>
              <Flex gap={20}>
                <div style={{ width: 100 }}>
                  <p className="label">Mẫu A4</p>
                  <Select
                    placeholder="Chọn quầy bán"
                    onChange={(v) => applyChangeFilter("Horizontal", v)}
                    style={{
                      width: "100%",
                    }}
                    options={[
                      { ID: false, Name: "Dọc" },
                      { ID: true, Name: "Ngang" },
                    ]}
                    value={filterCondition?.Horizontal}
                    fieldNames={{ label: "Name", value: "ID" }}
                  />
                </div>
                <div>
                  <p className="label">Từ ngày</p>
                  <DatePicker
                    format="DD-MM-YYYY"
                    style={{ width: "100%" }}
                    defaultValue={dayjs(filterCondition.TuNgay)}
                    placeholder="Từ ngày"
                    onChange={(v) => applyChangeFilter("TuNgay", v)}
                    // locale={locale}
                  />
                  {/* <p className="label">Thời gian</p>
                  <RangePicker
                    format="DD-MM-YYYY"
                    style={{ width: "100%" }}
                    defaultValue={[
                      dayjs(filterCondition.TuNgay),
                      dayjs(filterCondition.DenNgay),
                    ]}
                    placeholder={["Từ ngày", "Đến ngày"]}
                    onChange={(v) => applyChangeFilter("ThoiGian", v)}
                  /> */}
                </div>
                <div>
                  <p className="label">Đến ngày</p>
                  <DatePicker
                    format="DD-MM-YYYY"
                    style={{ width: "100%" }}
                    defaultValue={dayjs(filterCondition.DenNgay)}
                    placeholder="Đến ngày "
                    // locale={locale}
                    onChange={(v) => applyChangeFilter("DenNgay", v)}
                  />
                </div>
                <div style={{ width: 150 }}>
                  <p className="label">Nhân viên</p>
                  <Select
                    mode="multiple"
                    placeholder="Chọn nhân viên"
                    onChange={(v) => applyChangeFilter("NhanVien", v)}
                    style={{
                      width: "100%",
                    }}
                    options={nhanvien}
                    fieldNames={{ label: "HoTen", value: "MaNV" }}
                  />
                </div>
                <div style={{ width: 150 }}>
                  <p className="label">Quầy bán</p>
                  <Select
                    mode="multiple"
                    placeholder="Chọn quầy bán"
                    onChange={(v) => applyChangeFilter("QuayBan", v)}
                    style={{
                      width: "100%",
                    }}
                    options={quay}
                    fieldNames={{ label: "TenQuay", value: "ID" }}
                  />
                </div>
                <div style={{ width: 150 }}>
                  <p className="label">Loại HD</p>
                  <Select
                    mode="multiple"
                    allowClear
                    placeholder="Chọn loại HD"
                    onChange={(v) => applyChangeFilter("LoaiHD", v)}
                    style={{
                      width: "100%",
                    }}
                    options={loaiHD}
                    fieldNames={{ label: "TenLHD", value: "MaLHD" }}
                  />
                </div>
              </Flex>
            </Col>
            <Col
              span={8}
              style={{ justifyContent: "flex-end", textAlign: "right" }}
            >
              <Button type="primary" onClick={() => handlePrint()}>
                In
              </Button>
              <Button
                danger
                type="primary"
                style={{ marginLeft: 10 }}
                onClick={() => handleGeneratePdf()}
              >
                Xuất PDF
              </Button>
            </Col>
          </Row>
        </div>
      </Col>
      <Col span={24}>
        <Print
          filter={filterCondition}
          ref={componentRef}
          data={data}
          data2={data2}
        />
      </Col>
    </Row>
  );
}
