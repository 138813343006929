import axiosApi from "./api_helper";
export const MSBService = {
  Login: async () => {
    return await axiosApi()
      .get("/api/msb/login")
      .then((res) => res.data);
  },
  CreateAccount: async (payload) => {
    return await axiosApi()
      .post("/api/msb/create-account", payload)
      .then((res) => res.data);
  },
  UpdateAccount: async (payload) => {
    return await axiosApi()
      .post("/api/msb/update-account", payload)
      .then((res) => res.data);
  },
  CreateQR: async (payload) => {
    return await axiosApi()
      .post("/api/msb/create-qr", payload)
      .then((res) => res.data);
  },
  GetVirtualAccount: async (payload) => {
    return await axiosApi()
      .post("/api/msb/get-virtual-account", payload)
      .then((res) => res.data);
  },
  DeleteVirtualAccount: async (payload) => {
    return await axiosApi()
      .post("/api/msb/delete-virtual-account", payload)
      .then((res) => res.data);
  },
};
