import axiosApi from "./api_helper";
export const ReportService = {
  getBanHangTheoNgay: async (payload) => {
    return await axiosApi()
      .post("api/report/ban-hang", payload)
      .then((res) => res.data);
  },
  getBanHangTheoSP: async (payload) => {
    return await axiosApi()
      .post("api/report/ban-hang-by-sanpham", payload)
      .then((res) => res.data);
  },
  getVeBanTheoThang: async (payload) => {
    return await axiosApi()
      .post("api/report/ve-ban-theo-thang", payload)
      .then((res) => res.data);
  },
  getVeBanTheoNgay: async (payload) => {
    return await axiosApi()
      .post("api/report/ve-ban-theo-ngay", payload)
      .then((res) => res.data);
  },
  getLichSuQuetTheError: async (payload) => {
    return await axiosApi()
      .post("api/report/lich-su-quet-the-error", payload)
      .then((res) => res.data);
  },
  getLichSuQuetThe: async (payload) => {
    return await axiosApi()
      .post("api/report/lich-su-quet-the", payload)
      .then((res) => res.data);
  },
  getLichSuQuetTheDetail: async (payload) => {
    return await axiosApi()
      .post("api/report/lsquet-detail", payload)
      .then((res) => res.data);
  },
};
