import React from "react";
import { Format_Time } from "layouts/ultis/common";
import {
  notification,
  Row,
  Col,
  Flex,
  Select,
  Skeleton,
  Input,
  Image,
  Button,
  Tag,
} from "antd";
import { FaFilePdf, FaFileWord } from "react-icons/fa6";
import { RiFileExcel2Fill } from "react-icons/ri";
import { GetRandomColor } from "layouts/ultis/common";
const getItemFile = (message) => {
  return (
    <Image.PreviewGroup>
      {JSON.parse(message)?.map((url, index) => {
        // let type = GetImageFile(url);
        return GetImageFile(url);
        return (
          <Image
            key={index}
            style={{ paddingRight: 10 }}
            width={200}
            src={url}
          />
        );
      })}
    </Image.PreviewGroup>
  );
};
 const GetImageFile = (url) => {
   let index = url.lastIndexOf(".");
   let type = url.slice(index);
   let startindex = url.lastIndexOf("/");
   let endindex = url.indexOf(type);
   let name = url.slice(startindex + 1, endindex);
   switch (type) {
     case ".pdf":
       return (
         <Flex align="center" gap={5}>
           <a href={url} target="_blank">
             <FaFilePdf size={35} />
           </a>
           <p style={{ fontSize: 12, fontWeight: "bold" }}>
             {name}
             {type}
           </p>
         </Flex>
       );
     case ".doc":
     case ".docx":
       return (
         <Flex align="center" gap={5}>
           <a href={url} target="_blank">
             <FaFileWord size={35} />
           </a>
           <p style={{ fontSize: 12, fontWeight: "bold" }}>
             {name}
             {type}
           </p>
         </Flex>
       );
     case ".xls":
     case ".xlsx":
       return <Flex align="center" gap={5}>
          <a href={url} target="_blank">
            <RiFileExcel2Fill size={35} />
          </a>
          <p style={{ fontSize: 12, fontWeight: "bold" }}>
            {name}
            {type}
          </p>
        </Flex>;
     default:
       return (
         <Image
           key={index}
           style={{ paddingRight: 10 }}
           width={200}
           src={url}
         />
       );
   }
 };
const MessageItem = ({ message,messagepre }) => {
  return (
    <Flex
      key={message.id}
      align="center"
      gap={10}
      justify={
        message?.user_id ===
        Number(JSON.parse(localStorage.getItem("NhanVien"))?.maNV)
          ? "end"
          : "start"
      }
    >
      {messagepre?.user_id !== message.user_id &&
      message?.user_id !==
        Number(JSON.parse(localStorage.getItem("NhanVien"))?.maNV) ? (
        <div
          className="avatar-mess"
          style={{
            background: GetRandomColor(message.user_id),
          }}
        >
          {message?.user_name?.charAt(0)}
        </div>
      ) : (
        <div style={{ width: "35px" }}></div>
      )}

      {message?.is_image ? (
        <div style={{ marginBottom: 6 }}>
          {messagepre?.user_id !== message.user_id && (
            <p
              style={{
                fontWeight: "bold",
                fontSize: 11,
                color: "#7bc862",
              }}
            >
              {message.user_name}
            </p>
          )}
          {getItemFile(message.message)}
          {/* <Image.PreviewGroup>
            {JSON.parse(message.message)?.map((url, index) => {
              return (
                <Image
                  key={index}
                  style={{ paddingRight: 10 }}
                  width={200}
                  src={url}
                />
              );
            })}
          </Image.PreviewGroup> */}
        </div>
      ) : (
        <div
          className={
            message?.user_id ===
            Number(JSON.parse(localStorage.getItem("NhanVien"))?.maNV)
              ? "message-item-right"
              : "message-item"
          }
        >
          {messagepre?.user_id !== message.user_id && (
            <p
              style={{
                fontWeight: "bold",
                fontSize: 11,
                color: "#7bc862",
              }}
            >
              {message.user_name}
            </p>
          )}
          <p>
            {message?.message}
            <span className="time">{Format_Time(message.create_at)}</span>
          </p>
        </div>
      )}
    </Flex>
  );
};

export default React.memo(MessageItem);
