import axiosApi from "./api_helper";
export const BanHangService = {
  Categories: {
    getLoaiHD: async () => {
      return await axiosApi()
        .post("api/banhang/get-loaihd")
        .then((res) => res.data);
    },
    getKhuVuc: async () => {
      return await axiosApi()
        .post("api/banhang/khu-vuc")
        .then((res) => res.data);
    },
    addKhuVuc: async (payload) => {
      return await axiosApi()
        .post("api/banhang/add-khu-vuc", payload)
        .then((res) => res.data);
    },
    deleteKhuVuc: async (payload) => {
      return await axiosApi()
        .post("api/banhang/delete-khu-vuc", payload)
        .then((res) => res.data);
    },
    getBan: async (payload) => {
      return await axiosApi()
        .post("api/banhang/ban", payload)
        .then((res) => res.data);
    },
    addBan: async (payload) => {
      return await axiosApi()
        .post("api/banhang/add-ban", payload)
        .then((res) => res.data);
    },
    deleteBan: async (payload) => {
      return await axiosApi()
        .post("api/banhang/delete-ban", payload)
        .then((res) => res.data);
    },
  },
  getList: async (payload) => {
    return await axiosApi()
      .post("api/banhang/get-list", payload)
      .then((res) => res.data);
  },
  getDonHangTheoCa: async (payload) => {
    return await axiosApi()
      .post("api/banhang/get-donhang-theoca", payload)
      .then((res) => res.data);
  },
 
  addBanHang: async (payload) => {
    return await axiosApi()
      .post("api/banhang/add", payload)
      .then((res) => res.data);
  },
  addBanHangThe: async (payload) => {
    return await axiosApi()
      .post("api/banhang/add-the", payload)
      .then((res) => res.data);
  },
  reportDoanhThuTheoNgay: async (payload) => {
    return await axiosApi()
      .post("api/report/doanhthu-theongay", payload)
      .then((res) => res.data);
  },
  reportTopSPbyDoanhThu: async (payload) => {
    return await axiosApi()
      .post("api/report/topsp-bydoanhthu", payload)
      .then((res) => res.data);
  },
  reportTopSPbySoLuong: async (payload) => {
    return await axiosApi()
      .post("api/report/topsp-bysoluong", payload)
      .then((res) => res.data);
  },
  changeState: async (payload) => {
    return await axiosApi()
      .post("api/banhang/change-state", payload)
      .then((res) => res.data);
  },
  getBHSanPHam: async (payload) => {
    return await axiosApi()
      .post("api/banhang/detail-sanpham", payload)
      .then((res) => res.data);
  },
  getBHThe: async (payload) => {
    return await axiosApi()
      .post("api/banhang/list-the", payload)
      .then((res) => res.data);
  },

  checkCaLamViec: async () => {
    return await axiosApi()
      .post("api/banhang/check-calamviec")
      .then((res) => res.data);
  },
  addCaLamViec: async (payload) => {
    return await axiosApi()
      .post("api/banhang/add-vaoca", payload)
      .then((res) => res.data);
  },
  addKetThucCa: async (payload) => {
    return await axiosApi()
      .post("api/banhang/add-ketthucca", payload)
      .then((res) => res.data);
  },
  addKetThucCa: async (payload) => {
    return await axiosApi()
      .post("api/banhang/add-ketthucca", payload)
      .then((res) => res.data);
  },
  getListCaLamViec: async (payload) => {
    return await axiosApi()
      .post("api/banhang/filter-calamviec", payload)
      .then((res) => res.data);
  },

  //danh muc
  getQuayBan: async () => {
    return await axiosApi()
      .post("api/banhang/quay-ban")
      .then((res) => res.data);
  },
  addQuayBan: async (payload) => {
    return await axiosApi()
      .post("api/banhang/add-quay-ban", payload)
      .then((res) => res.data);
  },
  deleteQuayBan: async (payload) => {
    return await axiosApi()
      .post("api/banhang/delete-quay-ban", payload)
      .then((res) => res.data);
  },
  BanTheoBan: {
    check: async (payload) => {
      return await axiosApi()
        .post("api/banhang/check-theoban", payload)
        .then((res) => res.data);
    },
    add: async (payload) => {
      return await axiosApi()
        .post("api/banhang/add-theoban", payload)
        .then((res) => res.data);
    },
    update: async (payload) => {
      return await axiosApi()
        .post("api/banhang/update-theoban", payload)
        .then((res) => res.data);
    },
    pay: async (payload) => {
      return await axiosApi()
        .post("api/banhang/pay-theoban", payload)
        .then((res) => res.data);
    },
  },
  BanVeKinhDoanh: {
    getList: async (payload) => {
      return await axiosApi()
        .post("api/banhang/get-list-kinhdoanh", payload)
        .then((res) => res.data);
    },
    addBanHang: async (payload) => {
      return await axiosApi()
        .post("api/banhang/add-ve-kinhdoanh", payload)
        .then((res) => res.data);
    },
    updateBanHang: async (payload) => {
      return await axiosApi()
        .post("api/banhang/update-bhkinhdoanh", payload)
        .then((res) => res.data);
    },
    deleteSPBanHang: async (payload) => {
      return await axiosApi()
        .post("api/banhang/delete-sanpham", payload)
        .then((res) => res.data);
    },
  },
};
