import axiosApi from "./api_helper";
export const SoQuyService = {
  PhieuThu: {
    getChiTietPhieuThu: async (payload) => {
      return await axiosApi()
        .post("api/phieuthu/get-detail-bymapt", payload)
        .then((res) => res.data);
    },
    getLoaiPhieuThu: async () => {
      return await axiosApi()
        .post("api/phieuthu/get-loaipt")
        .then((res) => res.data);
    },
    getPhieuThu: async (payload) => {
      return await axiosApi()
        .post("api/phieuthu/get-list", payload)
        .then((res) => res.data);
    },
    getByID: async (payload) => {
      return await axiosApi()
        .post("api/phieuthu/get-byid", payload)
        .then((res) => res.data);
    },
    addPhieuThu: async (payload) => {
      return await axiosApi()
        .post("api/phieuthu/add", payload)
        .then((res) => res.data);
    },
    updatePhieuThu: async (payload) => {
      return await axiosApi()
        .post("api/phieuthu/update", payload)
        .then((res) => res.data);
    },
    deletePhieuThu: async (payload) => {
      return await axiosApi()
        .post("api/phieuthu/delete", payload)
        .then((res) => res.data);
    },
  },
  PhieuThuChiTiet: {
    getPhieuThuChiTiet: async (payload) => {
      return await axiosApi()
        .post("api/phieuthu/get-list-detail", payload)
        .then((res) => res.data);
    },
  },
  PhieuChi: {
    getPhieuChi: async (payload) => {
      return await axiosApi()
        .post("api/phieuchi/get-list", payload)
        .then((res) => res.data);
    },

    addPhieuChi: async (payload) => {
      return await axiosApi()
        .post("api/phieuchi/add", payload)
        .then((res) => res.data);
    },
    updatePhieuChi: async (payload) => {
      return await axiosApi()
        .post("api/phieuchi/update", payload)
        .then((res) => res.data);
    },
    deletePhieuChi: async (payload) => {
      return await axiosApi()
        .post("api/phieuchi/delete", payload)
        .then((res) => res.data);
    },
  },
};
