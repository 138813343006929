import { ColorPicker } from "antd";

const colorIntToHex = (colorInt) => {
  if (colorInt === undefined || colorInt === null) {
    return "red";
  }
  // Rest of your function logic
  if (colorInt < 0) {
    colorInt = 0xffffffff + colorInt + 1;
  }
  let hex = colorInt.toString(16).toUpperCase();
  hex = hex.padStart(6, "0").slice(-6);
  return `#${hex}`;
};
export const columnsTrangThaiCuDan = [
  { title: "Tên trạng thái", ellipsis: true, dataIndex: "TenTrangThai", key: "TenTrangThai" },

  {
    title: "Màu nền",
    dataIndex: "MauNen",
    ellipsis: true,
    key: "MauNen",
    render: (text, record) => {
      const colorHex = colorIntToHex(record.MauNen);
      return <ColorPicker value={colorHex} disabled />;
    },
  },
  { title: "MaCTDK", ellipsis: true, dataIndex: "MaCTDK", key: "MaCTDK" },
];

export const columnsQuanHeCuDan = [
  { title: "Tên quan hệ", ellipsis: true, dataIndex: "Name", key: "Name" },
];
