import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Button, Space, Drawer, notification, Modal, Col, Row } from "antd";
import _ from "lodash";
import { DateTime } from "luxon";
import { DichVuService } from "services/DichVuService";
import TextArea from "antd/es/input/TextArea";
import InputCustom from "components/Forms/InputCustom";
import { CongNoService } from "services/CongNoService";
import InputNumberCustom from "components/Forms/InputNumberCustom";

const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");
  const defaultData = {};
  const [data, setData] = useState(defaultData);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [table, setTable] = useState("1");
  const defaultErrors = {};
  const [errors, setErrors] = useState(defaultErrors);

  useImperativeHandle(ref, () => ({
    show: async (item, tab) => {
      setOpen(true);
      setTable(tab);
      setData(item);
    },
    update: async (data) => {
      setOpen(true);
      setData(data);
    },
  }));

  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let _res;
    _res = await CongNoService.HoaDonChiTiet.SuaCN({
      SoTien: _data?.SoTienNhap,
      DaThu: _data?.DaThu,
      GhiChu: _data?.GhiChu,
      MaSoMB: _data?.MaSoMB,
      ID: _data?.ID,
      IsPhieuThu: table === "1" ? false : true,
    });

    setLoading(false);
    if (_res.status === 2000) {
      openNotification("success", "topRight", `Sửa hóa đơn thành công!`);

      onAfterSubmit();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const handleOk = () => {
    onSubmit();
    setOpen(false);
    setData(defaultData);
    setLoading(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <>
      <Modal
        title="Sửa hóa đơn"
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        width={400}
      >
        <Row
          style={{ marginTop: "25px", marginBottom: "20px" }}
          gutter={[24, 20]}
        >
          <Col span={24}>
            <InputNumberCustom
              label="Số tiền:"
              applyChange={applyChange}
              prop="SoTienNhap"
              value={data?.SoTienNhap}
              placeholder=""
            />
          </Col>

          <Col span={24}>
            <p style={{ fontWeight: "600" }}>Diễn giải:</p>
            <TextArea
              value={data?.GhiChu || ""}
              onChange={(e) => applyChange("GhiChu", e.target.value)}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default forwardRef(FormAdd);
