import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Space,
  Drawer,
  notification,
  Table,
  Upload,
  message,
  Select,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import { UploadOutlined } from "@ant-design/icons";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { DichVuService } from "services/DichVuService";
import { CongNoService } from "services/CongNoService";
import { ConvertExcelDateToJSDate } from "layouts/ultis/common";

const FormAdd = (props, ref) => {
  const { onAfterSubmit, options, maTN } = props;
  const [api, contextHolder] = notification.useNotification();

  const [data, setData] = useState([]);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = { TenKH: null };
  const [errors, setErrors] = useState(defaultErrors);
  const [sheetExcel, setSheetExcel] = useState();
  const [workbook, setWorkbook] = useState(null);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData([]);
    setSheetExcel();
    setCol(columns);
  };

  const columns = [
    {
      title: "Mã số MB", // Mã số máy bay
      dataIndex: "MaSoMB",
      key: "MaSoMB",
    },
    {
      title: "Mã số KH", // Mã số khách hàng
      dataIndex: "MaSoKH",
      key: "MaSoKH",
    },
    {
      title: "Mã LDV", // Mã loại dịch vụ
      dataIndex: "MaLDV",
      key: "MaLDV",
    },
    {
      title: "Ngày TT", // Ngày thanh toán
      dataIndex: "NgayTT",
      key: "NgayTT",
    },
    {
      title: "Số tiền", // Số tiền
      dataIndex: "SoTien",
      key: "SoTien",
    },
    {
      title: "Ngày nhập", // Ngày nhập
      dataIndex: "NgayNhap",
      key: "NgayNhap",
    },
    {
      title: "Diễn giải", // Diễn giải
      dataIndex: "DienGiai",
      key: "DienGiai",
    },
    {
      title: "Mã TX", // Mã tài xế
      dataIndex: "MaTX",
      key: "MaTX",
    },
    {
      title: "Mã công tơ", // Mã công tơ
      dataIndex: "MaCongTo",
      key: "MaCongTo",
    },
    {
      title: "Mã đồng hồ", // Mã đồng hồ
      dataIndex: "MaDongHo",
      key: "MaDongHo",
    },
  ];

  const [col, setCol] = useState(columns);
  const sampleData = [
    {
      MaSoMB: "HH1-0303",
      MaSoKH: "KH001",
      MaLDV: "Nước (HH1-0303)",
      NgayTT: "2024-10-09",
      SoTien: 1000000,
      NgayNhap: "2024-10-08",
      DienGiai: "Thanh toán tiền dịch vụ",
      MaTX: "TX001",
      MaCongTo: "CT001",
      MaDongHo: "DH001",
    },
    {
      MaSoMB: "HH1-0305",
      MaSoKH: "KH002",
      MaLDV: "Giữ xe",
      NgayTT: "2024-09-15",
      SoTien: 500000,
      NgayNhap: "2024-10-07",
      DienGiai: "Thanh toán tiền điện",
      MaTX: "30A-1234228",
      MaCongTo: "CT002",
      MaDongHo: "DH002",
    },
  ];

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  // const exportToCSV = () => {
  //   const ws = XLSX.utils.json_to_sheet(sampleData);
  //   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //   const dataEX = new Blob([excelBuffer], { type: fileType });
  //   FileSaver.saveAs(dataEX, "File mẫu " + fileExtension);
  // };
  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(sampleData);

    // Define the styles for the header cells
    const headerCellStyle = {
      font: { bold: true, color: { rgb: "FF0000" } }, // Red text
      fill: {
        fgColor: { rgb: "FFFF00" }, // Yellow background
      },
    };
    ws["A1"].s = headerCellStyle;
    ws["B1"].s = headerCellStyle;
    ws["C1"].s = headerCellStyle;
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "File mẫu " + fileExtension);
  };
  const loadDataImport = (data) => {
    let res = [];
    data?.map((item, i) => {
      if (i > 0) {
        res.push({
          STT: i + 1,
          MaSoMB: item[0],
          MaSoKH: item[1],
          MaLDV: item[2],
          NgayTT:
            typeof item[3] === "number"
              ? ConvertExcelDateToJSDate(item[3])
              : item[3],
          SoTien: item[4],
          NgayNhap:
            typeof item[5] === "number"
              ? ConvertExcelDateToJSDate(item[5])
              : item[5],
          DienGiai: item[6],
          MaTX: item[7],
          MaCongTo: item[8],
          MaDongHo: item[9],
        });
      }
    });
    setData(res);
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);

    const filterData = _data.map((item) => {
      return {
        ...item,
      };
    });

    let _res = await CongNoService.HoaDonChiTiet.importExcel({
      MaTN: maTN,
      List: filterData,
    });
    setLoading(false);

    if (_res.status === 2000 && _res.data.length === 0) {
      openNotification("success", "topRight", "Thao tác thành công");
      setIsUpdate(true);

      onClose();
    } else {
      const pushErrorData = _data.map((item) => {
        let updatedItem = { ...item };
        _res?.data?.forEach((i) => {
          if (item.MaSoMB === i.maSoMB) {
            updatedItem = { ...item, error: i.error };
          }
        });
        return updatedItem;
      });

      setData(pushErrorData.filter((item) => item.error));
      setCol([
        {
          title: "Error",
          dataIndex: "error",
          key: "error",
          width: 180,
          render: (item) => <p style={{ color: "red" }}>{item}</p>,
        },
        ...columns,
      ]);

      openNotification("success", "topRight", "Thao tác thành công");
    }
    onAfterSubmit();
  };
  const handleSheet = (value) => {
    const wsname = workbook.SheetNames[value];
    const ws = workbook.Sheets[wsname];
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
    loadDataImport(data);
    setCol(columns);
  };
  return (
    <Drawer
      title="Excel"
      width={960}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          {sheetExcel && (
            <Select
              placeholder="Chọn Sheet"
              style={{
                width: 115,
                border: "1px solid #d9d9d9",
                borderBottom: "3px solid #d9d9d",
                borderRadius: "5px",
                padding: "0 2px 0 10px",
              }}
              onChange={handleSheet}
              options={sheetExcel}
            />
          )}
          <Upload
            accept=" .xlsx, .xls"
            showUploadList={false}
            beforeUpload={(file) => {
              setWorkbook(null);
              setSheetExcel(null);
              const reader = new FileReader();
              const rABS = !!reader.readAsBinaryString;
              reader.onload = (e) => {
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
                const sheets = wb.SheetNames.map((item, index) => ({
                  value: index,
                  label: item,
                }));
                if (sheets.length === 1) {
                  const wsname = wb.SheetNames[0];
                  const ws = wb.Sheets[wsname];
                  const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
                  loadDataImport(data);
                  setCol(columns);
                } else {
                  setWorkbook(wb);
                  setSheetExcel(sheets);
                  setData();
                  setCol(columns);
                }
              };
              if (rABS) reader.readAsBinaryString(file);
              else reader.readAsArrayBuffer(file);
            }}
          >
            <Button icon={<UploadOutlined />}>Upload File</Button>
          </Upload>
          <Button onClick={() => exportToCSV()}>Tải file mẫu</Button>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Table
        style={{ margin: 0, marginTop: "20px" }}
        dataSource={data}
        columns={col}
        scroll={{
          y: window.innerHeight - 250,
          x: 1200,
        }}
      />
    </Drawer>
  );
};
export default forwardRef(FormAdd);
