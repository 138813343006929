import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Drawer,
  Row,
  Col,
  Input,
  DatePicker,
  Select,
  Tabs,
  Checkbox,
  Flex,
} from "antd";
// import { useDispatch, useSelector } from "react-redux";
// import moment from "moment";
import SelectCustom from "components/Forms/SelectCustom";
import InputCustom from "components/Forms/InputCustom";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import TabPane from "antd/es/tabs/TabPane";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import TextArea from "antd/es/input/TextArea";
import DebounceSelect from "components/DebounceSelect";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
const { Option } = Select;

export const FormDrawerUser = (props) => {
  const { isEdit, isOpen, toggle, detail } = props;
  const [data, setData] = useState({});
  const [maTinh, setMaTinh] = useState(0);
  const [maHuyen, setMaHuyen] = useState(0);
  const [maTinh2, setMaTinh2] = useState(0);
  const [maHuyen2, setMaHuyen2] = useState(0);

  useEffect(() => {
    setMaTinh(detail?.maTinh);
    setMaHuyen(detail?.maHuyen);
    setMaTinh2(detail?.maTinh2);
    setMaHuyen2(detail?.maHuyen2);
  }, [detail]);

  const handleValidOrderSubmit = (values) => {
    const insertAndUpdate = {
      MaKH: isEdit ? detail.maKH : null,
      TenKH: values.TenKH,
      MaSoKH: values.MaSoKH,
      NgaySinh: values.NgaySinh?.format("DD/MM/YYYY"),
      SoCMND: values.SoCMND,
      NgayCap: values.NgayCap?.format("DD/MM/YYYY"),
      NoiCap: values.NoiCap,
      DiDong: values.DiDong,
      DiDong2: values.DiDong2,
      Email: values.Email,
      Email2: values.Email2,
      PurposeID: Number(values.PurposeID),
      ThuongTru: values.ThuongTru,
      DiaChi: values.DiaChi,
      MaXa: Number(values.MaXa) === 0 ? null : Number(values.MaXa),
      MaHuyen: Number(values.MaHuyen) === 0 ? null : Number(values.MaHuyen),
      MaTinh: Number(values.MaTinh) === 0 ? null : Number(values.MaTinh),
      MaXa2: Number(values.MaXa2) === 0 ? null : Number(values.MaXa2),
      MaHuyen2: Number(values.MaHuyen2) === 0 ? null : Number(values.MaHuyen2),
      MaTinh2: Number(values.MaTinh2) === 0 ? null : Number(values.MaTinh2),
      MaNKH: Number(values.MaNKH),
      HowToKnowID: Number(values.HowToKnowID),
      MaNN: Number(values.MaNN),
      MaTT: Number(values.MaTT),
      MaQD: Number(values.MaQD),
    };
    // dispatch(addEditCustomer(insertAndUpdate))
    // dispatch(getCustomers({ inputSearch: "", Offset: 1, Limit: 10 }))
    toggle();
  };

  const getHuyen = () => {
    return []?.find((item) => {
      return item.maTinh === maTinh;
    })?.listHuyen;
  };

  const getXa = () => {
    return getHuyen()?.find((item) => {
      return item.maHuyen === maHuyen;
    })?.listXa;
  };

  const getHuyen2 = () => {
    return []?.find((item) => {
      return item.maTinh === maTinh2;
    })?.listHuyen;
  };

  const getXa2 = () => {
    return getHuyen2()?.find((item) => {
      return item.maHuyen === maHuyen2;
    })?.listXa;
  };

  const onChangeTinh = (value) => {
    setMaTinh(Number(value));
  };

  const onChangeTinh2 = (value) => {
    setMaTinh2(Number(value));
  };

  const onChangeHuyen = (value) => {
    setMaHuyen(Number(value));
  };

  const onChangeHuyen2 = (value) => {
    setMaHuyen2(Number(value));
  };

  const onClickClose = () => {
    toggle();
  };

  const applyChange = (field, value) => {
    // Update detail state or other state as needed
    // Example:
    // setDetail(prev => ({ ...prev, [field]: value }));
  };
  const tabs = [
    {
      title: "Thông tin chung",
      key: "0",
    },
    {
      title: "Thông tin khác",
      key: "1",
    },
  ];
  const [activeTab, setActiveTab] = useState("0");
  const onChange = (newActiveKey) => {
    setActiveTab(newActiveKey);
  };
  const renderThongTinChung = () => {
    return (
      <Row style={{ marginTop: "-25px" }} gutter={[20, 12]}>
        <Col span={18}>
          <Row gutter={[20, 12]}>
            <Col span={24}>
              <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
                Thông tin chung
              </p>
            </Col>
            <Col span={8}>
              <InputCustom
                label="Kí hiệu:"
                applyChange={applyChange}
                prop="KiHieu"
                value={data?.KiHieu}
                placeholder="Nhập kí hiệu"
              />
            </Col>
            <Col span={8}>
              {" "}
              <SelectCustom
                label="Loại MB:"
                applyChange={applyChange}
                prop="LoaiMB"
                value={data?.LoaiMB}
                placeholder="Chọn loại MB"
                options={[]}
                //   fieldNames={{ label: "tenDA", value: "maDA" }}
              />
            </Col>
            <Col span={8}>
              <InputNumberCustom
                label="Số N định mức:"
                applyChange={applyChange}
                prop={"SoNDinhMuc"}
                value={data?.SoNDinhMuc}
                placeholder="0"
              />
            </Col>
            <Col span={8}>
              {" "}
              <SelectCustom
                label="Dự án:"
                applyChange={applyChange}
                prop="DuAn"
                value={data?.DuAn}
                placeholder="Chọn dự án"
                options={[]}
                //   fieldNames={{ label: "tenDA", value: "maDA" }}
              />
            </Col>
            <Col span={8}>
              {" "}
              <SelectCustom
                label="Trạng thái:"
                applyChange={applyChange}
                prop="TrangThai"
                value={data?.TrangThai}
                placeholder="Chọn trang thái"
                options={[]}
                //   fieldNames={{ label: "tenDA", value: "maDA" }}
              />
            </Col>
            <Col span={8}>
              {" "}
              <SelectCustom
                label="Nhân viên:"
                applyChange={applyChange}
                prop="NhanVien"
                value={data?.NhanVien}
                placeholder="Chọn nhân viên"
                options={[]}
                //   fieldNames={{ label: "tenDA", value: "maDA" }}
              />
            </Col>
            <Col span={8}>
              {" "}
              <SelectCustom
                label="Khối nhà:"
                applyChange={applyChange}
                prop="KhoiNha"
                value={data?.KhoiNha}
                placeholder="Chọn khối nhà"
                options={[]}
                //   fieldNames={{ label: "tenDA", value: "maDA" }}
              />
            </Col>
            <Col span={8}>
              <InputNumberCustom
                label="TG miễn phí:"
                applyChange={applyChange}
                prop={"TGMienPhi"}
                value={data?.TGMienPhi}
                placeholder="0"
              />
            </Col>
            <Col span={8}>
              <InputNumberCustom
                label="DT thực tế:"
                applyChange={applyChange}
                prop={"DTThucTe"}
                value={data?.DTThucTe}
                placeholder="0"
              />
            </Col>
            <Col span={8}>
              {" "}
              <SelectCustom
                label="Tầng(lầu):"
                applyChange={applyChange}
                prop="Tang"
                value={data?.Tang}
                placeholder="Chọn tầng"
                options={[]}
                //   fieldNames={{ label: "tenDA", value: "maDA" }}
              />
            </Col>
            <Col span={8}>
              {" "}
              <SelectCustom
                label="HT thu phí:"
                applyChange={applyChange}
                prop="HTThuPhi"
                value={data?.HTThuPhi}
                placeholder="Chọn hình thức thu phí"
                options={[]}
                //   fieldNames={{ label: "tenDA", value: "maDA" }}
              />
            </Col>
            <Col span={8}>
              <InputNumberCustom
                label="DT thông thủy:"
                applyChange={applyChange}
                prop={"DTThongThuy"}
                value={data?.DTThongThuy}
                placeholder="0"
              />
            </Col>
            <Col span={8}>
              <InputCustom
                label="Số căn hộ:"
                applyChange={applyChange}
                prop="SoCanHo"
                value={data?.SoCanHo}
                placeholder="Nhập kí hiệu"
              />
            </Col>
            <Col span={8}>
              {" "}
              <SelectCustom
                label="Loại tiền:"
                applyChange={applyChange}
                prop="LoaiTien"
                value={data?.LoaiTien}
                placeholder="Chọn loại tiền"
                options={[]}
                //   fieldNames={{ label: "tenDA", value: "maDA" }}
              />
            </Col>
            <Col span={8}>
              <InputNumberCustom
                label="DT tim tường:"
                applyChange={applyChange}
                prop={"DTTimTuong"}
                value={data?.DTTimTuong}
                placeholder="0"
              />
            </Col>
            <Col span={16}>
              <Flex style={{ borderBottom: "1px solid #d9d9d9" }}>
                <p
                  style={{
                    fontWeight: "600",
                    width: "30% ",
                  }}
                >
                  Khách hàng:
                </p>
                <DebounceSelect
                   open={isOpen}
                  value={data?.KhachHang}
                  placeholder="Tìm theo mã mặt bằng"
                  // fetchOptions={fetchMatBang}
                  onChange={(v) => applyChange("KhachHang", v)}
                  style={{
                    width: "100%",
                  }}
                />

                <Button
                  style={{
                    margin: 0,
                    padding: 0,

                    borderRight: "none",
                  }}
                  icon={<PlusOutlined />}
                  type="text"
                  onClick={[]}
                />
                <Button
                  style={{
                    margin: 0,
                    padding: 0,
                    borderRight: "0px",
                  }}
                  icon={<EditOutlined />}
                  type="text"
                  onClick={[]}
                />
              </Flex>
            </Col>
            <Col span={8}>
              <InputCustom
                label="Địa chỉ:"
                applyChange={applyChange}
                prop="DiaChi"
                value={data?.DiaChi}
                placeholder="Nhập kí hiệu"
              />
            </Col>
            <Col span={24}>
              <Flex>
                <p
                  style={{
                    fontWeight: "600",
                    width: "20%",
                    borderBottom: "1px solid #d9d9d9",
                  }}
                >
                  Diễn giải:
                </p>
                <TextArea
                  value={data?.TenCongTy}
                  onChange={(e) => applyChange("TenCongTy", e.target.value)}
                />
              </Flex>
            </Col>
            <Col span={24}>
              <Checkbox onChange={[]}>Cho thuê</Checkbox>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Row gutter={[20, 12]}>
            <Col span={24}>
              <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
                Phí quản lý
              </p>
            </Col>
            <Col span={24}>
              <Checkbox onChange={[]}>Theo diện tích</Checkbox>
            </Col>
            <Col span={24}>
              <InputNumberCustom
                label="Diện tích thu phí:"
                applyChange={applyChange}
                prop={"DTThuPhi"}
                value={data?.DTThuPhi}
                placeholder="0"
              />
            </Col>
            <Col span={24}>
              <InputNumberCustom
                label="Số tiền:"
                applyChange={applyChange}
                prop={"SoTien"}
                value={data?.SoTien}
                placeholder="0"
              />
            </Col>
            <Col span={24}>
              <InputNumberCustom
                label="% VAT:"
                applyChange={applyChange}
                prop={"VAT"}
                value={data?.VAT}
                placeholder="0"
              />
            </Col>
            <Col span={24}>
              <InputNumberCustom
                label="Phí quản lý:"
                applyChange={applyChange}
                prop={"PhiQuanLy"}
                value={data?.PhiQuanLy}
                placeholder="0"
              />
            </Col>

            <Col span={24}>
              <InputNumberCustom
                label="Tiền VAT:"
                applyChange={applyChange}
                prop={"TienVAT"}
                value={data?.TienVAT}
                placeholder="0"
              />
            </Col>
            <Col span={24}>
              <DatePickerCustom
                label="Ngày bắt đầu tính:"
                applyChange={applyChange}
                prop={"NgayBatDauTinh"}
                value={data?.NgayBatDauTinh}
                placeholder={"Chọn ngày bắt đầu tính"}
              />
            </Col>
            <Col span={24}>
              <DatePickerCustom
                label="Ngày kết thúc tính:"
                applyChange={applyChange}
                prop={"NgayKetThucTinh"}
                value={data?.NgayKetThucTinh}
                placeholder={"Chọn ngày kết thúc tính"}
              />
            </Col>
            <Col span={24}>
              <DatePickerCustom
                label="Ngày thanh toán:"
                applyChange={applyChange}
                prop={"NgayThanhToan"}
                value={data?.NgayThanhToan}
                placeholder={"Chọn ngày thanh toán"}
              />
            </Col>

            <Col span={24}>
              <InputNumberCustom
                label="Thanh toán:"
                applyChange={applyChange}
                prop={"ThanhToan"}
                value={data?.ThanhToan}
                placeholder="0"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };
  const renderThongTinKhac = () => {
    return (
      <Row style={{ marginTop: "-25px" }} gutter={[20, 12]}>
        <Col span={24}>
          {" "}
          <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
            Cài đặt điện nước
          </p>
        </Col>
        <Col span={6}>
          <InputCustom
            label="Seri đồng hồ nước:"
            applyChange={applyChange}
            prop="SeriDongHoNuoc"
            value={data?.SeriDongHoNuoc}
            placeholder="Nhập seri đông hồ nước"
          />
        </Col>
        <Col span={6}>
          <InputCustom
            label="Seri đồng hồ điện:"
            applyChange={applyChange}
            prop="SeriDongHoDien"
            value={data?.SeriDongHoDien}
            placeholder="Nhập đồng hồ điện"
          />
        </Col>
        <Col span={6}>
          <InputNumberCustom
            label="Chỉ số nước bàn giao:"
            applyChange={applyChange}
            prop={"ChiSoNuocBanGiao"}
            value={data?.ChiSoNuocBanGiao}
            placeholder="0"
          />
        </Col>
        <Col span={6}>
          <InputNumberCustom
            label="Chỉ số điện bàn giao:"
            applyChange={applyChange}
            prop={"ChiSoDienBanGiao"}
            value={data?.ChiSoDienBanGiao}
            placeholder="0"
          />
        </Col>
        <Col span={24}>
          {" "}
          <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
            Thiết lập khác
          </p>
        </Col>
        <Col span={8}>
          <Checkbox onChange={[]}>Xác nhận diện tích</Checkbox>
        </Col>
        <Col span={8}>
          <Checkbox onChange={[]}>Chìa khóa dự phòng</Checkbox>
        </Col>
        <Col span={8}>
          <Checkbox onChange={[]}>Đây là căn hộ cá nhân</Checkbox>
        </Col>
        <Col span={12}>
          <DatePickerCustom
            label="Ngày kí hợp đồng dịch vụ:"
            applyChange={applyChange}
            prop={"NgayKiHDDichVu"}
            value={data?.NgayKiHDDichVu}
            placeholder={"Chọn ngày kí hợp đồng dịch vụ"}
          />
        </Col>
        <Col span={12}>
          <DatePickerCustom
            label="Ngày bàn giao dự kiến:"
            applyChange={applyChange}
            prop={"NgayBanDaoDuKien"}
            value={data?.NgayBanDaoDuKien}
            placeholder={"Chọn ngày bàn giao dự kiến"}
          />
        </Col>
        <Col span={12}>
          <DatePickerCustom
            label="Ngày bàn giao căn hộ:"
            applyChange={applyChange}
            prop={"NgayBanDaoCanHo"}
            value={data?.NgayBanDaoCanHo}
            placeholder={"Chọn ngày bàn dao căn hộ"}
          />
        </Col>
        <Col span={12}>
          <DatePickerCustom
            label="Ngày bàn giao thực tế:"
            applyChange={applyChange}
            prop={"NgayBanGiaoThucTe"}
            value={data?.NgayBanGiaoThucTe}
            placeholder={"Chọn ngày bàn giao thực tế"}
          />
        </Col>
        <Col span={12}>
          <DatePickerCustom
            label="Ngày bàn giao thông báo:"
            applyChange={applyChange}
            prop={"NgayBanGiaoThongBao"}
            value={data?.NgayBanGiaoThongBao}
            placeholder={"Chọn ngày bàn giao thông báo"}
          />
        </Col>
        <Col span={12}>
          <Flex>
            <p
              style={{
                fontWeight: "600",
                width: "20%",
                borderBottom: "1px solid #d9d9d9",
              }}
            >
              Diễn giải:
            </p>
            <TextArea
              value={data?.TenCongTy}
              onChange={(e) => applyChange("TenCongTy", e.target.value)}
            />
          </Flex>
        </Col>
      </Row>
    );
  };
  return (
    <Drawer
      title={!!isEdit ? "Sửa khách hàng" : "Mặt bằng"}
      visible={isOpen}
      onClose={onClickClose}
      width={1280}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button onClick={onClickClose} style={{ marginRight: 8 }}>
            Close
          </Button>
          <Button
            onClick={() => {
              document
                .getElementById("customerForm")
                .dispatchEvent(
                  new Event("submit", { cancelable: true, bubbles: true })
                );
            }}
            type="primary"
          >
            Save changes
          </Button>
        </div>
      }
    >
      <Tabs onChange={onChange} activeKey={activeTab}>
        {tabs?.map((item) => {
          return (
            <TabPane tab={item.title} key={item.key}>
              {activeTab === "0" ? renderThongTinChung() : renderThongTinKhac()}
            </TabPane>
          );
        })}
      </Tabs>
    </Drawer>
  );
};

FormDrawerUser.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  isEdit: PropTypes.bool,
  detail: PropTypes.object,
};

export default FormDrawerUser;
