const { Checkbox, ColorPicker } = require("antd");

export const columnsLoaiYeuCau = [
  {
    title: "Tên loại yêu cầu",
    dataIndex: "TenLYC",
  },
  {
    title: "Hiển thị app cư dân",
    dataIndex: "IsViewApCuDan",
    render: (item) => <Checkbox checked={item} />,
  },
];
export const columnsHinhThucYeuCau = [
  {
    title: "Tên hình thức",
    dataIndex: "TenHT",
  },
];

export const columnsMucDoYeuCau = [
  {
    title: "Tên độ ưu tiên",
    dataIndex: "TenDoUuTien",
  },
  {
    title: "Màu nền",
    dataIndex: "Color",
    render: (item) => <ColorPicker disabled value={item} size="small" />,
  },
];

export const columnsTrangThaiYeuCau = [
  {
    title: "Tên trạng thái",
    dataIndex: "TenTT",
  },
  {
    title: "Màu nền",
    dataIndex: "Color",
    render: (item) => <ColorPicker disabled value={item} size="small" />,
  },
  {
    title: "Mặc định cư dân",
    dataIndex: "DefautCuDan",
    render: (item) => <Checkbox checked={item} />,
  },
  {
    title: "Hiển thị app bql",
    dataIndex: "isViewApp",
    render: (item) => <Checkbox checked={item} />,
  },
  {
    title: "Hiển thị app cư dân",
    dataIndex: "ViewAppCuDan",
    render: (item) => <Checkbox checked={item} />,
  },
  {
    title: "Cư dân đánh giá",
    dataIndex: "isDanhGia",
    render: (item) => <Checkbox checked={item} />,
  },
  {
    title: "Đánh dấu hoàn thành",
    dataIndex: "isNgayHoanThanh",
    render: (item) => <Checkbox checked={item} />,
  },
];
