import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Tabs,
  Checkbox,
  Flex,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { DatePicker } from "antd/lib";
import vi from "dayjs/locale/vi";
import { SoQuyService } from "services/SoQuyService";
import TabPane from "antd/es/tabs/TabPane";
import { ToaNhaService } from "services/ToaNhaService";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import SelectCustom from "components/Forms/SelectCustom";
import InputCustom from "components/Forms/InputCustom";
import DebounceSelect from "components/DebounceSelect";
import FormDrawerUser from "components/FormMatBang";
import { EditOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";
import { MatBangService } from "services/MatBangService";
import { DichVuService } from "services/DichVuService";
const { TextArea } = Input;

const FormAdd = (props, ref) => {
  const { onAfterSubmit, filterCondition } = props;
  const [api, contextHolder] = notification.useNotification();
  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");
  const defaulData = {
    VAT: 0,
    HeSoDC: 1,
    HaoHut: 0,
    ChiSoCu: 0,
    NoTruoc: 0,
    NgayNhap: dateInHoChiMinh.toISO(),
  };
  const [data, setData] = useState(defaulData);
  const [congTo, setCongTo] = useState([]);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = {
    MatBang: null,
    // TenCongTo: null,
    // ChiSoCu: null,
    ChiSoMoi: null,

    // SoTieuThu: null,
    // KhachHang: null,
    // NgayNhap: null,
    // TienDien: null,
    // NoTruoc: null,

    // ThueVAT: null,

    // TienHaoHut: null,
    // TongTien: null,
  };
  const [errors, setErrors] = useState(defaultErrors);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      setErrors(defaultErrors);
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
  };
  const filterByMatBang = async (_data) => {
    let _res = await DichVuService.Dien.getChiSoCu({
      MaMB: _data?.MaMB,
      MaCongTo: _data?.MaCongTo,
    });
    if (_res.data) {
      _data.ChiSoCu = _res.data?.ChiSoCu;
    }
    let _resVAT = await DichVuService.Dien.getVAT({
      MaKN: _data?.MaKN,
    });
    if (_resVAT.data) {
      _data.VAT = _resVAT.data;
    } else {
      _data.VAT = 0;
    }
  };

  const filterTienDien = async (_data) => {
    let _res = await DichVuService.Dien.getTienDien({
      MaMB: _data?.MaMB,
      SoTieuThu: _data?.SoTieuThu,
      MaTN: _data?.MaTN,
      MaLMB: _data?.MaLMB,
      NgayNhap: _data?.NgayNhap,
    });

    if (_res.status === 2000) {
      setData((prevData) => {
        const updatedData = {
          ...prevData,
          TienDien: _res.data?.tienDien,
          ThueVAT: (_res.data?.tienDien * (prevData?.VAT ?? 0)) / 100,
          TienHaoHut: (_res.data?.tienDien * (prevData?.HaoHut ?? 0)) / 100,
        };
        sumTongTien(updatedData);
        return updatedData;
      });
    }
  };
  const sumTongTien = (_data) => {
    _data.TongTien =
      (_data?.TienDien ?? 0) +
      (_data?.ThueVAT ?? 0) +
      (_data?.TienHaoHut ?? 0) -
      (_data?.NoTruoc ?? 0);
  };

  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        if (data) filterTienDien(data);
      }, 500);
    }
    return () => clearTimeout(time);
  }, [data?.ChiSoMoi, data?.NgayNhap, data?.MatBang]);

  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MatBang":
        const KH = JSON.parse(v?.title);
        const payLoad = { MaKH: KH?.MaKH };
        let _res = await DichVuService.Dien.getCongTo(payLoad);
        if (_res.data) setCongTo(_res.data);

        _data.KhachHang = KH?.TenKH;
        _data.TenCongTo = _res.data[0]?.TenCongTo;
        _data.MaCongTo = _res.data[0]?.ID;
        _data.MaMB = KH?.MaMB;
        _data.MaKN = KH?.MaKN;
        _data.MaKH = KH?.MaKH;
        _data.MaLMB = KH?.MaLMB;
        _data.MaTN = KH?.MaTN;

        // Call filterByMatBang and wait for the results before calculating SoTieuThu
        await filterByMatBang(_data);
        _data.SoTieuThu = (_data?.ChiSoMoi ?? 0) - (_data?.ChiSoCu ?? 0);
        _data[p] = v.key;
        break;

      case "NgayNhap":
        const date = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString = date.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString;
        break;

      case "ChiSoCu":
        _data.SoTieuThu = (_data?.ChiSoMoi ?? 0) - v;
        _data[p] = v;
        break;

      case "ChiSoMoi":
        _data.SoTieuThu = v - (_data?.ChiSoCu ?? 0);
        _data[p] = v;
        break;

      case "VAT":
        _data.ThueVAT = (v * (_data?.TienDien ?? 0)) / 100;
        _data[p] = v;
        break;

      case "HaoHut":
        _data.TienHaoHut = (v * (_data?.TienDien ?? 0)) / 100;
        _data[p] = v;
        break;

      default:
        _data[p] = v;
        break;
    }

    // Call sumTongTien with the modified _data before updating the state
    sumTongTien(_data);

    // Update the state
    setData(_data);

    // Optionally validate data
    validate(_data);
  };

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = (_data) => {
    let _errors = _.cloneDeep(errors);
    let _dtcheck = _data || data;
    Object.keys(_errors)?.map((item) => {
      _errors[item] = _dtcheck[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    let _res = await DichVuService.Dien.add(_data);

    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} thông tin điện!`
      );
      setIsUpdate(true);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  async function fetchMatBang(input) {
    return MatBangService.getMatBang({
      InputSearch: input,
      MaTN: filterCondition?.MaTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.MaSoMB}`,
        value: item.MaMB,
        title: JSON.stringify(item),
      }));
    });
  }

  const tabs = [
    {
      title: "Thông tin",
      key: "0",
    },
    {
      title: "Cài đặt nâng cao",
      key: "1",
    },
  ];
  const [activeTab, setActiveTab] = useState("0");
  const onChange = (newActiveKey) => {
    setActiveTab(newActiveKey);
  };
  // const [modal, setModal] = useState(false);
  // const [isEdit, setIsEdit] = useState(false);
  // const [staffForm, setStaffForm] = useState([]);

  // const toggle = () => {
  //   setModal(!modal);
  // };
  // const handleAdd = () => {
  //   setStaffForm([]);
  //   setIsEdit(false);
  //   toggle();
  // };
  const renderThongTinChung = () => {
    return (
      <>
        <Row gutter={[20, 12]}>
          <Col span={24}>
            <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
              Thông tin chung
            </p>
          </Col>

          <Col span={14}>
            <Row gutter={[20, 12]}>
              <Col span={24}>
                <Flex
                  align="center"
                  style={{ borderBottom: "1px solid #ff9087" }}
                >
                  <p
                    style={{
                      fontWeight: "600",
                      width: "35% ",
                    }}
                  >
                    Mặt bằng: <span style={{ color: "red" }}>*</span>
                  </p>
                  <DebounceSelect
                   open={open}   
                    value={data?.MatBang}
                    placeholder="Tìm theo mã mặt bằng"
                    fetchOptions={fetchMatBang}
                    onChange={(v) => applyChange("MatBang", v)}
                    style={{
                      width: "100%",
                    }}
                  />
                </Flex>
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["MatBang"]}
                  </p>
                )}
              </Col>
              <Col span={24}>
                {" "}
                <SelectCustom
                  label="Công tơ điện:"
                  applyChange={applyChange}
                  prop="TenCongTo"
                  value={data?.TenCongTo}
                  placeholder="Chọn công tơ điện"
                  options={congTo}
                  fieldNames={{ label: "TenCongTo", value: "ID" }}
                />
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["TenCongTo"]}
                  </p>
                )}
              </Col>
              <Col span={12}>
                <InputNumberCustom
                  // required={true}
                  label="Chỉ số cũ:"
                  applyChange={applyChange}
                  prop={"ChiSoCu"}
                  value={data?.ChiSoCu}
                  placeholder="0"
                />
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["ChiSoCu"]}
                  </p>
                )}
              </Col>
              <Col span={12}>
                <InputNumberCustom
                  required={true}
                  label="Chỉ số mới:"
                  applyChange={applyChange}
                  prop={"ChiSoMoi"}
                  value={data?.ChiSoMoi}
                  placeholder="0"
                />
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["ChiSoMoi"]}
                  </p>
                )}
              </Col>
              <Col span={12}>
                <InputNumberCustom
                  label="Hệ số DC:"
                  applyChange={applyChange}
                  prop={"HeSoDC"}
                  value={data?.HeSoDC}
                  placeholder="1.00"
                />
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["HeSoDC"]}
                  </p>
                )}
              </Col>
              <Col span={12}>
                <InputNumberCustom
                  label="Tiêu thụ:"
                  applyChange={applyChange}
                  prop={"SoTieuThu"}
                  value={data?.SoTieuThu}
                  placeholder="0"
                  disabled={true}
                />
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["SoTieuThu"]}
                  </p>
                )}
              </Col>
              <Col span={24}>
                <Flex>
                  <p
                    style={{
                      fontWeight: "600",
                      width: "20%",
                      borderBottom: "1px solid #d9d9d9",
                    }}
                  >
                    Diễn giải:
                  </p>
                  <TextArea
                    value={data?.TenCongTy}
                    onChange={(e) => applyChange("TenCongTy", e.target.value)}
                  />
                </Flex>
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={[20, 12]}>
              <Col span={24}>
                <InputCustom
                  label="Khách hàng:"
                  applyChange={applyChange}
                  prop="KhachHang"
                  value={data?.KhachHang}
                  placeholder="Nhập di tên khách hàng"
                  disabled={true}
                />
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["KhachHang"]}
                  </p>
                )}
              </Col>

              <Col span={24}>
                <DatePickerCustom
                  required={true}
                  label="Ngày nhập:"
                  applyChange={applyChange}
                  prop={"NgayNhap"}
                  value={data?.NgayNhap}
                />
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["NgayNhap"]}
                  </p>
                )}
              </Col>
              <Col span={24}>
                <InputNumberCustom
                  label="Số tiền:"
                  applyChange={applyChange}
                  prop={"TienDien"}
                  value={data?.TienDien}
                  placeholder="0"
                  disabled={true}
                />
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["TienDien"]}
                  </p>
                )}
              </Col>
              <Col span={24}>
                <InputNumberCustom
                  // required={true}
                  label="Nợ kì đầu:"
                  applyChange={applyChange}
                  prop={"NoTruoc"}
                  value={data?.NoTruoc}
                  placeholder="0"
                />
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["NoTruoc"]}
                  </p>
                )}
              </Col>
              <Col span={24}>
                <InputNumberCustom
                  max={100}
                  label="% VAT:"
                  applyChange={applyChange}
                  prop={"VAT"}
                  value={data?.VAT}
                  placeholder="0"
                />
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["VAT"]}
                  </p>
                )}
              </Col>
              <Col span={24}>
                <InputNumberCustom
                  label="Tiền VAT:"
                  applyChange={applyChange}
                  prop={"ThueVAT"}
                  value={data?.ThueVAT}
                  placeholder="0"
                  disabled={true}
                />
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["ThueVAT"]}
                  </p>
                )}
              </Col>
              <Col span={24}>
                <InputNumberCustom
                  max={100}
                  label="% Hao hụt:"
                  applyChange={applyChange}
                  prop={"HaoHut"}
                  value={data?.HaoHut}
                  placeholder="0"
                />
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["HaoHut"]}
                  </p>
                )}
              </Col>
              <Col span={24}>
                <InputNumberCustom
                  label="Tiền hao hụt:"
                  applyChange={applyChange}
                  prop={"TienHaoHut"}
                  value={data?.TienHaoHut}
                  placeholder="0"
                  disabled={true}
                />
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["TienHaoHut"]}
                  </p>
                )}
              </Col>
              <Col span={24}>
                <InputNumberCustom
                  label="Tổng tiền:"
                  applyChange={applyChange}
                  prop={"TongTien"}
                  value={data?.TongTien}
                  placeholder="0"
                  disabled={true}
                />
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["TongTien"]}
                  </p>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };
  return (
    <Drawer
      title="Thêm: điện"
      width={920}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined /> Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {/* <FormDrawerUser
        className="vh-100"
        isEdit={isEdit}
        isOpen={modal}
        toggle={toggle}
        detail={staffForm}
      /> */}
      {contextHolder}
      {renderThongTinChung()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
