import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Flex,
  Input,
  Popconfirm,
  Row,
  Select,
  Table,
  Tooltip,
  notification,
} from "antd";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import FormDetail from "./FormDetail";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { CiChat2, CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import { ToaNhaService } from "services/ToaNhaService";
import { MatBangService } from "services/MatBangService";
import { AiOutlineMenuFold } from "react-icons/ai";
import { MSBService } from "services/MSBService";
import { CongNoService } from "services/CongNoService";
import { SoQuyService } from "services/SoQuyService";
import FormAddExcel from "./FormAddExcel";
import {
  ExportOutlined,
  ImportOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import MauChiTiet from "./FormPrint/MauChiTiet";
import { useReactToPrint } from "react-to-print";
import Mau1A5 from "./FormPrint/Mau1A5";
export default function Index() {
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("filter") === "true"
  );
  const componentRef = useRef();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [onOff, setOnOff] = useState(false);
  const [toanha, setToanha] = useState([]);
  const [khoinha, setKhoinha] = useState([]);
  const [tanglau, setTanglau] = useState([]);
  const [filterCondition, setFilterCondition] = useState({
    MaTN: null,
    InputSearch: "",
    TuNgay: new Date(),
    DenNgay: new Date(),
    PageIndex: 1,
    PageSize: 10000,
  });
  const refDetail = useRef(null);
  const refDetailView = useRef(null);
  const refAddExcel = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const loadCategories = async () => {
    let _tn = await ToaNhaService.getToaNhaByNV();
    setToanha(_tn.data);
    let _filter = _.cloneDeep(filterCondition);
    _filter.MaTN = _tn.data[0]?.MaTN;
    setFilterCondition(_filter);
    loadcategoriesByMaTN(_tn.data[0]?.MaTN);
    filter(_filter);
  };
  const loadcategoriesByMaTN = async (matn) => {
    let _tn = await ToaNhaService.Categories.getKhoiNha({
      MaTN: matn,
    });
    setKhoinha(_tn.data);
  };
  useEffect(() => {
    loadCategories();
  }, []);
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await SoQuyService.PhieuThu.getPhieuThu(_filter);
    if (_res.data) setData(_res.data);
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () => {
    filter(filterCondition);
  };

  const columns = [
    {
      title: "Số phiếu",
      dataIndex: "SoPhieu",
      width: 150,
      // ellipsis: true,
      render: (item, record) => (
        <p
          style={{ fontWeight: "bold", color: "#1890ff", cursor: "pointer" }}
          onClick={() => refDetailView.current.show(record.MaPhieu)}
        >
          {record.SoPhieu}
        </p>
      ),
    },
    {
      title: "Ngày thu",
      dataIndex: "NgayThu",
      // ellipsis: true,
      render: (item) => Format_Date(item),
    },
    {
      title: "Mặt bằng",
      dataIndex: "MaSoMB",
      // ellipsis: true,
    },
    {
      title: "Mã khách hàng",
      dataIndex: "KyHieu",
      // ellipsis: true,
    },
    {
      title: "Người nộp",
      dataIndex: "NguoiNop",
      // ellipsis: true,
    },
    {
      title: "Địa chỉ",
      dataIndex: "DiaChi",
      width: 150,
      // ellipsis: true,
    },
    {
      title: "Số tiền",
      dataIndex: "SoTienThanhToan",
      // ellipsis: true,
      render: (item) => Format_Currency(item),
    },
    {
      title: "Tổng tiền CT",
      dataIndex: "SoTienTongTT",
      // ellipsis: true,
      render: (item) => Format_Currency(item),
    },
    {
      title: "Hình thức thu tiền",
      dataIndex: "HinhThuc",
      // ellipsis: true,
    },
    {
      title: "Số tài khoản",
      dataIndex: "SoTaiKhoan",
      // ellipsis: true,
    },
    {
      title: "Đợt TT",
      dataIndex: "DotThanhToan",
      // ellipsis: true,
      render: (item) => Format_Date(item),
    },
    {
      title: "Đã duyệt",
      dataIndex: "KeToanDaDuyet",
      // ellipsis: true,

      render: (text, record) => (
        <Checkbox checked={record?.KeToanDaDuyet}></Checkbox>
      ),
    },
    {
      title: "Số tháng",
      dataIndex: "SoThangThuPhiQuanLy",
      // ellipsis: true,
    },
    {
      title: "Loại phiếu thu",
      dataIndex: "LoaiPhieuThu",
      // ellipsis: true,
    },
    {
      title: "Thu Thừa",
      dataIndex: "ThuThua",
      // ellipsis: true,
    },
    {
      title: "Người thu",
      dataIndex: "NguoiThu",
      // ellipsis: true,
    },
    {
      title: "Diễn giải",
      dataIndex: "DienGiai",
      width: 200,
      // ellipsis: true,
    },
  ];
  const remove = async (value) => {
    let _res = await ToaNhaService.deleteToaNha({ ID: Number(value) });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.MaTN !== Number(value));
      setData(fil);
      openNotification("success", "topRight", "Xoá thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const onHandleHide = () => {
    setCollapsed(!collapsed);
    localStorage.setItem("filter", !collapsed);
  };
  const onSelectChange = async (newSelectedRowKeys) => {
    if (newSelectedRowKeys?.length > 100) {
      openNotification("error", "topRight", "Số lượng chọn không quá 100");
      return;
    }
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  function removeSpecialCharacters(str) {
    return str.replace(/[_\.\s]/g, "");
  }
  const createAccount = async () => {
    setLoading(true);
    let _data = data?.filter((v) => selectedRowKeys.includes(v.MaMB));
    let _login = await MSBService.Login();
    let _payload = {
      Token: JSON.parse(_login.data)?.access_token,
      ServiceCode: "ADL",
      Rows: [],
    };
    _data?.map((item) => {
      if (item.TenKH) {
        let masomb = removeSpecialCharacters(item.MaSoMB);
        let _row = {
          AccountNumber: "ADL" + masomb,
          Status: "1",
          ReferenceNumber: masomb,
          SuggestAmount: "",
          PayType: "0",
          Name: item.TenKH,
          Detail1: String(item.MaMB),
          Detail2: String(item.MaTN),
          Phone: item.DienThoaiKH,
        };
        _payload.Rows.push(_row);
      }
    });
    let _res = await MSBService.CreateAccount(_payload);
    if (_res.status === 2000) {
      openNotification("success", "topRight", "Tạo tài khoản thành công");
      filter(filterCondition);
    } else {
      openNotification("error", "topRight", _res?.message);
    }
    setLoading(false);
  };

  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        if (filterCondition.MaTN) filter(filterCondition);
      }, 500);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = () => {
    const fieldsToExport = columns.map((col) => col.dataIndex);
    const filteredData = data.map((item) => {
      let filteredItem = {};
      fieldsToExport.forEach((field) => {
        filteredItem[field] = item[field];
      });
      return filteredItem;
    });
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "Danh sách phiếu thu" + fileExtension);
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <Row gutter={15}>
      <Col span={collapsed ? 0 : 4} style={{ transition: "all 0.3s ease" }}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
          options={{
            ToaNha: toanha,
            KhoiNha: khoinha,
          }}
        />
      </Col>
      <Col span={collapsed ? 24 : 20}>
        <Toolbar
          left={() => (
            <Flex align="center" gap={10}>
              <AiOutlineMenuFold
                size={20}
                className="icon-filter"
                color="primary"
                onClick={() => onHandleHide()}
              />
              <Input
                style={{
                  width: "300px",
                  padding: "4px 11px",
                  borderRadius: 10,
                }}
                placeholder="Tìm mã số mặt bằng, điện thoại, tên khách hàng"
                onChange={(e) => onSearch(e.target.value)}
              />
            </Flex>
          )}
          right={() => (
            <Flex gap={10}>
              {selectedRowKeys?.length > 0 && (
                <Button
                  loading={loading}
                  type="primary"
                  ghost
                  onClick={() => createAccount()}
                >
                  Tạo tài khoản MSB ({selectedRowKeys?.length})
                </Button>
              )}
              <Button
                onClick={() => {
                  setOnOff(!onOff);
                  handlePrint();
                }}
              >
                Xem mẫu
              </Button>
              <Button
                loading={loading}
                type="primary"
                onClick={() => refDetail.current.show()}
              >
                <PlusOutlined style={{ fontSize: "13px", margin: "0 -4px" }} />{" "}
                Thêm
              </Button>
              <Button onClick={() => refAddExcel.current.show()}>
                <ImportOutlined
                  style={{ fontSize: "13px", margin: "0 -2px" }}
                />
                Import
              </Button>
              <Button onClick={() => exportToCSV()}>
                <ExportOutlined
                  style={{ fontSize: "13px", margin: "0 -2px" }}
                />
                Export
              </Button>
            </Flex>
          )}
        />
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          bordered
          scroll={{
            y: window.innerHeight - 250,
            x: 2000,
          }}
          // rowSelection={rowSelection}
          pagination={{
            showSizeChanger: true,
            defaultPageSize: 20,
            pageSizeOptions: ["20", "50", "100", "500", "10000"],
            position: ["bottomRight"],
          }}
          virtual={true}
          size="small"
          summary={(pageData) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={6}>
                    <p style={{ fontWeight: "bold" }}>
                      Tổng: {pageData?.length}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={0}>
                    <p style={{ fontWeight: "bold" }}>
                      {" "}
                      {Format_Currency(
                        _.sum(_.map(pageData, "SoTienThanhToan"))
                      )}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={0}>
                    <p style={{ fontWeight: "bold" }}>
                      {" "}
                      {Format_Currency(_.sum(_.map(pageData, "SoTienTongTT")))}
                    </p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Col>
      <FormAdd
        ref={refDetail}
        onAfterSubmit={onAfterSubmit}
        filterCondition={filterCondition}
      />
      <FormDetail ref={refDetailView} onAfterSubmit={onAfterSubmit} />
      <FormAddExcel ref={refAddExcel} />
      {/* <Mau1A5 ref={componentRef} /> */}

      {contextHolder}
    </Row>
  );
}
