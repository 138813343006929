import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Button,
  Popconfirm,
  notification,
  Tooltip,
  Badge,
  Row,
  Col,
  Flex,
  Tag,
  Checkbox,
  Input,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import FilterModal from "./FilterModal";
import { CiTrash } from "react-icons/ci";
import Toolbar from "components/Toolbar";
import { Format_Date } from "layouts/ultis/common";
import {
  MdEdit,
  MdNotificationsActive,
  MdOutlinePendingActions,
  MdOutlinePreview,
} from "react-icons/md";
import { OtherService } from "services/OtherService";
import { Format_Datetime } from "layouts/ultis/common";
import { AiOutlineMenuFold } from "react-icons/ai";
import { DeleteOutlined, EditTwoTone, PlusOutlined } from "@ant-design/icons";
import FormAdd from "./FormAdd";
import { HeThongService } from "services/HeThongService";
import { ToaNhaService } from "services/ToaNhaService";
export default function BieuMau() {
  const [data, setData] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [toanha, setToanha] = useState([]);
  const [loaiBM, setLoaiBM] = useState([]);
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("filter") === "true"
  );
  const [filterCondition, setFilterCondition] = useState({
    MaTN: null,
  });
  const refDetail = useRef(null);
  useEffect(() => {
    loadCategories(filterCondition);
  }, []);

  const loadCategories = async () => {
    let _tn = await ToaNhaService.getToaNhaByNV();
    setToanha(_tn.data);
    let _filter = _.cloneDeep(filterCondition);
    _filter.MaTN = _tn.data[0]?.MaTN;
    let _res = await HeThongService.BieuMau.getLBM();
    if (_res.data) setLoaiBM(_res.data);

    setFilterCondition(_filter);
    filter(_filter);
  };
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await HeThongService.BieuMau.get(_filter);
    setData(_res.data);
    setLoading(false);
  };

  const handleXemMau = (link) => {
    if (link) {
      const fullLink = `https://view.officeapps.live.com/op/view.aspx?src=${link}`;
      window.open(fullLink, "_blank"); // Opens the link in a new tab
    }
  };
  const handleEditMau = async (value) => {
    HeThongService.BieuMau.editMau({ MaBM: value.MaBM });
  };
  const columns = [
    {
      title: "Tên biểu mẫu",
      dataIndex: "TenBM",
    },
    {
      title: "Loại biểu mẫu",
      dataIndex: "MaLBM",
      render: (item) => loaiBM.map((lBM) => lBM.MaLBM === item)?.TenLBM,
    },
    {
      title: "Khóa",
      dataIndex: "IsLock",
      render: (item) => <Checkbox checked={item}></Checkbox>,
    },
    {
      title: "Ghi chú",
      dataIndex: "Description",
    },
    {
      title: "Người tạo",
      dataIndex: "NguoiTao",
    },
    {
      title: "Ngày tạo",
      dataIndex: "NgayThem",
      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Người cập nhật",
      dataIndex: "NguoiCapNhat",
    },
    {
      title: "Ngày cập nhật",
      dataIndex: "NgayCN",
      render: (item) => (item ? Format_Date(item) : ""),
    },

    {
      title: "",
      fixed: "right",
      width: "150px",
      render: (item) => (
        <div style={{ display: "flex" }}>
          <Tooltip placement="topRight" title="Xem mẫu">
            <Button
              style={{ marginLeft: 10 }}
              type="primary"
              ghost
              icon={<MdOutlinePreview />}
              onClick={() => handleXemMau(item?.Link)}
            />
          </Tooltip>
          <Tooltip placement="topRight" title="Sửa mẫu">
            <Button
              style={{ marginLeft: 10 }}
              type="primary"
              ghost
              icon={<EditTwoTone />}
              onClick={() => handleEditMau(item)}
            />
          </Tooltip>
          <Tooltip placement="topRight" title="Sửa">
            <Button
              style={{ marginLeft: 10 }}
              type="primary"
              ghost
              icon={<EditTwoTone />}
              onClick={() => refDetail.current.update(item)}
            />
          </Tooltip>
          <Popconfirm
            title="Xoá biểu mẫu"
            description="Bạn có chắc chắn muốn xoá biểu mẫu?"
            okText="Đồng ý"
            cancelText="Không"
            onConfirm={() => remove(item.MaBM)}
          >
            <Tooltip placement="topRight" title="Xoá">
              <Button
                style={{ marginLeft: 10 }}
                danger
                ghost
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        </div>
      ),
    },
  ];
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const remove = async (item) => {
    let _res = await HeThongService.BieuMau.delete({
      MaBM: item,
    });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.MaBM !== Number(item));
      setData(fil);
      openNotification("success", "topRight", "Xoá  thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const onAfterSubmit = () => {
    filter(filterCondition);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };

  const onHandleHide = () => {
    setCollapsed(!collapsed);
    localStorage.setItem("filter", !collapsed);
  };
  return (
    <Row gutter={[10, 10]}>
      <Col span={collapsed ? 0 : 4} style={{ transition: "all 0.3s ease" }}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
          options={{
            ToaNha: toanha,
          }}
        />
      </Col>
      <Col span={collapsed ? 24 : 20}>
        <Toolbar
          left={() => (
            <Flex align="center" gap={10}>
              <AiOutlineMenuFold
                size={20}
                className="icon-filter"
                color="primary"
                onClick={() => onHandleHide()}
              />
              <Input
                style={{
                  width: "300px",
                  padding: "4px 11px",
                  borderRadius: 10,
                }}
                placeholder="Tìm mã số mặt bằng, điện thoại, tên khách hàng"
                // onChange={(e) => onSearch(e.target.value)}
              />
            </Flex>
          )}
          right={() => (
            <Flex gap={10}>
              <Button
                loading={loading}
                type="primary"
                onClick={() => refDetail.current.show()}
              >
                <PlusOutlined style={{ fontSize: "13px", margin: "0 -4px" }} />{" "}
                Thêm
              </Button>
              {/* <Button onClick={() => refAddExcel.current.show()}>
                <ImportOutlined
                  style={{ fontSize: "13px", margin: "0 -2px" }}
                />
                Import
              </Button>
              <Button onClick={() => exportToCSV()}>
                <ExportOutlined
                  style={{ fontSize: "13px", margin: "0 -2px" }}
                />{" "}
                Export
              </Button> */}
            </Flex>
          )}
        />

        <Table
          style={{ height: 100 }}
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 250,
            // x: 2000,
          }}
          size="small"
          bordered
        />
      </Col>
      {contextHolder}
      <FormAdd
        ref={refDetail}
        onAfterSubmit={onAfterSubmit}
        maTN={filterCondition.MaTN}
        loaiBM={loaiBM}
      />
    </Row>
  );
}
