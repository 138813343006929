import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Select,
  Row,
  Col,
  Drawer,
  notification,
  DatePicker,
  Table,
  Divider,
  Checkbox,
} from "antd";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import vi from "dayjs/locale/vi";
import _ from "lodash";
import { CardService } from "services/CardService";
import { NhanVienService } from "services/NhanVienService";
import { CustomerService } from "services/CustomerService";
import { Format_Date } from "layouts/ultis/common";
import { YeuCauService } from "services/YeuCauService";
import { Format_Datetime } from "layouts/ultis/common";

const { TextArea } = Input;
const FormDetail = (props, ref) => {
  const { onAfterSubmit ,options } = props;
  const [api, contextHolder] = notification.useNotification();
  const [maKH,setMaKH] = useState(null);
  const [status, setStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [nhatky, setNhatky] = useState([]);
  const [open, setOpen] = useState(false);

  const loadChamSoc  = async (mayc) =>{
    let _res = await YeuCauService.getPhanHoi({ ID: mayc });
    setNhatky(_res.data ?? []);
  }
  useImperativeHandle(ref, () => ({
    show: async (MaYC) => {
      setOpen(true);
      setData({YeuCauID: MaYC})
      loadChamSoc(MaYC);
    },
   
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let _res = await YeuCauService.addPhanHoi(_data);
    setLoading(false);
    if (_res.status === 2000) {
      setData({ YeuCauID: _data.YeuCauID });
      loadChamSoc(_data.YeuCauID);
      onAfterSubmit();
      openNotification("success", "topRight", `Thêm lịch sử thành công!`);
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  // chăm sóc
  const columns = [
    {
      title: "Ngày xử lý",
      dataIndex: "NgayThang",
      render: (item) => Format_Datetime(item),
      width: "140px",
    },
    {
      title: "Trạng thái",
      dataIndex: "MaTT",
      render: (item) => options?.TrangThai?.find((v) => v.MaTT === item)?.TenTT,
      width: "120px",
    },
    {
      title: "Ghi chú",
      dataIndex: "NoiDung",
    },
    {
      title: "Nhân viên XL",
      dataIndex: "MaNVXL",
      render: (item) => options?.NhanVien?.find((v) => v.MaNV === item)?.HoTenNV,

      width: "150px",
    },
  ];
  return (
    <Drawer
      title="Phản hồi"
      width={720}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <>
          <p style={{ fontSize: 14, fontWeight: "bold", marginRight: 20 }}>
            {options?.ToaNha?.find((v) => v.MaTN === data?.MaTN)?.TenTN}
          </p>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & đóng
          </Button>
        </>
      }
    >
      {contextHolder}
      <Row gutter={[24, 8]}>
        <Col span={data?.isGiaoViec ? 12 : 24} className="gutter-row">
          <p>Trạng thái</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaTT}
            placeholder="Chọn trạng thái"
            onChange={(v) => applyChange("MaTT", v)}
            options={options?.TrangThai?.filter((v) => v.MaTT !== 0)}
            fieldNames={{ label: "TenTT", value: "MaTT" }}
          />
        </Col>
        {data?.isGiaoViec && (
          <Col span={12} className="gutter-row">
            <p>Nhân viên</p>
            <Select
              style={{
                width: "100%",
              }}
              value={data?.MaNVXL}
              placeholder="Chọn nhân viên"
              onChange={(v) => applyChange("MaNVXL", v)}
              options={options?.NhanVien}
              fieldNames={{ label: "HoTenNV", value: "MaNV" }}
            />
          </Col>
        )}

        <Col span={24}>
          <p>Ghi chú</p>
          <TextArea
            value={data?.NoiDung}
            onChange={(e) => applyChange("NoiDung", e.target.value)}
            placeholder="Nhập ghi chú"
            // rows={4}
          />
        </Col>
        <Col span={8}>
          <Checkbox
            checked={data?.isGiaoViec}
            onChange={(v) => applyChange("isGiaoViec", v.target.checked)}
          >
            Giao việc
          </Checkbox>
        </Col>
        <Col span={8}>
          <Checkbox
            checked={data?.isCuDanXem}
            onChange={(v) => applyChange("isCuDanXem", v.target.checked)}
          >
            TB app cư dân
          </Checkbox>
        </Col>
        {/* <Col span={8}>
          <Checkbox
          // checked={isbarcode}
          // onChange={(v) => setIsbarcode(v.target.checked)}
          >
            Đã hoàn thành
          </Checkbox>
        </Col> */}
        {/* <Col span={2} style={{ textAlign: "right" }}>
          <Button onClick={() => onSubmit(false)} type="primary">
            Lưu
          </Button>
        </Col> */}

        <Col span={24}>
          <Divider orientation="left">Lịch sử</Divider>
          <Table
            style={{ height: 100 }}
            loading={loading}
            dataSource={nhatky}
            columns={columns}
            size="small"
          />
        </Col>
      </Row>
    </Drawer>
  );
};
export default forwardRef(FormDetail);
