import React from "react";
import {
  notification,
  Row,
  Col,
  Flex,
  Select,
  Skeleton,
  Input,
  Image,
  Button,
  Tag,
} from "antd";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import MessageItem from "./MessageItem";
import { CgHello } from "react-icons/cg";
const MessageBox = ({ setMessageBox, loadingms, messages }) => {
  return (
    <div className="content-mid">
      <PerfectScrollbar containerRef={(ref) => setMessageBox(ref)}>
        {loadingms ? (
          <Flex align="end" justify="center" style={{ height: "100%" }}>
            <Skeleton active avatar style={{ width: "60%" }} />
          </Flex>
        ) : (
          <div className="content">
            {messages?.length === 0 ? (
              <Flex align="center" justify="center">
                <div style={{ textAlign: "center" }}>
                  <CgHello size={100} />
                  <p style={{ fontWeight: "bold", fontSize: 16 }}>
                    Nhóm chat đã được cư dân tạo. Chưa có tin nhắn mới!
                  </p>
                </div>
              </Flex>
            ) : (
              messages?.map((item, i) => {
                return (
                  <MessageItem
                    key={item.id}
                    message={item}
                    messagepre={messages[i - 1]}
                  />
                );
              })
            )}
          </div>
        )}
      </PerfectScrollbar>
    </div>
  );
};

export default React.memo(MessageBox);
