import React, { useEffect, useState } from "react";
import { DatePicker, Select } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import ActiveRoute from "components/ActiveRoute";
import { ToaNhaService } from "services/ToaNhaService";
import FilterDate from "components/FilterDate";

const FilterKN = ({ ...props }) => {
  const {
    filterCondition,
    onChangeFilter,
    toanha,
    secondaryOption,
    activeTab,
  } = props;

  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      default:
        _filter[p] = v;
        break;
    }
    onChangeFilter(_filter);
  };
  // const loadcategories = async () => {
  //   let _tn = await ToaNhaService.getToaNhaByNV();
  //   setToanha(_tn.data);
  // };
  // useEffect(() => {
  //   loadcategories();
  // }, []);
  const onChangeDate = (v) => {
    let _filter = _.cloneDeep(filterCondition);
    _filter.TuNgay = v.TuNgay;
    _filter.DenNgay = v.DenNgay;
    onChangeFilter(_filter);
  };
  const shouldRenderFilterDate = () => {
    return !(
      (activeTab === 3 && secondaryOption === 2) ||
      activeTab === 1 ||
      (activeTab === 3 && secondaryOption === 1)
    );
  };

  return (
    <div>
      <div className="box-search">
        <p className="label">Dự án</p>
        <Select
          placeholder="Chọn dự án"
          onChange={(v) => applyChangeFilter("MaTN", v)}
          style={{
            width: "100%",
          }}
          value={filterCondition.MaTN}
          options={toanha}
          fieldNames={{ label: "TenTN", value: "MaTN" }}
        />
      </div>

      {shouldRenderFilterDate() && <FilterDate onChangeDate={onChangeDate} />}

      {/* <div className="box-search">
        <p className="label">Loại thu</p>
        <Select
          mode="multiple"
          placeholder="Chọn loại thu"
          onChange={(v) => applyChangeFilter("LoaiThu", v)}
          style={{
            width: "100%",
          }}
          options={loaiPT}
          fieldNames={{ label: "TenLPT", value: "ID" }}
        />
      </div> */}
    </div>
  );
};
export default FilterKN;
