import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Flex,
  Table,
} from "antd";
import _ from "lodash";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import { DateTime } from "luxon";
import TextArea from "antd/es/input/TextArea";
import { Format_Date } from "layouts/ultis/common";
import { DichVuService } from "services/DichVuService";
import { Format_Currency } from "layouts/ultis/common";
import { SaveOutlined } from "@ant-design/icons";
const FormAdd = (props, ref) => {
  const {
    onAfterSubmit,
    dataSelect,
    selectedRowKeys,
    maTN,
    setSelectedRowKeys,
    dataAllDV,
  } = props;
  const [api, contextHolder] = notification.useNotification();
  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");
  const defaultData = {
    NgayTao: dateInHoChiMinh.toISO(),
  };
  const [data, setData] = useState(defaultData);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [onOffTable, setOnOffTable] = useState(false);
  const [table, setTable] = useState("1");
  const defaultErrors = {};
  const [errors, setErrors] = useState(defaultErrors);
  const [titleCongNo, setTiTleCongNo] = useState("");

  useImperativeHandle(ref, () => ({
    show: async (item, titleCongNo) => {
      setTable(item);
      setOpen(true);
      setTiTleCongNo(titleCongNo);
    },
    update: async (data) => {
      setOpen(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaultData);
    setLoading(false);
    setErrors(defaultErrors);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "NgayTao":
        const date = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString = date.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    let _res;
    if (table == "1") {
      _res = await DichVuService.DichVuKhac.addCongNoByTN({
        ..._data,
        MaTN: maTN,
      });
    } else if (table == "2") {
      const payloadMB = [
        ...new Set(
          dataAllDV
            .filter((item) => selectedRowKeys.includes(item.MaDV))
            .map((item) => item.MaMB)
        ),
      ];

      _res = await DichVuService.DichVuKhac.addCongNoByMB({
        ..._data,
        MaTN: maTN,
        MatBang: payloadMB,
      });
    } else {
      _res = await DichVuService.DichVuKhac.addCongNoByDV({
        ..._data,
        MaTN: maTN,
        DichVu: selectedRowKeys,
      });
    }

    setLoading(false);
    if (_res.status === 2000) {
      setSelectedRowKeys([]);
      openNotification(
        "success",
        "topRight",
        `${_data.MaNV ? "Sửa" : "Tạo"} công nợ thành công!`
      );
      if (close) onClose();
      onAfterSubmit();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const columns = [
    {
      title: "Mặt bằng",
      dataIndex: "MaSoMB",
      ellipsis: true,
    },
    {
      title: "Ngày TT",
      dataIndex: "NgayTT",
      render: (item) => Format_Date(item),
    },
    {
      title: "Loại dịch vụ",
      dataIndex: "TenLDV",
    },
    {
      title: "Số lượng",
      dataIndex: "SoLuong",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Tổng tiền",
      dataIndex: "SoTien",
      render: (item) => Format_Currency(item),
    },
  ];
  return (
    <Drawer
      title={`Tạo công nợ - ${titleCongNo}`}
      width={820}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined /> Lưu & đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      {table === "1" ? (
        <Row
          style={{ marginTop: "25px", marginBottom: "20px" }}
          gutter={[24, 20]}
        >
          <Col span={24}>
            <DatePickerCustom
              label="Ngày nhập:"
              applyChange={applyChange}
              prop={"NgayTao"}
              value={data?.NgayTao}
              placeholder={"Chọn ngày tao"}
            />
          </Col>
          <Col span={24}>
            <Flex>
              <p
                style={{
                  fontWeight: "600",
                  width: "20%",
                  borderBottom: "1px solid #d9d9d9",
                }}
              >
                Diễn giải:
              </p>
              <TextArea
                value={data?.DienGiai}
                onChange={(e) => applyChange("DienGiai", e.target.value)}
              />
            </Flex>
            {errors && (
              <p style={{ textAlign: "center", color: "red" }}>
                {errors && errors["DienGiai"]}
              </p>
            )}
          </Col>
        </Row>
      ) : table === "2" ? (
        <>
          <Row
            style={{ marginTop: "25px", marginBottom: "20px" }}
            gutter={[24, 20]}
          >
            <Col span={24}>
              <DatePickerCustom
                label="Ngày nhập:"
                applyChange={applyChange}
                prop={"NgayTao"}
                value={data?.NgayTao}
                placeholder={"Chọn ngày tao"}
              />
            </Col>
          </Row>
          <Table
            style={{ marginTop: "30px" }}
            dataSource={dataSelect}
            columns={columns}
          />
        </>
      ) : (
        <>
          <Row
            style={{ marginTop: "25px", marginBottom: "20px" }}
            gutter={[24, 20]}
          >
            <Col span={24}>
              <DatePickerCustom
                label="Ngày nhập:"
                applyChange={applyChange}
                prop={"NgayTao"}
                value={data?.NgayTao}
                placeholder={"Chọn ngày tao"}
              />
            </Col>
          </Row>
          <Table dataSource={dataSelect} columns={columns} />
        </>
      )}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
