import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  ColorPicker,
  Dropdown,
  Popconfirm,
  Row,
  Table,
  Tabs,
  Tooltip,
  notification,
} from "antd";
import FormAdd from "./FormAdd";
import { CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import TabPane from "antd/es/tabs/TabPane";
import { ToaNhaService } from "services/ToaNhaService";
import FilterKN from "./FilterKN";
import FilterTL from "./FilterTL";
import { CategoryService } from "services/CategoryService";
import { PlusOutlined } from "@ant-design/icons";
import FormAddMultiple from "./FormAddMultiple";

export default function Index() {
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toaNha, setToaNha] = useState([]);
  // const [filterCondition, setFilterCondition] = useState({
  //   MaTN: null,
  // });
  const [filterCondition, setFilterCondition] = useState({
    MaTN: null,
    MaKN: null,
  });

  const refDetail = useRef(null);
  const refMultiple = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const tabs = [
    {
      title: "Khối nhà",
      key: "0",
    },
    {
      title: "Tầng lầu",
      key: "1",
    },
    {
      title: "Loại mặt bằng",
      key: "2",
    },
    {
      title: "Trạng thái căn hộ",
      key: "3",
    },
  ];
  const [activeTab, setActiveTab] = useState("0");

  // useEffect(() => {
  //   ToaNhaService.getToaNhaByNV().then((res) => {
  //     let _filter = _.cloneDeep(filterCondition);
  //     _filter.MaTN = res.data[0].MaTN;
  //     filter(activeTab, _filter);
  //     setFilterCondition(_filter);
  //     setToaNha(res.data);
  //   });
  // }, []);
  // const filter = async (tab, _filter) => {
  //   setLoading(true);
  //   let _res;
  //   switch (tab) {
  //     case "0":
  //       _res = await ToaNhaService.Categories.getKhoiNha(_filter);
  //       if (_res?.data) {
  //         let _filter = _.cloneDeep(filterCondition);
  //         _filter.MaKN = _res.data[0]?.MaKN;
  //       }
  //       break;
  //     case "1":
  //       _res = await ToaNhaService.Categories.getTang(_filter);
  //       break;
  //     case "2":
  //       _res = await ToaNhaService.Categories.getLMB(_filter);
  //       break;
  //     case "3":
  //       // _res = await ToaNhaService.Categories.getTrangThaiCanHo(_filter);
  //       break;
  //     default:
  //       break;
  //   }

  //   if (_res?.data) {
  //     setData(_res.data);
  //   }
  //   setLoading(false);
  // };

  useEffect(() => {
    ToaNhaService.getToaNhaByNV().then((res) => {
      let _filter = _.cloneDeep(filterCondition);
      _filter.MaTN = res.data[0].MaTN;
      filter(activeTab, _filter);
      setFilterCondition(_filter);
      setToaNha(res.data);
    });
  }, []);

  const filter = async (tab, _filter) => {
    setLoading(true);
    let _res;

    if (tab === "0") {
      _res = await ToaNhaService.Categories.getKhoiNha(_filter);
      if (_res?.data) {
        _filter.MaKN = _res.data[0]?.MaKN;
      }
    }

    switch (tab) {
      case "0":
        if (!_res?.data) {
          _res = await ToaNhaService.Categories.getKhoiNha(_filter);
          if (_res?.data) {
            _filter.MaKN = _res.data[0]?.MaKN;
          }
        }
        break;
      case "1":
        if (!_filter.MaKN) {
          // If MaKN is not set, retrieve it by calling getKhoiNha
          const khoiNhaRes = await ToaNhaService.Categories.getKhoiNha(_filter);
          if (khoiNhaRes?.data) {
            _filter.MaKN = khoiNhaRes.data[0]?.MaKN;
          }
        }
        _res = await ToaNhaService.Categories.getTang(_filter);
        break;
      case "2":
        _res = await ToaNhaService.Categories.getLMB(_filter);
        break;
      case "3":
        _res = await CategoryService.DuAn.MatBang.TrangThai.get(_filter);
        break;
      default:
        break;
    }

    if (_res?.data) {
      setData(_res.data);
    }
    setLoading(false);
  };

  const onAfterSubmit = () => {
    if (activeTab === "0") {
      filter(activeTab, filterCondition);
    } else if (activeTab === "1") {
      filter(activeTab, filterCondition);
    } else if (activeTab === "2") {
      filter(activeTab, filterCondition);
    } else if (activeTab === "3") {
      filter(activeTab, filterCondition);
    }
  };

  const onMenuClick = (e) => {
    refMultiple.current.show(activeTab, filterCondition);
  };
  const items = [
    {
      key: "1",
      label: "Thêm nhiều",
    },
  ];
  const columns = [
    {
      title: "Tên khối nhà",
      dataIndex: "TenKN",
    },
    {
      title: (
        <div style={{ textAlign: "center" }}>
          <Button
            type="primary"
            onClick={() => refDetail.current.show(activeTab, filterCondition)}
          >
            <PlusOutlined style={{ fontSize: "13px", margin: "0 -4px" }} /> Thêm
          </Button>
        </div>
      ),
      fixed: "right",
      width: "120px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item, activeTab)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá danh mục"
              description="Bạn có chắc chắn muốn xoá danh mục?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.MaKN)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const columns2 = [
    {
      title: "Tên tầng lầu",
      dataIndex: "TenTL",
    },
    {
      title: (
        <div style={{ textAlign: "center" }}>
          {/* <Button
            type="primary"
            onClick={() => refDetail.current.show(activeTab, filterCondition)}
          >
            <PlusOutlined style={{ fontSize: "13px", margin: "0 -4px" }} /> Thêm
          </Button> */}
          <Dropdown.Button
            type="primary"
            onClick={() => refDetail.current.show(activeTab, filterCondition)}
            menu={{
              items,
              onClick: onMenuClick,
            }}
          >
            <PlusOutlined style={{ fontSize: "13px", margin: "0 -4px" }} /> Thêm
          </Dropdown.Button>
        </div>
      ),
      fixed: "right",
      width: "120px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item, activeTab)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá danh mục"
              description="Bạn có chắc chắn muốn xoá danh mục?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.MaTL)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const columns3 = [
    {
      title: "Tên loại mặt bằng",
      dataIndex: "TenLMB",
    },
    {
      title: (
        <div style={{ textAlign: "center" }}>
          <Button
            type="primary"
            onClick={() => refDetail.current.show(activeTab, filterCondition)}
          >
            <PlusOutlined style={{ fontSize: "13px", margin: "0 -4px" }} /> Thêm
          </Button>
        </div>
      ),
      fixed: "right",
      width: "120px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item, activeTab)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá danh mục"
              description="Bạn có chắc chắn muốn xoá danh mục?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.MaLMB)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const columns4 = [
    {
      title: "Tên trạng thái",
      dataIndex: "TenTT",
      ellipsis: true,
    },
    {
      title: "Màu nền",
      dataIndex: "ColorWeb",
      render: (text, record) => {
        return <ColorPicker value={record.ColorWeb} disabled />;
      },
    },
    {
      title: "Cho thuê",
      dataIndex: "ChoThue",
      render: (text, record) => <Checkbox checked={record?.ChoThue}></Checkbox>,
      ellipsis: true,
    },
    {
      title: "Diễn giải",
      dataIndex: "DienGiai",
      ellipsis: true,
    },
    {
      title: (
        <div style={{ textAlign: "center" }}>
          <Button
            type="primary"
            onClick={() => refDetail.current.show(activeTab, filterCondition)}
          >
            <PlusOutlined style={{ fontSize: "13px", margin: "0 -4px" }} /> Thêm
          </Button>
        </div>
      ),
      fixed: "right",
      width: "120px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item, activeTab)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá danh mục"
              description="Bạn có chắc chắn muốn xoá danh mục?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.MaTT)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const remove = async (value) => {
    const handleResponse = (response, dataKey) => {
      if (response.status === 2000) {
        let _data = _.cloneDeep(data);
        let filteredData = _data.filter((v) => v[dataKey] !== Number(value));
        setData(filteredData);
        openNotification("success", "topRight", "Xoá danh mục thành công");
      } else {
        openNotification("error", "topRight", response?.message);
      }
    };

    let _res = null;
    try {
      switch (activeTab) {
        case "0":
          _res = await ToaNhaService.Categories.deleteKhoiNha({
            ID: Number(value),
          });
          handleResponse(_res, "MaKN");
          break;
        case "1":
          _res = await ToaNhaService.Categories.deleteTang({
            ID: Number(value),
          });
          handleResponse(_res, "MaTL");
          break;
        case "2":
          _res = await ToaNhaService.Categories.deleteLMB({
            ID: Number(value),
          });
          handleResponse(_res, "MaLMB");
          break;
        case "3":
          _res = await CategoryService.DuAn.MatBang.TrangThai.delete({
            ID: Number(value),
          });
          handleResponse(_res, "MaTT");
          break;
        default:
          openNotification("error", "topRight", "Invalid tab selected");
          break;
      }
    } catch (error) {
      openNotification("error", "topRight", "An error occurred");
    }
  };

  // const remove = async (value) => {
  //   let _res = null;
  //   let _data = _.cloneDeep(data);

  //   switch (activeTab) {
  //     case 1:
  //       _res = await ToaNhaService.Categories.deleteKhoiNha({
  //         ID: Number(value),
  //       });
  //       if (_res.status === 2000) {
  //         let fil = _data.filter((v) => v.MaKN !== Number(value));
  //         setData(fil);
  //       }
  //       break;
  //     case 2:
  //       _res = await ToaNhaService.Categories.deleteTang({
  //         ID: Number(value),
  //       });
  //       if (_res.status === 2000) {
  //         let fil = _data.filter((v) => v.MaTL !== Number(value));
  //         setData(fil);
  //       }
  //       break;
  //     case 3:
  //       _res = await ToaNhaService.Categories.deleteLMB({
  //         ID: Number(value),
  //       });
  //       if (_res.status === 2000) {
  //         let fil = _data.filter((v) => v.MaLMB !== Number(value));
  //         setData(fil);
  //       }
  //       break;

  //     default:
  //       break;
  //   }

  //   if (_res.status === 2000) {
  //     openNotification("success", "topRight", "Xoá danh mục thành công");
  //   } else {
  //     openNotification("error", "topRight", _res?.message);
  //   }
  // };

  const onChange = (newActiveKey) => {
    setActiveTab(newActiveKey);
    if (newActiveKey === "0") {
      filter(newActiveKey, filterCondition);
    } else if (newActiveKey === "1") {
      filter(newActiveKey, filterCondition);
    } else if (newActiveKey === "2") {
      filter(newActiveKey, filterCondition);
    } else if (newActiveKey === "3") {
      filter(newActiveKey, filterCondition);
    }
    // filter(newActiveKey, filterCondition);
  };
  const onChangeFilter = (_filter) => {
    // filter(activeTab,_filter);
    // setFilterCondition(_filter);
    if (activeTab === "0") {
      filter(activeTab, _filter);
      setFilterCondition(_filter);
    } else if (activeTab === "1") {
      filter(activeTab, _filter);
      setFilterCondition(_filter);
    } else if (activeTab === "2") {
      filter(activeTab, _filter);
      setFilterCondition(_filter);
    } else if (activeTab === "3") {
      filter(activeTab, _filter);
      setFilterCondition(_filter);
    }
  };
  const renderTabContent = (tabKey) => {
    switch (tabKey) {
      case "0":
        return (
          <Row gutter={[10, 10]}>
            <Col span={4}>
              <FilterKN
                filterCondition={filterCondition}
                onChangeFilter={onChangeFilter}
                toaNha={toaNha}
              />
            </Col>
            <Col span={20}>
              <Table
                loading={loading}
                dataSource={data}
                columns={columns}
                scroll={{ y: window.innerHeight - 350 }}
                size="small"
                bordered
                pagination={false}
              />
            </Col>
          </Row>
        );
      case "1":
        return (
          <Row gutter={[10, 10]}>
            <Col span={4}>
              <FilterTL
                filterCondition={filterCondition}
                onChangeFilter={onChangeFilter}
                toaNha={toaNha}
              />
            </Col>
            <Col span={20}>
              <Table
                loading={loading}
                dataSource={data}
                columns={columns2}
                scroll={{ y: window.innerHeight - 350 }}
                size="small"
                bordered
                pagination={false}
              />
            </Col>
          </Row>
        );
      case "2":
        return (
          <Row gutter={[10, 10]}>
            <Col span={4}>
              <FilterKN
                filterCondition={filterCondition}
                onChangeFilter={onChangeFilter}
                toaNha={toaNha}
              />
            </Col>
            <Col span={20}>
              <Table
                loading={loading}
                dataSource={data}
                columns={columns3}
                scroll={{ y: window.innerHeight - 350 }}
                size="small"
                bordered
                pagination={false}
              />
            </Col>
          </Row>
        );
      case "3":
        return (
          <Row gutter={[10, 10]}>
            <Col span={4}>
              <FilterKN
                filterCondition={filterCondition}
                onChangeFilter={onChangeFilter}
                toaNha={toaNha}
              />
            </Col>
            <Col span={20}>
              <Table
                loading={loading}
                dataSource={data}
                columns={columns4}
                scroll={{ y: window.innerHeight - 350 }}
                size="small"
                bordered
                pagination={false}
              />
            </Col>
          </Row>
        );
      default:
        return null;
    }
  };

  return (
    <Box>
      <Tabs onChange={onChange} activeKey={activeTab}>
        {tabs?.map((item) => (
          <TabPane tab={item.title} key={item.key}>
            {renderTabContent(item.key)}
          </TabPane>
        ))}
      </Tabs>

      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
      <FormAddMultiple ref={refMultiple} onAfterSubmit={onAfterSubmit} />

      {contextHolder}
    </Box>
  );
}
