import axiosApi from "./api_helper";
export const CuDanService = {
  KhachHang: {
    GetByMaTN: async (payload) => {
      return await axiosApi()
        .post("api/khach-hang/get-bymatn", payload)
        .then((res) => res.data);
    },
  },
  getCuDanByMaMB: async (payload) => {
    return await axiosApi()
      .post("api/cu-dan/get-bymamb", payload)
      .then((res) => res.data);
  },
};
