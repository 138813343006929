import React, { useState } from "react";
import { DatePicker, Select } from "antd";
import _ from "lodash";
import ActiveRoute from "components/ActiveRoute";
import { YeuCauService } from "services/YeuCauService";
import FilterDate from "components/FilterDate";
import dayjs from "dayjs";
import { ToaNhaService } from "services/ToaNhaService";
import DatePickerCustomFilter from "components/Forms/DatePickerCustomFilter";
import { DateTime } from "luxon";

const FilterModal = ({ ...props }) => {
  const { filterCondition, onChangeFilter, options } = props;
  const [loaiyeucau, setLoaiyeucau] = useState([]);
  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "MaTL":
      case "MaLDV":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        break;
      case "MaTN":
        _filter[p] = v;
        _filter.MaBP = null;
        _filter.MaLYC = null;
        localStorage.setItem("MaTN", v);
        break;
      case "MaBP":
        _filter.MaLYC = null;
        _filter[p] = v;
        break;
      case "DenNgay":
        const dateInVietnam = DateTime.fromJSDate(v.$d, {
          zone: "utc",
        }).setZone("Asia/Ho_Chi_Minh");
        _filter[p] = dateInVietnam.toISO();
        break;
      default:
        _filter[p] = v;
        break;
    }
    onChangeFilter(_filter);
  };
  return (
    // <div className="left-filter">
    //   <ActiveRoute />
    //   <div className="box-search">
    //     <p className="label" style={{ marginTop: 10 }}>
    //       Đến ngày
    //     </p>
    //     <DatePicker
    //       format="DD-MM-YYYY"
    //       style={{ width: "100%" }}
    //       defaultValue={dayjs(filterCondition.DenNgay)}
    //       placeholder="Đến ngày "
    //       // locale={locale}
    //       onChange={(v) => applyChangeFilter("DenNgay", v)}
    //     />
    //   </div>
    //   <div className="box-search">
    //     <p className="label">Dự án</p>
    //     <Select
    //       // mode="multiple"
    //       // defaultValue={localStorage.getItem("MaTN")}
    //       value={filterCondition.MaTN}
    //       placeholder="Chọn dự án"
    //       onChange={(v) => applyChangeFilter("MaTN", v)}
    //       style={{
    //         width: "100%",
    //       }}
    //       options={options?.ToaNha}
    //       fieldNames={{ label: "TenTN", value: "MaTN" }}
    //     />
    //     {filterCondition?.MaTN && (
    //       <Select
    //         // mode="multiple"
    //         value={filterCondition.MaKN}
    //         placeholder="Chọn khối nhà"
    //         onChange={(v) => applyChangeFilter("MaKN", v)}
    //         style={{
    //           width: "100%",
    //         }}
    //         allowClear
    //         options={options?.KhoiNha}
    //         fieldNames={{ label: "TenKN", value: "MaKN" }}
    //       />
    //     )}
    //     {filterCondition?.MaKN && (
    //       <Select
    //         mode="multiple"
    //         // value={filterCondition.MaTL}
    //         placeholder="Chọn tầng lầu"
    //         onChange={(v) => applyChangeFilter("MaTL", v)}
    //         style={{
    //           width: "100%",
    //         }}
    //         allowClear
    //         options={options.TangLau}
    //         fieldNames={{ label: "TenTL", value: "MaTL" }}
    //       />
    //     )}
    //   </div>

    //   <div className="box-search">
    //     <p className="label">Loại dịch vụ</p>
    //     <Select
    //       mode="multiple"
    //       // value={filterCondition.DoUuTien}
    //       placeholder="Chọn loại dịch vụ"
    //       onChange={(v) => applyChangeFilter("MaLDV", v)}
    //       style={{
    //         width: "100%",
    //       }}
    //       options={[
    //         { ID: 1, Name: "Điện" },
    //         { ID: 2, Name: "Nước" },
    //         { ID: 6, Name: "Giữ xe" },
    //         { ID: 12, Name: "Phí quản lý" },
    //         { ID: 3, Name: "Dịch vụ khác" },
    //         { ID: 5, Name: "Hợp đồng thuê" },
    //         { ID: 33, Name: "Tiền nội thất" },
    //       ]}
    //       fieldNames={{ label: "Name", value: "ID" }}
    //     />
    //   </div>
    // </div>
    <>
      <ActiveRoute />
      <div className="box-search">
        {/* <p className="label" style={{ marginTop: 10 }}>
          Đến ngày
        </p>
        <DatePicker
          format="DD-MM-YYYY"
          style={{ width: "100%" }}
          defaultValue={dayjs(filterCondition.DenNgay)}
          placeholder="Đến ngày "
          // locale={locale}
          onChange={(v) => applyChangeFilter("DenNgay", v)}
        /> */}
        <DatePickerCustomFilter
          label="Đến ngày"
          applyChange={applyChangeFilter}
          prop={"DenNgay"}
          value={filterCondition.DenNgay}
          placeholder={"Chọn ngày nhập"}
        />
      </div>
      <div className="box-search">
        {/* <p className="label">Dự án</p> */}
        <Select
          // mode="multiple"
          // defaultValue={localStorage.getItem("MaTN")}
          value={filterCondition.MaTN}
          placeholder="Chọn dự án"
          onChange={(v) => applyChangeFilter("MaTN", v)}
          style={{
            width: "100%",
          }}
          options={options?.ToaNha}
          fieldNames={{ label: "TenTN", value: "MaTN" }}
        />
        {filterCondition?.MaTN && (
          <Select
            // mode="multiple"
            value={filterCondition.MaKN}
            placeholder="Chọn khối nhà"
            onChange={(v) => applyChangeFilter("MaKN", v)}
            style={{
              width: "100%",
            }}
            allowClear
            options={options?.KhoiNha}
            fieldNames={{ label: "TenKN", value: "MaKN" }}
          />
        )}
        {filterCondition?.MaKN && (
          <Select
            mode="multiple"
            // value={filterCondition.MaTL}
            placeholder="Chọn tầng lầu"
            onChange={(v) => applyChangeFilter("MaTL", v)}
            style={{
              width: "100%",
            }}
            allowClear
            options={options.TangLau}
            fieldNames={{ label: "TenTL", value: "MaTL" }}
          />
        )}
        <Select
          mode="multiple"
          // value={filterCondition.DoUuTien}
          placeholder="Chọn loại dịch vụ"
          onChange={(v) => applyChangeFilter("MaLDV", v)}
          style={{
            width: "100%",
          }}
          options={[
            { ID: 1, Name: "Điện" },
            { ID: 2, Name: "Nước" },
            { ID: 6, Name: "Giữ xe" },
            { ID: 12, Name: "Phí quản lý" },
            { ID: 3, Name: "Dịch vụ khác" },
            { ID: 5, Name: "Hợp đồng thuê" },
            { ID: 33, Name: "Tiền nội thất" },
          ]}
          fieldNames={{ label: "Name", value: "ID" }}
          
        />
      </div>

      {/* <div className="box-search">
        <p className="label">Loại dịch vụ</p>
        <Select
          mode="multiple"
          // value={filterCondition.DoUuTien}
          placeholder="Chọn loại dịch vụ"
          onChange={(v) => applyChangeFilter("MaLDV", v)}
          style={{
            width: "100%",
          }}
          options={[
            { ID: 1, Name: "Điện" },
            { ID: 2, Name: "Nước" },
            { ID: 6, Name: "Giữ xe" },
            { ID: 12, Name: "Phí quản lý" },
            { ID: 3, Name: "Dịch vụ khác" },
            { ID: 5, Name: "Hợp đồng thuê" },
            { ID: 33, Name: "Tiền nội thất" },
          ]}
          fieldNames={{ label: "Name", value: "ID" }}
        />
      </div> */}
    </>
  );
};
export default FilterModal;
