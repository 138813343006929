import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  notification,
  Row,
  Col,
  Flex,
  Select,
  Skeleton,
  Input,
  Image,
  Button,
} from "antd";
import _ from "lodash";
import "./index.scss";
import { ChatsService } from "services/ChatsService";
import { FaUserGroup } from "react-icons/fa6";
const BoxRight = (props, ref) => {
  const { members ,images } = props;
  // const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
  }, []);
  useImperativeHandle(ref, () => ({
    show: async () => {
      filterMemberGroup(members);
    },
  }));
  const filterMemberGroup = async (memberReq) => {
    // let _members = memberReq || members;
    let mem = [];
    await Promise.all(
      memberReq?.map(async (item) => {
        if (item.is_admin) {
          let _user = await ChatsService.User.getById(item.user_id);
          mem.push({
            ...item,
            // user_id: _user?.data?.MaNV,
            name: _user?.data?.HoTenNV,
            //is_admin: true,
          });
        } else {
          let _user = await ChatsService.MatBang.getById(item.user_id);
          mem.push({
            ...item,
            // user_id: _user?.data?.MaMB,
            name: _user?.data?.MaSoMB,
            // is_admin: false,
          });
        }
      })
    );
    // setMembers(mem);
  };
  return (
    <Col span={6}>
      <div
        className="box-search"
        style={{ height: window.innerHeight - 80, overflow: "scroll" }}
      >
        <p style={{ fontWeight: "bold", marginBottom: 5 }}>Thành viên nhóm</p>
        <Flex gap={10}>
          <FaUserGroup size={20} color="gray" />
          <p>{members?.length} thành viên</p>
        </Flex>
        <p style={{ fontWeight: "bold", marginBottom: 5, marginTop: 15 }}>
          Ảnh/ video
        </p>
        <Image.PreviewGroup>
          <Row>
            {images?.map((item) => {
              return JSON.parse(item.message)?.map((url, index) => {
                return (
                  <Col span={8}>
                    <Image
                      key={index}
                      style={{ paddingRight: 10 }}
                      width="100%"
                      height={100}
                      src={url}
                    />
                  </Col>
                );
              });
            })}
          </Row>
        </Image.PreviewGroup>

        <p style={{ fontWeight: "bold", marginBottom: 5, marginTop: 15 }}>
          File
        </p>
        <p style={{ fontWeight: "bold", marginBottom: 5, marginTop: 15 }}>
          Thiết lập bảo mật
        </p>
      </div>
    </Col>
  );
};
export default React.memo( forwardRef(BoxRight));