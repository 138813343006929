import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Flex,
  Popover,
  Row,
  Select,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import ActiveRoute from "components/ActiveRoute";
import { ToaNhaService } from "services/ToaNhaService";
import { NhanVienService } from "services/NhanVienService";
import { YeuCauService } from "services/YeuCauService";
import { FaSort } from "react-icons/fa6";
import FilterDate from "components/FilterDate";

const FilterModal = ({ ...props }) => {
  const { filterCondition, onChangeFilter, options } = props;
  const onChangeDate = (v) => {
    let _filter = _.cloneDeep(filterCondition);
    _filter.TuNgay = v.TuNgay;
    _filter.DenNgay = v.DenNgay;
    onChangeFilter(_filter);
  };
  return (
    <div className="">
      <ActiveRoute />
      <FilterDate onChangeDate={onChangeDate} />
    </div>
  );
};
export default FilterModal;
