import { Button, Col, Drawer, Flex, notification, Row, Space } from "antd";
import DebounceSelect from "components/DebounceSelect";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import InputCustom from "components/Forms/InputCustom";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import { DateTime } from "luxon";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { DichVuService } from "services/DichVuService";
import { MatBangService } from "services/MatBangService";
import _ from "lodash";
import SelectCustom from "components/Forms/SelectCustom";
import { KhachHangService } from "services/KhachHangService";
import { CategoryService } from "services/CategoryService";
import { CuDanService } from "services/CuDanService";

const FormDatLich = (props, ref) => {
  const { onAfterSubmit, maTN, tienIch } = props;
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState({});
  const [khuBan, setKhuBan] = useState();
  const [khungGio, setKhungGio] = useState();
  const [trangThai, setTrangThai] = useState();
  const [cuDan, setCuDan] = useState();
  const defaultErrors = {
    MatBang: null,
  };
  const [errors, setErrors] = useState(defaultErrors);
  const onClose = () => {
    setOpen(false);
    setData({});
  };
  const filter = async (maMB) => {
    const _res = await CategoryService.KhachHang.TienIch.TrangThai.get();
    if (_res.data) setTrangThai(_res.data);
    const _resCuDan = await CuDanService.getCuDanByMaMB({ MaMB: maMB });
    if (_resCuDan.data) setCuDan(_resCuDan.data);
  };
  useImperativeHandle(ref, () => ({
    show: async (item) => {
      setData((pr) => ({
        ...pr,
        ID: item?.ID,
        NgayDat: item?.NgayTaoLich,
        TenTI: item?.TenTienIch,
        TenBan: item?.Ban,
        TenKhungGio: item?.KhungGio,
      }));
      setOpen(true);
      filter(item?.MaMB);
    },
    update: async (data) => {
      setOpen(true);
    },
  }));
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "NgayNhap":
        const date = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString = date.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString;
        break;
      case "TenTI":
        const _res = await CategoryService.KhachHang.TienIch.khuBan.get({
          MaTN: maTN,
          MaTenTienIch: v,
        });
        if (_res.data) setKhuBan(_res.data);
        _data.TenBan = null;
        _data.TenKhungGio = null;
        _data[p] = tienIch.find((item) => item.ID === v)?.TenTienIch;
        break;
      case "TenBan":
        const _resBan = await CategoryService.KhachHang.TienIch.KhungGio.get({
          MaTN: maTN,
          MaBan: v,
        });
        if (_resBan.data) setKhungGio(_resBan.data);
        _data[p] = khuBan.find((item) => item.ID === v)?.TenBan;
        break;
      case "TenKhungGio":
        _data[p] = khungGio.find((item) => item.ID === v)?.KhungGio;
        break;
      case "MatBang":
        const KH = JSON.parse(v?.title);
        // filterDongHo(KH?.MaKH);\
        _data.MaTN = maTN;

        _data.MaSoMB = KH?.MaSoMB;
        _data.MaKH = KH?.MaKH;
        _data.TenKH = KH?.TenKH;
        _data.MaMB = KH?.MaMB;
        _data.MaLMB = KH?.MaLMB;
        _data[p] = v;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };

  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);

    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    let _res = await KhachHangService.TienIch.datLich(_data);

    setLoading(false);
    if (_res.status === 2000) {
      openNotification("success", "topRight", ` Đặt lịch thành công!`);
      setIsUpdate(true);
      onClose();
      onAfterSubmit();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  async function fetchMatBang(input) {
    return MatBangService.getMatBang({
      InputSearch: input,
      MaTN: maTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.MaSoMB}`,
        value: item.MaMB,
        title: JSON.stringify(item),
      }));
    });
  }
  return (
    <div>
      <Drawer
        title="Đặt lịch"
        width={960}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button
              loading={loading}
              onClick={() => onSubmit(true)}
              type="primary"
            >
              Lưu & Đóng
            </Button>
          </Space>
        }
      >
        <Row gutter={[20, 20]}>
          <Col style={{ paddingTop: "15px" }} span={12}>
            <Flex style={{ borderBottom: "1px solid #d9d9d9" }}>
              <p
                style={{
                  fontWeight: "600",
                  width: "30% ",
                  color: "red",
                }}
              >
                Mặt bằng:
              </p>
              <DebounceSelect
                open={open}   
                value={data?.MatBang}
                placeholder="Tìm theo mã mặt bằng"
                fetchOptions={fetchMatBang}
                onChange={(v) => applyChange("MatBang", v)}
                style={{
                  width: "100%",
                }}
              />
            </Flex>
            {errors && (
              <p style={{ color: "red", textAlign: "center" }}>
                {errors && errors["MatBang"]}
              </p>
            )}
          </Col>
          <Col style={{ paddingTop: "15px" }} span={12}>
            <SelectCustom
              label="Tiện ích:"
              applyChange={applyChange}
              prop="TenTI"
              value={data?.TenTI}
              placeholder="Chọn loại tiện ích"
              options={tienIch}
              fieldNames={{ label: "TenTienIch", value: "ID" }}
            />
          </Col>
          <Col span={12}>
            <SelectCustom
              label="Cư dân:"
              applyChange={applyChange}
              prop="MaLX"
              value={data?.MaLX}
              placeholder="Chọn cư dân"
              options={[]}
              fieldNames={{ label: "TenLX", value: "MaLX" }}
            />
          </Col>
          <Col span={12}>
            <SelectCustom
              label="Khu/Bàn/Vị trí:"
              applyChange={applyChange}
              prop="TenBan"
              value={data?.TenBan}
              placeholder="Chọn khu, bà, vị trí"
              options={khuBan}
              fieldNames={{ label: "TenBan", value: "ID" }}
            />
          </Col>
          <Col span={12}>
            <InputCustom
              label="Khách hàng:"
              applyChange={applyChange}
              prop="TenKH"
              value={data?.TenKH}
              placeholder=""
            />
          </Col>
          <Col span={12}>
            <SelectCustom
              label="Khung giờ:"
              applyChange={applyChange}
              prop="TenKhungGio"
              value={data?.TenKhungGio}
              placeholder="Chọn loại tiện ích"
              options={khungGio}
              fieldNames={{ label: "KhungGio", value: "ID" }}
            />
          </Col>
          <Col span={12}>
            <InputCustom
              label="Điện thoại:"
              applyChange={applyChange}
              prop="DiDong"
              value={data?.DiDong}
              placeholder=""
            />
          </Col>
          <Col span={12}>
            <DatePickerCustom
              label="Ngày đặt:"
              applyChange={applyChange}
              prop={"NgayDat"}
              value={data?.NgayDat}
              placeholder={"Chọn ngày đặt"}
            />
            {errors && (
              <p style={{ color: "red", textAlign: "center" }}>
                {errors && errors["NgayNhap"]}
              </p>
            )}
          </Col>
          <Col span={12}>
            <SelectCustom
              label="Trang thái:"
              applyChange={applyChange}
              prop="MaTT"
              value={data?.MaTT}
              placeholder="Chọn loại tiện ích"
              options={trangThai}
              fieldNames={{ label: "TenTT", value: "ID" }}
            />
          </Col>
          <Col span={12}>
            <InputNumberCustom
              label="Tiền cọc:"
              applyChange={applyChange}
              prop={"SoTienCoc"}
              value={data?.SoTienCoc}
              placeholder="100"
            />
          </Col>
        </Row>
      </Drawer>
      {contextHolder}{" "}
    </div>
  );
};

export default forwardRef(FormDatLich);
