import React, { useEffect, useState } from "react";
import { DatePicker, Select } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import ActiveRoute from "components/ActiveRoute";
import { ToaNhaService } from "services/ToaNhaService";
import FilterDate from "components/FilterDate";
import { CategoryService } from "services/CategoryService";

const FilterKN = ({ ...props }) => {
  const {
    filterCondition,
    onChangeFilter,
    toanha,
    tenTienIch,
    setData,
    banViTri,
    activeTab,
  } = props;

  const applyChangeFilter = async (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "MaTenTI":
        const _res = await CategoryService.KhachHang.TienIch.khuBan.get({
          MaTN: _filter.MaTN,
          MaTenTienIch: v,
        });
        if (_res.data) setData(_res.data);
        _filter[p] = v;
        break;
      case "MaBan":
        const _res2 = await CategoryService.KhachHang.TienIch.KhungGio.get({
          MaTN: _filter.MaTN,
          MaBan: v,
        });
        if (_res2.data) setData(_res2.data);
        _filter[p] = v;
        break;
      default:
        _filter[p] = v;
        break;
    }
    onChangeFilter(_filter);
  };
  // const loadcategories = async () => {
  //   let _tn = await ToaNhaService.getToaNhaByNV();
  //   setToanha(_tn.data);
  // };
  // useEffect(() => {
  //   loadcategories();
  // }, []);
  const onChangeDate = (v) => {
    let _filter = _.cloneDeep(filterCondition);
    _filter.TuNgay = v.TuNgay;
    _filter.DenNgay = v.DenNgay;
    onChangeFilter(_filter);
  };
  return (
    <div>
      <div className="box-search">
        <p className="label">Dự án</p>
        <Select
          placeholder="Chọn dự án"
          onChange={(v) => applyChangeFilter("MaTN", v)}
          style={{
            width: "100%",
          }}
          value={filterCondition.MaTN}
          options={toanha}
          fieldNames={{ label: "TenTN", value: "MaTN" }}
        />
      </div>
      {/* <FilterDate onChangeDate={onChangeDate} /> */}
      {activeTab === 2 && (
        <>
          <div className="box-search">
            <p className="label">Tiện ích</p>
            <Select
              placeholder="Chọn tiện ích"
              onChange={(v) => applyChangeFilter("MaTenTI", v)}
              style={{
                width: "100%",
              }}
              value={filterCondition.MaTenTI}
              options={tenTienIch}
              fieldNames={{ label: "TenTienIch", value: "ID" }}
            />
          </div>
          <div className="box-search">
            <p className="label">Khu bàn vị trí </p>
            <Select
              placeholder="Chọn khu/ban"
              onChange={(v) => applyChangeFilter("MaBan", v)}
              style={{
                width: "100%",
              }}
              value={filterCondition?.MaBan}
              options={banViTri}
              fieldNames={{ label: "TenBan", value: "ID" }}
            />
          </div>
        </>
      )}
    </div>
  );
};
export default FilterKN;
