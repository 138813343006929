import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { Col, Flex, Row, Segmented, Table, notification } from "antd";
import { MdSkipNext } from "react-icons/md";
import _ from "lodash";
import { ToaNhaService } from "services/ToaNhaService";
import FilterKN from "./FilterKN";
import { CategoryService } from "services/CategoryService";
import getColumns from "./ColumnsDichVu";
import TrangThaiCuDan from "./CuDan/TrangThaiCuDan";
import TenTienIch from "./TienIch/TenTienIch";
import KhuTienIch from "./TienIch/KhuTienIch";
import KhungGioTienIch from "./TienIch/KhungGioTienIch";
import TrangThaiTienIch from "./TienIch/TrangThaiTienIch";
import QuanHeCuDan from "./CuDan/QuanHeCuDan";
import FilterLYC from "./FilterLYC";
import { YeuCauService } from "services/YeuCauService";
import TrangThaiYeuCau from "./YeuCau/TrangThaiYeuCau";
import LoaiYeuCau from "./YeuCau/LoaiYeuCau";
import HinhThucYeuCau from "./YeuCau/HinhThucYeuCau";
import MucDoYeuCau from "./YeuCau/MucDoYeuCau";

export default function Index() {
  const [secondaryOption, setSecondaryOption] = useState(1);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [animate, setAnimate] = useState(true);
  const [activeTab, setActiveTab] = useState(1);
  const [toanha, setToanha] = useState([]);
  const [responseColum, setResponseColum] = useState(false);
  const [xColumns, setXColumns] = useState(2000);
  const [tenTienIch, setTenTienIch] = useState();
  const [banViTri, setBanViTri] = useState();

  useEffect(() => {
    filter(activeTab, secondaryOption, filterCondition);
  }, []);
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 7);
  const nextWeekDate = new Date();

  const [loading, setLoading] = useState(false);
  const [filterCondition, setFilterCondition] = useState({
    MaTN: null,
    MaTenTI: null,
    MaBan: null,
    TuNgay: currentDate.toISOString(),
    DenNgay: nextWeekDate.toISOString(),
    InputSearch: "",
  });

  const optionsCudan = [
    { value: 1, label: "Trạng thái" },
    { value: 2, label: "Quan hệ" },
  ];
  const optionsTienIch = [
    { value: 1, label: "Tên tiện ích" },
    { value: 2, label: "Khu/bàn/vị trí" },
    { value: 3, label: "Khung giờ" },
    { value: 4, label: "Trạng thái" },
  ];
  const optionsYeuCau = [
    { value: 1, label: "Trạng thái" },
    { value: 2, label: "Mức độ" },
    { value: 3, label: "Hình thức" },
    { value: 4, label: "Loại yêu cầu" },
  ];

  const refTrangThaiCuDan = useRef(null);
  const refTenTienIch = useRef(null);
  const refLoaiCongTo = useRef(null);
  const refKhuTienIch = useRef(null);
  const refKhungGioTienIch = useRef(null);
  const refTrangThaiTienIch = useRef(null);
  const refQuanHeCuDan = useRef(null);
  const refTrangThaiYC = useRef(null);
  const refHinhThucYC = useRef(null);
  const refMucDoYC = useRef(null);
  const refLoaiiYC = useRef(null);

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };

  const filter = async (activeTab, secondaryOption, _filter) => {
    setLoading(true);
    let _res;
    let _tn = await ToaNhaService.getToaNhaByNV();
    setToanha(_tn.data);

    switch (activeTab) {
      case 1:
        setResponseColum(false);
        switch (secondaryOption) {
          case 1:
            setXColumns(500);

            _res = await CategoryService.KhachHang.CuDan.TrangThai.get();
            if (_res.data) {
              setData(_res.data);
            }
            break;
          case 2:
            setXColumns(500);

            _res = await CategoryService.KhachHang.CuDan.QuanHe.get();
            if (_res.data) {
              setData(_res.data);
            }
            break;

          default:
            break;
        }
        break;
      case 2:
        setResponseColum(false);
        switch (secondaryOption) {
          case 1:
            setXColumns(500);
            filterCondition.MaTN = _filter.MaTN || _tn.data[0].MaTN;
            _res = await CategoryService.KhachHang.TienIch.TenTienIch.get(
              _filter.MaTN === null
                ? (_filter.MaTN = _tn.data[0].MaTN)
                : _filter
            );
            if (_res.data) {
              setData(_res.data);
            }
            break;
          case 2:
            setXColumns(500);
            const _resTenTienIch =
              await CategoryService.KhachHang.TienIch.TenTienIch.get(
                _filter.MaTN === null
                  ? (_filter.MaTN = _tn.data[0].MaTN)
                  : _filter
              );
            if (_resTenTienIch.data) {
              setTenTienIch(_resTenTienIch.data);
              filterCondition.MaTenTI =
                _filter.MaTenTI || _resTenTienIch.data[0]?.ID;
            }
            _res = await CategoryService.KhachHang.TienIch.khuBan.get({
              MaTN: _filter.MaTN,
              MaTenTienIch: _filter.MaTenTI || _resTenTienIch.data[0]?.ID,
            });
            if (_res.data) setData(_res.data);
            break;
          case 3:
            setXColumns(500);
            const _resMaBan =
              await CategoryService.KhachHang.TienIch.khuBan.get({
                MaTN: _filter.MaTN,
                MaTenTienIch: _filter.MaTenTI,
              });
            if (_resMaBan.data) {
              setBanViTri(_resMaBan.data);
              filterCondition.MaBan = _filter.MaBan || _resMaBan.data[0]?.ID;
            }
            _res = await CategoryService.KhachHang.TienIch.KhungGio.get({
              MaTN: _filter.MaTN,
              MaBan: _filter.MaBan || _resMaBan.data[0]?.ID,
            });
            if (_res.data) setData(_res.data);
            break;
          case 4:
            setXColumns(500);
            _res = await CategoryService.KhachHang.TienIch.TrangThai.get();
            if (_res.data) setData(_res.data);
            break;
          default:
            break;
        }
        break;
      case 3:
        setResponseColum(false);
        switch (secondaryOption) {
          case 1:
            setXColumns(500);
            filterCondition.MaTN = _filter.MaTN || _tn.data[0].MaTN;

            _res = await YeuCauService.Categories.getTrangThai(_filter);
            if (_res.data) setData(_res.data);
            break;
          case 2:
            setXColumns(500);
            _res = await YeuCauService.Categories.getMucDo();
            if (_res.data) setData(_res.data);
            break;
          case 3:
            setXColumns(500);
            _res = await YeuCauService.Categories.getHinhThuc();
            if (_res.data) setData(_res.data);
            break;
          case 4:
            setXColumns(500);
            _res = await YeuCauService.Categories.getLoaiYeuCau(_filter);
            if (_res.data) setData(_res.data);
            break;
          default:
            break;
        }
        break;

      default:
        break;
    }

    setLoading(false);
  };

  const onAfterSubmit = () => {
    filter(activeTab, secondaryOption, filterCondition);
  };
  const remove = async (value) => {
    const handleResponse = (response, dataKey) => {
      if (response.status === 2000) {
        let _data = _.cloneDeep(data);
        let filteredData = _data.filter((v) => v[dataKey] !== Number(value));
        setData(filteredData);
        openNotification("success", "topRight", "Xoá danh mục thành công");
      } else {
        openNotification("error", "topRight", response?.message);
      }
    };

    let _res = null;
    try {
      switch (activeTab) {
        case 1:
          switch (secondaryOption) {
            case 1:
              _res = await CategoryService.KhachHang.CuDan.TrangThai.delete({
                ID: Number(value),
              });

              handleResponse(_res, "ID");
              break;
            case 2:
              _res = await CategoryService.KhachHang.CuDan.QuanHe.delete({
                ID: Number(value),
              });
              handleResponse(_res, "ID");
              break;
            default:
              break;
          }
          break;
        case 2:
          switch (secondaryOption) {
            case 1:
              _res = await CategoryService.KhachHang.TienIch.TenTienIch.delete({
                ID: Number(value),
              });

              handleResponse(_res, "ID");
              break;
            case 2:
              _res = await CategoryService.KhachHang.TienIch.khuBan.delete({
                ID: Number(value),
              });

              handleResponse(_res, "ID");
              break;
            case 3:
              _res = await CategoryService.KhachHang.TienIch.KhungGio.delete({
                ID: Number(value),
              });
              handleResponse(_res, "ID");
              break;
            default:
              break;
          }
          break;
        case 3:
          switch (secondaryOption) {
            case 1:
              _res = await YeuCauService.Categories.deleteTrangThai({
                ID: Number(value),
              });

              handleResponse(_res, "MaTT");
              break;
            case 2:
              _res = await YeuCauService.Categories.deleteMucDo({
                ID: Number(value),
              });
              handleResponse(_res, "MaDoUuTien");
              break;
            case 3:
              _res = await YeuCauService.Categories.deleteHinhThuc({
                ID: Number(value),
              });

              handleResponse(_res, "ID");
              break;
            case 4:
              _res = await YeuCauService.Categories.deleteLoaiYeuCau({
                ID: Number(value),
              });

              handleResponse(_res, "MaLYC");
              break;
            default:
              break;
          }

          break;
        default:
          break;
      }
    } catch (error) {
      openNotification("error", "topRight", "An error occurred");
    }
  };
  const onChange = (newActiveKey) => {
    setSecondaryOption(newActiveKey);
    filter(activeTab, newActiveKey, filterCondition);
  };
  const onChangeFilter = (_filter) => {
    filter(activeTab, secondaryOption, _filter);
    setFilterCondition(_filter);
  };
  const handleClick = (v) => {
    setSecondaryOption(1);
    setTimeout(() => setAnimate(false), 100);
    setTimeout(() => setActiveTab(v), 200);
    setTimeout(() => setAnimate(true), 400);
    setTimeout(() => filter(v, 1, filterCondition), 300);
  };

  const getColumnsByTab = getColumns(
    activeTab,
    secondaryOption,
    {
      refTrangThaiCuDan,
      refTenTienIch,
      refLoaiCongTo,
      refKhuTienIch,
      refKhungGioTienIch,
      refTrangThaiTienIch,
      refQuanHeCuDan,
      refTrangThaiYC,
      refLoaiiYC,
      refMucDoYC,
      refHinhThucYC,
    },
    remove,
    data
  );

  const getOptionsByTab = (tab) => {
    switch (tab) {
      case 1:
        return optionsCudan;
      case 2:
        return optionsTienIch;
      case 3:
        return optionsYeuCau;

      default:
        return [];
    }
  };

  return (
    <Box>
      <Flex align="center" gap={10} style={{ marginBottom: 20 }}>
        <Segmented
          defaultValue={1}
          style={{ padding: "3px", zIndex: 3 }}
          onChange={(value) => handleClick(value)}
          options={[
            { value: 1, label: "Cư dân" },
            { value: 2, label: "Tiện ích" },
            { value: 3, label: "Yêu cầu" },
          ]}
          size="large"
        />

        <Flex
          align="center"
          className={`segment-right ${animate ? "animate-right" : ""}`}
        >
          <MdSkipNext size={20} color="rgb(0, 112, 244)" />
          <Segmented
            defaultValue={1}
            value={secondaryOption}
            style={{ padding: "3px" }}
            onChange={(value) => onChange(value)}
            options={getOptionsByTab(activeTab)}
            size="middle"
          />
        </Flex>
      </Flex>
      <Row gutter={[10, 10]}>
        {activeTab === 3 && secondaryOption !== 1 ? null : (
          <Col span={responseColum ? 0 : 4}>
            <FilterKN
              filterCondition={filterCondition}
              onChangeFilter={onChangeFilter}
              toanha={toanha}
              tenTienIch={tenTienIch}
              setData={setData}
              banViTri={banViTri}
              activeTab={activeTab}
            />
          </Col>
        )}
        {activeTab === 3 && secondaryOption === 4 && (
          <Col span={4}>
            <FilterLYC
              filterCondition={filterCondition}
              onChangeFilter={onChangeFilter}
            />
          </Col>
        )}

        <Col
          span={
            responseColum
              ? 24
              : (activeTab === 3 && secondaryOption === 3) ||
                (activeTab === 3 && secondaryOption === 2)
              ? 24
              : 20
          }
        >
          <Table
            loading={loading}
            dataSource={data}
            columns={getColumnsByTab}
            scroll={{
              y: window.innerHeight - 350,
              x: xColumns,
            }}
            size="small"
            bordered
          />
        </Col>
      </Row>

      <TrangThaiCuDan
        ref={refTrangThaiCuDan}
        onAfterSubmit={onAfterSubmit}
        filterCondition={filterCondition}
      />
      <QuanHeCuDan
        ref={refQuanHeCuDan}
        onAfterSubmit={onAfterSubmit}
        filterCondition={filterCondition}
      />

      <TenTienIch
        ref={refTenTienIch}
        onAfterSubmit={onAfterSubmit}
        maTN={filterCondition.MaTN}
      />
      <KhuTienIch
        ref={refKhuTienIch}
        onAfterSubmit={onAfterSubmit}
        filterCondition={filterCondition}
      />
      <KhungGioTienIch
        ref={refKhungGioTienIch}
        onAfterSubmit={onAfterSubmit}
        filterCondition={filterCondition}
      />
      <TrangThaiTienIch
        ref={refTrangThaiTienIch}
        onAfterSubmit={onAfterSubmit}
        filterCondition={filterCondition}
      />
      <TrangThaiYeuCau
        ref={refTrangThaiYC}
        onAfterSubmit={onAfterSubmit}
        filterCondition={filterCondition}
        toanha={toanha}
      />
      <HinhThucYeuCau
        ref={refHinhThucYC}
        onAfterSubmit={onAfterSubmit}
        filterCondition={filterCondition}
      />
      <MucDoYeuCau
        ref={refMucDoYC}
        onAfterSubmit={onAfterSubmit}
        filterCondition={filterCondition}
      />
      <LoaiYeuCau
        ref={refLoaiiYC}
        onAfterSubmit={onAfterSubmit}
        filterCondition={filterCondition}
        toanha={toanha}
      />

      {contextHolder}
    </Box>
  );
}
