import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Button,
  Popconfirm,
  notification,
  Tooltip,
  Badge,
  Row,
  Col,
  Flex,
  Tag,
  Input,
  Pagination,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { CiTrash } from "react-icons/ci";
import Toolbar from "components/Toolbar";
import {
  MdEdit,
  MdOutlineContentPasteSearch,
  MdOutlinePendingActions,
} from "react-icons/md";
import FormDetail from "./FormDetail";
import { YeuCauService } from "services/YeuCauService";
import { NhanVienService } from "services/NhanVienService";
import { ToaNhaService } from "services/ToaNhaService";
import FormView from "./FormView";
import { Format_Datetime } from "layouts/ultis/common";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { CongNoService } from "services/CongNoService";
import { Format_Currency } from "layouts/ultis/common";
import { CuDanService } from "services/CuDanService";
import {
  CreditCardOutlined,
  DollarOutlined,
  ExportOutlined,
  PayCircleOutlined,
} from "@ant-design/icons";
import { AiOutlineMenuFold } from "react-icons/ai";
import { DateTime } from "luxon";
import FormModal from "./FormThongBao";
import { HeThongService } from "services/HeThongService";

export default function Index() {
  const [data, setData] = useState([]);
  const [stateActive, setStateActive] = useState({
    MaTT: 0,
    TenTT: "Tất cả",
    Color: "gray",
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const refDetail = useRef(null);
  const refDetailView = useRef(null);
  const refAdd = useRef(null);
  const [loading, setLoading] = useState(false);
  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");
  const [filterCondition, setFilterCondition] = useState({
    DenNgay: dateInHoChiMinh.toISO(),
    PageSize: 20,
    PageIndex: 1,
    Input: "",
    MaTN: 0,
    MaKN: null,
    MaTL: ",,",
    MaLDV: ",,",
  });
  const [nhanvien, setNhanvien] = useState([]);
  const [phongban, setPhongban] = useState([]);
  const [toanha, setToanha] = useState([]);
  const [khoinha, setKhoinha] = useState([]);
  const [tanglau, setTanglau] = useState([]);
  const [khachhang, setKhachhang] = useState([]);
  const [bieuMau, setBieuMau] = useState([]);
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("filter") === "true"
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    loadCategories();
  }, []);

  const load = async (_filter) => {
    setLoading(true);
    let _res = await CongNoService.GetCongNoTongHop(_filter);
    // let _tt = await YeuCauService.Categories.getTrangThai({
    //   MaTN: _filter.MaTN,
    // });
    // let status = [
    //   { MaTT: 0, TenTT: "Tất cả", Color: "#cccccc" },
    //   ..._tt.data?.filter((v) => v.isViewApp),
    // ];
    // status.map((item) => {
    //   if (item.MaTT === 0) {
    //     item.Total = _res.data?.length;
    //   } else {
    //     item.Total = _res?.data?.filter((v) => v.MaTT === item.MaTT)?.length;
    //   }
    // });
    // setTrangthai(status);
    // setDataAll(_res.data);
    setData(_res.data);
    setLoading(false);
  };
  const loadCategories = async () => {
    let _tn = await ToaNhaService.getToaNhaByNV();
    setToanha(_tn.data);
    let _filter = _.cloneDeep(filterCondition);
    _filter.MaTN = _tn.data[0]?.MaTN;
    setFilterCondition(_filter);
    loadcategoriesByMaTN(_tn.data[0]?.MaTN);
    load(_filter);
  };
  const loadcategoriesByMaTN = async (matn) => {
    let _kh = await CuDanService.KhachHang.GetByMaTN({
      MaTN: matn,
    });
    setKhachhang(_kh.data);
    let _pb = await NhanVienService.Categories.getPhongBan({
      MaTN: matn,
    });
    setPhongban(_pb.data);
    let _nv = await NhanVienService.getNhanVienByMaTN({ MaTN: matn });
    setNhanvien(_nv.data);
    let _tn = await ToaNhaService.Categories.getKhoiNha({
      MaTN: matn,
    });
    setKhoinha(_tn.data);
    loadTangLau("", matn);
    let _resBM = await HeThongService.BieuMau.get({ MaTN: matn });
    setBieuMau(_resBM.data);
  };
  const loadTangLau = async (makn, matn) => {
    let _res = await ToaNhaService.Categories.getTangByKhoiNhas({
      KhoiNha: "," + makn + ",",
      MaTN: matn,
    });
    setTanglau(_res.data);
  };

  const columns = [
    {
      title: "Mặt bằng",
      // width: 120,
      render: (item) => (
        <p
          style={{ fontWeight: "bold", color: "cadetblue", cursor: "pointer" }}
          onClick={() => refDetailView.current.show(item)}
        >
          {item.MaSoMB}
        </p>
      ),
    },
    {
      title: "Khối nhà",
      dataIndex: "MaKN",
      // ellipsis: true,
      // width: 100,

      render: (item) => (
        <Tag color="#bae0ff">
          <p style={{ color: "black" }}>
            {khoinha?.find((v) => v.MaKN === item)?.TenKN}
          </p>
        </Tag>
      ),
    },
    {
      title: "Tầng lầu",
      dataIndex: "MaTL",
      // ellipsis: true,
      // width: 120,
      render: (item) => tanglau?.find((v) => v.MaTL === item)?.TenTL,
    },
    {
      title: "Khách hàng",
      dataIndex: "MaKH",
      // ellipsis: true,
      render: (item) => khachhang?.find((v) => v.MaKH === item)?.TenKH,
    },
    {
      title: "Điện thoại",
      dataIndex: "MaKH",
      // ellipsis: true,
      render: (item) => khachhang?.find((v) => v.MaKH === item)?.DienThoaiKH,
      // width: 120,
    },
    {
      title: "Email",
      dataIndex: "MaKH",
      // ellipsis: true,
      render: (item) => khachhang?.find((v) => v.MaKH === item)?.EmailKH,
      // width: 120,
    },
    // {
    //   title: "Tiền điện",
    //   fixed: "right",
    //   dataIndex: "TienDien",
    //   render: (item) => Format_Currency(item),
    //   width: 100,
    // },
    // {
    //   title: "Tiền nước",
    //   fixed: "right",
    //   dataIndex: "TienNuoc",
    //   width: 100,
    //   render: (item) => Format_Currency(item),
    // },
    // {
    //   title: "Tiền xe",
    //   fixed: "right",
    //   dataIndex: "TienXe",
    //   width: 100,
    //   render: (item) => Format_Currency(item),
    // },
    // {
    //   title: "Tiền PQL",
    //   fixed: "right",
    //   dataIndex: "TienPQL",
    //   width: 100,
    //   render: (item) => Format_Currency(item),
    // },
    {
      title: "Tổng tiền nợ",
      fixed: "right",
      dataIndex: "TongTien",
      // ellipsis: true,
      // width: 100,
      render: (item) => Format_Currency(item),
    },
    {
      title: "",
      fixed: "right",
      width: 80,
      render: (item) => {
        return (
          <Flex gap={5} justify="center">
            <Tooltip placement="topRight" title="Thanh toán">
              <Button
                type="primary"
                ghost
                icon={<CreditCardOutlined />}
                onClick={() => refDetail.current.show(item)}
              />
            </Tooltip>
            {/* <Tooltip placement="topRight" title="Sửa">
              <Button
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refAdd.current.update(item)}
              />
            </Tooltip> */}
            {/* <Popconfirm
              title="Xoá yêu cầu"
              description="Bạn có chắc chắn muốn xoá hay không?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item)}
            >
              <Button danger ghost icon={<CiTrash />} />
            </Popconfirm> */}
          </Flex>
        );
      },
    },
  ];
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const onAfterSubmit = () => {
    load(filterCondition);
  };
  const onChangeFilter = (_filter) => {
    load(_filter);
    setFilterCondition(_filter);
    loadcategoriesByMaTN(_filter.MaTN);
    if (_filter.MaKN) {
      loadTangLau(_filter.MaKN, _filter.MaTN);
    }
  };
  const onChangePage = (page, size) => {
    if (page === 0) {
      page = 1;
    }
    let _ft = _.cloneDeep(filterCondition);
    _ft.PageIndex = page;
    _ft.PageSize = size;
    setFilterCondition(_ft);
    load(_ft);
  };

  const onSelectChange = async (newSelectedRowKeys) => {
    if (newSelectedRowKeys?.length > 100) {
      openNotification("error", "topRight", "Số lượng chọn không quá 100");
      return;
    }
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = () => {
    const fieldsToExport = columns.map((col) => col.dataIndex);

    const filteredData = data.map((item) => {
      let filteredItem = {};
      fieldsToExport.forEach((field) => {
        filteredItem[field] = item[field];
      });
      return filteredItem;
    });
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "Danh sách công nợ tổng hợp" + fileExtension);
  };
  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.Input = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time && filterCondition.MaTN) {
      time = setTimeout(() => {
        load(filterCondition);
      }, 800);
    }
    return () => clearTimeout(time);
  }, [filterCondition.Input]);

  const onHandleHide = () => {
    setCollapsed(!collapsed);
    localStorage.setItem("filter", !collapsed);
  };
  const handleThongBao = () => {
    if (selectedRowKeys.length < 1) {
      openNotification(
        "error",
        "topRight",
        "Vui lòng chọn mặt bằng, xin cảm ơn."
      );
      return;
    }
    setIsModalVisible(true);
  };
  return (
    <Row gutter={[10, 10]} className="congno-tonghop">
      <Col span={collapsed ? 0 : 4} style={{ transition: "all 0.3s ease" }}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
          options={{
            TangLau: tanglau,
            ToaNha: toanha,
            KhoiNha: khoinha,
          }}
        />
      </Col>
      <Col span={collapsed ? 24 : 20}>
        <Toolbar
          left={() => (
            <Flex align="center" gap={10}>
              <AiOutlineMenuFold
                size={20}
                color="primary"
                className="icon-filter"
                onClick={() => onHandleHide()}
              />
              <Input
                style={{
                  width: "300px",
                  padding: "4px 11px",
                  borderRadius: 10,
                }}
                placeholder="Tìm mã số mặt bằng, điện thoại, tên khách hàng"
                onChange={(e) => onSearch(e.target.value)}
              />
            </Flex>
          )}
          right={() => (
            <Flex gap={10}>
              <Button
                onClick={() => handleThongBao()}
                icon={
                  <MdOutlineContentPasteSearch
                    style={{ fontSize: "13px", margin: "0 -2px" }}
                  />
                }
              >
                {" "}
                Thông báo
              </Button>
              <Button onClick={() => exportToCSV()}>
                {" "}
                <ExportOutlined
                  style={{ fontSize: "13px", margin: "0 -2px" }}
                />
                Export
              </Button>
            </Flex>
          )}
        />

        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 250,
            x: 1000,
          }}
          bordered
          pagination={false}
          size="small"
          virtual={true}
          rowSelection={{
            ...rowSelection,
            columnWidth: 60,
          }}
          summary={(pageData) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={6}>
                    <p style={{ fontWeight: "bold" }}>Tổng</p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} fixed="right">
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(_.sum(_.map(pageData, "TongTien")))}
                    </p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
        <div style={{ textAlign: "right", paddingTop: "15px" }}>
          <Pagination
            onChange={onChangePage}
            total={data[0]?.TotalRows}
            showSizeChanger
            pageSizeOptions={["10", "20", "50", "100"]}
            defaultPageSize={"20"}
          />
        </div>
      </Col>

      <FormAdd
        ref={refAdd}
        onAfterSubmit={onAfterSubmit}
        options={{
          ToaNha: toanha,
        }}
      />
      <FormDetail
        ref={refDetail}
        onAfterSubmit={onAfterSubmit}
        options={{
          NhanVien: nhanvien,
          KhachHang: khachhang,
          ToaNha: toanha,
        }}
        filterCondition={filterCondition}
      />
      <FormView
        ref={refDetailView}
        options={{
          ToaNha: toanha,
          NhanVien: nhanvien,
          PhongBan: phongban,
        }}
        khachhang={khachhang}
      />
      <FormModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        bieuMau={bieuMau}
        onAfterSubmit={onAfterSubmit}
        maTN={filterCondition.MaTN}
        selectedRowKeys={selectedRowKeys}
        dataAll={data}
        khachhang={khachhang}
      />
      {contextHolder}
    </Row>
  );
}
