import React from "react";
import { Icon } from "@chakra-ui/react";
import { MdLock } from "react-icons/md";
import { CiChat2, CiHome } from "react-icons/ci";
import { IoSettingsOutline } from "react-icons/io5";
import { BiCategoryAlt } from "react-icons/bi";
import { BsCash } from "react-icons/bs";
import { BiListMinus } from "react-icons/bi";

import BaoCao from "views/admin/BaoCao/index";
import Chats from "views/admin/Chats/index";

// he thong
// import DanhSachLSCaLamViec from "views/admin/HeThong/LichSuCaLamViec/index";
import LichSuOTP from "views/admin/HeThong/LichSuOTP/index";
import DanhSachVirtualAccount from "views/admin/HeThong/VirtualAccount/index";
import Dashboard from "views/admin/HeThong/Dashboard";
import BieuMau from "views/admin/HeThong/BieuMau";
import HistoryNotification from "views/admin/HeThong/LichSuNotification";

//công nợ
import CongNoTongHop from "views/admin/CongNo/TongHop/index";
import HoaDonChiTiet from "views/admin/CongNo/HoaDonChiTiet/index";
import HoaDonChiTietXoa from "views/admin/CongNo/HoaDonChiTietXoa/index";
import ThanhToanApp from "views/admin/CongNo/ThanhToanApp/index";
//dịch vụ
import DichVuNuoc from "views/admin/DichVu/Nuoc/index";
import DichVuDien from "views/admin/DichVu/Dien/index";
import DichVuXe from "views/admin/DichVu/Xe/index";
import DichVuKhac from "views/admin/DichVu/Khac/index";

// so quy
import DanhSachPhieuThu from "views/admin/SoQuy/PhieuThu/index";
import DanhSachPhieuChi from "views/admin/SoQuy/PhieuChi/index";
// import DanhSach from "views/admin/SoQuy/DanhSach/index";
import PhieuThuChiTiet from "views/admin/SoQuy/PhieuThuChiTiet/index";
//danh muc
import DanhMucNhanVien from "views/admin/DanhMuc/NhanVien/index";
import DanhMucToaNha from "views/admin/DanhMuc/ToaNha/index";
import DanhMucYeuCau from "views/admin/DanhMuc/YeuCau/index";
import DanhMucDichVu from "views/admin/DanhMuc/DichVu/index";
import DanhMucKhachHang from "views/admin/DanhMuc/KhachHang/index";

//du an
import DanhSachDuAn from "views/admin/DuAn/index";
import DanhSachMatBang from "views/admin/DuAn/MatBang/index";
import DanhSachNhanVien from "views/admin/DuAn/NhanVien/index";

//khach hang
import YeuCau from "views/admin/KhachHang/YeuCau/index";
import CuDan from "views/admin/KhachHang/CuDan/index";
import KhachHang from "views/admin/KhachHang/KhachHang/index";
import ThongBao from "views/admin/KhachHang/ThongBao/index";
import TienIch from "views/admin/KhachHang/TienIch/index";
//Thong ke
import BieuDo from "views/admin/ThongKe/BieuDo";
import BaoCaoThongKe from "views/admin/ThongKe/BaoCao";
//bao cao
// import BaoCaoLichSuQuetThe from "views/admin/BaoCao/LichSuQuetVe/index";
// Auth Imports
import SignInCentered from "views/auth/signIn";
import { GoDotFill } from "react-icons/go";
import { CgPlayListAdd } from "react-icons/cg";
import {
  RiBuilding2Line,
  RiMoneyDollarCircleLine,
  RiServiceLine,
} from "react-icons/ri";
import { FaUsersViewfinder } from "react-icons/fa6";
import InfoUser from "views/admin/InfoUser";
import CauHinh from "views/admin/HeThong/CauHinh";
import { FcStatistics } from "react-icons/fc";
import { StockOutlined } from "@ant-design/icons";


const routes = [
  {
    key: "trang-chu",
    // label: "Trang chủ",
    layout: "/admin",
    icon: <Icon as={CiHome} width="20px" height="20px" color="inherit" />,
    path: "/trang-chu",
    component: BaoCao,
    id: 0,
  },
  // {
  //   key: "chats",
  //   label: "Nhắn tin",
  //   layout: "/admin",
  //   icon: <Icon as={CiChat2} width="20px" height="20px" color="inherit" />,
  //   path: "/chats",
  //   component: Chats,
  //   id: 0,
  // },
  {
    label: "Dự án",
    layout: "/admin",
    icon: <RiBuilding2Line size={18} />,
    children: [
      {
        key: "du-an",
        label: "Dự án",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/du-an",
        component: DanhSachDuAn,
        id: 120,
      },
      {
        key: "mat-bang",
        label: "Mặt bằng",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/mat-bang",
        component: DanhSachMatBang,
        id: 120,
      },
      {
        key: "nhan-vien",
        label: "Nhân viên",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/nhan-vien",
        component: DanhSachNhanVien,
        id: 120,
      },
    ],
    id: 57,
  },
  {
    label: "Công nợ",
    layout: "/admin",
    icon: <RiMoneyDollarCircleLine size={18} />,
    children: [
      {
        key: "cong-no-tong-hop",
        label: "Công nợ tổng hợp",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/cong-no-tong-hop",
        component: CongNoTongHop,
        id: 120,
      },
      {
        key: "hoa-don-chi-tiet",
        label: "Hóa đơn chi tiết",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/hoa-don-chi-tiet",
        component: HoaDonChiTiet,
        id: 120,
      },
      {
        key: "hoa-don-chi-tiet-xoa",
        label: "Hóa đơn chi tiết xóa",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/hoa-don-chi-tiet-xoa",
        component: HoaDonChiTietXoa,
        id: 120,
      },
      {
        key: "thanh-toan-app",
        label: "Thanh toán app",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/thanh-toan-app",
        component: ThanhToanApp,
        id: 120,
      },
    ],
    id: 57,
  },

  {
    label: "Dịch vụ",
    layout: "/admin",
    icon: <RiServiceLine size={18} />,
    children: [
      {
        key: "dich-vu-nuoc",
        label: "Nước",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/dich-vu-nuoc",
        component: DichVuNuoc,
        id: 120,
      },
      {
        key: "dich-vu-dien",
        label: "Điện",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/dich-vu-dien",
        component: DichVuDien,
        id: 120,
      },
      {
        key: "dich-vu-xe",
        label: "Xe",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/dich-vu-xe",
        component: DichVuXe,
        id: 120,
      },
      {
        key: "dich-vu-khac",
        label: "Dịch vụ khác",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/dich-vu-khac",
        component: DichVuKhac,
        id: 120,
      },
    ],
    id: 57,
  },
  {
    label: "Khách hàng",
    layout: "/admin",
    icon: <FaUsersViewfinder  style={{marginRight:'3px'}}/>,
    
    children: [
      {
        key: "yeu-cau",
        label: "Yêu cầu",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/yeu-cau",
        component: YeuCau,
        id: 120,
      },
      {
        key: "thong-bao",
        label: "Thông báo",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/thong-bao",
        component: ThongBao,
        id: 120,
      },
      {
        key: "khach-hang",
        label: "Khách hàng",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/khach-hang",
        component: KhachHang,
        id: 120,
      },
      {
        key: "cu-dan",
        label: "Cư dân",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/cu-dan",
        component: CuDan,
        id: 120,
      },
      {
        key: "tien-ich",
        label: "Tiện Ích",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/tien-ich",
        component: TienIch,
        id: 120,
      },
    ],
    id: 57,
  },
  {
    label: "Sổ quỹ",
    layout: "/admin",
    icon: <Icon as={BsCash} width="20px" height="20px" color="inherit" style={{marginRight:'3px'}} />,
    children: [
      {
        key: "danh-sach-phieu-thu",
        label: "Phiếu thu",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/danh-sach-phieu-thu",
        component: DanhSachPhieuThu,
        id: 120,
      },
      {
        key: "phieu-thu-chi-tiet",
        label: "Phiếu thu chi tiết",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/phieu-thu-chi-tiet",
        component: PhieuThuChiTiet,
        id: 121,
      },
      {
        key: "danh-sach-phieu-chi",
        label: "Phiếu chi",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/danh-sach-phieu-chi",
        component: DanhSachPhieuChi,
        id: 121,
      },
      // {
      //   key: "danh-sach",
      //   label: "Danh sách",
      //   layout: "/admin",
      //   icon: <GoDotFill />,
      //   path: "/danh-sach",
      //   component: DanhSach,
      //   id: 121,
      // },
    ],
    id: 57,
  },
  {
    label: "Thống kê",
    layout: "/admin",
    icon: <Icon as={StockOutlined} width="20px" height="20px" color="inherit" />,
    children: [
      {
        key: "bao-cao",
        label: "Báo cáo",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/bao-cao",
        component: BaoCaoThongKe,
        id: 120,
      },
      {
        key: "bieu-do",
        label: "Biểu đồ",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/bieu-do",
        component: BieuDo,
        id: 120,
      },
    ],
    id: 99,
  },
  {
    label: "Hệ thống",
    layout: "/admin",
    icon: (
      <Icon as={IoSettingsOutline} width="20px" height="20px" color="inherit" />
    ),
    children: [
      {
        key: "lichsu-otp",
        label: "Lịch sử otp",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/lichsu-otp",
        component: LichSuOTP,
        id: 401,
      },

      {
        key: "virtual-account",
        label: "Tài khoản MSB",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/virtual-account",
        component: DanhSachVirtualAccount,
        id: 401,
      },

      {
        key: "muc-dashboard",
        label: "Mục Dashboard",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/muc-dashboard",
        component: Dashboard,
        id: 401,
      },
      {
        key: "bieu-mau",
        label: "Biểu mẫu",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/bieu-mau",
        component: BieuMau,
        id: 401,
      },
      {
        key: "lich-su-notification",
        label: "Lịch sử notification",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/lich-su-notification",
        component: HistoryNotification,
        id: 401,
      },
      {
        key: "cau-hinh",
        label: "Cấu hình",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/cau-hinh",
        component: CauHinh,
        id: 401,
      },
    ],
    id: 51,
  },
  {
    label: "Danh mục",
    layout: "/admin",
    icon: (
      <Icon as={BiCategoryAlt} width="20px" height="20px" color="inherit" />
    ),
    children: [
      {
        key: "danh-muc-du-an",
        label: "Dự án",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/danh-muc-du-an",
        component: DanhMucToaNha,
        id: 395,
      },
      {
        key: "danhmuc-nhan-vien",
        label: "Nhân viên",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/danhmuc-nhan-vien",
        component: DanhMucNhanVien,
        id: 395,
      },
      // {
      //   key: "danhmuc-yeu-cau",
      //   label: "Yêu cầu",
      //   layout: "/admin",
      //   icon: <GoDotFill />,
      //   path: "/danhmuc-yeu-cau",
      //   component: DanhMucYeuCau,
      //   id: 395,
      // },
      {
        key: "danh-muc-dich-vu",
        label: "Dịch vụ",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/danh-muc-dich-vu",
        component: DanhMucDichVu,
        id: 395,
      },
      {
        key: "danh-muc-khach-hang",
        label: "Khách hàng",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/danh-muc-khach-hang",
        component: DanhMucKhachHang,
        id: 395,
      },
    ],
    id: 94,
  },
  {
    // label: "Thông tin người dùng",
    layout: "/admin",
    // icon: (
    //   <Icon as={BiCategoryAlt} width="20px" height="20px" color="inherit" />
    // ),
    children: [
      {
        key: "info-user",
        label: "Info user",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/info-user",
        component: InfoUser,
        // id: 395,
      },
    ],
    // id: 94,
  },
  // {
  //   label: "Báo cáo",
  //   layout: "/admin",
  //   icon: <Icon as={FaChartPie} width="20px" height="20px" color="inherit" />,
  //   children: [
  //     {
  //       key: "lich-su-quet-the",
  //       label: "Lịch sử quẹt vé",
  //       layout: "/admin",
  //       icon: (
  //         <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
  //       ),
  //       path: "/lich-su-quet-the",
  //       component: BaoCaoLichSuQuetThe,
  //       id: 406,
  //     },
  //   ],
  //   id: 61,
  // },
  {
    label: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
];

export default routes;
