import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Space,
  Drawer,
  notification,
  Table,
  Upload,
  message,
  Select,
  DatePicker,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import { UploadOutlined } from "@ant-design/icons";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import SelectCustom from "components/Forms/SelectCustom";
import { DichVuService } from "services/DichVuService";
import { DateTime } from "luxon";

const FormAdd = (props, ref) => {
  const { onAfterSubmit, maTN } = props;
  const [api, contextHolder] = notification.useNotification();

  const [data, setData] = useState();
  const [dataTT, setDataTT] = useState({
    NgayTT: DateTime.now().toFormat("yyyy-MM-dd"),
  });
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sheetExcel, setSheetExcel] = useState();
  const [workbook, setWorkbook] = useState(null);
  const defaultErrors = { TenKH: null };
  const [errors, setErrors] = useState(defaultErrors);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData();
    setSheetExcel();
    setCol(columns);
  };

  const columns = [
    {
      title: "Mã Số MB",
      dataIndex: "MaSoMB",
      key: "MaSoMB",
    },
    {
      title: "Mã Số KH",
      dataIndex: "MaSoKH",
      key: "MaSoKH",
    },
    {
      title: "Mã CT",
      dataIndex: "MaCongTo",
      key: "MaCongTo",
    },
    {
      title: "Chỉ Số Cũ",
      dataIndex: "ChiSoCu",
      key: "ChiSoCu",
    },
    {
      title: "Chỉ Số Mới",
      dataIndex: "ChiSoMoi",
      key: "ChiSoMoi",
    },
    {
      title: "Điện Công C",
      dataIndex: "DienCongCong",
      key: "DienCongCong",
      width: 120,
    },
    {
      title: "Diễn Giải",
      dataIndex: "DienGiai",
      key: "DienGiai",
    },
    {
      title: "Nợ Đầu Kỳ",
      dataIndex: "NoDauKy",
      key: "NoDauKy",
    },
    {
      title: "Hệ Số DC",
      dataIndex: "HeSoDC",
      key: "HeSoDC",
    },
    {
      title: "Thuế VAT",
      dataIndex: "VAT",
      key: "VAT",
    },
    {
      title: "Hao Hụt",
      dataIndex: "HaoHut",
      key: "HaoHut",
    },
  ];
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const sampleData = [
    {
      MaSoMB: "HH1-0301",
      MaSoKH: "KH001",
      MaCongTo: "HH1-0301",
      ChiSoCu: 100,
      ChiSoMoi: 150,
      DienCongCong: 50,
      DienGiai: "Tháng 7",
      NoDauKy: 600000,
      HeSoDC: 1.2,
      VAT: 10,
      HaoHut: 3,
    },
  ];
  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(sampleData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "File mẫu điện" + fileExtension);
  };

  const loadDataImport = (data) => {
    let res = [];
    data?.map((item, i) => {
      if (i > 0) {
        res.push({
          STT: i + 1,
          MaSoMB: item[0],
          MaSoKH: item[1],
          MaCongTo: item[2],
          ChiSoCu: item[3],
          ChiSoMoi: item[4],
          DienCongCong: item[5],
          DienGiai: item[6],
          NoDauKy: item[7],
          HeSoDC: item[8],
          VAT: item[9],
          HaoHut: item[10],
        });
      }
    });
    setData(res);
  };
  const handleSheet = (value) => {
    const wsname = workbook.SheetNames[value];
    const ws = workbook.Sheets[wsname];
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
    loadDataImport(data);
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    if (!_data) {
      setLoading(false);
      return;
    }

    const filterData = _data.map((item) => {
      return {
        ...item,
      };
    });
    let _res = await DichVuService.Dien.import({
      MaTN: maTN,
      NgayTT: dataTT?.NgayTT,
      List: filterData,
    });
    setLoading(false);

    if (_res.status !== 2000) {
      openNotification("error", "topRight", _res.message);
    } else if (_res.status === 2000 && _res.data.length === 0) {
      openNotification("success", "topRight", "Thao tác thành công");
      setIsUpdate(true);
      onClose();
    } else {
      const pushErrorData = _data.map((item) => {
        let updatedItem = { ...item };
        _res?.data.forEach((i) => {
          if (item.MaSoMB === i.maSoMB) {
            updatedItem = { ...item, error: i.error };
          }
        });
        return updatedItem;
      });
      setData(pushErrorData.filter((item) => item.error));
      setCol([
        {
          title: "Error",
          dataIndex: "error",
          key: "error",
          width: 180,
          render: (item) => <p style={{ color: "red" }}>{item}</p>,
        },
        ...columns,
      ]);
      // onClose()
      openNotification("success", "topRight", "Thao tác thành công");
    }
    onAfterSubmit();
  };

  const [col, setCol] = useState(columns);
  const onChangeDate = (date, dateString) => {
    setDataTT({ ...data, NgayTT: dateString });
  };

  return (
    <Drawer
      title="Excel"
      width={960}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          {sheetExcel && (
            <Select
              placeholder="Chọn Sheet"
              style={{
                width: 115,
                border: "1px solid #d9d9d9",
                borderBottom: "3px solid #d9d9d",
                borderRadius: "5px",
                padding: "0 2px 0 10px",
              }}
              onChange={handleSheet}
              options={sheetExcel}
            />
          )}
          <DatePicker
            style={{
              border: "1px solid #d9d9d9",
              borderBottom: "3px solid #d9d9d",
              borderRadius: "5px",
              padding: "5px",
            }}
            placeholder="Chọn ngày TT"
            // value={dataTT?.NgayTT}
            onChange={onChangeDate}
          />

          <Upload
            accept=".txt, .csv, .xlsx, .xls"
            showUploadList={false}
            beforeUpload={(file) => {
              setWorkbook(null);
              setSheetExcel(null);
              const reader = new FileReader();
              const rABS = !!reader.readAsBinaryString;
              reader.onload = (e) => {
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
                const sheets = wb.SheetNames.map((item, index) => ({
                  value: index,
                  label: item,
                }));
                if (sheets.length === 1) {
                  const wsname = wb.SheetNames[0];
                  const ws = wb.Sheets[wsname];
                  const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
                  loadDataImport(data);
                  setCol(columns);
                } else {
                  setWorkbook(wb);
                  setSheetExcel(sheets);
                  setData();
                  setCol(columns);
                }
              };
              if (rABS) reader.readAsBinaryString(file);
              else reader.readAsArrayBuffer(file);
            }}
          >
            <Button icon={<UploadOutlined />}>Upload File</Button>
          </Upload>
          <Button onClick={() => exportToCSV()}>Tải file mẫu</Button>
          <Button type="" danger onClick={() => setData([])}>
            Xóa
          </Button>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Table
        style={{ margin: 0, marginTop: "20px" }}
        dataSource={data}
        columns={col}
        scroll={{
          y: window.innerHeight - 250,
          x: 1200,
        }}
      />
    </Drawer>
  );
};
export default forwardRef(FormAdd);
