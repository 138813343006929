import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Space,
  Drawer,
  notification,
  Table,
  Upload,
  message,
  Select,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import { UploadOutlined } from "@ant-design/icons";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { DichVuService } from "services/DichVuService";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Days from "react-calendar/dist/umd/MonthView/Days";
import { ConvertExcelDateToJSDate } from "layouts/ultis/common";
const props2 = {
  name: "file",
  action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const FormAdd = (props, ref) => {
  const { maTN, onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();

  const [data, setData] = useState([]);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = { TenKH: null };
  const [errors, setErrors] = useState(defaultErrors);
  const [sheetExcel, setSheetExcel] = useState();
  const [workbook, setWorkbook] = useState(null);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData([]);

    setSheetExcel();
    setCol(columns);
  };

  const columns = [
    {
      title: "Mã số MB",
      dataIndex: "MaSoMB",
      key: "MaSoMB",
    },
    {
      title: "Chủ Thẻ",
      dataIndex: "ChuThe",
      key: "ChuThe",
    },
    {
      title: "Quan Hệ CH",
      dataIndex: "QuanHeCH",
      key: "QuanHeCH",
    },
    {
      title: "Điện Thoại",
      dataIndex: "DienThoai",
      key: "DienThoai",
    },

    {
      title: "Số Thẻ",
      dataIndex: "SoThe",
      key: "SoThe",
    },
    {
      title: "Card No",
      dataIndex: "CardNo",
      key: "CardNo",
    },
    {
      title: "Biển Số",
      dataIndex: "BienSo",
      key: "BienSo",
    },
    {
      title: "Nhãn Hiệu",
      dataIndex: "NhanHieu",
      key: "NhanHieu",
    },
    {
      title: "Màu Xe",
      dataIndex: "MauXe",
      key: "MauXe",
    },
    {
      title: "Loại xe",
      dataIndex: "LoaiXe",
      key: "LoaiXe",
    },

    {
      title: "Tiền Đặt Cọc",
      dataIndex: "TienDatCoc",
      key: "TienDatCoc",
    },
    {
      title: "Phí Gửi Xe",
      dataIndex: "PhiGuiXe",
      key: "PhiGuiXe",
    },
    {
      title: "% VAT",
      dataIndex: "PTVAT",
      key: "PTVAT",
    },

    {
      title: "Tiền VAT",
      dataIndex: "TienVAT",
      key: "TienVAT",
    },

    {
      title: "Nhóm thẻ",
      dataIndex: "NhomThe",
      key: "NhomThe",
    },

    {
      title: "Ghi chú",
      dataIndex: "GhiChu",
      key: "GhiChu",
    },
    {
      title: "Ngày ĐK",
      dataIndex: "NgayDK",
      key: "NgayDK",
    },
    {
      title: "Ngày Tính Phí",
      dataIndex: "NgayTinhPhi",
      key: "NgayTinhPhi",
    },

    {
      title: "Ngày Hết Hạn",
      dataIndex: "NgayHetHan",
      key: "NgayHetHan",
    },
    {
      title: "Ngày TT",
      dataIndex: "NgayTT",
      key: "NgayTT",
    },
    {
      title: "Ngày nhập",
      dataIndex: "NgayNhap",
      key: "NgayNhap",
    },
  ];

  const [col, setCol] = useState(columns);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const sampleData = [
    {
      MaSoMB: "HH1-0308",
      ChuThe: "Mr.AAAAAAAAAAAAAAAAA",
      QuanHeCH: "Owner",
      DienThoai: "0123456789",
      SoThe: "123456789",
      CardNo: "987654321",
      BienSo: "30A-12345",
      NhanHieu: "Toyota",
      MauXe: "Red",
      LoaiXe: "Ô tô",
      TienDatCoc: 100000,
      PhiGuiXe: 50000,
      PTVAT: 10, // 10% VAT
      TienVAT: 5000,
      NhomThe: "Group A",
      GhiChu: "No additional notes",
      NgayDK: "30/01/2020",
      NgayTinhPhi: "30/01/2020",
      NgayHetHan: "30/01/2020",
      NgayTT: "30/01/2020",
      NgayNhap: "30/01/2020",
    },
  ];
  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(sampleData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "File mẫu xe" + fileExtension);
  };

  const loadDataImport = (data) => {
    let res = [];
    data?.forEach((item, i) => {
      if (i > 0) {
        res.push({
          MaSoMB: item[0]?.toString(),
          ChuThe: item[1]?.toString(),
          QuanHeCH: item[2]?.toString(),
          DienThoai: item[3]?.toString(),
          SoThe: item[4]?.toString(),
          CardNo: item[5]?.toString(),
          BienSo: item[6]?.toString(),
          NhanHieu: item[7]?.toString(),
          MauXe: item[8]?.toString(),
          LoaiXe: item[9]?.toString(),
          TienDatCoc: item[10],
          PhiGuiXe: item[11],
          PTVAT: item[12],
          TienVAT: item[13],
          NhomThe: item[14]?.toString(),
          GhiChu: item[15]?.toString(),
          NgayDK:
            typeof item[16] === "number"
              ? ConvertExcelDateToJSDate(item[16])
              : item[16],
          NgayTinhPhi:
            typeof item[17] === "number"
              ? ConvertExcelDateToJSDate(item[17])
              : item[17],
          NgayHetHan:
            typeof item[18] === "number"
              ? ConvertExcelDateToJSDate(item[18])
              : item[18],
          NgayTT:
            typeof item[19] === "number"
              ? ConvertExcelDateToJSDate(item[19])
              : item[19],
          NgayNhap:
            typeof item[20] === "number"
              ? ConvertExcelDateToJSDate(item[20])
              : item[20],
        });
      }
    });
    setData(res);
  };

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };

  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);

    dayjs.extend(customParseFormat);

    const formatDate = (dateString) => {
      if (dayjs(dateString, "DD/MM/YYYY", true).isValid()) {
        return dayjs(dateString, "DD/MM/YYYY").format("YYYY-MM-DD");
      } else if (dayjs(dateString, "YYYY/MM/DD", true).isValid()) {
        return dayjs(dateString, "YYYY/MM/DD").format("YYYY-MM-DD");
      } else if (dayjs(dateString, "DD-MM-YYYY", true).isValid()) {
        return dayjs(dateString, "DD-MM-YYYY").format("YYYY-MM-DD");
      } else {
        return dateString;
      }
    };

    const filterData = _data.map((item) => {
      return {
        ...item,
        NgayDK: formatDate(item.NgayDK),
        NgayTinhPhi: formatDate(item.NgayTinhPhi),
        NgayHetHan: formatDate(item.NgayHetHan),
        NgayTT: formatDate(item.NgayTT),
        NgayNhap: formatDate(item.NgayNhap),
      };
    });

    let _res = await DichVuService.Xe.importTX({
      MaTN: maTN,
      ListXe: filterData,
    });
    setLoading(false);

    if (_res.status !== 2000) {
      openNotification("error", "topRight", _res.message);
    } else if (_res.status === 2000 && _res.data.length === 0) {
      openNotification("success", "topRight", "Thao tác thành công");
      setIsUpdate(true);
      onClose();
    } else {
      const pushErrorData = _data.map((item) => {
        let updatedItem = { ...item };
        _res?.data.forEach((i) => {
          if (item.MaSoMB === i.matBang && item.LoaiXe === i.loaiXe) {
            updatedItem = { ...item, error: i.error };
          }
        });
        return updatedItem;
      });
      setData(pushErrorData.filter((item) => item.error));
      setCol([
        {
          title: "Error",
          dataIndex: "error",
          key: "error",
          width: 180,
          render: (item) => <p style={{ color: "red" }}>{item}</p>,
        },
        ...columns,
      ]);

      // openNotification("success", "topRight", "Thao tác thành công");
    }

    onAfterSubmit();
  };
  const handleSheet = (value) => {
    const wsname = workbook.SheetNames[value];
    const ws = workbook.Sheets[wsname];
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
    loadDataImport(data);
    setCol(columns);
  };

  return (
    <Drawer
      title="Excel"
      width={1260}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          {sheetExcel && (
            <Select
              placeholder="Chọn Sheet"
              style={{
                width: 115,
                border: "1px solid #d9d9d9",
                borderBottom: "3px solid #d9d9d",
                borderRadius: "5px",
                padding: "0 2px 0 10px",
              }}
              onChange={handleSheet}
              options={sheetExcel}
            />
          )}
          <Upload
            accept=".txt, .csv, .xlsx, .xls"
            showUploadList={false}
            beforeUpload={(file) => {
              setWorkbook(null);
              setSheetExcel(null);
              const reader = new FileReader();
              const rABS = !!reader.readAsBinaryString;
              reader.onload = (e) => {
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
                const sheets = wb.SheetNames.map((item, index) => ({
                  value: index,
                  label: item,
                }));
                if (sheets.length === 1) {
                  const wsname = wb.SheetNames[0];
                  const ws = wb.Sheets[wsname];
                  const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

                  loadDataImport(data);
                  setCol(columns);
                } else {
                  setWorkbook(wb);
                  setSheetExcel(sheets);
                  setData();
                }
              };
              if (rABS) reader.readAsBinaryString(file);
              else reader.readAsArrayBuffer(file);
            }}
          >
            <Button icon={<UploadOutlined />}>Upload File</Button>
          </Upload>
          <Button onClick={() => exportToCSV()}>Tải file mẫu</Button>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Table
        style={{ margin: 0, marginTop: "20px" }}
        dataSource={data}
        columns={col}
        scroll={{
          y: window.innerHeight - 250,
          x: 2300,
        }}
      />
    </Drawer>
  );
};
export default forwardRef(FormAdd);
