import { Drawer, notification, Table, Tabs } from "antd";
import { Format_Date } from "layouts/ultis/common";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { KhachHangService } from "services/KhachHangService";

const FormHistory = (props, ref) => {
  const { onAfterSubmit, options } = props;
  const [api, contextHolder] = notification.useNotification();
  const [dataTab1, setDataTab1] = useState([]);
  const [dataTab2, setDataTab2] = useState([]);

  const [visible, setVisible] = useState(false);

  const { TabPane } = Tabs;
  // Mở Drawer
  const showDrawer = () => {
    setVisible(true);
  };


  // Đóng Drawer
  const onClose = () => {
    setVisible(false);
  };
  const filter = async (value) => {
    let _res = await KhachHangService.KhachHang.getLichSuKH({
      MaKH: value,
    });
    if (_res.data) setDataTab1(_res.data);
    let _res2 = await KhachHangService.KhachHang.getMatBang({
      MaKH: value,
    });
    if (_res2.data) {
      setDataTab2(_res2.data);
    }
  };

  useImperativeHandle(ref, () => ({
    show: async (item) => {
      setVisible(true);
      filter(item?.MaKH);
    },
  }));

  const columns = [
    {
      title: "Mã KH",
      dataIndex: "KyHieu",
      key: "KyHieu",
    },
    {
      title: "Email",
      dataIndex: "EmailKH",
      key: "EmailKH",
    },
    {
      title: "Tên",
      dataIndex: "TenKH",
      key: "TenKH",
    },
    {
      title: "Điện thoại",
      dataIndex: "DienThoaiKH",
      key: "DienThoaiKH",
    },
    {
      title: "Địa chỉ liên lạc",
      dataIndex: "DCLL",
      key: "DCLL",
    },
    {
      title: "Địa chỉ thường trú",
      dataIndex: "DCTT",
      key: "DCTT",
    },
    {
      title: "Người nhập",
      dataIndex: "HoTenNV",
      key: "HoTenNV",
    },
    {
      title: "Ngày nhập",
      dataIndex: "NgayNhap",
      key: "NgayNhap",

      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Diễn giải",
      dataIndex: "DienGiai",
      key: "DienGiai",
    },
  ];
  const columns2 = [
    {
      title: "Ký hiệu",
      dataIndex: "MaSoMB",
      key: "MaSoMB",
    },

    {
      title: "Loại mặt bằng",
      dataIndex: "TenLMB",
      key: "TenLMB",
    },
    {
      title: "Đơn giá",
      dataIndex: "DonGia",
      key: "DonGia",
    },
    {
      title: "Diện tích",
      dataIndex: "DienTich",
      key: "DienTich",
    },
    {
      title: "Trạng thái",
      dataIndex: "TenTT",
      key: "TenTT",
    },
    {
      title: "Chủ hộ",
      dataIndex: "TenKH",
      key: "TenKH",
    },
    {
      title: "Ngày bàn giao",
      dataIndex: "NgayBanGiao",
      key: "NgayBanGiao",

      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Nhân viên",
      dataIndex: "HoTenNV",
      key: "HoTenNV",
    },
    {
      title: "Diễn giải",
      dataIndex: "DienGiai",
      key: "DienGiai",
    },
    {
      title: "Ngày tính phí",
      dataIndex: "BatDauTinhPhi",
      key: "BatDauTinhPhi",

      render: (item) => (item ? Format_Date(item) : ""),
    },
  ];

  return (
    <>
      <Drawer
        title="Lịch sử sủa khách hàng"
        placement="right"
        closable={true}
        onClose={onClose}
        visible={visible}
        width={1400}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Lịch sử cập nhật" key="1">
            <Table bordered pagination={false} columns={columns} dataSource={dataTab1} />
          </TabPane>
          <TabPane tab="Mặt bằng đang sử dụng" key="2">
            <Table
              bordered
              pagination={false}
              columns={columns2}
              dataSource={dataTab2}
              scroll={{
                y: window.innerHeight - 250,
                x: 1200,
              }}
            />
          </TabPane>
        </Tabs>
      </Drawer>
    </>
  );
};

export default forwardRef(FormHistory);
