import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Space,
  Drawer,
  notification,
  Table,
  Upload,
  Select,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import { UploadOutlined } from "@ant-design/icons";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { ConvertExcelDateToJSDate } from "layouts/ultis/common";

const FormAdd = (props, ref) => {
  const { onAfterSubmit, options, filterCondition } = props;
  const [api, contextHolder] = notification.useNotification();

  const [data, setData] = useState([]);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = { TenKH: null };
  const [errors, setErrors] = useState(defaultErrors);
  const [sheetExcel, setSheetExcel] = useState();
  const [workbook, setWorkbook] = useState(null);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData([]);
    setSheetExcel();
  };

  const columns = [
    { title: "Mã Phiếu", dataIndex: "MaPhieu", key: "MaPhieu", ellipsis: true },
    { title: "Mã MB", dataIndex: "MaMB", key: "MaMB", ellipsis: true },
    { title: "Mã KH", dataIndex: "MaKH", key: "MaKH", ellipsis: true },
    {
      title: "Người Nộp",
      dataIndex: "NguoiNop",
      key: "NguoiNop",
      ellipsis: true,
    },
    {
      title: "Hình Thức Thu",
      dataIndex: "HinhThucThu",
      key: "HinhThucThu",
      ellipsis: true,
    },
    { title: "Số Tài Khoản", dataIndex: "SoTK", key: "SoTK", ellipsis: true },
    { title: "Mã LDV", dataIndex: "MaLDV", key: "MaLDV", ellipsis: true },
    { title: "Ngày Thu", dataIndex: "NgayThu", key: "NgayThu", ellipsis: true },
    { title: "Ngày TT", dataIndex: "NgayTT", key: "NgayTT", ellipsis: true },
    { title: "Số Tiền", dataIndex: "SoTien", key: "SoTien", ellipsis: true },
    { title: "Biển Số", dataIndex: "BienSo", key: "BienSo", ellipsis: true },
    {
      title: "Mã Đồng Hồ Nước",
      dataIndex: "MaDongHoNuoc",
      key: "MaDongHoNuoc",
      ellipsis: true,
    },
    {
      title: "Mã Công Tơ Điện",
      dataIndex: "MaCongToDien",
      key: "MaCongToDien",
      ellipsis: true,
    },
    {
      title: "Diễn Giải",
      dataIndex: "DienGiai",
      key: "DienGiai",
      ellipsis: true,
    },
  ];

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const sampleData = [
    {
      MaPhieu: "P001",
      MaMB: "MB001",
      MaKH: "KH001",
      NguoiNop: "Nguyễn Văn A",
      HinhThucThu: "Chuyển khoản",
      SoTK: "123456789",
      MaLDV: "LDV001",
      NgayThu: "01/01/2024",
      NgayTT: "02/01/2024",
      SoTien: "1000000",
      BienSo: "29A-12345",
      MaDongHoNuoc: "DH001",
      MaCongToDien: "CTD001",
      DienGiai: "Thanh toán tiền nước và điện tháng 1",
    },
  ];
  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(sampleData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "File mẫu " + fileExtension);
  };
  const loadDataImport = (data) => {
    let res = [];
    data?.map((item, i) => {
      if (i > 0) {
        res.push({
          STT: i + 1,
          MaPhieu: item[0],
          MaMB: item[1],
          MaKH: item[2],
          NguoiNop: item[3],
          HinhThucThu: item[4],
          SoTK: item[5],
          MaLDV: item[6],
          NgayThu:
            typeof item[7] === "number"
              ? ConvertExcelDateToJSDate(item[7])
              : item[7],
          NgayTT:
            typeof item[8] === "number"
              ? ConvertExcelDateToJSDate(item[8])
              : item[8],
          SoTien: item[9],
          BienSo: item[10],
          MaDongHoNuoc: item[11],
          MaCongToDien: item[12],
          DienGiai: item[13],
        });
      }
    });
    setData(res);
  };
  const handleSheet = (value) => {
    const wsname = workbook.SheetNames[value];
    const ws = workbook.Sheets[wsname];
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
    loadDataImport(data);
  };
  return (
    <Drawer
      title="Excel"
      width={960}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          {sheetExcel && (
            <Select
              placeholder="Chọn Sheet"
              style={{
                width: 115,
                border: "1px solid #d9d9d9",
                borderBottom: "3px solid #d9d9d",
                borderRadius: "5px",
                padding: "0 2px 0 10px",
              }}
              onChange={handleSheet}
              options={sheetExcel}
            />
          )}
          <Upload
            accept=".txt, .csv, .xlsx, .xls"
            showUploadList={false}
            beforeUpload={(file) => {
              setWorkbook(null);
              setSheetExcel(null);
              const reader = new FileReader();
              const rABS = !!reader.readAsBinaryString;
              reader.onload = (e) => {
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
                const sheets = wb.SheetNames.map((item, index) => ({
                  value: index,
                  label: item,
                }));
                if (sheets.length === 1) {
                  const wsname = wb.SheetNames[0];
                  const ws = wb.Sheets[wsname];
                  const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
                  loadDataImport(data);
                } else {
                  setWorkbook(wb);
                  setSheetExcel(sheets);
                  setData();
                }
              };
              if (rABS) reader.readAsBinaryString(file);
              else reader.readAsArrayBuffer(file);
            }}
          >
            <Button icon={<UploadOutlined />}>Upload File</Button>
          </Upload>
          <Button onClick={() => exportToCSV()}>Tải file mẫu</Button>
          <Button danger onClick={() => setData()}>
            Xóa
          </Button>
          <Button
            loading={loading}
            // onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Table
        style={{ margin: 0, marginTop: "20px" }}
        dataSource={data}
        columns={columns}
        scroll={{ x: 1500 }}
      />
    </Drawer>
  );
};
export default forwardRef(FormAdd);
