import routes from "routes.js";
const ActiveRoute = () => {
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes?.length; i++) {
      if (routes[i].children) {
        let collapseActiveRoute = getActiveRoute(routes[i].children);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].label;
        }
      }
    }
    return activeRoute;
  };
  return (
    <p
      style={{
        fontWeight: "bold",
        fontSize: 15,
        color: "coral",
        padding: "10px 0px 5px",
      }}
    >
      {getActiveRoute(routes)}
    </p>
  );
};
export default ActiveRoute;
