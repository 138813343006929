import { Drawer, notification, Table, Tabs } from "antd";
import { Format_Currency } from "layouts/ultis/common";
import { Format_Date } from "layouts/ultis/common";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { MatBangService } from "services/MatBangService";

const FormQLHistory = (props, ref) => {
  const { onAfterSubmit, options } = props;
  const [api, contextHolder] = notification.useNotification();
  const [lichSuCN, setLichSuCN] = useState([]);

  const [payLoad, setPayload] = useState();

  const [visible, setVisible] = useState(false);

  const { TabPane } = Tabs;

  const onClose = () => {
    setVisible(false);
  };

  useImperativeHandle(ref, () => ({
    show: async (item) => {
      setVisible(true);
      setPayload(item?.MaMB);
      fetchDataForTab("1", item?.MaMB);
    },
  }));

  const columns = [
    {
      title: "Ký hiệu",
      dataIndex: "MaSoMB",
      key: "MaSoMB",
      ellipsis: true,
    },
    {
      title: "Lầu",
      dataIndex: "TenTL",
      key: "TenTL",
      ellipsis: true,
    },
    {
      title: "Loại mặt bằng",
      dataIndex: "TenLMB",
      key: "TenLMB",
      ellipsis: true,
    },

    {
      title: "Ngày tính phí quản lý",
      dataIndex: "BatDauTinhPhi",
      key: "BatDauTinhPhi",
      ellipsis: true,
      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Ngày bàn giao mặt bằng",
      dataIndex: "NgayBanGiao",
      key: "NgayBanGiao",
      ellipsis: true,
      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Số người hưởng định mức",
      dataIndex: "SoNguoiHDM",
      key: "SoNguoiHDM",
      ellipsis: true,
    },
    {
      title: "Người nhập",
      dataIndex: "HoTenNV",
      key: "HoTenNV",
      ellipsis: true,
    },
    {
      title: "Ngày cập nhật",
      dataIndex: "DateCreate",
      key: "DateCreate",
      ellipsis: true,
      render: (item) => (item ? Format_Date(item) : ""),
    },
  ];

  const fetchDataForTab = async (key, value) => {
    switch (key) {
      case "1":
        let _res = await MatBangService.getLichSuCN({
          MaMB: value,
        });
        if (_res.data) setLichSuCN(_res.data);

        break;

      default:
        break;
    }
  };

  const onTabChange = (key) => {
    fetchDataForTab(key, payLoad);
  };

  return (
    <>
      <Drawer
        title="Quản lý lịch sử nước"
        placement="right"
        closable={true}
        onClose={onClose}
        visible={visible}
        width={1300}
      >
        <Tabs defaultActiveKey="1" onChange={onTabChange}>
          <TabPane tab="Lịch sử cập nhật" key="1">
            <Table
              pagination={false}
              columns={columns}
              dataSource={lichSuCN}
              scroll={{
                y: window.innerHeight - 250,
                x: 1200,
              }}
            />
          </TabPane>
        </Tabs>
      </Drawer>
    </>
  );
};

export default forwardRef(FormQLHistory);
