import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Flex,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { DatePicker } from "antd/lib";
import vi from "dayjs/locale/vi";
import DebounceSelect from "components/DebounceSelect";
import { SoQuyService } from "services/SoQuyService";
import { CustomerService } from "services/CustomerService";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import { DateTime } from "luxon";
const { TextArea } = Input;

const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const defaulData = {
    NgayChi: new Date(),
  };
  const [data, setData] = useState(defaulData);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = {
    MaKH: null,
    SoTien: null,
  };
  const [errors, setErrors] = useState(defaultErrors);
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
    setErrors(defaultErrors);
    onAfterSubmit();
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "NgayChi":
        const date = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString = date.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString;
        break;

      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    if (_data.MaKH?.length === 0) {
      setLoading(false);
      return;
    }
    if (Array.isArray(_data.MaKH)) {
      _data.MaKH = _data.MaKH[0].value;
    }
    let _res = null;
    if (isupdate) {
      _res = await SoQuyService.PhieuChi.updatePhieuChi(_data);
    } else {
      _res = await SoQuyService.PhieuChi.addPhieuChi(_data);
      setData((pre) => {
        return { ...pre, ID: _res.data };
      });
    }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} phiếu chi thành công!`
      );
      // setIsUpdate(true);
      if (close) onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  async function fetchUserList(username) {
    return CustomerService.filter({ InputSearch: username }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.TenCongTy} - ${item.DiDong} - ${new Date(
          item.NgaySinh
        ).getDate()}/${new Date(item.NgaySinh).getMonth() + 1}/${new Date(
          item.NgaySinh
        ).getFullYear()}`,
        value: item.MaKH,
        TenKH: item.TenCongTy,
        DiDong: item.DiDong,
      }));
    });
  }
  return (
    <Drawer
      title="PHIẾU CHI"
      width={620}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          {/* <Button
            loading={loading}
            onClick={() => onSubmit(false)}
            type="primary"
            ghost
          >
            Lưu
          </Button> */}
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Row gutter={[24, 18]}>
        <Col span={24} className="gutter-row">
          <Flex
            style={{ borderBottom: "1px solid #d9d9d9", marginTop: "20px" }}
          >
            <p style={{ marginTop: "5px", fontWeight: "600" }}>
              Khách hàng <span style={{ color: "red" }}>*</span>
            </p>
            <DebounceSelect
              open={open}   
              mode="multiple"
              value={data?.MaKH}
              placeholder="Tìm kiểm khách hàng"
              status={data?.MaKH ? "" : "error"}
              fetchOptions={fetchUserList}
              onChange={(newValue) => applyChange("MaKH", newValue)}
              style={{
                width: "100%",
              }}
            />
          </Flex>

          <p style={{ color: "red" }}>{errors?.MaKH}</p>
        </Col>
        <Col span={24}>
          <DatePickerCustom
            label="Ngày chi:"
            applyChange={applyChange}
            prop={"NgayChi"}
            value={data?.NgayChi}
            placeholder={"Chọn ngày chi"}
          />
        </Col>

        <Col span={24}>
          <InputNumberCustom
            label="Số tiền:"
            applyChange={applyChange}
            prop={"SoTien"}
            value={data?.SoTien}
            placeholder=""
            addonAfter="vnđ"
          />
        </Col>

        <Col span={24}>
          <p style={{ fontWeight: "600" }}>Lý do:</p>
          <TextArea
            value={data?.LyDo}
            onChange={(e) => applyChange("LyDo", e.target.value)}
            placeholder="Nhập lý do"
          />
        </Col>
      </Row>
    </Drawer>
  );
};
export default forwardRef(FormAdd);
