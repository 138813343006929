import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Checkbox,
  Flex,
} from "antd";
import _, { cloneDeep } from "lodash";
import "dayjs/locale/vi";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import SelectCustom from "components/Forms/SelectCustom";
import InputCustom from "components/Forms/InputCustom";
import DebounceSelect from "components/DebounceSelect";
import { SaveOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";
import { MatBangService } from "services/MatBangService";
import { CategoryService } from "services/CategoryService";
import { DichVuService } from "services/DichVuService";
const { TextArea } = Input;

const FormAdd = (props, ref) => {
  const { onAfterSubmit, filterCondition, loaiDV } = props;
  const [api, contextHolder] = notification.useNotification();
  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");
  const firstDayOfMonth = dateInHoChiMinh.startOf("month");
  const lastDayOfMonth = dateInHoChiMinh.endOf("month");
  const defaulData = {
    ThanhToanTheoKy: false,
    NgayNhap: dateInHoChiMinh.toISO(),
    NgayTinhPhi: firstDayOfMonth.toISO(),
    NgayTT: firstDayOfMonth.toISO(),
  };
  const [data, setData] = useState(defaulData);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const defaultErrors = {
    MatBang: null,
    NgayNhap: null,
    MaLoaiDV: null,
    SoLuong: null,
    NgayTinhPhi: null,
    NgayTT: null,
  };
  const [errors, setErrors] = useState(defaultErrors);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      setErrors(defaultErrors);
    },
    update: async (item) => {
      setOpen(true);
      setIsUpdate(true);
      setData({
        ...item,
        MatBang: {
          value: item?.MaMB,
          label: item?.MaSoMB,
        },
        PhiDV: item?.SoTien,
      });
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "NgayNhap":
        const date = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString = date.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString;
        break;
      case "NgayTT":
        const date2 = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString2 = date2.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString2;
        break;
      case "NgayTinhPhi":
        const date3 = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString3 = date3.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString3;
        break;
      case "ThanhToanTheoKy":
        _data[p] = !v;
        break;
      case "SoLuong":
        _data[p] = v;
        _data.PhiDV = (v ?? 0) * (_data?.DonGia ?? 0);
        break;
      case "DonGia":
        _data.PhiDV = (v ?? 0) * (_data?.SoLuong ?? 0);
        _data[p] = v;
        break;
      case "MaLoaiDV":
        const DV = loaiDV.find((item) => item.MaLDV === v);
        _data.DonGia = DV?.PhiDV;
        _data.TenLoaiDV = DV?.TenLDV;
        _data.PhiDV = (_data.DonGia ?? 0) * (_data?.SoLuong ?? 0);
        _data.DienGiai = DV?.TenLDV;
        _data[p] = v;
        break;
      case "MatBang":
        const KH = JSON.parse(v?.title);
        _data.TenKH = KH?.TenKH;
        _data.MaTN = KH?.MaTN;
        _data.MaMB = KH?.MaMB;
        _data.MaKH = KH?.MaKH;
        _data[p] = v;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    let _res = await DichVuService.DichVuKhac.add(_data);
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} dịch vụ khác thành công!`
      );
      setIsUpdate(true);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  async function fetchMatBang(input) {
    return MatBangService.getMatBang({
      InputSearch: input,
      MaTN: filterCondition?.MaTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.MaSoMB}`,
        value: item.MaMB,
        title: JSON.stringify(item),
      }));
    });
  }

  const renderThongTinChung = () => {
    return (
      <>
        <Row gutter={[20, 12]}>
          <Col span={12}>
            <Row gutter={[20, 12]}>
              <Col span={24}>
                {" "}
                <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
                  Thông tin chung
                </p>
              </Col>
              <Col span={24}>
                <Flex style={{ borderBottom: "1px solid #ff9087" }}>
                  <p
                    style={{
                      fontWeight: "600",
                      width: "30% ",
                    }}
                  >
                    Mặt bằng:<span color="red">*</span>
                  </p>
                  <DebounceSelect
                    open={open}
                    value={data?.MatBang}
                    placeholder="Tìm theo mã mặt bằng"
                    fetchOptions={fetchMatBang}
                    onChange={(v) => applyChange("MatBang", v)}
                    style={{
                      width: "100%",
                    }}
                  />
                </Flex>
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["MatBang"]}
                  </p>
                )}
              </Col>
              <Col span={24}>
                <InputCustom
                  label="Khách hàng:"
                  applyChange={applyChange}
                  prop="TenKH"
                  value={data?.TenKH}
                  placeholder="Nhập di tên khách hàng"
                  disabled={true}
                />
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["MaSoMB"]}
                  </p>
                )}
              </Col>
              <Col span={24}>
                <DatePickerCustom
                  required={true}
                  label="Ngày nhập:"
                  applyChange={applyChange}
                  prop={"NgayNhap"}
                  value={data?.NgayNhap}
                  placeholder={"Chọn ngày nhập"}
                />
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["NgayNhap"]}
                  </p>
                )}
              </Col>
              <Col span={24}>
                <SelectCustom
                  required={true}
                  label="Loại dịch vụ:"
                  applyChange={applyChange}
                  prop="MaLoaiDV"
                  value={data?.MaLoaiDV}
                  placeholder="Chọn loại dịch vụ"
                  options={loaiDV}
                  fieldNames={{ label: "TenLDV", value: "MaLDV" }}
                />
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["MaLoaiDV"]}
                  </p>
                )}
              </Col>
              <Col span={24}>
                <Flex>
                  <p
                    style={{
                      fontWeight: "600",
                      width: "20%",
                      borderBottom: "1px solid #d9d9d9",
                    }}
                  >
                    Diễn giải:
                  </p>
                  <TextArea
                    value={data?.DienGiai}
                    onChange={(e) => applyChange("DienGiai", e.target.value)}
                  />
                </Flex>
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["MaSoMB"]}
                  </p>
                )}
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={[20, 12]}>
              <Col span={24}>
                <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
                  Thông tin chi tiết
                </p>
              </Col>

              <Col span={12}>
                <InputNumberCustom
                  required={true}
                  label="Số lượng:"
                  applyChange={applyChange}
                  prop={"SoLuong"}
                  value={data?.SoLuong}
                  placeholder="0"
                />
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["SoLuong"]}
                  </p>
                )}
              </Col>
              <Col span={12}>
                <InputNumberCustom
                  label="Đơn giá:"
                  applyChange={applyChange}
                  prop={"DonGia"}
                  value={data?.DonGia}
                  placeholder="0"
                />
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["MaSoMB"]}
                  </p>
                )}
              </Col>
              <Col span={24}>
                <InputNumberCustom
                  disabled={true}
                  label="Thành tiền:"
                  applyChange={applyChange}
                  prop={"PhiDV"}
                  value={data?.PhiDV ? Math.round(data.PhiDV) : 0} // rounding to nearest unit
                  placeholder="0"
                />
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["MaSoMB"]}
                  </p>
                )}
              </Col>
              <Col span={24}>
                <DatePickerCustom
                  required={true}
                  label="Ngày Tính phí:"
                  applyChange={applyChange}
                  prop={"NgayTinhPhi"}
                  value={data?.NgayTinhPhi}
                />
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["NgayTinhPhi"]}
                  </p>
                )}
              </Col>
              <Col span={24}>
                <DatePickerCustom
                  required={true}
                  label="Ngày TT:"
                  applyChange={applyChange}
                  prop={"NgayTT"}
                  value={data?.NgayTT}
                />
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["NgayTT"]}
                  </p>
                )}
              </Col>
              <Col span={24}>
                <Flex>
                  <p
                    style={{
                      fontWeight: "600",
                      width: "20%",
                      borderBottom: "1px solid #d9d9d9",
                    }}
                  >
                    Gi chú:
                  </p>
                  <TextArea
                    value={data?.GhiChu}
                    onChange={(e) => applyChange("GhiChu", e.target.value)}
                  />
                </Flex>
              </Col>
              <Col span={24} style={{ paddingTop: "0px" }}>
                <Checkbox
                  value={data?.ThanhToanTheoKy}
                  checked={data?.ThanhToanTheoKy}
                  onChange={(e) =>
                    applyChange("ThanhToanTheoKy", e.target.value)
                  }
                >
                  Thanh toán theo kỳ
                </Checkbox>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Drawer
      title="Thêm: dịch vụ khác"
      width={920}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined /> Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      {renderThongTinChung()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
