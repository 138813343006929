import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Space,
  Drawer,
  notification,
  Table,
  Upload,
  message,
  Modal,
  Col,
  Row,
  Flex,
  Input,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import { KhachHangService } from "services/KhachHangService";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import DebounceSelect from "components/DebounceSelect";
import { MatBangService } from "services/MatBangService";
import { DatabaseCoreService } from "services/DatabaseCoreService";
import { QuestionCircleOutlined } from "@ant-design/icons";

const FormModal = (props, ref) => {
  const {
    filterCondition,
    handleCancel,
    isModalOpen,
    caseModal,
    setIsModalOpen,
    selectedRowKeys,
    onAfterSubmit,
    dataSelect,
  } = props;
  const [api, contextHolder] = notification.useNotification();

  const [data, setData] = useState([]);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "KhachHang":
        const KH = JSON.parse(v?.title);
        _data.DiDongMoi = KH?.DienThoaiKH;
        _data.MaKH = KH?.MaKH;
        _data.TenKH = v?.label;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const transformedArray = dataSelect.map((item) => ({
    MaMB: item.MaMB,
    MaKN: item.MaKN,
    MaTL: item.MaTL,
    isCuDan: false,
    MaSoMB: item.MaSoMB,
    DiDong: item.DienThoai,
    TenKH: item.TenKH,
  }));
  const handleSubmit = async () => {
    if (caseModal === 7 && (!data.DiDongMoi || data.DiDongMoi.trim() === "")) {
      openNotification(
        "error",
        "topRight",
        "Vui lòng cập nhật số điện thoại cho chủ hộ."
      );
      return;
    }

    let _res;
    try {
      switch (caseModal) {
        case 3:
          _res = await DatabaseCoreService.updateDongBoUser({
            MaTN: filterCondition.MaTN,
            MatBang: transformedArray,
          });
          break;
        case 4:
          _res = await MatBangService.updateDonGia({
            DonGia: data.DonGia,
            MatBang: selectedRowKeys,
          });
          break;
        case 5:
          _res = await MatBangService.updateCapNhatNgayTT({
            NgayTT: data.NgayTT,
            MatBang: selectedRowKeys,
          });
          break;
        case 6:
          _res = await MatBangService.updateVAT({
            VAT: data.VAT,
            MatBang: selectedRowKeys,
          });
          break;
        case 7:
          _res = await MatBangService.updateDCH({
            MaTN: filterCondition.MaTN,
            MaMB: dataSelect[0]?.MaMB,
            DiDongCu: dataSelect[0]?.DienThoaiKH,
            MaKH: data?.MaKH,
            TenKH: data?.TenKH,
            DiDongMoi: data?.DiDongMoi,
          });
          break;
        default:
          throw new Error("Invalid caseModal value");
      }

      if (_res?.status === 2000) {
        openNotification("success", "topRight", `Cập nhật thành công!`);
        setIsModalOpen(false);
        setData([]);
        onAfterSubmit();
      } else {
        openNotification(
          "error",
          "topRight",
          _res?.message || "Cập nhật thất bại."
        );
      }
    } catch (error) {
      openNotification(
        "error",
        "topRight",
        error.message || "Đã có lỗi xảy ra."
      );
    }
  };

  async function fetchKhachHang(input) {
    return KhachHangService.filterKhachHang({
      InputSearch: input,
      MaTN: filterCondition?.MaTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.TenKH}`,
        value: item.MaKH,
        title: JSON.stringify(item),
      }));
    });
  }
  return (
    <Modal
      width={`${caseModal === 3 ? "300px" : "500px"}`}
      title={`${
        caseModal === 3
          ? "Thông báo"
          : caseModal === 4
          ? "Cập nhật đơn giá mặt bằng"
          : caseModal === 5
          ? "Cập nhật ngày TT mặt bằng"
          : caseModal === 6
          ? "Cập nhật % VAT mặt bằng"
          : "Cập nhật chủ hộ"
      }`}
      open={isModalOpen}
      onOk={handleSubmit}
      onCancel={handleCancel}
    >
      {caseModal === 3 ? (
        <p style={{ margin: "30px 0" }}>
          <QuestionCircleOutlined /> Bạn có chắc đồng bộ
        </p>
      ) : caseModal === 4 ? (
        <Col style={{ margin: "30px 0" }} span={24}>
          <InputNumberCustom
            label="Đơn giá:"
            applyChange={applyChange}
            prop={"DonGia"}
            value={data?.DonGia}
            placeholder="0"
          />
        </Col>
      ) : caseModal === 5 ? (
        <Col style={{ margin: "30px 0" }} span={24}>
          <DatePickerCustom
            label="Ngày thanh toán:"
            applyChange={applyChange}
            prop={"NgayTT"}
            value={data?.NgayTT}
            placeholder={""}
          />
        </Col>
      ) : caseModal === 6 ? (
        <Col style={{ margin: "30px 0" }} span={24}>
          <InputNumberCustom
            label="VAT:"
            applyChange={applyChange}
            prop={"VAT"}
            value={data?.VAT}
            placeholder="0"
            max={100}
          />
        </Col>
      ) : (
        <Row gutter={[20, 12]} style={{ margin: "30px 0" }}>
          <Col span={24}>
            <Flex style={{ borderBottom: "1px solid #d9d9d9" }}>
              <p
                style={{
                  fontWeight: "600",
                  width: "15% ",
                }}
              >
                Chủ hộ:
              </p>
              <DebounceSelect
               open={isModalOpen}   
                value={data?.KhachHang}
                placeholder="Tìm khách hàng"
                fetchOptions={fetchKhachHang}
                onChange={(v) => applyChange("KhachHang", v)}
                style={{
                  width: "100%",
                }}
              />
            </Flex>
          </Col>
          <Col span={24}>
            <Input
              style={{ paddingLeft: "60px" }}
              onChange={applyChange}
              prop="DiDongMoi"
              value={data?.DiDongMoi}
              placeholder=""
              disabled={true}
            />
          </Col>
        </Row>
      )}
      {contextHolder}
    </Modal>
  );
};
export default forwardRef(FormModal);
