import React, { useEffect, useState } from "react";
import { Upload, Button, message } from "antd";
import {
  FileImageOutlined,
  FileOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import { URL_UPLOAD } from "services/UploadService";
import { UploadService } from "services/UploadService";

const UploadComponent = ({ files, affterUpload, onRemove, multiple }) => {
  const [fileList, setFileList] = useState(files ?? []);
  useEffect(() => {
    if (files?.length > 0) {
      setFileList(files);
    }
    return () => setFileList([]);
  }, [files]);

  const customUpload = async (options) => {
    const { onSuccess, onError } = options;
    const files = options.fileList ? options.fileList : [options.file];
    const formData = new FormData();
    for (var i = 0; i < files.length; i++) {
      formData.append("Image", files[i]);
    }
    try {
      const _res = await UploadService.upload(formData);
      if (_res.status === 2000) {
        affterUpload(_res.data);
        // setFileList((prevList) => [
        //   ...prevList,
        //   ..._res.data.map((file) => (file = URL_UPLOAD + file)),
        // ]);
      }
      //   if (response.ok) {
      //     // Call the onSuccess callback
      //     onSuccess(result, file)
      //     // Update the UI or state as needed
      //     setFileList(prevList => [...prevList, result])
      //     message.success(`${file.name} file uploaded successfully`)
      //   } else {
      //     onError(new Error("Upload failed"))
      //   }
    } catch (error) {
      onError(new Error("Upload failed"));
    }
  };
  const handleRemove = (file) => {
    onRemove(file);
    setFileList((prevList) => prevList.filter((item) => item !== file.url));
  };
  const getFileName = (url) => {
    let filename = url.split("/")?.pop();
    let fileNotType = filename?.split(".")?.shift();
    return fileNotType;
  };
  const fileIcon = (file) => {
    let _file = file?.url?.slice(file.url.lastIndexOf("."));
    if (_file === ".jpg" || _file === ".png" || _file === ".jpeg") {
      return <FileImageOutlined />;
    } else if (_file === ".pdf") {
      return <FilePdfOutlined />;
    } else {
      return <FileOutlined />;
    }
  };
  const handlePreview = (file) => {
    let _file = file?.url?.slice(file.url.lastIndexOf("."));
    if (_file === ".doc" || _file === ".docx" || _file === ".xlsx"|| _file === ".xls") {
      const fullLink = `https://view.officeapps.live.com/op/view.aspx?src=${file?.url}`;
      window.open(fullLink, "_blank");
    } else {
      const fullLink = file?.url;
      window.open(fullLink, "_blank");
    }
  };
  return (
    <Upload
      customRequest={customUpload}
      listType="picture-card"
      // listType="picture"
      fileList={fileList?.map((file, index) => ({
        uid: index,
        status: "done",
        url: file,
        name: getFileName(file),
      }))}
      onRemove={(file) => handleRemove(file)}
      multiple={multiple}
      iconRender={(file) => fileIcon(file)}
      onPreview={(file) => handlePreview(file)}
    >
      {multiple || fileList?.length === 0 ? <p>Tải lên</p> : null}
    </Upload>
  );
};

export default React.memo(UploadComponent);
