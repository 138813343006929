import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Flex,
  Input,
  Menu,
  Modal,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Table,
  Tooltip,
  notification,
} from "antd";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { CiChat2, CiTrash } from "react-icons/ci";
import { MdEdit, MdOutlineUpdate, MdUpdate } from "react-icons/md";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import { ToaNhaService } from "services/ToaNhaService";
import { MatBangService } from "services/MatBangService";
import { AiOutlineMenuFold } from "react-icons/ai";
import { MSBService } from "services/MSBService";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import FormAddExcel from "./FormAddExcel";
import {
  CaretRightOutlined,
  DeleteOutlined,
  DollarOutlined,
  DownOutlined,
  EditTwoTone,
  ExportOutlined,
  ImportOutlined,
  PlusOutlined,
  SearchOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import FormAddCongNo from "./FormAddCongNo";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import DebounceSelect from "components/DebounceSelect";
import { KhachHangService } from "services/KhachHangService";
import InputCustom from "components/Forms/InputCustom";
import FormModal from "./FormModal";
import FormQLHistory from "./FormQLHistory";
import { BsMotherboard } from "react-icons/bs";
import { TbArrowsExchange } from "react-icons/tb";
export default function Index() {
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("filter") === "true"
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toanha, setToanha] = useState([]);
  const [khoiNha, setKhoiNha] = useState([]);
  const [tanglau, setTanglau] = useState([]);
  const [dataSelect, setDataSelect] = useState([]);
  const [filterCondition, setFilterCondition] = useState({
    MaTN: 0,
    InputSearch: "",
    PageIndex: 1,
    PageSize: 10,
  });

  const refDetail = useRef(null);
  const refCongNo = useRef(null);
  const refAddExcel = useRef(null);
  const refHistory = useRef(null);

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [caseModal, setCaseModal] = useState(4);

  const showModal = (value) => {
    setIsModalOpen(true);
    setCaseModal(value);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const loadCategories = async () => {
    let _tn = await ToaNhaService.getToaNhaByNV();
    setToanha(_tn.data);
    let _filter = _.cloneDeep(filterCondition);
    _filter.MaTN = _tn.data[0]?.MaTN;
    setFilterCondition(_filter);
    loadcategoriesByMaTN(_tn.data[0]?.MaTN);
    filter(_filter);
  };
  const loadcategoriesByMaTN = async (matn) => {
    let _tn = await ToaNhaService.Categories.getKhoiNha({
      MaTN: matn,
    });
    setKhoiNha(_tn.data);
  };
  useEffect(() => {
    loadCategories();
  }, []);
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await MatBangService.get(_filter);
    if (_res.data) setData(_res.data);
    setLoading(false);
  };
  const onChangePage = (page, size) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.PageIndex = page;
    _ft.PageSize = size;
    setFilterCondition(_ft);
    filter(_ft);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () => {
    filter(filterCondition);
  };
  const columns = [
    {
      title: "Mã số MB",
      // ellipsis: true,
      render: (item) => (
        <p
          style={{
            fontWeight: "bold",
            color: "cadetblue",
            cursor: "pointer",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            // ellipsis: true,
          }}
          onClick={() => refHistory.current.show(item)}
        >
          {item?.MaSoMB}
        </p>
      ),
    },
    {
      title: "Tài khoản VA",
      dataIndex: "AccountNumber",
      // ellipsis: true,
    },
    {
      title: "Khối nhà",
      dataIndex: "MaKN",
      // ellipsis: true,
      render: (item) => khoiNha?.find((v) => v.MaKN === item)?.TenKN,
    },
    {
      title: "Diện tích",
      dataIndex: "DienTich",
      // ellipsis: true,
      render: (item) =>
        item != null
          ? item.toLocaleString("vi-VN", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : null,
    },
    {
      title: "Trạng thái",
      dataIndex: "TenTT",
      // ellipsis: true,
    },
    {
      title: "Loại căn hộ",
      dataIndex: "TenLMB",
      // ellipsis: true,
    },
    {
      title: "Tên khách hàng",
      dataIndex: "TenKH",
      // ellipsis: true,
      width: 150,
    },
    {
      title: "Điện thoại",
      dataIndex: "DienThoaiKH",
      // ellipsis: true,
    },
    // {
    //   title: "DT Tính phí",
    //   dataIndex: "DienTichThuPhi",
    //   // ellipsis: true,
    //   render: (item) => (item ? Format_Currency(item) : null),
    // },
    {
      title: "DT Tính phí",
      dataIndex: "DienTichThuPhi",
      render: (item) =>
        item != null
          ? item.toLocaleString("vi-VN", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : null,
    },
    {
      title: "Đơn giá",
      dataIndex: "SoTienPerMet",
      // ellipsis: true,
      render: (item) => (item ? Format_Currency(item) : null),
    },
    {
      title: "Phí quản lý",
      dataIndex: "PhiQuanLy",
      // ellipsis: true,
      render: (item) => (item ? Format_Currency(item) : null),
    },
    {
      title: "Ngày tính PQL",
      dataIndex: "BatDauTinhPhi",
      // ellipsis: true,
      render: (item) => (item ? Format_Date(item) : null),
    },
    {
      title: "Ngày thanh toán",
      width: 150,
      dataIndex: "NgayTTPQL",
      // ellipsis: true,
      render: (item) => (item ? Format_Date(item) : null),
    },
    {
      title: "",
      fixed: "right",
      width: "100px",
      render: (item) => (
        <div>
          <Tooltip placement="topRight" title="Sửa">
            <Button
              style={{ marginLeft: 10 }}
              type="primary"
              ghost
              icon={<EditTwoTone />}
              onClick={() => refDetail.current.update(item)}
            />
          </Tooltip>
          <Popconfirm
            title="Xoá danh mục"
            description="Bạn có chắc chắn muốn xoá danh mục?"
            okText="Đồng ý"
            cancelText="Không"
            onConfirm={() => remove(item.MaMB)}
          >
            <Tooltip placement="topRight" title="Xoá">
              <Button
                style={{ marginLeft: 10 }}
                danger
                ghost
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        </div>
      ),
    },
  ];
  const [col, setCol] = useState(columns);
  const remove = async (value) => {
    let _res = await MatBangService.delete({ ID: Number(value) });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.MaMB !== Number(value));
      setData(fil);
      openNotification("success", "topRight", "Xoá thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const onHandleHide = () => {
    setCollapsed(!collapsed);
    localStorage.setItem("filter", !collapsed);
  };
  const onSelectChange = async (newSelectedRowKeys) => {
  
    setDataSelect(data.filter((item) => newSelectedRowKeys.includes(item.key)));
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  function removeSpecialCharacters(str) {
    return str.replace(/[_\.\s-]/g, "");
  }
  const createAccount = async () => {
    setLoading(true);
    let _data = data?.filter((v) => selectedRowKeys.includes(v.key));
    let _login = await MSBService.Login();
    let _payload = {
      Token: JSON.parse(_login.data)?.access_token,
      ServiceCode: "ADL",
      Rows: [],
      RowsUpdate: [],
    };
    _data?.map((item) => {
      if (item.TenKH) {
        let masomb = removeSpecialCharacters(item.MaSoMB);
        if (item.AccountNumber) {
          let _row = {
            AccountNumber: "ADL" + masomb,
            Status: "1",
            ReferenceNumber: masomb,
            SuggestAmount: "",
            PayType: "0",
            Name: item.TenKH,
            Detail1: String(item.MaMB),
            Detail2: String(item.MaTN),
            Phone: item.DienThoaiKH,
          };
          _payload.RowsUpdate.push(_row);
        } else {
          let _row = {
            AccountNumber: "ADL" + masomb,
            Status: "1",
            ReferenceNumber: masomb,
            SuggestAmount: "",
            PayType: "0",
            Name: item.TenKH,
            Detail1: String(item.MaMB),
            Detail2: String(item.MaTN),
            Phone: item.DienThoaiKH,
          };
          _payload.Rows.push(_row);
        }
      }
    });
    let _res = await MSBService.CreateAccount(_payload);
    await MSBService.UpdateAccount({
      Token: _payload.Token,
      ServiceCode: _payload.ServiceCode,
      Rows: _payload.RowsUpdate,
    });
    if (_res.status === 2000) {
      openNotification("success", "topRight", "Tạo tài khoản thành công");
      filter(filterCondition);
    } else {
      openNotification("error", "topRight", _res?.message);
    }
    setLoading(false);
  };

  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        if (filterCondition.MaTN) filter(filterCondition);
      }, 500);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  // const exportToCSV = () => {
  //   const ws = XLSX.utils.json_to_sheet(data);
  //   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //   const dataEX = new Blob([excelBuffer], { type: fileType });
  //   FileSaver.saveAs(dataEX, "Danh sách mặt bằng" + fileExtension);
  // };
  const exportToCSV = () => {
    const fieldsToExport = columns.map((col) => col.dataIndex);
    const filteredData = data.map((item) => {
      let filteredItem = {};
      fieldsToExport.forEach((field) => {
        filteredItem[field] = item[field];
      });
      return filteredItem;
    });
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "Danh sách mặt bằng" + fileExtension);
  };
  const handleMenuClick = (e) => {
    if (e.key === "2") {
      if (selectedRowKeys.length < 1) {
        openNotification(
          "error",
          "topRight",
          "Vui lòng chọn mặt bằng, xin cảm ơn."
        );
        return;
      }
    }
    refCongNo.current.show(e.key);
  };

  const handleMenuClick2 = async (e) => {
    if (e.key !== "1" && e.key !== "2" && selectedRowKeys.length < 1) {
      openNotification(
        "error",
        "topRight",
        "Vui lòng chọn mặt bằng, xin cảm ơn."
      );
      return;
    }
    switch (e.key) {
      case "1":
        refAddExcel.current.show();
        break;
      case "2":
        exportToCSV();
        break;
      case "3":
        showModal(3);
        break;
      case "4":
        showModal(4);
        break;
      case "5":
        showModal(5);
        break;
      case "6":
        showModal(6);
        break;
      case "7":
        if (selectedRowKeys.length > 1) {
          openNotification(
            "error",
            "topRight",
            "Vui lòng chỉ chọn được một mặt bằng."
          );
          return;
        }
        const DT = data.find((item) => item.MaMB === selectedRowKeys[0]);
        if (DT.DienThoaiKH === "") {
          openNotification(
            "error",
            "topRight",
            "Vui lòng cập nhật điện thoại khách hàng."
          );
          return;
        }
        showModal(7);
        break;
      case "8":
        createAccount();
        break;
      case "9":
        const filterData = data.filter((obj) =>
          selectedRowKeys.includes(obj.MaMB)
        );
        let _data = _.cloneDeep(data);
        let hasDeleted = false;

        for (const item of filterData) {
          try {
            let _res = await MatBangService.delete({ ID: Number(item.MaMB) });

            if (_res.status === 2000) {
              _data = _data.filter((v) => v.MaMB !== Number(item.MaMB));
              hasDeleted = true;
            } else {
              openNotification("error", "topRight", _res?.message);
            }
          } catch (error) {
            openNotification(
              "error",
              "topRight",
              "Có lỗi xảy ra trong quá trình xóa"
            );
          }
        }

        if (hasDeleted) {
          setData(_data);
          setSelectedRowKeys([]);
          openNotification("success", "topRight", "Xoá mặt bằng thành công");
        }
        break;
      default:
        break;
    }
  };
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">
        <CaretRightOutlined style={{ paddingRight: "5px" }} />
        Theo tòa nhà
      </Menu.Item>
      <Menu.Item key="2">
        {" "}
        <CaretRightOutlined style={{ paddingRight: "5px" }} />
        Theo căn hộ
      </Menu.Item>
    </Menu>
  );
  const menu2 = (
    <Menu onClick={handleMenuClick2}>
      <Menu.Item key="1">
        {" "}
        <ImportOutlined style={{ paddingRight: "9px" }} />
        Import
      </Menu.Item>
      <Menu.Item key="2">
        <ExportOutlined style={{ paddingRight: "9px" }} />
        Export
      </Menu.Item>
      <Menu.Item key="3">
        <Flex align="center">
          <MdUpdate
            style={{ marginRight: "7px", marginLeft: "-2px", fontSize: "18px" }}
          />
          Đồng bộ
        </Flex>
      </Menu.Item>
      <Menu.Item key="4">
        {" "}
        <SyncOutlined style={{ paddingRight: "8px" }} />
        Cập nhật đơn giá PQL
      </Menu.Item>
      <Menu.Item key="5">
        {" "}
        <SyncOutlined style={{ paddingRight: "5px" }} /> Cập nhật ngày TT
      </Menu.Item>
      <Menu.Item key="6">
        {" "}
        <SyncOutlined style={{ paddingRight: "5px" }} /> Cập nhật PTVAT
      </Menu.Item>
      <Menu.Item key="7">
        <Flex align="center">
          <TbArrowsExchange
            style={{
              marginRight: "8px",
              // marginLeft: "-5px",
              fontSize: "16px",
            }}
          />{" "}
          Đổi chủ hộ
        </Flex>
      </Menu.Item>
      <Menu.Item key="8">
        Tạo tài khoản MSB ({selectedRowKeys?.length})
      </Menu.Item>
      <Menu.Item key="9">Xóa nhiều</Menu.Item>
    </Menu>
  );

  return (
    <Row gutter={15}>
      <Col span={collapsed ? 0 : 4} style={{ transition: "all 0.3s ease" }}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
          options={{
            ToaNha: toanha,
            KhoiNha: khoiNha,
          }}
          loadcategoriesByMaTN={loadcategoriesByMaTN}
        />
      </Col>
      <Col span={collapsed ? 24 : 20}>
        <Toolbar
          left={() => (
            <Flex align="center" gap={10}>
              <AiOutlineMenuFold
                size={20}
                color="primary"
                className="icon-filter"
                onClick={() => onHandleHide()}
              />
              <Input
                style={{
                  width: "300px",
                  padding: "4px 11px",
                  borderRadius: 10,
                }}
                placeholder="Tìm mã số mặt bằng, điện thoại, tên khách hàng"
                onChange={(e) => onSearch(e.target.value)}
              />
            </Flex>
          )}
          right={() => (
            <Flex gap={10}>
              <Button
                loading={loading}
                type="primary"
                onClick={() => refDetail.current.show()}
              >
                <PlusOutlined style={{ fontSize: "13px", margin: "0 -4px" }} />{" "}
                Thêm
              </Button>
              <Dropdown overlay={menu} trigger={["click"]}>
                <Button>
                  <DollarOutlined />
                  Tạo công nợ
                  <DownOutlined />
                </Button>
              </Dropdown>
              <Dropdown overlay={menu2} trigger={["click"]}>
                <Button style={{ display: "flex", alignItems: "center" }}>
                  <BsMotherboard style={{ marginRight: "5px" }} /> Thao tác khác
                  <DownOutlined />
                </Button>
              </Dropdown>
            </Flex>
          )}
        />
        <Table
          loading={loading}
          dataSource={data}
          bordered
          columns={columns}
          scroll={{
            y: window.innerHeight - 250,
            x: 1600,
          }}
          rowSelection={{ ...rowSelection, columnWidth: 60 }}
          pagination={false}
          virtual={true}
          size="small"
          summary={(pageData) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={2}>
                    <p style={{ fontWeight: "bold" }}>
                      Tổng: {pageData?.length}
                    </p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
        <div style={{ textAlign: "right", paddingTop: "10px" }}>
          <Pagination
            current={filterCondition?.PageIndex}
            onChange={onChangePage}
            total={data[0]?.TotalRows}
            showTotal={(total) => `Tổng: ${total}`}
            pageSizeOptions={[10, 20, 50, 100, 1000]}
          />
        </div>
      </Col>
      <FormAdd
        ref={refDetail}
        onAfterSubmit={onAfterSubmit}
        filterCondition={filterCondition}
        options={{
          ToaNha: toanha,
          KhoiNha: khoiNha,
        }}
        loadcategoriesByMaTN={loadcategoriesByMaTN}
      />
      <FormAddExcel
        ref={refAddExcel}
        onAfterSubmit={onAfterSubmit}
        filterCondition={filterCondition}
      />
      <FormAddCongNo
        ref={refCongNo}
        dataSelect={dataSelect}
        selectedRowKeys={selectedRowKeys}
        filterCondition={filterCondition}
        setSelectedRowKeys={setSelectedRowKeys}
        onAfterSubmit={onAfterSubmit}
      />
      <FormModal
        filterCondition={filterCondition}
        handleCancel={handleCancel}
        isModalOpen={isModalOpen}
        caseModal={caseModal}
        setIsModalOpen={setIsModalOpen}
        selectedRowKeys={selectedRowKeys}
        onAfterSubmit={onAfterSubmit}
        dataSelect={dataSelect}
      />
      <FormQLHistory ref={refHistory} onAfterSubmit={onAfterSubmit} />
      {contextHolder}
    </Row>
  );
}
