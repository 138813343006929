import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Flex,
  Input,
  Menu,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  notification,
} from "antd";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { CiChat2, CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import { ToaNhaService } from "services/ToaNhaService";
import { MatBangService } from "services/MatBangService";
import { AiOutlineMenuFold } from "react-icons/ai";
import { MSBService } from "services/MSBService";
import { DichVuService } from "services/DichVuService";
import { DateTime } from "luxon";
import { KhachHangService } from "services/KhachHangService";
import {
  CheckCircleOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  EditTwoTone,
  ExportOutlined,
  PlusOutlined,
  SafetyCertificateOutlined,
} from "@ant-design/icons";
import FormDatLich from "./FormDatLich";
import { CategoryService } from "services/CategoryService";
import FormModal from "./FormModal";

export default function Index() {
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("filter") === "true"
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toanha, setToanha] = useState([]);
  const [khoinha, setKhoinha] = useState([]);
  const [tanglau, setTanglau] = useState([]);
  const [tienIch, setTienIch] = useState();
  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: dateInHoChiMinh.toISO(),
    DenNgay: dateInHoChiMinh.toISO(),
    MaTN: null,
  });
  const refDetail = useRef(null);
  const refDatLich = useRef(null);
  const refModal = useRef(null);

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const loadCategories = async () => {
    let _tn = await ToaNhaService.getToaNhaByNV();
    setToanha(_tn.data);

    let _filter = _.cloneDeep(filterCondition);
    _filter.MaTN = _tn.data[0]?.MaTN;
    setFilterCondition(_filter);
    const _resTienIch = await CategoryService.KhachHang.TienIch.TenTienIch.get({
      MaTN: _filter.MaTN,
    });
    if (_resTienIch.data) {
      setTienIch(_resTienIch.data);
    }
    loadcategoriesByMaTN(_tn.data[0]?.MaTN);
    filter(_filter);
  };
  const loadcategoriesByMaTN = async (matn) => {
    let _tn = await ToaNhaService.Categories.getKhoiNha({
      MaTN: matn,
    });
    setKhoinha(_tn.data);
  };
  useEffect(() => {
    loadCategories();
  }, []);
  const filter = async (_filter) => {
    setLoading(true);

    let _res = await KhachHangService.TienIch.get(_filter);
    if (_res.data) setData(_res.data);
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () => {
    filter(filterCondition);
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "STT",

      width: 50,
    },
    {
      title: "Tên tiện ích",
      dataIndex: "TenTienIch",
    },
    {
      title: "Khu/bản/vị trí",
      dataIndex: "Ban",
    },
    {
      title: "Khung giờ",
      dataIndex: "KhungGio",
    },
    {
      title: "Ngày",
      dataIndex: "NgayNhap",

      render: (item) => (item ? Format_Date(item) : null),
    },
    {
      title: "Mã căn hộ",
      dataIndex: "MaSoMB",
    },
    {
      title: "Tên khách",
      dataIndex: "TenKH",
    },
    {
      title: "Điện thoại",
      dataIndex: "DiDong",
    },
    {
      title: "Số tiền cọc",
      dataIndex: "SoTienCoc",
    },
    {
      title: "Tình Trạng",
      dataIndex: "TinhTrang",
    },
    {
      title: "Ngày Đặt",
      dataIndex: "NgayDat",

      render: (item) => (item ? Format_Date(item) : null),
    },
    {
      title: "Người tạo",
      dataIndex: "NguoiNhap",
    },
    {
      title: "Ngày tạo",
      dataIndex: "NgayTaoLich",

      render: (item) => (item ? Format_Date(item) : null),
    },
    {
      title: "",
      fixed: "right",
      width: 80,
      render: (item) => (
        <div style={{ display: "flex" }}>
          <Popconfirm
            title="Xoá tiện ích"
            description="Bạn có chắc chắn muốn xoá ?"
            okText="Đồng ý"
            cancelText="Không"
            onConfirm={() => remove(item.ID)}
          >
            <Tooltip placement="topRight" title="Xoá">
              <Button
                style={{ marginLeft: 10 }}
                danger
                ghost
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        </div>
      ),
    },
  ];
  const remove = async (value) => {
    let _res = await KhachHangService.TienIch.delete({ ID: Number(value) });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.ID !== Number(value));
      setData(fil);
      openNotification("success", "topRight", "Xoá thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const onHandleHide = () => {
    setCollapsed(!collapsed);
    localStorage.setItem("filter", !collapsed);
  };
  const onSelectChange = async (newSelectedRowKeys) => {
    if (newSelectedRowKeys?.length > 100) {
      openNotification("error", "topRight", "Số lượng chọn không quá 100");
      return;
    }
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  function removeSpecialCharacters(str) {
    return str.replace(/[_\.\s]/g, "");
  }
  const createAccount = async () => {
    setLoading(true);
    let _data = data?.filter((v) => selectedRowKeys.includes(v.MaMB));
    let _login = await MSBService.Login();
    let _payload = {
      Token: JSON.parse(_login.data)?.access_token,
      ServiceCode: "ADL",
      Rows: [],
    };
    _data?.map((item) => {
      if (item.TenKH) {
        let masomb = removeSpecialCharacters(item.MaSoMB);
        let _row = {
          AccountNumber: "ADL" + masomb,
          Status: "1",
          ReferenceNumber: masomb,
          SuggestAmount: "",
          PayType: "0",
          Name: item.TenKH,
          Detail1: String(item.MaMB),
          Detail2: String(item.MaTN),
          Phone: item.DienThoaiKH,
        };
        _payload.Rows.push(_row);
      }
    });
    let _res = await MSBService.CreateAccount(_payload);
    if (_res.status === 2000) {
      openNotification("success", "topRight", "Tạo tài khoản thành công");
      filter(filterCondition);
    } else {
      openNotification("error", "topRight", _res?.message);
    }
    setLoading(false);
  };

  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        if (filterCondition.MaTN) filter(filterCondition);
      }, 500);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = () => {
    const fieldsToExport = columns.map((col) => col.dataIndex);
    const filteredData = data.map((item) => {
      let filteredItem = {};
      fieldsToExport.forEach((field) => {
        filteredItem[field] = item[field];
      });
      return filteredItem;
    });
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "Danh sách dịch vụ điện" + fileExtension);
  };
  const onChangePage = (page, size) => {
    if (page === 0) {
      page = 1;
    }
    let _ft = _.cloneDeep(filterCondition);
    _ft.PageIndex = page;
    _ft.PageSize = size;
    setFilterCondition(_ft);
    loadCategories(_ft);
  };

  const handleMenuClick = (e) => {
    if (selectedRowKeys.length < 1) {
      openNotification(
        "error",
        "topRight",
        "Vui lòng chọn tiện ích, xin cảm ơn."
      );
      return;
    }
    if (selectedRowKeys.length > 1) {
      openNotification(
        "error",
        "topRight",
        "Vui lòng chỉ chọn 1 tiện ích, xin cảm ơn."
      );
      return;
    }
    switch (e.key) {
      case "1":
        refDatLich.current.show(
          data.find((item) => item.ID === selectedRowKeys[0])
        );
        break;
      case "2":
        refModal.current.show(
          data.find((item) => item.ID === selectedRowKeys[0]),
          e.key
        );
        break;
      case "3":
        refModal.current.show(
          data.find((item) => item.ID === selectedRowKeys[0]),
          e.key
        );
        break;
      default:
        break;
    }
  };

  const datLich = (
    <Menu onClick={handleMenuClick}>
      {selectedRowKeys.length > 1 ? (
        <>
          <Menu.Item key="1">
            <CheckOutlined style={{ marginRight: "5px" }} /> Đặt
          </Menu.Item>
          <Menu.Item key="2">
            <CloseOutlined style={{ marginRight: "5px" }} /> Hủy
          </Menu.Item>
          <Menu.Item key="3">
            <SafetyCertificateOutlined style={{ marginRight: "5px" }} /> Duyệt
          </Menu.Item>
        </>
      ) : data.find((item) => item.ID === selectedRowKeys[0])?.MaSoMB ? (
        <>
          <Menu.Item key="2">
            <CloseOutlined style={{ marginRight: "5px" }} /> Hủy
          </Menu.Item>
          <Menu.Item key="3">
            <SafetyCertificateOutlined style={{ marginRight: "5px" }} /> Duyệt
          </Menu.Item>
        </>
      ) : (
        <Menu.Item key="1">
          <CheckOutlined style={{ marginRight: "5px" }} /> Đặt
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Row gutter={15}>
      <Col span={collapsed ? 0 : 4} style={{ transition: "all 0.3s ease" }}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
          options={{
            ToaNha: toanha,
            KhoiNha: khoinha,
          }}
          loadcategoriesByMaTN={loadcategoriesByMaTN}
        />
      </Col>
      <Col span={collapsed ? 24 : 20}>
        <Toolbar
          left={() => (
            <Flex align="center" gap={10}>
              <AiOutlineMenuFold
                size={20}
                className="icon-filter"
                color="primary"
                onClick={() => onHandleHide()}
              />
              <Input
                style={{
                  width: "300px",
                  padding: "4px 11px",
                  borderRadius: 10,
                }}
                placeholder="Tìm mã số mặt bằng, điện thoại, tên khách hàng"
                onChange={(e) => onSearch(e.target.value)}
              />
            </Flex>
          )}
          right={() => (
            <Flex gap={10}>
              {/* {selectedRowKeys?.length > 0 && (
                <Button
                  loading={loading}
                  type="primary"
                  ghost
                  onClick={() => createAccount()}
                >
                  Tạo tài khoản MSB ({selectedRowKeys?.length})
                </Button>
              )} */}

              <Button
                loading={loading}
                type="primary"
                onClick={() => refDetail.current.show()}
              >
                <PlusOutlined style={{ fontSize: "13px", margin: "0 -4px" }} />{" "}
                Tạo lịch
              </Button>

              <Button onClick={() => exportToCSV()}>
                <ExportOutlined
                  style={{ fontSize: "13px", margin: "0 -2px" }}
                />
                Export
              </Button>
              <Dropdown overlay={datLich} trigger={["click"]}>
                <Button>
                  <ClockCircleOutlined /> Đặt lịch
                  <DownOutlined />
                </Button>
              </Dropdown>
            </Flex>
          )}
        />
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 250,
            x: 1500,
          }}
          rowSelection={{
            ...rowSelection,
            columnWidth: 60,
          }}
          bordered
          pagination={false}
          virtual={true}
          size="small"
          summary={(pageData) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={3}>
                    <p style={{ fontWeight: "bold" }}>
                      Tổng: {pageData?.length}
                    </p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
        <div style={{ textAlign: "right", paddingTop: "15px" }}>
          <Pagination onChange={onChangePage} total={data[0]?.TotalRows} />
        </div>
      </Col>
      <FormAdd
        ref={refDetail}
        onAfterSubmit={onAfterSubmit}
        filterCondition={filterCondition}
        options={{
          ToaNha: toanha,
        }}
        tienIch={tienIch}
      />
      <FormDatLich
        ref={refDatLich}
        onAfterSubmit={onAfterSubmit}
        maTN={filterCondition.MaTN}
        tienIch={tienIch}
      />
      <FormModal
        ref={refModal}
        onAfterSubmit={onAfterSubmit}
        maTN={filterCondition.MaTN}
      />
      {contextHolder}
    </Row>
  );
}
