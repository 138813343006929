import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Button, Row, Col, Space, Drawer, notification } from "antd";
import _ from "lodash";
import { ToaNhaService } from "services/ToaNhaService";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import SelectCustom from "components/Forms/SelectCustom";
import { DateTime } from "luxon";
import { CategoryService } from "services/CategoryService";
import { KhachHangService } from "services/KhachHangService";
import { SaveOutlined } from "@ant-design/icons";

const FormAdd = (props, ref) => {
  const { onAfterSubmit, filterCondition, options, tienIch } = props;
  const [api, contextHolder] = notification.useNotification();
  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");
  const defaulData = {
    TuNgay: dateInHoChiMinh.toISO(),
    DenNgay: dateInHoChiMinh.toISO(),
  };
  const [data, setData] = useState(defaulData);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const defaultErrors = {};
  const [errors, setErrors] = useState(defaultErrors);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      setErrors(defaultErrors);
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "TuNgay":
        const date = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString = date.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString;
        break;
      case "DenNgay":
        const date2 = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString2 = date2.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString2;
        break;
      case "TienIch":
        _data[p] = v;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    let _res = await KhachHangService.TienIch.add({
      ..._data,
      MaTN: filterCondition.MaTN,
    });

    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} lịch thành công!`
      );
      setIsUpdate(true);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const renderThongTinChung = () => {
    return (
      <>
        <Row style={{ marginTop: "10px" }} gutter={[20, 20]}>
          <Col span={24}>
            {" "}
            <SelectCustom
              disabled={true}
              label="Dự án:"
              applyChange={applyChange}
              prop="MaTN"
              value={filterCondition?.MaTN}
              placeholder="Chọn dự án"
              options={options?.ToaNha}
              fieldNames={{ label: "TenTN", value: "MaTN" }}
            />
            {errors && (
              <p style={{ color: "red", textAlign: "center" }}>
                {errors && errors["MaTN"]}
              </p>
            )}
          </Col>
          <Col span={24}>
            {" "}
            <SelectCustom
              mode={"multiple"}
              label="Tiện tích:"
              applyChange={applyChange}
              prop="TienIch"
              value={data?.TienIch}
              placeholder="Chọn loại tiện ích"
              options={tienIch}
              fieldNames={{ label: "TenTienIch", value: "ID" }}
            />
            {errors && (
              <p style={{ color: "red", textAlign: "center" }}>
                {errors && errors["TienIch"]}
              </p>
            )}
          </Col>
          <Col span={24}>
            <DatePickerCustom
              label="Từ ngày:"
              applyChange={applyChange}
              prop={"TuNgay"}
              value={data?.TuNgay}
              placeholder={""}
            />
          </Col>
          <Col span={24}>
            <DatePickerCustom
              label="Đến ngày:"
              applyChange={applyChange}
              prop={"DenNgay"}
              value={data?.DenNgay}
              placeholder={""}
            />
          </Col>
        </Row>
      </>
    );
  };
  return (
    <Drawer
      title="Tạo lịch"
      width={720}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined />
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {/* <FormDrawerUser
        className="vh-100"
        isEdit={isEdit}
        isOpen={modal}
        toggle={toggle}
        detail={staffForm}
      /> */}
      {contextHolder}
      {renderThongTinChung()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
