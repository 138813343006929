import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Button,
  Popconfirm,
  notification,
  Tooltip,
  Badge,
  Row,
  Col,
  Flex,
  Tag,
  Checkbox,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import FilterModal from "./FilterModal";
import { CiTrash } from "react-icons/ci";
import Toolbar from "components/Toolbar";
import { Format_Date } from "layouts/ultis/common";
import {
  MdEdit,
  MdNotificationsActive,
  MdOutlinePendingActions,
} from "react-icons/md";
import { OtherService } from "services/OtherService";
import { Format_Datetime } from "layouts/ultis/common";
import { AiOutlineMenuFold } from "react-icons/ai";
export default function Index() {
  const [data, setData] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("filter") === "true"
  );
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
  });
  useEffect(() => {
    load(filterCondition);
  }, []);
  const load = async (_filter) => {
    setLoading(true);
    let _res = await OtherService.getOTP(_filter);
    setData(_res.data);
    setLoading(false);
  };
  const columns = [
    {
      title: "Mặt bằng",
      dataIndex: "MaSoMB",
      // ellipsis: true,
    },
    {
      title: "DiDong",
      dataIndex: "DiDong",
      // ellipsis: true,
    },
    {
      title: "Mã otp",
      dataIndex: "MaOTP",
      // ellipsis: true,
    },
    {
      title: "Ngày gửi",
      dataIndex: "NgayGui",
      // ellipsis: true,
      render: (item) => Format_Datetime(item),
    },
    {
      title: "Trạng thái",
      dataIndex: "State",
      // ellipsis: true,
      render: (item) => (
        <Tag color={item === "NEW" ? "cyan" : "green"}>
          {item === "NEW" ? "MỚI" : "ĐÃ ACTIVE"}
        </Tag>
      ),
    },
    {
      title: "Ngày active",
      dataIndex: "NgayActive",
      // ellipsis: true,
      render: (item) => (item ? Format_Datetime(item) : null),
    },

    // {
    //   title: "",
    //   fixed: "right",
    //   width: "120px",
    //   render: (item) => {
    //     return (
    //       <Flex gap={10} justify="center">
    //         <Tooltip placement="topRight" title="Gửi thông báo">
    //           <Button
    //             type="link"
    //             icon={<MdNotificationsActive size={18} />}
    //             onClick={() => sendThongBao(item)}
    //           />
    //         </Tooltip>
    //         <Tooltip placement="topRight" title="Sửa">
    //           <Button
    //             type="link"
    //             icon={<MdEdit size={18} color="" />}
    //             onClick={() => refAdd.current.update(item.MaTB)}
    //           />
    //         </Tooltip>
    //         <Popconfirm
    //           title="Xoá yêu cầu"
    //           description="Bạn có chắc chắn muốn xoá hay không?"
    //           okText="Đồng ý"
    //           cancelText="Không"
    //           onConfirm={() => remove(item.MaTB)}
    //         >
    //           <Button type="text" danger icon={<CiTrash size={18} />} />
    //         </Popconfirm>
    //       </Flex>
    //     );
    //   },
    // },
  ];
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const remove = async (item) => {
    let _res = await OtherService.deleteThongBao({
      ID: item,
    });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.MaTB !== Number(item));
      setData(fil);
      openNotification("success", "topRight", "Xoá  thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const sendThongBao = async (item) => {
    setLoading(true);
    let _res = await OtherService.sendThongBao({
      TieuDe: item.TieuDe,
      NoiDung: item.DienGiai,
      FormID: 11172,
      LinkID: item.MaTB,
      MatBang: item?.MatBang?.split(",")?.map(Number),
    });
    setLoading(false);
    if (_res.status === 2000) {
      openNotification("success", "topRight", "Gửi thông báo thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const onAfterSubmit = () => {
    load(filterCondition);
  };
  const onChangeFilter = (_filter) => {
    load(_filter);
    setFilterCondition(_filter);
  };

  const onHandleHide = () => {
    setCollapsed(!collapsed);
    localStorage.setItem("filter", !collapsed);
  };
  return (
    <Row gutter={[10, 10]}>
      <Col span={collapsed ? 0 : 4} style={{ transition: "all 0.3s ease" }}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
        />
      </Col>
      <Col span={collapsed ? 24 : 20}>
        <Toolbar
          left={() => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                alignItems: "center",
              }}
            >
              <AiOutlineMenuFold
                size={20}
                color="primary"
                className="icon-filter"
                onClick={() => onHandleHide()}
              />
            </div>
          )}
        />
        <Table
          style={{ height: 100 }}
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 250,
            // x: 2000,
          }}
          size="small"
          bordered
        />
      </Col>
      {contextHolder}
    </Row>
  );
}
