import { Format_Currency } from "layouts/ultis/common";

const {
  EditTwoTone,
  DeleteOutlined,
  PlusOutlined,
} = require("@ant-design/icons");
const { Button, Tooltip, Popconfirm, ColorPicker, Checkbox } = require("antd");
const { Format_Date } = require("layouts/ultis/common");
const colorIntToHex = (colorInt) => {
  if (colorInt === undefined || colorInt === null) {
    return "red";
  }
  // Rest of your function logic
  if (colorInt < 0) {
    colorInt = 0xffffffff + colorInt + 1;
  }
  let hex = colorInt.toString(16).toUpperCase();
  hex = hex.padStart(6, "0").slice(-6);
  return `#${hex}`;
};

const getColumns = (activeTab, secondaryOption, refs, remove, data) => {
  const {
    refDinhMucNuoc,
    refDinhMucDien,
    refLoaiXe,
    refDinhMucXe,
    refTrangThaiXe,
    refKetNoiBaiXe,
    refKhac,
    refLoaiCongTo,
    refDongHoNuoc,
    refDanhSachKhoThe,
  } = refs;

  switch (activeTab) {
    case 1:
      switch (secondaryOption) {
        case 1:
          return [
            { title: "STT", ellipsis: true, dataIndex: "STT", key: "STT" },

            {
              title: "Số định mức",
              dataIndex: "SoDM",

              key: "SoDM",
            },
            {
              title: "Đơn giá",

              dataIndex: "DonGia",
              key: "DonGia",
            },

            {
              title: "Tiền BVMT",

              dataIndex: "TienBVMT",
              key: "TienBVMT",
            },

            {
              title: "Tiền hao hụt",
              dataIndex: "TienHaoHut",

              key: "TienHaoHut",
            },
            {
              title: "Loại mặt bằng",

              dataIndex: "TenLMB",
              width: 200,
              key: "TenLMB",
            },

            {
              title: "Từ ngày",
              dataIndex: "TuNgay",

              key: "TuNgay",
              render: (item) => (item ? Format_Date(item) : ""),
            },
            {
              title: "Đến ngày",
              dataIndex: "DenNgay",

              key: "DenNgay",
              render: (item) => (item ? Format_Date(item) : ""),
            },
            {
              title: "Diễn giải",

              dataIndex: "DienGiai",
              key: "DienGiai",
            },

            {
              title: "Người nhập",
              dataIndex: "NguoiTao",

              key: "NguoiTao",
            },
            {
              title: "Ngày nhập",
              dataIndex: "NgayNhap",

              key: "NgayNhap",
              render: (item) => (item ? Format_Date(item) : ""),
            },
            // {
            //   title: "Người cập nhật",
            //   dataIndex: "NguoiCapNhat",
            // ellipsis: true,
            //   key: "NguoiCapNhat",
            // },
            {
              title: "Ngày cập nhật",
              dataIndex: "NgayCN",

              key: "NgayCN",
              render: (item) => (item ? Format_Date(item) : ""),
            },
            {
              title: (
                <div style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    onClick={() => refDinhMucNuoc.current.show()}
                  >
                    <PlusOutlined
                      style={{ fontSize: "13px", margin: "0 -4px" }}
                    />{" "}
                    Thêm
                  </Button>{" "}
                </div>
              ),
              fixed: "right",
              width: "100px",
              render: (item) => (
                <div style={{ display: "flex" }}>
                  <Tooltip placement="topRight" title="Sửa">
                    <Button
                      style={{ marginLeft: 10 }}
                      type="primary"
                      ghost
                      icon={<EditTwoTone />}
                      onClick={() => refDinhMucNuoc.current.update(item)}
                    />
                  </Tooltip>
                  <Popconfirm
                    title="Xoá danh mục"
                    description="Bạn có chắc chắn muốn xoá danh mục?"
                    okText="Đồng ý"
                    cancelText="Không"
                    onConfirm={() => remove(item.ID)}
                  >
                    <Tooltip placement="topRight" title="Xoá">
                      <Button
                        style={{ marginLeft: 10 }}
                        danger
                        ghost
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </div>
              ),
            },
          ];
        case 2:
          return [
            {
              title: "Số phiếu",

              dataIndex: "SoPhieu",
              key: "SoPhieu",
            },

            {
              title: "Ngày đăng ký",
              dataIndex: "NgayDK",

              key: "NgayDK",
              render: (item) => (item ? Format_Date(item) : ""),
            },
            {
              title: "Người đăng ký",
              dataIndex: "TenKH",

              key: "TenKH",
            },
            {
              title: "Mã mặt bằng",
              dataIndex: "MaSoMB",

              key: "MaSoMB",
            },
            {
              title: "Mã đồng hồ",

              dataIndex: "MaDongHo",
              key: "MaDongHo",
            },

            {
              title: "Tên đồng hồ",
              dataIndex: "TenDongHo",

              key: "TenDongHo",
            },

            {
              title: "Duyệt",
              dataIndex: "IsConfirm",

              key: "IsConfirm",
              render: (item) => <Checkbox checked={item}></Checkbox>,
            },
            {
              title: "Diễn giải",

              dataIndex: "DienGiai",
              key: "DienGiai",
            },

            {
              title: "Người tạo",

              dataIndex: "NguoiTao",
              key: "NguoiTao",
            },

            {
              title: "Ngày tạo",
              dataIndex: "NgayTao",

              key: "NgayTao",
              render: (item) => (item ? Format_Date(item) : ""),
            },
            {
              title: "Người cập nhật",
              dataIndex: "NguoiCN",

              key: "NguoiCN",
            },
            {
              title: "Ngày cập nhật",
              dataIndex: "NgayCN",

              key: "NgayCN",
              render: (item) => (item ? Format_Date(item) : ""),
            },
            {
              title: (
                <div style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    onClick={() => refDongHoNuoc.current.show()}
                  >
                    <PlusOutlined
                      style={{ fontSize: "13px", margin: "0 -4px" }}
                    />{" "}
                    Thêm
                  </Button>{" "}
                </div>
              ),
              fixed: "right",
              width: "100px",
              render: (item) => (
                <div style={{ display: "flex" }}>
                  <Tooltip placement="topRight" title="Sửa">
                    <Button
                      style={{ marginLeft: 10 }}
                      type="primary"
                      ghost
                      icon={<EditTwoTone />}
                      onClick={() => refDongHoNuoc.current.update(item)}
                    />
                  </Tooltip>
                  <Popconfirm
                    title="Xoá danh mục"
                    description="Bạn có chắc chắn muốn xoá danh mục?"
                    okText="Đồng ý"
                    cancelText="Không"
                    onConfirm={() => remove(item.ID)}
                  >
                    <Tooltip placement="topRight" title="Xoá">
                      <Button
                        style={{ marginLeft: 10 }}
                        danger
                        ghost
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </div>
              ),
            },
          ];

        default:
          break;
      }
      break;
    case 2:
      switch (secondaryOption) {
        case 1:
          return [
            { title: "STT", ellipsis: true, dataIndex: "STT", key: "STT" },

            {
              title: "Loại mặt bằng",
              dataIndex: "TenLMB",

              key: "TenLMB",
            },
            {
              title: "Số định mức",
              dataIndex: "SoDM",

              key: "SoDM",
            },
            {
              title: "Đơn giá",

              dataIndex: "DonGia",
              key: "DonGia",
            },

            { title: "VAT", ellipsis: true, dataIndex: "VAT", key: "VAT" },

            {
              title: "Từ ngày",
              dataIndex: "TuNgay",

              key: "TuNgay",
              render: (item) => (item ? Format_Date(item) : ""),
            },
            {
              title: "Đến ngày",
              dataIndex: "DenNgay",

              key: "DenNgay",
              render: (item) => (item ? Format_Date(item) : ""),
            },
            {
              title: "Diễn giải",
              dataIndex: "DienGiai",

              key: "DienGiai",
              width: "15%",
            },
            {
              title: "Người nhập",

              dataIndex: "NguoiTao",
              key: "NguoiTao",
            },

            {
              title: "Ngày nhập",
              dataIndex: "NgayNhap",

              key: "NgayNhap",
              render: (item) => (item ? Format_Date(item) : ""),
            },
            // {
            //   title: "Người cập nhật",
            //   dataIndex: "NguoiCapNhat",
            // ellipsis: true,
            //   key: "NguoiCapNhat",
            // },
            // {
            //   title: "Ngày cập nhật",
            //   dataIndex: "NgayCapNhat",
            // ellipsis: true,
            //   key: "NgayCapNhat",
            //        render: (item) => (item ? Format_Date(item) : ""),
            // },
            {
              title: (
                <div style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    onClick={() => refDinhMucDien.current.show()}
                  >
                    <PlusOutlined
                      style={{ fontSize: "13px", margin: "0 -4px" }}
                    />{" "}
                    Thêm
                  </Button>{" "}
                </div>
              ),
              fixed: "right",
              width: "100px",
              render: (item) => (
                <div style={{ display: "flex" }}>
                  <Tooltip placement="topRight" title="Sửa">
                    <Button
                      style={{ marginLeft: 10 }}
                      type="primary"
                      ghost
                      icon={<EditTwoTone />}
                      onClick={() => refDinhMucDien.current.update(item)}
                    />
                  </Tooltip>
                  <Popconfirm
                    title="Xoá danh mục"
                    description="Bạn có chắc chắn muốn xoá danh mục?"
                    okText="Đồng ý"
                    cancelText="Không"
                    onConfirm={() => remove(item.ID)}
                  >
                    <Tooltip placement="topRight" title="Xoá">
                      <Button
                        style={{ marginLeft: 10 }}
                        danger
                        ghost
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </div>
              ),
            },
          ];
        case 2:
          return [
            // { title: "STT", dataIndex: "STT", key: "STT" },
            // ellipsis: true,
            {
              title: "Số phiếu",

              dataIndex: "SoPhieu",
              key: "SoPhieu",
            },

            {
              title: "Ngày đăng ký",
              dataIndex: "NgayDK",

              key: "NgayDK",
              render: (item) => (item ? Format_Date(item) : ""),
            },
            // {
            //   title: "Người đăng ký",
            //   dataIndex: "NguoiDangKy",
            // ellipsis: true,
            //   key: "NguoiDangKy",
            // },
            {
              title: "Mã công tơ",

              dataIndex: "MaCongTo",
              key: "MaCongTo",
            },

            {
              title: "Tên công tơ",
              dataIndex: "TenCongTo",

              key: "TenCongTo",
            },
            // {
            //   title: "Loại công tơ",
            //   dataIndex: "LoaiCongTo",
            // ellipsis: true,
            //   key: "LoaiCongTo",
            // },
            // {
            //   title: "Số HĐMB",
            //   dataIndex: "SoHDMB",
            // ellipsis: true,
            //   key: "SoHDMB",
            // },
            {
              title: "Duyệt",
              dataIndex: "Duyet",

              key: "Duyet",
              render: (item) => (
                <div>
                  <Checkbox></Checkbox>
                </div>
              ),
            },
            {
              title: "Diễn giải",

              dataIndex: "DienGiai",
              key: "DienGiai",
            },

            {
              title: "Ngày tạo",
              dataIndex: "NgayTao",

              key: "NgayTao",
              render: (item) => (item ? Format_Date(item) : ""),
            },
            {
              title: "Người tạo",

              dataIndex: "NguoiTao",
              key: "NguoiTao",
            },

            {
              title: "Người cập nhật",
              dataIndex: "NguoiCapNhat",

              key: "NguoiCapNhat",
            },
            {
              title: "Ngày cập nhật",
              dataIndex: "NgayCapNhat",

              key: "NgayCapNhat",
            },
            {
              title: (
                <div style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    onClick={() => refLoaiCongTo.current.show()}
                  >
                    <PlusOutlined
                      style={{ fontSize: "13px", margin: "0 -4px" }}
                    />{" "}
                    Thêm
                  </Button>{" "}
                </div>
              ),
              fixed: "right",
              width: "100px",
              render: (item) => (
                <div style={{ display: "flex" }}>
                  <Tooltip placement="topRight" title="Sửa">
                    <Button
                      style={{ marginLeft: 10 }}
                      type="primary"
                      ghost
                      icon={<EditTwoTone />}
                      onClick={() => refLoaiCongTo.current.update(item)}
                    />
                  </Tooltip>
                  <Popconfirm
                    title="Xoá danh mục"
                    description="Bạn có chắc chắn muốn xoá danh mục?"
                    okText="Đồng ý"
                    cancelText="Không"
                    onConfirm={() => remove(item.ID)}
                  >
                    <Tooltip placement="topRight" title="Xoá">
                      <Button
                        style={{ marginLeft: 10 }}
                        danger
                        ghost
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </div>
              ),
            },
          ];

        default:
          break;
      }
      break;
    case 3:
      switch (secondaryOption) {
        case 1:
          return [
            {
              title: "Loại xe",

              dataIndex: "TenLX",
              key: "TenLX",
            },

            {
              title: "Phí làm thẻ",
              dataIndex: "PhiLamThe",

              key: "PhiLamThe",
            },
            {
              title: "Từ ngày",
              dataIndex: "TuNgay",

              key: "TuNgay",
              render: (item) => (item ? Format_Date(item) : ""),
            },
            {
              title: "Đến ngày",
              dataIndex: "DenNgay",

              key: "DenNgay",
              render: (item) => (item ? Format_Date(item) : ""),
            },
            {
              title: "Người tạo",

              dataIndex: "NguoiTao",
              key: "NguoiTao",
            },

            {
              title: "Ngày tạo",
              dataIndex: "NgayTao",

              key: "NgayTao",
              render: (item) => (item ? Format_Date(item) : ""),
            },
            // {
            //   title: "Người cập nhật",
            //   dataIndex: "NguoiCapNhat",
            // ellipsis: true,
            //   key: "NguoiCapNhat",
            // },
            // {
            //   title: "Ngày cập nhật",
            //   dataIndex: "NgayCapNhat",
            // ellipsis: true,
            //   key: "NgayCapNhat",
            //        render: (item) => (item ? Format_Date(item) : ""),
            // },
            {
              title: (
                <div style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    onClick={() => refLoaiXe.current.show()}
                  >
                    <PlusOutlined
                      style={{ fontSize: "13px", margin: "0 -4px" }}
                    />{" "}
                    Thêm
                  </Button>{" "}
                </div>
              ),
              fixed: "right",
              width: "100px",
              render: (item) => (
                <div style={{ display: "flex" }}>
                  <Tooltip placement="topRight" title="Sửa">
                    <Button
                      style={{ marginLeft: 10 }}
                      type="primary"
                      ghost
                      icon={<EditTwoTone />}
                      onClick={() => refLoaiXe.current.update(item)}
                    />
                  </Tooltip>
                  <Popconfirm
                    title="Xoá danh mục"
                    description="Bạn có chắc chắn muốn xoá danh mục?"
                    okText="Đồng ý"
                    cancelText="Không"
                    onConfirm={() => remove(item.MaLX)}
                  >
                    <Tooltip placement="topRight" title="Xoá">
                      <Button
                        style={{ marginLeft: 10 }}
                        danger
                        ghost
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </div>
              ),
            },
          ];
        case 2:
          return [
            {
              title: "Tên mức",

              dataIndex: "TenMuc",
              key: "TenMuc",
            },

            {
              title: "Loại MB",

              dataIndex: "TenLMB",
              key: "TenLMB",
            },

            {
              title: "Loại xe",

              dataIndex: "TenLX",
              key: "TenLX",
            },

            {
              title: "Số lượng",

              dataIndex: "SoLuong",
              key: "SoLuong",
            },

            {
              title: "Đơn giá",

              dataIndex: "DonGia",
              key: "DonGia",
            },

            {
              title: "Vượt định mức",
              dataIndex: "VuotDM",

              key: "VuotDM",
            },
            {
              title: "Từ ngày",
              dataIndex: "TuNgay",

              key: "TuNgay",
              render: (item) => (item ? Format_Date(item) : ""),
            },
            {
              title: "Đến ngày",
              dataIndex: "DenNgay",

              key: "DenNgay",
              render: (item) => (item ? Format_Date(item) : ""),
            },
            {
              title: "Diễn giải",

              dataIndex: "DienGiai",
              key: "DienGiai",
            },

            {
              title: "Người nhập",

              dataIndex: "NguoiTao",
              key: "NguoiTao",
            },

            {
              title: "Ngày nhập",
              dataIndex: "NgayNhap",

              key: "NgayNhap",
              render: (item) => (item ? Format_Date(item) : ""),
            },

            {
              title: (
                <div style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    onClick={() => refDinhMucXe.current.show()}
                  >
                    <PlusOutlined
                      style={{ fontSize: "13px", margin: "0 -4px" }}
                    />{" "}
                    Thêm
                  </Button>{" "}
                </div>
              ),
              fixed: "right",
              width: "100px",
              render: (item) => (
                <div style={{ display: "flex" }}>
                  <Tooltip placement="topRight" title="Sửa">
                    <Button
                      style={{ marginLeft: 10 }}
                      type="primary"
                      ghost
                      icon={<EditTwoTone />}
                      onClick={() => refDinhMucXe.current.update(item)}
                    />
                  </Tooltip>
                  <Popconfirm
                    title="Xoá danh mục"
                    description="Bạn có chắc chắn muốn xoá danh mục?"
                    okText="Đồng ý"
                    cancelText="Không"
                    onConfirm={() => remove(item.ID)}
                  >
                    <Tooltip placement="topRight" title="Xoá">
                      <Button
                        style={{ marginLeft: 10 }}
                        danger
                        ghost
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </div>
              ),
            },
          ];
        case 3:
          return [
            {
              title: "Tên trạng thái",
              dataIndex: "TenTrangThai",

              key: "TenTrangThai",
            },
            {
              title: "Màu nền",
              dataIndex: "MauNen",

              key: "MauNen",
              render: (text, record) => {
                const colorHex = colorIntToHex(record.MauNen);
                return <ColorPicker value={colorHex} disabled />;
              },
            },
          ];
        case 4:
          return [
            { title: "Dự án", ellipsis: true, dataIndex: "DuAn", key: "DuAn" },

            {
              title: "Sever",

              dataIndex: "Sever",
              key: "Sever",
            },

            {
              title: "Username",

              dataIndex: "Username",
              key: "Username",
            },

            {
              title: "Password",

              dataIndex: "Password",
              key: "Password",
            },

            {
              title: "Database MPRKING",
              dataIndex: "Mparking",

              key: "Mparking",
            },
            {
              title: "Database MPRKINGEVENT",
              dataIndex: "MparkingEvent",

              key: "MparkingEvent",
            },
            {
              title: data && data.length === 0 && (
                <Button
                  type="primary"
                  onClick={() => refKetNoiBaiXe.current.show()}
                >
                  <PlusOutlined
                    style={{ fontSize: "13px", margin: "0 -4px" }}
                  />{" "}
                  Thêm
                </Button>
              ),
              fixed: "right",
              width: "100px",
              render: (item) => (
                <div style={{ display: "flex" }}>
                  <Tooltip placement="topRight" title="Sửa">
                    <Button
                      style={{ marginLeft: 10 }}
                      type="primary"
                      ghost
                      icon={<EditTwoTone />}
                      onClick={() => refKetNoiBaiXe.current.update(item)}
                    />
                  </Tooltip>
                  <Popconfirm
                    title="Xoá danh mục"
                    description="Bạn có chắc chắn muốn xoá danh mục?"
                    okText="Đồng ý"
                    cancelText="Không"
                    onConfirm={() => remove(item.ID)}
                  >
                    <Tooltip placement="topRight" title="Xoá">
                      <Button
                        style={{ marginLeft: 10 }}
                        danger
                        ghost
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </div>
              ),
            },
          ];
        case 5:
          return [
            {
              title: "CardNo",

              dataIndex: "CardNo",
              key: "CardNo",
            },

            {
              title: "Mã thẻ",

              dataIndex: "MaThe",
              key: "MaThe",
            },

            {
              title: "Nhóm xe",

              dataIndex: "NhomXe",
              key: "NhomXe",
            },

            {
              title: "Password",

              dataIndex: "Password",
              key: "Password",
            },

            {
              title: "Đã sử dụng",
              dataIndex: "DaSuDung",

              key: "DaSuDung",
              render: (item) => <Checkbox checked={item?.DaSuDung}></Checkbox>,
            },

            {
              title: (
                <Button
                  type="primary"
                  onClick={() => refDanhSachKhoThe.current.show()}
                >
                  <PlusOutlined
                    style={{ fontSize: "13px", margin: "0 -4px" }}
                  />{" "}
                  Thêm
                </Button>
              ),
              fixed: "right",
              width: "100px",
              render: (item) => (
                <div style={{ display: "flex" }}>
                  <Tooltip placement="topRight" title="Sửa">
                    <Button
                      style={{ marginLeft: 10 }}
                      type="primary"
                      ghost
                      icon={<EditTwoTone />}
                      onClick={() => refDanhSachKhoThe.current.update(item)}
                    />
                  </Tooltip>
                  <Popconfirm
                    title="Xoá danh mục"
                    description="Bạn có chắc chắn muốn xoá danh mục?"
                    okText="Đồng ý"
                    cancelText="Không"
                    onConfirm={() => remove(item.ID)}
                  >
                    <Tooltip placement="topRight" title="Xoá">
                      <Button
                        style={{ marginLeft: 10 }}
                        danger
                        ghost
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </div>
              ),
            },
          ];

        default:
          break;
      }
      break;
    case 4:
      switch (secondaryOption) {
        case 1:
          return [
            {
              title: "Tên loại dịch vụ",
              dataIndex: "TenLDV",

              key: "TenLDV",
            },
            {
              title: "Phí dịch vụ",
              dataIndex: "PhiDV",

              key: "PhiDV",
              render: (item) => Format_Currency(item),
            },
            {
              title: "Tỷ lệ VAT",

              dataIndex: "TyLeVAT",
              key: "TyLeVAT",
            },

            {
              title: "Thu hộ",
              dataIndex: "ThuHo",

              key: "ThuHo",
              render: (item) => <Checkbox></Checkbox>,
            },
            {
              title: "Tính trước tháng",
              dataIndex: "TinhTruocThang",

              key: "TinhTruocThang",
              render: (item) => <Checkbox></Checkbox>,
            },
            {
              title: (
                <div style={{ textAlign: "center" }}>
                  <Button type="primary" onClick={() => refKhac.current.show()}>
                    <PlusOutlined
                      style={{ fontSize: "13px", margin: "0 -4px" }}
                    />{" "}
                    Thêm
                  </Button>{" "}
                </div>
              ),
              fixed: "right",
              width: "100px",
              render: (item) => (
                <div style={{ display: "flex" }}>
                  <Tooltip placement="topRight" title="Sửa">
                    <Button
                      style={{ marginLeft: 10 }}
                      type="primary"
                      ghost
                      icon={<EditTwoTone />}
                      onClick={() => refKhac.current.update(item)}
                    />
                  </Tooltip>
                  <Popconfirm
                    title="Xoá danh mục"
                    description="Bạn có chắc chắn muốn xoá danh mục?"
                    okText="Đồng ý"
                    cancelText="Không"
                    onConfirm={() => remove(item.MaLDV)}
                  >
                    <Tooltip placement="topRight" title="Xoá">
                      <Button
                        style={{ marginLeft: 10 }}
                        danger
                        ghost
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </div>
              ),
            },
          ];

        default:
          break;
      }
      break;
    default:
      break;
  }
};
export default getColumns;
