import { Flex, InputNumber } from "antd";
import React from "react";
import "./index.scss";
const InputNumberCustom2 = ({
  applyChange,
  prop,
  value,
  placeholder,
  max,
  errors,
  required,
}) => {
  return (
    <div>
      <Flex        className={required ? "border-required" : "input-custom"}align="center" justify="flex-start">
        {/* <p style={{ fontWeight: "600", width: "35%" }}>{label}</p> */}
        <InputNumber
          style={{ width: "100% ", border: "none" ,color: required ? "red": "black" }}
          value={value}
          // placeholder={placeholder}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          onChange={(value) =>
            typeof applyChange === "function" && applyChange(prop, value)
          }
          max={max ? max : null}
        />
      </Flex>
      {errors && (
        <p style={{ color: "red", textAlign: "center" }}>
          {errors && errors[prop]}
        </p>
      )}
    </div>
  );
};
export default InputNumberCustom2;
