import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Flex,
  Input,
  Menu,
  Modal,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  notification,
} from "antd";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import { ToaNhaService } from "services/ToaNhaService";
import { AiOutlineMenuFold } from "react-icons/ai";
import { MSBService } from "services/MSBService";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { DichVuService } from "services/DichVuService";
import FormAddExcel from "./FormAddExcel";
import {
  CaretRightOutlined,
  DollarOutlined,
  DownOutlined,
  ExportOutlined,
  ImportOutlined,
  LockOutlined,
  PlusOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { BsMotherboard } from "react-icons/bs";
import { FaKey } from "react-icons/fa6";
import FormAddCongNo from "./FormAddCongNo";
import TextArea from "antd/es/input/TextArea";
import { EllipsisWithTooltip } from "layouts/ultis/tooltip";
import { DateTime } from "luxon";
import { CategoryService } from "services/CategoryService";
export default function Index() {
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("filter") === "true"
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [dataModal, setDataModal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toanha, setToanha] = useState([]);
  const [khoinha, setKhoinha] = useState([]);
  const [dataSelect, setDataSelect] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [loaiDV, SetLoaiDV] = useState([]);
  // const today = new Date();
  // const sevenDaysAgo = new Date();
  // sevenDaysAgo.setDate(today.getDate() - 7);

  const now = DateTime.now().setZone("Asia/Ho_Chi_Minh");
  const startOfMonth = now.startOf("month");
  const endOfMonth = now.endOf("month");

  const [filterCondition, setFilterCondition] = useState({
    TuNgay: startOfMonth.toISO(),
    DenNgay: endOfMonth.toISO(),
    PageSize: 10,
    PageIndex: 1,
    InputSearch: "",
    MaTN: null,
    MaKN: 0,
    LoaiDV: ",,",
  });
  const refDetail = useRef(null);
  const refAddExcel = useRef(null);
  const refCongNo = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const loadCategories = async () => {
    let _tn = await ToaNhaService.getToaNhaByNV();
    setToanha(_tn.data);
    let _filter = _.cloneDeep(filterCondition);
    _filter.MaTN = _tn.data[0]?.MaTN;
    setFilterCondition(_filter);
    loadcategoriesByMaTN(_tn.data[0]?.MaTN);

    let _res = await CategoryService.DichVu.Khac.get({
      _filter,
    });
    if (_res.data) SetLoaiDV(_res.data);

    filter(_filter);
  };
  const loadcategoriesByMaTN = async (matn) => {
    let _tn = await ToaNhaService.Categories.getKhoiNha({
      MaTN: matn,
    });
    setKhoinha(_tn.data);
  };
  useEffect(() => {
    loadCategories();
  }, []);
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await DichVuService.DichVuKhac.get(_filter);
    if (_res.data) {
      let totalTongTien = 0;

      _res.data.forEach((item) => {
        totalTongTien += item.SoTien;
      });
      setData(_res.data);
      setTotalPrice(totalTongTien);
      setLoading(false);
    }
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () => {
    filter(filterCondition);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [caseModal, setCaseModal] = useState(3);
  const [titleModal, setTitleModal] = useState();
  const showModal = (value, title) => {
    setIsModalOpen(true);
    setCaseModal(value);
    setTitleModal(title);
  };
  const handleOk = async () => {
    setLoading(true);
    let _res;
    if (caseModal === 3) {
      _res = await DichVuService.DichVuKhac.khoaMoThe({
        IsLock: true,
        DichVu: selectedRowKeys,
      });
    } else if (caseModal === 4) {
      _res = await DichVuService.DichVuKhac.khoaMoThe({
        IsLock: false,
        DichVu: selectedRowKeys,
      });
    }
    setLoading(false);
    if (_res?.status === 2000) {
      openNotification("success", "topRight", `Cập nhật thành công!`);
      setIsModalOpen(false);

      onAfterSubmit();
    } else {
      openNotification(
        "error",
        "topRight",
        _res?.message || "Cập nhật thất bại."
      );
    }
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(dataModal);
    switch (p) {
      default:
        _data[p] = v;
        break;
    }
    setDataModal(_data);
  };
  const columns = [
    {
      title: "Mã mặt bằng",
      dataIndex: "MaSoMB",
      fixed: "left",
    },
    {
      title: "Loại dịch vụ",
      dataIndex: "TenLDV",
    },
    {
      title: "Ngày tính phí",
      dataIndex: "NgayTinhPhi",
      render: (item) => Format_Date(item),
    },
    {
      title: "Ngày TT",
      dataIndex: "NgayTT",
      render: (item) => Format_Date(item),
    },

    {
      title: "Số lượng",
      dataIndex: "SoLuong",
      render: (item) => {
        const formattedNumber = parseFloat(item).toLocaleString("vi-VN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        return formattedNumber;
      },
      // ellipsis: true,
    },
    {
      title: "Đơn giá",
      dataIndex: "DonGia",
      render: (item) => Format_Currency(item),
      // ellipsis: true,
    },
    {
      title: "Thành tiền",
      dataIndex: "SoTien",
      render: (item) => Format_Currency(item),
      // ellipsis: true,
      // ellipsis: true,
    },
    {
      title: "Diễn giải",
      dataIndex: "DienGiai",
      render: (text) => <EllipsisWithTooltip text={text} />,
      // ellipsis: true,
    },
    {
      title: "Ghi chú",
      dataIndex: "GhiChu",
      render: (text) => <EllipsisWithTooltip text={text} />,
      // ellipsis: true,
    },
    {
      title: "Diện tích",
      dataIndex: "DienTich",
      // ellipsis: true,
    },
    {
      title: "Khách hàng",
      dataIndex: "TenKH",
      // ellipsis: true,
    },
    {
      title: "Địa chỉ",
      dataIndex: "DiaChi",
      // ellipsis: true,
    },
    {
      title: "Điện thoại",
      dataIndex: "DienThoai",
      // ellipsis: true,
    },
    {
      title: "Email/Fax",
      dataIndex: "Email",
      // ellipsis: true,
    },
    {
      title: "Nhân viên tạo",
      dataIndex: "NhanVienTao",
      width: 120,
      // ellipsis: true,
    },
    {
      title: "Nhân viên sửa",
      dataIndex: "NhanVienSua",
      width: 120,
      // ellipsis: true,
    },

    {
      title: "Trạng thái TT",
      dataIndex: "TrangThaiThanhToan",
      width: 120,
      // ellipsis: true,
    },
    {
      title: "Trang thái",
      dataIndex: "IsLock",
      // ellipsis: true,

      fixed: "right",
      render: (item) => (
        <Tag color={item === "Không khóa" ? "green" : "red"}>{item}</Tag>
      ),
    },
    // {
    //   title: "Ngày nhập",
    //   dataIndex: "NgayTao",
    //   render: (item) => (item ? Format_Date(item) : null),
    // },
    {
      title: "",
      fixed: "right",
      width: 110,
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá dịch vụ "
              description="Bạn có chắc chắn muốn xoá dịch vụ ?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.MaDV)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = await DichVuService.DichVuKhac.delete({ ID: Number(value) });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.MaDV !== Number(value));
      setData(fil);
      openNotification("success", "topRight", "Xoá thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const onHandleHide = () => {
    setCollapsed(!collapsed);
    localStorage.setItem("filter", !collapsed);
  };
  const onSelectChange = async (newSelectedRowKeys) => {
    if (newSelectedRowKeys?.length > 100) {
      openNotification("error", "topRight", "Số lượng chọn không quá 100");
      return;
    }
    setDataSelect(
      data.filter((item) => newSelectedRowKeys.includes(item.MaDV))
    );
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        if (filterCondition.MaTN) filter(filterCondition);
      }, 500);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = async () => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.PageSize = 5000;
    let _res = await DichVuService.DichVuKhac.get(_ft);

    const fieldsToExport = columns.map((col) => col.dataIndex);
    const filteredData = _res.data?.map((item) => {
      let filteredItem = {};
      fieldsToExport.forEach((field) => {
        filteredItem[field] = item[field];
      });
      return filteredItem;
    });
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "Danh sách dịch vụ khác" + fileExtension);
  };
  const onChangePage = (page, size) => {
    if (page === 0) {
      page = 1;
    }
    let _ft = _.cloneDeep(filterCondition);
    _ft.PageIndex = page;
    _ft.PageSize = size;
    setFilterCondition(_ft);
    filter(_ft);
  };
  const handleMenuClick = (e) => {
    if (e.key === "2") {
      if (selectedRowKeys.length < 1) {
        openNotification(
          "error",
          "topRight",
          "Vui lòng chọn mặt bằng, xin cảm ơn."
        );
        return;
      }
    }
    if (e.key === "3") {
      if (selectedRowKeys.length < 1) {
        openNotification(
          "error",
          "topRight",
          "Vui lòng chọn thẻ xe, xin cảm ơn."
        );
        return;
      }
    }
    let titleCongNo = "Theo dịch vụ";
    if (e.key === "1") {
      titleCongNo = toanha.find(
        (item) => item.MaTN === filterCondition.MaTN
      )?.TenTN;
    } else if (e.key === "2") {
      titleCongNo = "Theo mặt bằng";
    }
    refCongNo.current.show(e.key, titleCongNo);
  };
  const handleMenuClick2 = async (e) => {
    if (e.key !== "1" && e.key !== "2" && selectedRowKeys.length < 1) {
      openNotification(
        "error",
        "topRight",
        "Vui lòng chọn mặt bằng, xin cảm ơn."
      );
      return;
    }
    switch (e.key) {
      case "1":
        refAddExcel.current.show();
        break;
      case "2":
        exportToCSV();
        break;
      case "3":
        showModal(3, "Khóa dịch vụ");
        break;
      case "4":
        showModal(4, "Mở dịch vụ");
        break;
      case "5":
        const filterData = data.filter((obj) =>
          selectedRowKeys.includes(obj.MaDV)
        );
        let _data = _.cloneDeep(data);
        let hasDeleted = false;
        for (const item of filterData) {
          try {
            const _res = await DichVuService.DichVuKhac.delete({
              ID: Number(item.MaDV),
            });
            if (_res.status === 2000) {
              _data = _data.filter((v) => v.MaDV !== Number(item.MaDV));
              hasDeleted = true;
            } else {
              openNotification("error", "topRight", _res?.message);
            }
          } catch (error) {
            openNotification(
              "error",
              "topRight",
              "Có lỗi xảy ra trong quá trình xóa"
            );
          }
        }

        if (hasDeleted) {
          setData(_data);
          setSelectedRowKeys([]);
          openNotification("success", "topRight", "Xoá danh mục thành công");
        }
        break;
      default:
        break;
    }
  };
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">
        {" "}
        <CaretRightOutlined style={{ paddingRight: "5px" }} />
        Theo tòa nhà
      </Menu.Item>
      <Menu.Item key="2">
        {" "}
        <CaretRightOutlined style={{ paddingRight: "5px" }} />
        Theo mặt bằng
      </Menu.Item>
      <Menu.Item key="3">
        {" "}
        <CaretRightOutlined style={{ paddingRight: "5px" }} />
        Theo dịch vụ
      </Menu.Item>
    </Menu>
  );
  const menu2 = (
    <Menu onClick={handleMenuClick2}>
      <Menu.Item key="1">
        {" "}
        <ImportOutlined style={{ paddingRight: "8px" }} />
        Import
      </Menu.Item>
      <Menu.Item key="2">
        <ExportOutlined style={{ paddingRight: "8px" }} />
        Export
      </Menu.Item>
      <Menu.Item key="5">
        <ExportOutlined style={{ paddingRight: "8px" }} />
        Xóa nhiều
      </Menu.Item>
    </Menu>
  );
  const menuKhoaMoDV = (
    <Menu onClick={handleMenuClick2}>
      <Menu.Item key="3">
        {" "}
        <Flex align="center">
          {" "}
          <LockOutlined style={{ marginRight: "5px" }} />
          Khóa
        </Flex>
      </Menu.Item>
      <Menu.Item key="4">
        {" "}
        <Flex align="center">
          {" "}
          <UnlockOutlined style={{ marginRight: "5px" }} /> Mở khóa
        </Flex>
      </Menu.Item>
    </Menu>
  );
  return (
    <Row gutter={15}>
      <Modal
        title={titleModal}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Row
          style={{ marginTop: "25px", marginBottom: "20px" }}
          gutter={[24, 20]}
        >
          <Col span={24}>
            <p
              style={{
                fontWeight: "600",
              }}
            >
              Diễn giải:
            </p>
            <TextArea
              value={dataModal?.DienGiai}
              onChange={(e) => applyChange("DienGiai", e.target.value)}
            />
          </Col>
        </Row>
      </Modal>
      <Col span={collapsed ? 0 : 4} style={{ transition: "all 0.3s ease" }}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
          options={{
            ToaNha: toanha,
            KhoiNha: khoinha,
            LoaiDV: loaiDV,
          }}
          loadcategoriesByMaTN={loadcategoriesByMaTN}
        />
      </Col>
      <Col span={collapsed ? 24 : 20}>
        <Toolbar
          left={() => (
            <Flex align="center" gap={10}>
              <AiOutlineMenuFold
                size={20}
                className="icon-filter"
                color="primary"
                onClick={() => onHandleHide()}
              />
              <Input
                style={{
                  width: "300px",
                  padding: "4px 11px",
                  borderRadius: 10,
                }}
                placeholder="Tìm mã số mặt bằng, điện thoại, tên khách hàng"
                onChange={(e) => onSearch(e.target.value)}
              />
            </Flex>
          )}
          right={() => (
            <Flex gap={10}>
              <Button
                loading={loading}
                type="primary"
                onClick={() => refDetail.current.show()}
              >
                <PlusOutlined style={{ fontSize: "13px", margin: "0 -4px" }} />{" "}
                Thêm
              </Button>
              <Dropdown overlay={menu} trigger={["click"]}>
                <Button>
                  <DollarOutlined />
                  Tạo công nợ
                  <DownOutlined />
                </Button>
              </Dropdown>
              <Dropdown overlay={menuKhoaMoDV} trigger={["click"]}>
                <Button>
                  <Flex align="center">
                    <FaKey style={{ marginRight: "7px" }} />
                    Khóa DV
                    <DownOutlined
                      style={{ marginLeft: "7px", fontSize: "12px" }}
                    />
                  </Flex>
                </Button>
              </Dropdown>
              <Dropdown overlay={menu2} trigger={["click"]}>
                <Button style={{ display: "flex", alignItems: "center" }}>
                  <BsMotherboard style={{ marginRight: "5px" }} /> Thao tác khác
                  <DownOutlined />
                </Button>
              </Dropdown>
            </Flex>
          )}
        />
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 250,
            x: 2100,
          }}
          rowSelection={{ ...rowSelection, columnWidth: 60 }}
          bordered
          pagination={false}
          virtual={true}
          size="small"
          summary={(pageData) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={2}>
                    <p style={{ fontWeight: "bold" }}>
                      Tổng: {pageData?.length}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={0}
                    colSpan={14}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell index={0} colSpan={1}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(totalPrice)}
                    </p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
        <div style={{ textAlign: "right", paddingTop: "15px" }}>
          <Pagination
            onChange={onChangePage}
            showSizeChanger
            total={data[0]?.TotalRows}
            defaultPageSize={20}
          />
        </div>
      </Col>

      <FormAdd
        ref={refDetail}
        onAfterSubmit={onAfterSubmit}
        filterCondition={filterCondition}
        loaiDV={loaiDV}
      />
      {contextHolder}
      <FormAddExcel
        ref={refAddExcel}
        maTN={filterCondition.MaTN}
        onAfterSubmit={onAfterSubmit}
      />
      <FormAddCongNo
        ref={refCongNo}
        dataSelect={dataSelect}
        selectedRowKeys={selectedRowKeys}
        maTN={filterCondition.MaTN}
        setSelectedRowKeys={setSelectedRowKeys}
        onAfterSubmit={onAfterSubmit}
        dataAllDV={data}
      />
    </Row>
  );
}
