import axios from "axios";

export const API_URL = () => {
  let url = "";
  switch (window.location?.host) {
    case "crm.beesky.vn":
      url = "https://api-crm.beesky.vn";
    default:
      url = "https://api-bms.beesky.vn";
      break;
  }
  return url;
};
const axiosApi = (headers) => {
  return axios.create({
    baseURL: API_URL(),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      ...headers,
    },
  });
};
export default axiosApi;

// export async function get(url) {
//   return await axiosApi
//     .get(url, {
//       headers: {
//         Authorization:
//           "Bearer " + JSON.parse(localStorage.getItem("authUser"))?.acessToken,
//       },
//     })
//     .then(response => response.data)
// }
// export async function post(url, data) {
//   return await axiosApi
//     .post(
//       url,
//       { ...data },
//       {
//         headers: {
//           Authorization:
//             "Bearer " +
//             JSON.parse(localStorage.getItem("authUser"))?.acessToken,
//         },
//       }
//     )
//     .then(response => response.data)
// }
