import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Space,
  Drawer,
  notification,
  Table,
  Upload,
  message,
  Select,
  Checkbox,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import { UploadOutlined } from "@ant-design/icons";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { MatBangService } from "services/MatBangService";
import dayjs from "dayjs";
import { ConvertExcelDateToJSDate } from "layouts/ultis/common";
const props2 = {
  name: "file",
  action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const FormAdd = (props, ref) => {
  const { onAfterSubmit, options, filterCondition } = props;
  const [api, contextHolder] = notification.useNotification();

  const [data, setData] = useState([]);
  const [dataError, setDataError] = useState([]);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = { TenKH: null };
  const [errors, setErrors] = useState(defaultErrors);
  const [sheetExcel, setSheetExcel] = useState();
  const [workbook, setWorkbook] = useState(null);

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
    },
    update: async (data) => {
      setOpen(true);

      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData([]);
    setLoading(false);
    setCol(columns);
    // onAfterSubmit();
    setSheetExcel();
  };

  const columns = [
    { title: "Mã Căn Hộ", dataIndex: "MaSoMB", key: "MaSoMB" },
    { title: "Mã Khách Hàng", dataIndex: "KyHieu", key: "KyHieu" },
    { title: "Số Nhà", dataIndex: "SoNha", key: "SoNha" },
    { title: "Tên Tầng", dataIndex: "TenTL", key: "TenTL" },
    { title: "Tên Tòa Nhà", dataIndex: "TenKN", key: "TenKN" },
    {
      title: "Trạng Thái Căn Hộ",
      dataIndex: "TenTT",
      key: "TenTT",
    },
    { title: "Loại Căn Hộ", dataIndex: "TenLMB", key: "TenLMB" },
    {
      title: "Diện Tích Thông Thủy",
      dataIndex: "DTThongThuy",
      key: "DTThongThuy",
    },
    {
      title: "Diện Tích Tìm Tường",
      dataIndex: "DTTimTuong",
      key: "DTTimTuong",
    },
    {
      title: "Tính Phí Theo Diện Tích",
      dataIndex: "TinhPhiTheoDienTich",
      key: "TinhPhiTheoDienTich",
      render: (item) => (
        <Checkbox
          checked={
            (typeof item === "string" && item.toLowerCase() === "false") ||
            !item ||
            item === "" ||
            item === false
              ? false
              : true
          }
        />
      ),
    },
    {
      title: "Diện Tích Tính PQL",
      dataIndex: "DienTichThuPhi",
      key: "DienTichThuPhi",
    },
    {
      title: "Ngày Tính Phí",
      dataIndex: "BatDauTinhPhi",
      key: "BatDauTinhPhi",
    },
    { title: "Ngày TTPQL", dataIndex: "NgayTTPQL", key: "NgayTTPQL" },
    { title: "Kỳ TTPQL", dataIndex: "KyThanhToan", key: "KyThanhToan" },
    { title: "Phí Quản Lý", dataIndex: "PhiQuanLy", key: "PhiQuanLy" },
    { title: "VAT PQL", dataIndex: "VATPQL", key: "VATPQL" },
    { title: "Diễn Giải", dataIndex: "DienGiai", key: "DienGiai" },
    { title: "Số Người HDM", dataIndex: "SoNguoiHDM", key: "SoNguoiHDM" },
    {
      title: "Ngày Bàn Giao Căn Hộ",
      dataIndex: "NgayBanGiaoCanHo",
      key: "NgayBanGiaoCanHo",
    },
    { title: "CS Nước BG", dataIndex: "CSNuocBanGiao", key: "CSNuocBanGiao" },
    { title: "CS Điện BG", dataIndex: "CSDienBanGiao", key: "CSDienBanGiao" },
    {
      title: "Thời Gian Miễn Phí PQL",
      dataIndex: "ThoiGianMienPhi",
      key: "ThoiGianMienPhi",
    },
  ];
  const [col, setCol] = useState(columns);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const sampleData = [
    {
      MaSoMB: "CH06",
      KyHieu: "KH001",
      SoNha: "123",
      TenTL: "Tầng 1",
      TenKN: "BH1",
      TenTT: "Cho thuê",
      TenLMB: "Căn hộ",
      DTThongThuy: 8,
      DTTimTuong: 8,
      TinhPhiTheoDienTich: "TrUe",
      DienTichThuPhi: 8,
      BatDauTinhPhi: "2026-01-01",
      NgayTTPQL: "2026-01-01",
      KyThanhToan: "10",
      PhiQuanLy: 80,
      VAT_PQL: 10,
      DienGiai: "Căn hộ đang được sử dụng",
      SoNguoiHDM: 1,
      NgayBanGiaoCanHo: "2023-02-01",
      CSNuocBanGiao: 8,
      CSDienBanGiao: 8,
      ThoiGianMienPhi: 10,
    },
  ];
  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(sampleData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "File mẫu mặt bằng" + fileExtension);
  };

  const loadDataImport = (data) => {
    let res = [];
    data?.map((item, i) => {
      if (i > 0) {
        res.push({
          MaSoMB: item[0]?.toString() ?? null,
          KyHieu: item[1]?.toString() ?? null,
          SoNha: item[2]?.toString() ?? null,
          TenTL: item[3]?.toString() ?? null,
          TenKN: item[4]?.toString() ?? null,
          TenTT: item[5]?.toString() ?? null,
          TenLMB: item[6]?.toString() ?? null,
          DTThongThuy: Number(item[7]) ?? null,
          DTTimTuong: Number(item[8]) ?? null,
          TinhPhiTheoDienTich: item[9] ?? false,
          DienTichThuPhi: Number(item[10]) ?? null,
          BatDauTinhPhi: item[11]?.toString() ?? null,
          NgayTTPQL: typeof item[12] === "number"
          ? ConvertExcelDateToJSDate(item[12])
          : item[12],
          KyThanhToan: Number(item[13]) ?? null,
          PhiQuanLy: Number(item[14]) ?? null,
          VATPQL: Number(item[15]) ?? null,
          DienGiai: item[16]?.toString() ?? null,
          SoNguoiHDM: Number(item[17]) ?? null,
          NgayBanGiaoCanHo: typeof item[18] === "number"
          ? ConvertExcelDateToJSDate(item[18])
          : item[18],
          CSNuocBanGiao: Number(item[19]) ?? null,
          CSDienBanGiao: Number(item[20]) ?? null,
          ThoiGianMienPhi: Number(item[21]) ?? null,
        });
      }
    });
    setData(res);
  };

  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);

    const formatDate = (dateString) => {
      if (dayjs(dateString, "DD/MM/YYYY", true).isValid()) {
        return dayjs(dateString, "DD/MM/YYYY").format("YYYY-MM-DD");
      } else if (dayjs(dateString, "YYYY/MM/DD", true).isValid()) {
        return dayjs(dateString, "YYYY/MM/DD").format("YYYY-MM-DD");
      } else if (dayjs(dateString, "DD-MM-YYYY", true).isValid()) {
        return dayjs(dateString, "DD-MM-YYYY").format("YYYY-MM-DD");
      } else {
        return dateString;
      }
    };

    const filterData = _data.map((item) => {
      return {
        ...item,
        NgayTTPQL: formatDate(item.NgayTTPQL),
        NgayBanGiaoCanHo: formatDate(item.NgayBanGiaoCanHo),
        BatDauTinhPhi: formatDate(item.BatDauTinhPhi),
        TinhPhiTheoDienTich:
          (typeof item?.TinhPhiTheoDienTich === "string" &&
            item?.TinhPhiTheoDienTich?.toLowerCase() === "false") ||
          !item ||
          item === "" ||
          item === false
            ? false
            : true,
        // typeof item?.TinhPhiTheoDienTich === "string" &&
        // item.TinhPhiTheoDienTich.toLowerCase() === "true"
        //   ? true
        //   : false,

        // IsCanHoCaNhan: item.IsCanHoCaNhan.toLowerCase() === "có" ? true : false,
        // DaGiaoChiaKhoa:
        //   item.DaGiaoChiaKhoa.toLowerCase() === "có" ? true : false,
        // IsChuyenThangSau:
        //   item.IsChuyenThangSau.toLowerCase() === "có" ? true : false,
        // isChoThue: item.isChoThue.toLowerCase() === "có" ? true : false,
        MaMB: null,
      };
    });

    let _res;
    if (isupdate) {
      _res = await MatBangService.updateImportExcel({
        MaTN: filterCondition.MaTN,
        MatBang: filterData,
      });
    } else {
      _res = await MatBangService.importExcel({
        MaTN: filterCondition.MaTN,
        MatBang: filterData,
      });
    }

    setLoading(false);
    onAfterSubmit();
    if (_res.status === 2000 && _res.data === 0) {
      openNotification("success", "topRight", "Thao tác thành công");

      onClose();
    } else {
      const pushErrorData = _data.map((item) => {
        let updatedItem = { ...item };
        _res?.data.forEach((i) => {
          if (item.MaSoMB === i.maSoMB) {
            updatedItem = { ...item, error: i.error };
          }
        });
        return updatedItem;
      });
      setData(pushErrorData.filter((item) => item.error));
      setCol([
        {
          title: "Error",
          dataIndex: "error",
          key: "error",
          width: 180,
          render: (item) => <p style={{ color: "red" }}>{item}</p>,
        },
        ...columns,
      ]);
      openNotification("success", "topRight", "Thao tác thành công");
    }
  };
  const handleSheet = (value) => {
    const wsname = workbook.SheetNames[value];
    const ws = workbook.Sheets[wsname];
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
    loadDataImport(data);
    setCol(columns);
  };

  return (
    <Drawer
      title="Excel"
      width={1580}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Checkbox
            checked={isupdate}
            onClick={() => {
              setIsUpdate(!isupdate);
              setCol(columns);
              const pushErrorData = data.map((item) => {
                let updatedItem = { ...item };
                updatedItem = { ...item, error: null };
                return updatedItem;
              });
              setData(pushErrorData);
            }}
          >
            Update mặt bằng
          </Checkbox>
          {sheetExcel && (
            <Select
              placeholder="Chọn Sheet"
              style={{
                width: 115,
                border: "1px solid #d9d9d9",
                borderBottom: "3px solid #d9d9d",
                borderRadius: "5px",
                padding: "0 2px 0 10px",
              }}
              onChange={handleSheet}
              options={sheetExcel}
            />
          )}

          <Upload
            accept=".xlsx, .xls"
            showUploadList={false}
            beforeUpload={(file) => {
              setWorkbook(null);
              setSheetExcel(null);
              const reader = new FileReader();
              const rABS = !!reader.readAsBinaryString;
              reader.onload = (e) => {
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
                const sheets = wb.SheetNames.map((item, index) => ({
                  value: index,
                  label: item,
                }));
                if (sheets.length === 1) {
                  const wsname = wb.SheetNames[0];
                  const ws = wb.Sheets[wsname];
                  const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
                  loadDataImport(data);
                } else {
                  setWorkbook(wb);
                  setSheetExcel(sheets);
                  setData();
                }
              };
              if (rABS) reader.readAsBinaryString(file);
              else reader.readAsArrayBuffer(file);
            }}
          >
            <Button onClick={() => setCol(columns)} icon={<UploadOutlined />}>
              Upload File
            </Button>
          </Upload>
          <Button onClick={() => exportToCSV()}>Tải file mẫu</Button>
          <Button
            danger
            onClick={() => {
              setData([]);
              setCol(columns);
            }}
          >
            Xóa
          </Button>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Table
        bordered
        style={{ margin: 0, marginTop: "20px" }}
        dataSource={data}
        columns={col}
        scroll={{
          y: window.innerHeight - 250,
          x: 3000,
        }}
      />
    </Drawer>
  );
};
export default forwardRef(FormAdd);
