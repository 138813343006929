import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Tabs,
  Checkbox,
  Flex,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { DatePicker } from "antd/lib";
import vi from "dayjs/locale/vi";
import { SoQuyService } from "services/SoQuyService";
import TabPane from "antd/es/tabs/TabPane";
import { ToaNhaService } from "services/ToaNhaService";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import SelectCustom from "components/Forms/SelectCustom";
import InputCustom from "components/Forms/InputCustom";
import DebounceSelect from "components/DebounceSelect";
import FormDrawerUser from "components/FormMatBang";
import { EditOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { CategoryService } from "services/CategoryService";
import { MatBangService } from "services/MatBangService";
import { DateTime } from "luxon";
const { TextArea } = Input;

const FormAdd = (props, ref) => {
  const { onAfterSubmit, toanha, filterCondition } = props;
  const [api, contextHolder] = notification.useNotification();
  const [loaiMB, setLoaiMb] = useState([]);
  const defaulData = {
    NgayDK: new Date(),
  };
  const [data, setData] = useState(defaulData);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = {
    SoPhieu: null,
    // NgayDK: null,
    // ToaNha: null,
    // ToiTenLa: null,
    // MaCongTo: null,
    // TenCongTo: null,
    MaMB: null,
  };

  const [errors, setErrors] = useState(defaultErrors);

  async function fetchMatBang(input) {
    return MatBangService.getMatBang({
      InputSearch: input,
      MaTN: data?.MaTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.MaSoMB}`,
        value: item.MaMB,
        title: JSON.stringify(item),
      }));
    });
  }
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      setData((pr) => ({ ...pr, MaTN: filterCondition.MaTN }));
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
    setErrors(defaultErrors);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MaMB":
        const titleObject = JSON.parse(v?.title);
        const maSoMB = titleObject.MaSoMB;
        _data.MaCongTo = maSoMB;
        _data.TenCongTo = maSoMB;
        _data.MaNK = titleObject.MaKH;
        _data[p] = v.key;
        break;
      case "NgayDK":
        const date = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString = date.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString;
        break;

      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(defaultErrors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    let _res = await CategoryService.DichVu.Dien.CongTo.add(_data);
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} công tơ điện thành công!`
      );
      setIsUpdate(true);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const renderThongTinChung = () => {
    return (
      <>
        <Row style={{ marginTop: "10px" }} gutter={[20, 12]}>
          <Col span={8}>
            <InputCustom
              label="Số phiếu:"
              applyChange={applyChange}
              prop="SoPhieu"
              value={data?.SoPhieu}
              placeholder="Nhập số phiếu"
              errors={errors}
              required={true}
            />
          </Col>
          <Col span={8}>
            <DatePickerCustom
              label="Ngày đăng ký:"
              applyChange={applyChange}
              prop={"NgayDK"}
              value={data?.NgayDK}
              placeholder={"Chọn ngày đăng ký"}
              errors={errors}
            />
          </Col>
          <Col span={8}>
            <SelectCustom
              label="Tòa nhà:"
              applyChange={applyChange}
              prop="MaTN"
              value={data?.MaTN}
              placeholder="Chọn tòa nhà"
              options={toanha}
              fieldNames={{ label: "TenTN", value: "MaTN" }}
              errors={errors}
            />
          </Col>
          <Col span={8}>
            <InputCustom
              label="Tôi tên là:"
              applyChange={applyChange}
              prop="ToiTenLa"
              value={data?.ToiTenLa}
              placeholder="Nhập họ tên"
              errors={errors}
            />
          </Col>
          <Col span={8}>
            <InputCustom
              label="Mã CT:"
              applyChange={applyChange}
              prop="MaCongTo"
              value={data?.MaCongTo}
              placeholder="Nhập mã đồng hồ"
              errors={errors}
            />
          </Col>
          <Col span={8}>
            <InputCustom
              label="Tên CT:"
              applyChange={applyChange}
              prop="TenCongTo"
              value={data?.TenCongTo}
              placeholder="Nhập tên công tơ"
              errors={errors}
            />
          </Col>{" "}
          <Col span={24}>
            <Flex style={{ borderBottom: "1px solid #ff9087" }}>
              <p
                style={{
                  fontWeight: "600",
                  width: "30% ",
                }}
              >
                Mặt bằng: <span style={{ color: "red" }}>*</span>
              </p>
              <DebounceSelect
               open={open}
                value={data?.MaMB}
                placeholder="Tìm theo mã mặt bằng"
                fetchOptions={fetchMatBang}
                onChange={(v) => applyChange("MaMB", v)}
                style={{
                  width: "100%",
                }}
              />
            </Flex>
            {errors && (
              <p style={{ color: "red", textAlign: "center" }}>
                {errors && errors["MaMB"]}
              </p>
            )}
          </Col>
          {/* <Col span={24}>
            <SelectCustom
              label="Mặt bằng:"
              applyChange={applyChange}
              prop="MaMB"
              value={data?.MaMB}
              placeholder="Chọn tòa nhà"
              options={loaiMB}
              fieldNames={{ label: "TenLMB", value: "MaLMB" }}
              errors={errors}
            />
          </Col> */}
          <Col span={24}>
            <Flex>
              <p
                style={{
                  fontWeight: "600",
                  width: "20%",
                  borderBottom: "1px solid #d9d9d9",
                }}
              >
                Diễn giải:
              </p>
              <TextArea
                value={data?.DienGiai}
                onChange={(e) => applyChange("DienGiai", e.target.value)}
              />
            </Flex>
          </Col>
        </Row>
      </>
    );
  };
  return (
    <Drawer
      title={isupdate ? "Sửa công tơ điện" : "Thêm công tơ điện"}
      width={920}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined /> Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      {renderThongTinChung()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
