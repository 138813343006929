import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Table,
  Checkbox,
} from "antd";
import _ from "lodash";

import { Format_Date } from "layouts/ultis/common";
import { SoQuyService } from "services/SoQuyService";
import { Format_Currency } from "layouts/ultis/common";

const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();

  const [data, setData] = useState();
  const [dataSource, setDataSource] = useState();
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const filter = async (maPhieu) => {
    setLoading(true);
    let _resdetail = await SoQuyService.PhieuThu.getByID({ ID: maPhieu });
    setData(_resdetail.data);
    let _res = await SoQuyService.PhieuThu.getChiTietPhieuThu({ ID: maPhieu });
    if (_res.data) setDataSource(_res.data);
    setLoading(false);
  };
  useImperativeHandle(ref, () => ({
    show: async (maPT) => {
      setOpen(true);
      // setData(item);
      filter(maPT);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
  };

  const renderThongTinChung = () => {
    return (
      <>
        <p style={{ fontWeight: "bold", padding: "10px 0px 10px" }}>
          Thông tin khách hàng
        </p>

        <Row style={{ padding: "0 12px" }} gutter={[24, 5]}>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Số phiếu</p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {data?.SoPhieu}
            </p>
          </Col>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Ngày thu</p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {Format_Date(data?.NgayThu)}
            </p>
          </Col>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Mặt bằng</p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {data?.MaSoMB}
            </p>
          </Col>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Mã khách hàng</p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {data?.KyHieu}
            </p>
          </Col>
        </Row>
      </>
    );
  };

  const columns = [
    {
      title: "Nợ cũ",
      render: (item) => <Checkbox checked={item?.IsDebt}></Checkbox>,
    },
    {
      title: "Dịch vụ",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "Đợt thanh toán",
      dataIndex: "NgayThu",
      key: "NgayThu",
      render: (item) => Format_Date(item),
    },
    {
      title: "Phải thu",
      dataIndex: "PhaiThu",
      key: "PhaiThu",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Thực thu",
      dataIndex: "TongCong",
      key: "TongCong",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Diễn giải",
      dataIndex: "DienGiai",
      key: "DienGiai",
    },
  ];
  return (
    <Drawer
      title="Chi tiết phiếu thu"
      width={960}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button loading={loading} onClick={() => onClose()} type="primary">
            Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      {renderThongTinChung()}
      <Table
        style={{ marginTop: "20px" }}
        dataSource={dataSource}
        columns={columns}
      />
    </Drawer>
  );
};
export default forwardRef(FormAdd);
