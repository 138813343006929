import axiosApi from "./api_helper";

export const KhachHangService = {
  filterKhachHang: async (payload) => {
    return await axiosApi()
      .post("api/khach-hang/filter", payload)
      .then((res) => res.data);
  },
  KhachHang: {
    get: async (payload) => {
      return await axiosApi()
        .post("api/khach-hang/get", payload)
        .then((res) => res.data);
    },
    add: async (payload) => {
      return await axiosApi()
        .post("api/khach-hang/add", payload)
        .then((res) => res.data);
    },
    delete: async (payload) => {
      return await axiosApi()
        .post("api/khach-hang/delete", payload)
        .then((res) => res.data);
    },
    getLichSuKH: async (payload) => {
      return await axiosApi()
        .post("api/khach-hang/get-lscapnhat", payload)
        .then((res) => res.data);
    },
    getMatBang: async (payload) => {
      return await axiosApi()
        .post("api/khach-hang/get-matbang", payload)
        .then((res) => res.data);
    },
    import: async (payload) => {
      return await axiosApi()
        .post("api/khach-hang/import", payload)
        .then((res) => res.data);
    },
    updateImport: async (payload) => {
      return await axiosApi()
        .post("api/khach-hang/import-update", payload)
        .then((res) => res.data);
    },
  },
  CuDan: {
    get: async (payload) => {
      return await axiosApi()
        .post("api/cu-dan/get", payload)
        .then((res) => res.data);
    },
    add: async (payload) => {
      return await axiosApi()
        .post("api/cu-dan/add", payload)
        .then((res) => res.data);
    },
    delete: async (payload) => {
      return await axiosApi()
        .post("api/cu-dan/delete", payload)
        .then((res) => res.data);
    },
  },
  TienIch: {
    get: async (payload) => {
      return await axiosApi()
        .post("api/tien-ich/get", payload)
        .then((res) => res.data);
    },
    add: async (payload) => {
      return await axiosApi()
        .post("api/tien-ich/tao-lich", payload)
        .then((res) => res.data);
    },
    delete: async (payload) => {
      return await axiosApi()
        .post("api/tien-ich/delete", payload)
        .then((res) => res.data);
    },
    datLich: async (payload) => {
      return await axiosApi()
        .post("api/tien-ich/dat-lich", payload)
        .then((res) => res.data);
    },
    huy: async (payload) => {
      return await axiosApi()
        .post("api/tien-ich/huy", payload)
        .then((res) => res.data);
    },
    duyet: async (payload) => {
      return await axiosApi()
        .post("api/tien-ich/duyet", payload)
        .then((res) => res.data);
    },
  },
};
