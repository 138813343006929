import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  notification,
  Row,
  Col,
  Flex,
  Modal,
  Skeleton,
  Dropdown,
  Tag,
  Button,
} from "antd";
import _ from "lodash";
import "./index.scss";
import { CiSearch } from "react-icons/ci";
import { ToaNhaService } from "services/ToaNhaService";
import { useHistory } from "react-router-dom";
import { GetRandomColor } from "layouts/ultis/common";

function calculateMessageTime(messageTime) {
  // Chuyển đổi thời gian tin nhắn sang đối tượng Date
  const messageDate = new Date(messageTime);
  // Lấy thời gian hiện tại
  const currentDate = new Date();
  // Tính khoảng thời gian giữa hai thời điểm (tính bằng mili giây)
  const timeDifference = currentDate - messageDate;
  // Chuyển đổi khoảng thời gian sang phút, giờ và ngày
  const minutes = Math.floor(timeDifference / (1000 * 60));
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  // Nếu số ngày trôi qua lớn hơn 7, hiển thị dưới dạng dd/mm/yy
  if (days > 7) {
    const dd = messageDate.getDate();
    const mm = messageDate.getMonth() + 1; // Tháng bắt đầu từ 0
    const yy = messageDate.getFullYear().toString().slice(-2); // Lấy 2 chữ số cuối của năm
    return `${dd}/${mm}/${yy}`;
  } else {
    // Trả về kết quả dưới dạng chuỗi mô tả thời gian
    if (days > 0) {
      return `${days} ngày`;
    } else if (hours > 0) {
      return `${hours} giờ`;
    } else if (minutes > 0) {
      return `${minutes} phút`;
    } else {
      return "Vừa gửi";
    }
  }
}
function truncate(str, n) {
  return str?.length > n ? str.slice(0, n - 1) + "..." : str;
}
const BoxLeft = ({
  refInbox,
  onClickDetail,
  applyChange,
  loading,
  data,
  filterCondition,
}) => {
    const history = useHistory();
  const [toanha, setToanha] = useState([]);
  const [open, setOpen] = useState(false);
  const items = [
    // {
    //   label: <p>1.{toanha.find(v=>v.MaTN === filterCondition.MaTN)?.TenTN}</p>,
    //   key: "2",
    //   style: { width: 200 },
    //   onClick: () => changeTN(),
    // },
    {
      label: <p>Đổi toà nhà</p>,
      key: "0",
      style: { width: 200 },
      onClick: () => changeTN(),
    },
    // {
    //   label: <p>Tải ứng dụng desktop</p>,
    //   key: "1",
    //   style: { width: 200 },
    // },
    {
      type: "divider",
    },
    {
      label: "Đăng xuất",
      key: "3",
      onClick: () => logout(),
    },
  ];
  const logout = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("MaCTDK");
    localStorage.removeItem("NhanVien");
    history.push("/auth/sign-in?chat="+filterCondition.MaTN);
  };
  const changeTN = async () => {
    setOpen(true);
    let _tn = await ToaNhaService.getToaNhaByNV();
    setToanha(_tn.data);
  };
  const openChats = (v) => {
    let url = window.location.origin + "/#chats/" + v;
    window.open(url, "_self");
    window.location.reload();
  };
  const renderModal = () => {
    const hide = () => {
      setOpen(false);
    };

    return (
      <Modal
        title="Đổi toà nhà"
        open={open}
        width={400}
        onCancel={hide}
        footer=""
      >
        <Row gutter={[0, 10]}>
          {toanha?.map((item) => {
            return (
              <Col
                key={item.MaTN}
                span={24}
                style={{
                  background:
                    item.MaTN === filterCondition.MaTN
                      ? "rgb(51, 144, 236)"
                      : "white",
                  padding: 6,
                  borderRadius: 6,
                  cursor: "pointer",
                }}
                onClick={() =>
                  item.MaTN !== filterCondition.MaTN && openChats(item.MaTN)
                }
              >
                {item.TenTN}
              </Col>
            );
          })}
        </Row>
      </Modal>
    );
  };
  return (
    <>
      <Flex
        style={{
          padding: "5px 10px",
          boxShadow: "0 2px 2px rgb(114,114,114,0.169)",
        }}
        align="center"
        gap={10}
      >
        <Dropdown
          menu={{
            items,
          }}
          trigger={["click"]}
        >
          <Flex
            align="center"
            justify="center"
            style={{
              background: "linear-gradient(-225deg, #7de2fc 0%, #b9b6e5 100%)",
              width: 35,
              height: 35,
              borderRadius: 50,
              fontWeight: "bold",
              cursor: "pointer",
              fontSize: 9,
            }}
          >
            <p>User</p>
          </Flex>
        </Dropdown>
        <div className="search-input">
          <CiSearch size={20} />
          <input
            className="input"
            placeholder="Tìm kiếm"
            onChange={(v) => applyChange("InputSearch", v.target.value)}
          />
        </div>
      </Flex>
      <div className="inboxs">
        {loading && <Skeleton active />}
        {_.orderBy(data, ["date_send"], ["desc"]).map((item) => {
          return (
            <Flex
              className={
                item?.id === refInbox?.current?.id
                  ? "ripple-container-active"
                  : "ripple-container"
              }
              align="center"
              gap={10}
              key={item.id}
              onClick={() => onClickDetail(item)}
            >
              <div
                className="avatar"
                style={{
                  background: GetRandomColor(item.id),
                }}
              >
                {item?.name_chat?.charAt(0)}
              </div>
              <div style={{ width: "85%" }}>
                <Flex justify="space-between" style={{ width: "100%" }}>
                  <p style={{ fontWeight: item?.isread ? "400" : "bold" }}>
                    {item.name_chat}
                  </p>
                  <p>{calculateMessageTime(item.date_send)}</p>
                </Flex>
                <Flex justify="space-between">
                  <p
                    style={{
                      color:
                        item?.id === refInbox?.current?.id
                          ? "white"
                          : "rgb(142,142,146)",
                    }}
                  >
                    {item.last_sent_user_id ===
                      Number(
                        JSON.parse(localStorage.getItem("NhanVien"))?.maNV
                      ) && <span>Bạn: </span>}
                    {truncate(item.last_message, 30)}
                  </p>
                  {!item?.isread && (
                    <Tag color="green" style={{ height: "23px" }}>
                      +1
                    </Tag>
                  )}
                </Flex>
              </div>
            </Flex>
          );
        })}
      </div>
      {renderModal()}
    </>
  );
};
export default React.memo(BoxLeft);
