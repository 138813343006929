import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  ColorPicker,
  Flex,
  Checkbox,
} from "antd";
import _ from "lodash";
import { ToaNhaService } from "services/ToaNhaService";
import InputCustom from "components/Forms/InputCustom";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import { CategoryService } from "services/CategoryService";
import { SaveOutlined } from "@ant-design/icons";

const FormAdd = (props, ref) => {
  const { onAfterSubmit, maTN } = props;
  const [api, contextHolder] = notification.useNotification();
  const defaulData = {
    isView: false,
    SLThang: false,
    SLTuan: false,
  };

  const [data, setData] = useState(defaulData);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = {
    ThanhTien: null,
  };
  const [errors, setErrors] = useState(defaultErrors);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
    },
  }));

  const onClose = () => {
    setOpen(false);
    setData(defaulData);
   
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);

    switch (p) {
      case "isView":
        _data[p] = v;
        break;

      case "SLTuan":
        _data.Type = "WEEK";
        _data.SLThang = !v;
        _data[p] = v;
        break;
      case "SLThang":
        _data.Type = "MONTH";
        _data.SLTuan = !v;
        _data[p] = v;
        break;
      default:
        _data[p] = v;
        break;
    }

    setData(_data);
  };

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    // let valid = validate();
    // if (valid?.length > 0) {
    //   setLoading(false);
    //   return;
    // }
    let _res = await CategoryService.KhachHang.TienIch.TenTienIch.add({
      ..._data,
      MaTN: maTN,
    });
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} Tiện ích thành công!`
      );
      setIsUpdate(true);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const renderThongTinChung = () => {
    return (
      <>
        <Row style={{ marginTop: "10px" }} gutter={[20, 12]}>
          <Col span={24}>
            <InputCustom
              label="Tên tiên ích:"
              applyChange={applyChange}
              prop="TenTienIch"
              value={data?.TenTienIch}
              placeholder="Nhập tên tiện ích"
            />
          </Col>
          <Col span={24}>
            <InputNumberCustom
              label="Tiền đặt cọc:"
              applyChange={applyChange}
              prop={"TienCoc"}
              value={data?.TienCoc}
              placeholder={"100"}
            />
          </Col>
          <Col span={24}>
            <Row>
              <Col span={16}>
                {" "}
                <InputNumberCustom
                  label="Số lần:"
                  applyChange={applyChange}
                  prop={"SoLan"}
                  value={data?.SoLan}
                  placeholder={"1"}
                />
              </Col>
              <Col span={8}>
                {" "}
                <Flex
                  align="center"
                  style={{ paddingLeft: "15px", paddingTop: "5px" }}
                >
                  <Flex align="center" style={{ paddingRight: "10px" }}>
                    <p style={{ fontWeight: "600", paddingRight: "10px" }}>
                      Tuần:
                    </p>
                    <Checkbox
                      checked={data?.SLTuan}
                      onChange={(e) => applyChange("SLTuan", e.target.checked)}
                    />
                  </Flex>
                  <Flex align="center">
                    <p style={{ fontWeight: "600", paddingRight: "10px" }}>
                      Tháng:
                    </p>
                    <Checkbox
                      checked={data?.SLThang}
                      onChange={(e) => applyChange("SLThang", e.target.checked)}
                    />
                  </Flex>
                </Flex>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <InputCustom
              label="Ghi chú:"
              applyChange={applyChange}
              prop="GhiChu"
              value={data?.GhiChu}
              placeholder="Nhập ghi chú"
            />
          </Col>
          <Col span={24}>
            <InputCustom
              label="Icon:"
              applyChange={applyChange}
              prop="Icon"
              value={data?.Icon}
              placeholder="Nhập icons"
            />
          </Col>
          <Col span={24}>
            <Flex align="center">
              <p style={{ fontWeight: "600", paddingRight: "10px" }}>isView:</p>
              <Checkbox
                checked={data?.isView}
                onChange={(e) => applyChange("isView", e.target.checked)}
              />
            </Flex>
          </Col>
        </Row>
      </>
    );
  };
  return (
    <Drawer
      title={isupdate ? "Sửa tiện ích" : "Thêm tiện ích"}
      width={720}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
         <SaveOutlined />   Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      {renderThongTinChung()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
