import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Select,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Checkbox,
  Flex,
} from "antd";
import _ from "lodash";
import { DatePicker } from "antd/lib";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import vi from "dayjs/locale/vi";
import { NhanVienService } from "services/NhanVienService";
import { CongTyService } from "services/CongTyService";
import { ToaNhaService } from "services/ToaNhaService";
import InputCustom from "components/Forms/InputCustom";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import SelectCustom from "components/Forms/SelectCustom";
import { SaveOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";
const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const defaultData = {
    NgaySinh: new Date(),
    NgayCap: new Date(),
    Lock: false,
  };
  const [data, setData] = useState(defaultData);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [toaNha, setToaNha] = useState([]);
  const [nhomQuyen, setNhomQuyen] = useState([]);
  const [quyDanh, setQuyDanh] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [chucvu, setChucvu] = useState([]);
  const [phongban, setPhongban] = useState([]);
  const [nhomkd, setNhomkd] = useState([]);
  const defaultErrors = {
    MaGD: null,
    MaKH: null,
    MaHinhThuc: null,
  };
  const [errors, setErrors] = useState(defaultErrors);

  const loadCategories = async () => {
    let _ct = await ToaNhaService.getToaNhaByNV();
    let _cv = await NhanVienService.Categories.getChucVu();
    setCompanies(_ct.data);
    setChucvu(_cv?.data ?? []);
  };
  const loadPhongBan = async (matn) => {
    let _pb = await NhanVienService.Categories.getPhongBan({ MaTN: matn });
    setPhongban(_pb?.data ?? []);
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      let _tn = await ToaNhaService.getToaNhaByNV();
      if (_tn?.data) setToaNha(_tn.data);
      setOpen(true);
      setIsUpdate(false);
      loadCategories();
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      loadPhongBan(data?.MaTN);
      loadCategories();
      data.NgaySinh = new Date(data.NgaySinh);
      data.NgayCap = new Date(data.NgayCap);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaultData);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MaTN":
        loadPhongBan(v);
        _data[p] = v;
        break;
      case "NgaySinh":
        const date = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString = date.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    // let valid = validate();
    // if (valid?.length > 0) {
    //   return;
    // }
    let _res = await NhanVienService.addNhanVien(_data);
    if (!_data.MaNV) {
      setData((pre) => {
        return { ...pre, MaNV: _res.data };
      });
    }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${_data.MaNV ? "Sửa" : "Thêm"} nhân viên thành công!`
      );
      setIsUpdate(true);
      if (close) onClose();
      onAfterSubmit();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  return (
    <Drawer
      title="Nhân viên"
      width={920}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined />
            Lưu & đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Row style={{ marginTop: "25px" }} gutter={[24, 20]}>
        <Col span={8}>
          <InputCustom
            label="Họ và tên *:"
            applyChange={applyChange}
            prop="HoTenNV"
            value={data?.HoTenNV}
            placeholder="Nhập họ và tên"
            errors={errors}
          />
        </Col>
        <Col span={8}>
          <InputCustom
            label="Mã số:"
            applyChange={applyChange}
            prop="MaSoNV"
            value={data?.MaSoNV}
            placeholder="Nhập mã số nhân viên"
            errors={errors}
          />
        </Col>
        {isUpdate === false && (
          <>
            <Col span={8}>
              <InputCustom
                label="Mật khẩu:"
                applyChange={applyChange}
                prop="MatKhau"
                value={data?.MatKhau}
                placeholder="Nhập mật khẩu "
              />
            </Col>
            <Col span={12}>
              <Flex>
                <p
                  style={{
                    color: "red",
                    fontWeight: "600",
                    width: "50%",
                    borderBottom: "1px solid #d9d9d9",
                    marginTop: "5px",
                  }}
                >
                  Xác nhận mật khẩu:
                </p>
                <Input
                  value={data?.RePassword}
                  status={data?.Password === data?.RePassword ? "" : "error"}
                  placeholder="Nhập lại mật khẩu"
                  onChange={(v) => applyChange("RePassword", v.target.value)}
                />
              </Flex>
            </Col>
          </>
        )}
        {/* <Col span={6}>
          <p>Ngày sinh</p>
          <DatePicker
            allowClear={false}
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            value={dayjs(data?.NgaySinh)}
            placeholder="Ngày sinh"
            locale={vi}
            onChange={(v) => applyChange("NgaySinh", v)}
          />
        </Col>
        <Col span={6}>
          <p>Số CMND</p>
          <Input
            value={data?.SoCMND}
            placeholder="Nhập số cmnd/cccd"
            onChange={(v) => applyChange("SoCMND", v.target.value)}
          />
        </Col>
        <Col span={12}>
          <p>Ngày cấp</p>
          <DatePicker
            allowClear={false}
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            value={dayjs(data?.NgayCap)}
            placeholder="Ngày cấp"
            locale={vi}
            onChange={(v) => applyChange("NgayCap", v)}
          />
        </Col>
        <Col span={12}>
          <p>Nơi cấp</p>
          <Input
            value={data?.NoiCap}
            placeholder="Nhập nơi cấp"
            onChange={(v) => applyChange("NoiCap", v.target.value)}
          />
        </Col> */}

        <Col span={isUpdate ? 8 : 12}>
          <DatePickerCustom
            label="Ngày sinh:"
            applyChange={applyChange}
            prop={"NgaySinh"}
            value={data?.NgaySinh}
            placeholder={"Chọn ngày sinh"}
          />
        </Col>
        <Col span={8}>
          <InputCustom
            label="Điện thoại:"
            applyChange={applyChange}
            prop="DienThoai"
            value={data?.DienThoai}
            placeholder="Nhập số điện thoại "
          />
        </Col>

        <Col span={8}>
          <InputCustom
            label="Email:"
            applyChange={applyChange}
            prop="Email"
            value={data?.Email}
            placeholder="Nhập email "
          />
        </Col>
        <Col span={8}>
          <InputCustom
            label="Địa chỉ:"
            applyChange={applyChange}
            prop="DiaChi"
            value={data?.DiaChi}
            placeholder="Nhập địa chỉ"
          />
        </Col>
        <Col span={8}>
          <SelectCustom
            label="Chức vụ:"
            applyChange={applyChange}
            prop="MaCV"
            value={data?.MaCV}
            placeholder="Chọn chúc vụ"
            options={chucvu}
            fieldNames={{ label: "TenCV", value: "MaCV" }}
            errors={errors}
          />
        </Col>
        <Col span={8}>
          <SelectCustom
            label="Tòa nhà:"
            applyChange={applyChange}
            prop="MaTN"
            value={data?.MaTN}
            placeholder="Chọn tòa nhà"
            options={toaNha}
            fieldNames={{ label: "TenTN", value: "MaTN" }}
            errors={errors}
          />
        </Col>
        {/* <Col span={12}>
          <p style={{ color: "red" }}>Quyền truy cập</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.PerID}
            placeholder="Chọn quyền truy cập"
            onChange={(v) => applyChange("PerID", v)}
            options={nhomQuyen}
            fieldNames={{ label: "PerName", value: "PerID" }}
          />
        </Col> */}
        <Col span={8}>
          <SelectCustom
            label="Phòng ban:"
            applyChange={applyChange}
            prop="MaPB"
            value={data?.MaPB}
            placeholder="Chọn phòng ban"
            options={phongban}
            fieldNames={{ label: "TenPB", value: "MaPB" }}
            errors={errors}
          />
        </Col>

        {/* <Col span={12}>
          <p>Nhóm kinh doanh</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaNKD}
            placeholder="Chọn nhóm kinh doanh"
            onChange={(v) => applyChange("MaNKD", v)}
            options={nhomkd}
            fieldNames={{ label: "Name", value: "ID" }}
          />
        </Col> */}
        <Col span={4}>
          <Checkbox
            checked={data?.IsLocked === true}
            onChange={() => applyChange("IsLocked", !data?.IsLocked)}
          >
            Khoá tài khoản
          </Checkbox>
        </Col>
        <Col span={4}>
          <Checkbox
            checked={data?.IsChatCudan === true}
            onChange={() => applyChange("IsChatCudan", !data?.IsChatCudan)}
          >
            Chat cư dân
          </Checkbox>
        </Col>
        <Col span={4}>
          <Checkbox
            checked={data?.IsSuperAdmin === true}
            onChange={() => applyChange("IsSuperAdmin", !data?.IsSuperAdmin)}
          >
            Admin
          </Checkbox>
        </Col>
        <Col span={4}>
          <Checkbox
            checked={data?.noti_TienIch === true}
            onChange={() => applyChange("noti_TienIch", !data?.noti_TienIch)}
          >
            Noti tiện ích
          </Checkbox>
        </Col>
        <Col span={6}>
          <Checkbox
            checked={data?.noti_ChuyenDo === true}
            onChange={() => applyChange("noti_ChuyenDo", !data?.noti_ChuyenDo)}
          >
            Noti chuyển đồ
          </Checkbox>
        </Col>
      </Row>
    </Drawer>
  );
};
export default forwardRef(FormAdd);
