import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  ColorPicker,
  Flex,
} from "antd";
import _ from "lodash";
import { ToaNhaService } from "services/ToaNhaService";
import InputCustom from "components/Forms/InputCustom";
import { CategoryService } from "services/CategoryService";
import { SaveOutlined } from "@ant-design/icons";

const FormAdd = (props, ref) => {
  const { onAfterSubmit, filterCondition } = props;
  const [api, contextHolder] = notification.useNotification();
  const defaulData = {};
  const [data, setData] = useState(defaulData);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = {
    ThanhTien: null,
  };
  const [errors, setErrors] = useState(defaultErrors);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);

  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "TenBan":
        _data.TenBan = v;
        _data.MaTenTienIch = filterCondition.MaTenTI;
        _data.MaTN = filterCondition.MaTN;
        break;
      default:
        _data[p] = v;
        break;
    }

    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    // let valid = validate();
    // if (valid?.length > 0) {
    //   setLoading(false);
    //   return;
    // }
    let _res = await CategoryService.KhachHang.TienIch.khuBan.add(_data);
    if (!_data?.MaTN) {
      setData((pre) => {
        return { ...pre, MaTN: _res.data };
      });
    }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} phiếu thu thành công!`
      );
      setIsUpdate(true);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const renderThongTinChung = () => {
    return (
      <>
        <Row style={{ marginTop: "20px" }} gutter={[20, 12]}>
          <Col span={24}>
            <InputCustom
              label="Tên bàn:"
              applyChange={applyChange}
              prop="TenBan"
              value={data?.TenBan}
              placeholder="Nhập tên bàn"
            />
          </Col>
        </Row>
      </>
    );
  };
  return (
    <Drawer
      title={isupdate ? "Sửa khu/ban/vị trí" : "Thêm khu/ban/vị trí"}
      width={720}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
           <SaveOutlined /> Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      {renderThongTinChung()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
