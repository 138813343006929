import axiosApi from "./api_helper";
import axios from "axios";
const API_URL = "https://api-beesky.onrender.com";
// const API_URL = 'http://localhost:3000';
const axiosApiChat = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
export const ChatsService = {
  getInbox: async (payload) => {
    return await axiosApi()
      .post("api/chats/inbox", payload)
      .then((res) => res.data);
  },
  // getInbox: async (payload) => {
  //   return await axiosApiChat
  //     .get("/chats/inbox", {
  //       params: payload,
  //     })
  //     .then((res) => res.data);
  // },
  getMessages: async (payload) => {
    return await axiosApiChat
      .get("/chats/messages/" + payload)
      .then((res) => res.data);
  },
  getMemberGroup: async (payload) => {
    return await axiosApiChat
      .get("/chats/participants/member", {
        params: {
          inbox_id: payload,
        },
      })
      .then((res) => res.data);
  },
  sendMessage: async (payload) => {
    return await axiosApiChat
      .post("/chats/messages", payload)
      .then((res) => res.data);
  },
  readMessage: async (payload) => {
    return await axiosApiChat
      .put("/chats/messages/read", payload)
      .then((res) => res.data);
  },
  User: {
    getById: async (payload) => {
      return await axiosApiChat
        .get("/users/get-by-id", {
          params: { user_id: payload },
        })
        .then((res) => res.data);
    },
  },
  MatBang: {
    getById: async (payload) => {
      return await axiosApiChat
        .get("/mat-bang/get-by-id", {
          params: { ma_mb: payload },
        })
        .then((res) => res.data);
    },
  },
};
