import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Radio,
  Checkbox,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { DatePicker } from "antd/lib";
import { ToaNhaService } from "services/ToaNhaService";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import SelectCustom from "components/Forms/SelectCustom";
import InputCustom from "components/Forms/InputCustom";
import { CategoryService } from "services/CategoryService";
import { SaveOutlined } from "@ant-design/icons";

const { TextArea } = Input;

const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const defaulData = {
    NgayThu: new Date(),
  };
  const [data, setData] = useState(defaulData);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = {};
  const [errors, setErrors] = useState(defaultErrors);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
    },
  }));

  const onClose = () => {
    setOpen(false);
    setData(defaulData);
 
  };

  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    _data[p] = v;
    setData(_data);
  };

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };

  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    let _res = await CategoryService.DichVu.Khac.add(_data);
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} định mức điện thành công!`
      );
      setIsUpdate(true);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const renderThongTinChung = () => {
    return (
      <>
        <Row style={{ marginTop: "10px" }} gutter={[20, 12]}>
          <Col span={12}>
            <InputCustom
              label="Tên loại dịch vụ:"
              applyChange={applyChange}
              prop="TenLDV"
              value={data?.TenLDV}
              placeholder="Nhập tên loại dịch vụ"
            />
          </Col>
          <Col span={12}>
            <InputNumberCustom
              label="Phí dịch vụ:"
              applyChange={applyChange}
              prop={"PhiDV"}
              value={data?.PhiDV}
              placeholder="0"
            />
          </Col>

          <Col span={12}>
            <InputNumberCustom
              label="Tỷ lệ VAT:"
              applyChange={applyChange}
              prop={"TyLeVAT"}
              value={data?.TyLeVAT}
              placeholder="0"
              max={100}
              errors={errors}
            />
          </Col>
          <Col span={12}>
            <Checkbox
              value={data?.ThuHo}
              onChange={(e) => applyChange("ThuHo", e.target.value)}
            >
              Thu hộ{" "}
            </Checkbox>
            <Checkbox
              value={data.TinhTruocThang}
              onChange={(e) => applyChange("TinhTruocThang", e.target.value)}
            >
              Tính trước tháng
            </Checkbox>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Drawer
      title="Tên dịch vụ khác"
      width={920}
      onClose={onClose}
      open={open}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
          <SaveOutlined />  Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      {renderThongTinChung()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
