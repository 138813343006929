import axiosApi from "./api_helper";

export const DashboardService = {
  getYC: async (payload) => {
    return await axiosApi()
      .post("api/admin/report/yeucau-bymonth", payload)
      .then((res) => res.data);
  },

  getThongTinDashboard: async (payload) => {
    return await axiosApi()
      .post("api/admin/report/get-total-bymonth", payload)
      .then((res) => res.data);
  },

  getPLYC: async (payload) => {
    return await axiosApi()
      .post("api/yeu-cau/category/get-loaiyeucau-bymatn", payload)
      .then((res) => res.data);
  },
  getTheXe: async (payload) => {
    return await axiosApi()
      .post("api/admin/report/thexe-byyear", payload)
      .then((res) => res.data);
  },
  getNuoc: async (payload) => {
    return await axiosApi()
      .post("api/admin/report/nuoc-byyear", payload)
      .then((res) => res.data);
  },
  getDien: async (payload) => {
    return await axiosApi()
      .post("api/admin/report/dien-byyear", payload)
      .then((res) => res.data);
  },
};
