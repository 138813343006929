import { ConfigProvider, DatePicker, Flex, Input } from "antd";
import dayjs from "dayjs";
import vi from "dayjs/locale/vi";
import React, { useEffect, useState } from "react";
import { CiCalendarDate } from "react-icons/ci";
import viVN from "antd/es/locale/vi_VN";

const DatePickerCustomFilterYear = ({
  label,
  applyChange,
  prop,
  value,
  disabled,
  required,
}) => {
  const [open, setOpen] = useState(false);
  const [valueDefault, setValueDefault] = useState(
    value ? dayjs(value).format("MM-YYYY") : null
  );
  const [dateValue, setDateValue] = useState(null);
  const [err, setErr] = useState("");
  useEffect(() => {
    if (value) {
      setValueDefault(dayjs(value).format("MM-YYYY"));
      setDateValue(dayjs(value));
    } else {
      setDateValue(null);
      setValueDefault(null);
    }
  }, [value]);

  const isValidMonthYear = (month, year) => {
    const monthInt = parseInt(month, 10);
    return (
      monthInt >= 1 &&
      monthInt <= 12 &&
      year.length === 4 &&
      dayjs(`${year}-${month}`, "YYYY-MM").isValid()
    );
  };

  const handleInputChange = (e) => {
    const start = e.target.selectionStart;
    const end = e.target.selectionEnd;
    const inputValue = e.target.value;
    if (/^[0-9-]*$/.test(inputValue)) {
      let formattedValue = "";
      const sanitizedInput = inputValue.replace(/-/g, "");
      if (sanitizedInput.length > 0) {
        formattedValue += sanitizedInput.slice(0, 2); // Month
      }
      if (sanitizedInput.length > 2) {
        formattedValue += `-${sanitizedInput.slice(2, 6)}`; // Year
      }
      setValueDefault(formattedValue);

      const dateParts = formattedValue.split("-");
      const [month, year] = dateParts;

      if (dateParts.length === 2 && year.length === 4) {
        if (isValidMonthYear(month, year)) {
          const fullDate = dayjs(`${year}-${month}-01`, "YYYY-MM-DD");
          setDateValue(fullDate);
          setErr("");
          typeof applyChange === "function" && applyChange(prop, fullDate);
        } else {
          setErr("Sai định dạng MM-YYYY");
        }
      } else if (inputValue === "") {
        setDateValue(null);
        setErr("");
      }
    } else {
      e.preventDefault();
    }
    setTimeout(() => {
      e.target.selectionStart = start;
      e.target.selectionEnd = end;
    }, 0);
  };

  const handleDateChange = (date) => {
    const formattedDate = date ? dayjs(date).format("MM-YYYY") : "";
    setValueDefault(formattedDate);
    setDateValue(date);
    typeof applyChange === "function" && applyChange(prop, date);
    setOpen(false);
  };

  const openDatePicker = () => {
    setOpen(true);
  };

  return (
    <div>
      <div className={required ? "border-required" : "container-datePick2"}>
        <p style={{ fontWeight: "600" }}>
          {label}
          <span style={{ color: "red" }}> {required ? "*" : ""}</span>
        </p>
        <Flex align="center">
          <div style={{ position: "relative", width: "100%" }}>
            <Input
              value={valueDefault}
              onChange={handleInputChange}
              onClick={openDatePicker}
              placeholder="MM-YYYY"
              onBlur={() => setOpen(false)}
              style={{ paddingRight: "30px" }}
              disabled={disabled}
              allowClear={false}
            />
            <CiCalendarDate
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
              onClick={openDatePicker}
            />
          </div>
          <ConfigProvider locale={viVN}>
            <DatePicker
              picker="month"
              allowClear={false}
              open={open}
              style={{ width: 0 }} // Hide DatePicker
              onChange={handleDateChange}
              format="MM-YYYY"
              value={dateValue}
              locale={vi}
            />
          </ConfigProvider>
        </Flex>
        <p style={{ color: "red" }}>{err}</p>
      </div>
    </div>
  );
};

export default DatePickerCustomFilterYear;
