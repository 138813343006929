import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Tabs,
  Checkbox,
  Flex,
  Modal,
} from "antd";
import _, { cloneDeep } from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { DatePicker } from "antd/lib";
import vi from "dayjs/locale/vi";
import { SoQuyService } from "services/SoQuyService";
import TabPane from "antd/es/tabs/TabPane";
import { ToaNhaService } from "services/ToaNhaService";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import InputCustom from "components/Forms/InputCustom";
import DebounceSelect from "components/DebounceSelect";
import SelectCustom from "components/Forms/SelectCustom";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import InputNumberCustom2 from "components/Forms/InputNumberCustom2";
import FormDrawerUser from "components/FormMatBang";
import { EditOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";
import { MatBangService } from "services/MatBangService";
import { CategoryService } from "services/CategoryService";
import { DichVuService } from "services/DichVuService";
const { TextArea } = Input;

const FormAdd = (props, ref) => {
  const { onAfterSubmit, maTN, loaiXe } = props;
  const [api, contextHolder] = notification.useNotification();

  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");
  const firstDayOfMonth = dateInHoChiMinh.startOf("month");
  const lastDayOfMonth = dateInHoChiMinh.endOf("month");

  const dateInHoChiMinh2 = DateTime.now().setZone("Asia/Ho_Chi_Minh");
  const lastDayOfMonth2 = dateInHoChiMinh2.endOf("month");
  const currentDateTime2 = lastDayOfMonth2.set({
    hour: dateInHoChiMinh2.hour,
    minute: dateInHoChiMinh2.minute,
    second: dateInHoChiMinh2.second,
    millisecond: dateInHoChiMinh2.millisecond,
  });
  const defaulData = {
    NgayTT: currentDateTime2.toString(),
    NgayHetHan: firstDayOfMonth.toISO(),
    NgayDK: dateInHoChiMinh.toISO(),
    NgayTinhPhi: dateInHoChiMinh.toISO(),
    MaTrangThai: 2,
    PTVAT: 0,
  };

  const [data, setData] = useState(defaulData);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = {
    MaMB: null,
    MaLX: null,
  };

  const [errors, setErrors] = useState(defaultErrors);
  // const [loaiXe, setLoaiXe] = useState([]);
  const [trangThai, setTrangThai] = useState([]);
  const [quanHe, setQuanHe] = useState([]);
  const [chuThe, setChuThe] = useState([]);
  const [nhomThe, setNhomThe] = useState([]);
  const [theCSD, setTheCSD] = useState([]);
  const [isAdd, setIsAdd] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = (_data) => {
    _data.IsTrungBien = true;
    onSubmit();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getByMaTN = async () => {
    const _trangThai = await CategoryService.DichVu.Xe.TrangThai.get({
      MaTN: maTN,
    });
    if (_trangThai.data) setTrangThai(_trangThai.data);

    const quanHe = await CategoryService.KhachHang.CuDan.QuanHe.get();
    if (quanHe.data) setQuanHe(quanHe.data);

    const _nhomThe = await DichVuService.Xe.getNhomTheBaiXe({
      MaTN: maTN,
    });
    if (_nhomThe.data) {
      setNhomThe(_nhomThe.data);
    }

    const _res2 = await DichVuService.Xe.getTheChuaSuDung({
      MaTN: maTN,
    });
    if (_res2.data) {
      setTheCSD(_res2.data);
    }
  };
  const getNgayHH = async (valueDate) => {
    const _res = await DichVuService.Xe.getNgayHetHan({
      MaTN: maTN,
      NgayTinhPhi: valueDate ? valueDate : data?.NgayTinhPhi,
    });
    if (_res.data) {
      setData((pr) => ({ ...pr, NgayHetHan: _res.data?.ngayHH }));
    }
  };

  const filterPhiGiuiXe = async (maLX, maLMB, maMB, _data) => {
    let _res = await DichVuService.Xe.getPhiGiuiXe({
      MaMB: maMB,
      MaLMB: maLMB,
      MaLX: maLX,
      IsAdd: isAdd,
    });
    _data.PhiGuiXe = _res.data?.phiGuiXe ?? 0;
    _data.SoLuong = _res.data?.soLuong ?? 0;
  };

  const loadData = async (ID) => {
    const _res = await DichVuService.Xe.getByID({
      ID: ID,
    });
    let _data = _res.data ?? {};
    const _resMB = await DichVuService.Xe.getMBByID({
      MaMB: _res.data?.MaMB,
    });
    const _resCT = await DichVuService.Xe.getThongTinTheoMaMB({
      MaMB: _res.data?.MaMB,
      MaKH: _res.data?.MaKH,
    });

    // let _data = _res.data ?? {};

    _data.MatBang = {
      value: _resMB.data?.MaMB,
      label: _resMB.data?.MaSoMB,
    };
    _data.QuanHeCHID = _resCT.data?.chuTheChon[0]?.QuanHeID;
    _data.BienSoCu = _res.data?.BienSo;
    _data.CardNoCu = _res.data?.CardNo;
    _data.NgayTTCu = dayjs(_res.data?.NgayTT).format("DD/MM/YYYY");
    _data.NgayTinhPhiCu = dayjs(_res.data?.NgayTinhPhi).format("DD/MM/YYYY");

    setData(_data);
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      setDisabled(false);
      setOpen(true);
      setIsAdd(true);
      setIsUpdate(false);
      getByMaTN();
      getNgayHH();
    },
    update: async (data) => {
      setDisabled(true);
      setOpen(true);
      setIsAdd(false);
      setIsUpdate(true);
      getByMaTN();
      if (data.data === 14) {
        getNgayHH();
      }
      loadData(data?.ID);
    },
  }));

  const onClose = () => {
    setOpen(false);
    setData(defaulData);
    setErrors(defaultErrors);
    // onAfterSubmit();
  };
  const SumTongTien = (_data) => {
    _data.TienVAT = (_data.PTVAT * _data?.PhiGuiXe ?? 0) / 100;
    _data.TongTien = (_data.PhiGuiXe ?? 0) + _data.TienVAT;
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "NgayTinhPhi":
        const date = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString = date.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString;
        // _data.NgayTT = isoString;
        if (isAdd) {
          getNgayHH(isoString);
        }

        break;
      case "NgayDK":
        const date2 = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString2 = date2.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString2;
        break;
      case "NgayHetHan":
        const date3 = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString3 = date3.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString3;
        break;
      case "NgayTT":
        const date5 = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString5 = date5.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString5;
        // _data.NgayTinhPhi = isoString5;
        break;
      case "QuanHeCHID":
        const NameQHCH = quanHe.find((item) => item.ID === v);
        _data[p] = v;
        _data.QuanHeCH = NameQHCH?.Name;
        break;
      case "MaLX":
        await filterPhiGiuiXe(
          v,
          data?.MaLMB,
          data?.MaMB?.value ? data?.MaMB?.value : data?.MaMB,
          _data
        );
        _data[p] = v;
        const tenLX = loaiXe.find((item) => item.MaLX === v);
        _data.DinhMuc = `${tenLX?.TenLX} - Định mức`;
        SumTongTien(_data);

        break;
      case "MaKH":
        const NewChuThe = chuThe?.chuTheChon.find((item) => item.ID === v);
        _data[p] = v;
        _data.DienThoai = NewChuThe?.DienThoai;
        _data.QuanHeCHID = NewChuThe?.QuanHeID;
        _data.ChuThe = NewChuThe?.HoTenNK
          ? NewChuThe?.HoTenNK
          : chuThe?.chuTheNhap;
        break;
      case "MatBang":
        _data[p] = v;

        const valueMB = JSON.parse(v.title);
        await filterPhiGiuiXe(
          _data?.MaLX,
          valueMB?.MaLMB,
          valueMB?.MaMB,
          _data
        );
        const _res = await DichVuService.Xe.getThongTinTheoMaMB({
          MaMB: valueMB?.MaMB,
          MaKH: valueMB?.MaKH,
        });
        if (_res.data) {
          setChuThe(_res.data);
        }
        _data.MaMB = valueMB?.MaMB;
        _data.CusID = valueMB?.MaKH;
        _data.Phong = valueMB?.MaSoMB;
        _data.MaLMB = valueMB?.MaLMB;
        _data.DienThoai = _res.data?.chuTheChon[0]?.DienThoai;
        _data.QuanHeCHID = _res.data?.chuTheChon[0]?.QuanHeID;
        _data.ChuThe = _res.data?.chuTheChon[0]?.HoTenNK
          ? _res.data?.chuTheChon[0]?.HoTenNK
          : _res.data?.chuTheNhap;
        _data.MaKH = _res.data?.chuTheChon[0]?.ID;
        SumTongTien(_data);
        break;
      case "PhiGuiXe":
      case "PTVAT":
        _data[p] = v;
        SumTongTien(_data);
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    _data.MaTN = maTN;
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    let _res = await DichVuService.Xe.add(_data);
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} dịch vụ xe thành công!`
      );
      setIsUpdate(true);

      onAfterSubmit();
      onClose();
    } else if (_res.status === 2001) {
      showModal();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const tabs = [
    {
      title: "Thông tin",
      key: "0",
    },
    {
      title: "Cài đặt nâng cao",
      key: "1",
    },
  ];
  const [activeTab, setActiveTab] = useState("0");
  const onChange = (newActiveKey) => {
    setActiveTab(newActiveKey);
  };
  async function fetchMatBang(input) {
    return MatBangService.getMatBang({
      InputSearch: input,
      MaTN: maTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.MaSoMB}`,
        value: item.MaMB,
        title: JSON.stringify(item),
      }));
    });
  }

  const renderThongTinChung = () => {
    return (
      <>
        <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
          Thông tin thẻ
        </p>

        <Row gutter={[20, 16]}>
          <Col span={6}>
            <SelectCustom
              label="Chọn thẻ:"
              applyChange={applyChange}
              prop="CustomerID"
              value={data?.CustomerID}
              placeholder="Chọn thẻ"
              options={[]}
              //   fieldNames={{ label: "tenDA", value: "maDA" }}
            />
          </Col>
          <Col span={6}>
            <InputCustom
              disabled={disabled}
              label="Mã thẻ:"
              applyChange={applyChange}
              prop="SoThe"
              value={data?.SoThe}
              placeholder="Nhập di mã thẻ"
              required={true}
            />
          </Col>
          <Col span={6}>
            <SelectCustom
              required={true}
              label="Loại xe:"
              applyChange={applyChange}
              prop="MaLX"
              value={data?.MaLX}
              placeholder="Chọn loại xe"
              options={loaiXe}
              fieldNames={{ label: "TenLX", value: "MaLX" }}
            />
            {errors && (
              <p style={{ color: "red", textAlign: "center" }}>
                {errors && errors["MaLX"]}
              </p>
            )}
          </Col>
          <Col span={6}>
            <InputNumberCustom
              disabled={true}
              label="SL đăng kí:"
              applyChange={applyChange}
              prop={"SoLuong"}
              value={data?.SoLuong}
              placeholder={"0"}
            />
          </Col>

          <Col span={6}>
            <Flex align="center" style={{ borderBottom: "1px solid #ff9087" }}>
              <p
                style={{
                  fontWeight: "600",
                  width: "35% ",
                }}
              >
                Mặt bằng: <span style={{ color: "red" }}>*</span>
              </p>
              <div style={{ width: "65%" }}>
                <DebounceSelect
                  open={open}
                  disabled={disabled}
                  value={data?.MatBang}
                  placeholder="Tìm theo mã mặt bằng"
                  fetchOptions={fetchMatBang}
                  onChange={(v) => applyChange("MatBang", v)}
                  style={{
                    width: "100%",
                  }}
                />
              </div>
            </Flex>
            {errors && (
              <p style={{ color: "red", textAlign: "center" }}>
                {errors && errors["MaMB"]}
              </p>
            )}
          </Col>
          <Col span={6}>
            <InputCustom
              disabled={disabled}
              label="CardNo:"
              applyChange={applyChange}
              prop="CardNo"
              value={data?.CardNo}
              placeholder="Nhập cardNo"
              required={true}
            />
          </Col>
          <Col span={6}>
            <InputCustom
              label="Biển số:"
              applyChange={applyChange}
              prop="BienSo"
              value={data?.BienSo}
              placeholder="Nhập biển số xe"
            />
          </Col>
          <Col span={6}>
            <InputNumberCustom
              label="Tiền đặt cọc:"
              applyChange={applyChange}
              prop={"TienDatCoc"}
              value={data?.TienDatCoc}
              placeholder={"0"}
            />
          </Col>
          <Col span={6}>
            <SelectCustom
              label="Chủ thẻ(C):"
              applyChange={applyChange}
              prop="MaKH"
              value={data?.MaKH}
              placeholder="Chọn thẻ"
              options={chuThe?.chuTheChon}
              fieldNames={{ label: "HoTenNK", value: "ID" }}
            />
          </Col>
          <Col span={6}>
            <InputCustom
              label="Chủ thẻ(N):"
              applyChange={applyChange}
              prop="ChuThe"
              value={data?.ChuThe}
              placeholder="Nhập chủ thẻ (N)"
            />
          </Col>
          <Col span={6}>
            <InputCustom
              label="Màu xe:"
              applyChange={applyChange}
              prop="MauXe"
              value={data?.MauXe}
              placeholder="Nhập màu xe"
            />
          </Col>
          <Col span={6}>
            <InputNumberCustom
              label="Phí xe/tháng:"
              applyChange={applyChange}
              prop={"PhiGuiXe"}
              value={data?.PhiGuiXe}
              placeholder={"0"}
            />
          </Col>
          <Col span={6}>
            <SelectCustom
              label="Quan hệ CH:"
              applyChange={applyChange}
              prop="QuanHeCHID"
              value={data?.QuanHeCHID}
              placeholder="Chọn"
              options={quanHe}
              fieldNames={{ label: "Name", value: "ID" }}
            />
          </Col>
          <Col span={6}>
            <InputCustom
              label="Điện thoại:"
              applyChange={applyChange}
              prop={"DienThoai"}
              value={data?.DienThoai}
            />
          </Col>
          <Col span={6}>
            <InputCustom
              label="Nhãn hiệu:"
              applyChange={applyChange}
              prop="NhanHieu"
              value={data?.NhanHieu}
              placeholder="Nhập màu xe"
            />
          </Col>
          <Col span={6}>
            <InputNumberCustom
              max={100}
              label="% VAT:"
              applyChange={applyChange}
              prop={"PTVAT"}
              value={data?.PTVAT}
              placeholder="0"
            />
          </Col>
          <Col span={6}>
            <SelectCustom
              label="Nhóm thẻ:"
              applyChange={applyChange}
              prop="CardGroupID"
              value={data?.CardGroupID}
              placeholder="Chọn nhóm thẻ"
              options={nhomThe}
              fieldNames={{ label: "CardGroupName", value: "CardGroupID" }}
            />
          </Col>
          <Col span={6}>
            <InputCustom
              label="Phòng:"
              applyChange={applyChange}
              prop="Phong"
              value={data?.Phong}
              placeholder="Nhập số phòng"
            />
          </Col>
          <Col span={6}>
            <InputCustom
              label="Tên xe:"
              applyChange={applyChange}
              prop="TenXe"
              value={data?.TenXe}
              placeholder="Nhập tên xe"
            />
          </Col>
          <Col span={6}>
            <InputNumberCustom
              disabled={true}
              label="Tiền VAT:"
              applyChange={applyChange}
              prop={"TienVAT"}
              value={data?.TienVAT}
              placeholder="0"
            />
          </Col>

          {/* <Col span={6}>
            <SelectCustom
              disabled={true}
              label="Loại MB:"
              applyChange={applyChange}
              prop="MaLMB"
              value={data?.MaLMB}
              placeholder="Chọn loại mb"
              options={loaiMB}
              fieldNames={{ label: "TenLMB", value: "MaLMB" }}
            />
          </Col> */}
          <Col span={6}>
            <DatePickerCustom
              label="Ngày tính phí:"
              applyChange={applyChange}
              prop={"NgayTinhPhi"}
              value={data?.NgayTinhPhi}
              placeholder={""}
              required={true}
            />
          </Col>
          <Col span={6}>
            <DatePickerCustom
              label="Ngày đăng kí:"
              applyChange={applyChange}
              prop={"NgayDK"}
              value={data?.NgayDK}
              placeholder={""}
              required={true}
            />
          </Col>

          <Col span={6}>
            <DatePickerCustom
              label="Ngày HH:"
              applyChange={applyChange}
              prop={"NgayHetHan"}
              value={data?.NgayHetHan}
              placeholder={""}
              required={true}
            />
          </Col>
          <Col span={6}>
            <InputNumberCustom
              disabled={true}
              label="Tổng phí xe:"
              applyChange={applyChange}
              prop={"TongTien"}
              value={data?.TongTien}
              placeholder="0"
            />
          </Col>
          <Col span={6}>
            <DatePickerCustom
              label="Ngày TT:"
              applyChange={applyChange}
              prop={"NgayTT"}
              value={data?.NgayTT}
              placeholder={"Chọn ngày thanh toán"}
              required={true}
            />
          </Col>
          <Col span={6}>
            <SelectCustom
              disabled={true}
              label="Trạng thái:"
              applyChange={applyChange}
              prop="MaTrangThai"
              value={data?.MaTrangThai}
              placeholder="Chọn"
              options={trangThai}
              fieldNames={{ label: "TenTrangThai", value: "MaTrangThai" }}
              required={true}
            />
          </Col>
          {/* <Col span={6}>
            <SelectCustom
              label="Nhân viên:"
              applyChange={applyChange}
              prop="NhanVien"
              value={data?.NhanVien}
              placeholder="Chọn nhân viên"
              options={[]}
              //   fieldNames={{ label: "tenDA", value: "maDA" }}
            />
          </Col> */}

          <Col span={24}>
            <p style={{ fontWeight: "600", width: "13%" }}>Diễn giải:</p>
            <TextArea
              value={data?.DienGiai}
              onChange={(e) => applyChange("DienGiai", e.target.value)}
            />
            {/* </Flex> */}
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Drawer
      title="Thẻ xe"
      width={1280}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined />
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      <Modal
        title="Thông báo !"
        open={isModalOpen}
        // onOk={() => handleOk(data)}
        // onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Hủy
          </Button>,
          <Button
            key="ok"
            type="primary"
            loading={loading}
            onClick={() => handleOk(data)}
          >
            Tiếp tục
          </Button>,
        ]}
      >
        <p style={{ textAlign: "center", margin: "25px 0" }}>
          Biển số bị trùng, bạn có muốn tiếp tục ?
        </p>
      </Modal>
      {/* <FormDrawerUser
        className="vh-100"
        isEdit={isEdit}
        isOpen={modal}
        toggle={toggle}
        detail={staffForm}
      /> */}
      {contextHolder}
      {/* <Tabs onChange={onChange} activeKey={activeTab}>
        {tabs?.map((item) => {
          return (
            <TabPane tab={item.title} key={item.key}>
              {activeTab === "0" ? renderThongTinChung() : null}
            </TabPane>
          );
        })}
      </Tabs> */}

      {renderThongTinChung()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
