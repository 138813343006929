import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Flex,
  Table,
} from "antd";
import _ from "lodash";
import { NhanVienService } from "services/NhanVienService";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import { DateTime } from "luxon";
import TextArea from "antd/es/input/TextArea";
import { Format_Date } from "layouts/ultis/common";
import { MatBangService } from "services/MatBangService";
const FormAdd = (props, ref) => {
  const {
    onAfterSubmit,
    dataSelect,
    selectedRowKeys,
    filterCondition,
    setSelectedRowKeys,
  } = props;
  const [api, contextHolder] = notification.useNotification();
  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh").set({ day: 1 });
  const defaultData = {
    NgayTao: dateInHoChiMinh.toISO(),
  };
  const [data, setData] = useState(defaultData);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [onOffTable, setOnOffTable] = useState(false);
  const defaultErrors = {};
  const [errors, setErrors] = useState(defaultErrors);

  useImperativeHandle(ref, () => ({
    show: async (item) => {
      setOpen(true);
      if (item === "1") {
        setOnOffTable(false);
      } else {
        setOnOffTable(true);
      }
    },
    update: async (data) => {
      setOpen(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaultData);
    setLoading(false);
    setErrors(defaultErrors);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "NgayTao":
        const date = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString = date.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    let _res;
    if (onOffTable) {
      _res = await MatBangService.addCongNo({
        ..._data,
        MaTN: filterCondition.MaTN,
        MatBang: selectedRowKeys,
      });
    } else {
      _res = await MatBangService.addCongNoByTN({
        ..._data,
        MaTN: filterCondition.MaTN,
      });
    }

    setLoading(false);
    if (_res.status === 2000) {
      setSelectedRowKeys([]);
      openNotification(
        "success",
        "topRight",
        `${_data.MaNV ? "Sửa" : "Tạo"} công nợ thành công!`
      );
      if (close) onClose();
      onAfterSubmit();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const columns = [
    {
      title: "Mã số MB",
      dataIndex: "MaSoMB",
      key: "MaSoMB",
    },
    {
      title: "Tên khách Hàng",
      dataIndex: "TenKH",
      key: "TenKH",
    },
    {
      title: "Phí quản lý",
      dataIndex: "PhiQuanLy",
      key: "PhiQuanLy",
    },
    {
      title: "Ngày thanh toán",
      dataIndex: "NgayTTPQL",
      key: "NgayTTPQL",
      render: (item) => (item ? Format_Date(item) : null),
    },
  ];

  return (
    <Drawer
      title="Tạo công nợ"
      width={820}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Row
        style={{ marginTop: "25px", marginBottom: "20px" }}
        gutter={[24, 20]}
      >
        <Col span={24}>
          <DatePickerCustom
            label="Ngày nhập:"
            applyChange={applyChange}
            prop={"NgayTao"}
            value={data?.NgayTao}
            placeholder={"Chọn ngày tao"}
          />
        </Col>

        <Col span={24}>
          <Flex>
            <p
              style={{
                fontWeight: "600",
                width: "20%",
                borderBottom: "1px solid #d9d9d9",
              }}
            >
              Diễn giải:
            </p>
            <TextArea
              value={data?.DienGiai}
              onChange={(e) => applyChange("DienGiai", e.target.value)}
            />
          </Flex>
          {errors && (
            <p style={{ textAlign: "center", color: "red" }}>
              {errors && errors["DienGiai"]}
            </p>
          )}
        </Col>
      </Row>
      {onOffTable && <Table dataSource={dataSelect} columns={columns} />}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
