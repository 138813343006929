import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Row,
  Col,
  Drawer,
  notification,
  Tag,
  Flex,
  Image,
  Table,
  Button,
} from "antd";
import _ from "lodash";
import { YeuCauService } from "services/YeuCauService";
import FormDetail from "./FormDetail";
const FormView = (props, ref) => {
  const refDetail = useRef(null);
  const { onAfterSubmit, options } = props;
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [nhatky, setNhatky] = useState([]);
  const [dataHistory, setDataHistory] = useState([]);
  const loadChamSoc = async (mayc) => {
    let _res = await YeuCauService.getImages({ ID: mayc });
    setImages(_res.data ?? []);
    let _resData = await YeuCauService.getYCById({ ID: mayc });
    setData(_resData.data ?? []);
    let _resPH = await YeuCauService.getPhanHoi({ ID: mayc });
    setNhatky(_resPH.data ?? []);
  };

  useImperativeHandle(ref, () => ({
    show: async (maYC) => {
      setOpen(true);
      loadChamSoc(maYC);
      // setDataHistory(item);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const afterOnSubmit =async ()=>{
    let _resPH = await YeuCauService.getPhanHoi({ ID: data?.ID });
    setNhatky(_resPH.data ?? []);
  }
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let _res = await YeuCauService.addPhanHoi(_data);
    setLoading(false);
    if (_res.status === 2000) {
      setData({ YeuCauID: _data.YeuCauID });
      // loadChamSoc(_data.YeuCauID);
      onAfterSubmit();
      openNotification("success", "topRight", `Thêm lịch sử thành công!`);
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const columns = [
    {
      title: "Ngày xử lý",
      dataIndex: "ngayXL",
      // render: (item) => Format_Datetime(item),
    },
    {
      title: "Trạng thái",
      dataIndex: "tenTT",
      // render: (item) => options?.TrangThai?.find((v) => v.MaTT === item)?.TenTT,
    },
    {
      title: "Ghi chú",
      dataIndex: "noiDung",
    },
    {
      title: "Nhân viên XL",
      dataIndex: "hoTenNV",
      // render: (item) =>
      //   options?.NhanVien?.find((v) => v.MaNV === item)?.HoTenNV,
      // width: "150px",
    },
  ];

  return (
    <>
      <Drawer
        title="Chi tiết"
        width={920}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Flex>
            <p style={{ fontSize: 14, fontWeight: "bold", marginRight: 20 }}>
              {options?.ToaNha?.find((v) => v.MaTN === data?.MaTN)?.TenTN}
            </p>
            {/* <Tag
            color={
              options?.TrangThai?.find((v) => v.MaTT === data?.MaTT)?.Color
            }
          >
            {options?.TrangThai?.find((v) => v.MaTT === data?.MaTT)?.TenTT}
          </Tag> */}
            {/* <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & đóng
          </Button> */}
          </Flex>
        }
      >
        {contextHolder}
        <Row gutter={[24, 5]}>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Mã yêu cầu</p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {data?.MaYC}
            </p>
          </Col>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Mặt bằng</p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {data?.MaSoMB}
            </p>
          </Col>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Tiêu đề</p>
          </Col>
          <Col span={20} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {data?.TieuDe}
            </p>
          </Col>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Nội dung</p>
          </Col>
          <Col span={20} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {data?.NoiDung}
            </p>
          </Col>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Nhân viên xử lý</p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {data?.HoTenNV}
            </p>
          </Col>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Phòng ban</p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {data?.TenPB}
            </p>
          </Col>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Người yêu cầu</p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {data?.NguoiGui}
            </p>
          </Col>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Di động</p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {data?.SoDienThoaiNguoiGui}
            </p>
          </Col>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Trạng thái xử lý</p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              <Tag color={data?.Color}>{data?.TenTT}</Tag>
            </p>
          </Col>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Loại yêu cầu</p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {data?.TenLYC}
            </p>
          </Col>
          <Col style={{ margin: "20px 0" }} span={24}>
            <Image.PreviewGroup
              preview={{
                onChange: (current, prev) =>
                  console.log(`current index: ${current}, prev index: ${prev}`),
              }}
            >
              {images?.map((item) => (
                <Image
                  // width={140}
                  style={{ paddingRight: 10, height: "100px", width: "auto" }}
                  src={item.DuongDan}
                />
              ))}
            </Image.PreviewGroup>
          </Col>
          <Col span={24}>
            {/* <Divider orientation="left">Lịch sử</Divider> */}
            <Flex
              style={{ paddingBottom: "10px" }}
              align="center"
              justify="space-between"
            >
              <p
                style={{
                  fontWeight: "bold",
                  marginLeft: "-11px",
                  // paddingBottom: "20px",
                }}
              >
                Lịch sử xử lý
              </p>
              <Button onClick={() => refDetail.current.show(data)}>
                Xử lý
              </Button>
            </Flex>
            <Table
              style={{ height: 100 }}
              loading={loading}
              dataSource={nhatky}
              columns={columns}
              size="small"
            />
          </Col>
        </Row>
      </Drawer>

      <FormDetail
        ref={refDetail}
        onAfterSubmit={afterOnSubmit}
        options={{
          NhanVien: options?.NhanVien,
          TrangThai: options?.TrangThai,
          onOffHistory: true,
        }}
      />
    </>
  );
};
export default forwardRef(FormView);
