import React, { useEffect, useState } from "react";
import { Checkbox, Col, DatePicker, Flex, Popover, Row } from "antd";
import _ from "lodash";
import { FaSort } from "react-icons/fa6";
import dayjs from "dayjs";
import { DateTime } from "luxon";
import DatePickerCustomFilter from "./Forms/DatePickerCustomFilter";
// import { useClickAway } from "react-use";
const { RangePicker } = DatePicker;

function FilterDate({ ...props }) {
  const { onChangeDate, setActiveProp, defaultDateProp, defaultDateXe } = props;
  const [open, setOpen] = useState(false);
  const tuNgay = new Date();
  const denNgay = new Date();
  const defaultDate = {
    Name: "Hôm nay",
    TuNgay: new Date(),
    DenNgay: new Date(),
  };
  const [active, setActive] = useState({
    Type: setActiveProp ? 2 : 1,
    Date: defaultDateProp
      ? defaultDateProp
      : defaultDateXe
      ? defaultDateXe
      : defaultDate,
  });

  const applyChangeFilter = (p, v) => {
    let _active = _.cloneDeep(active);
    switch (p) {
      case "Check":
        _active.Type = 1;
        _active.Date = defaultDate;
        break;
      case "CheckOther":
        _active.Type = 2;
        // _active.Date.TuNgay = new Date(tuNgay);
        // _active.Date.DenNgay = new Date(denNgay);
        break;
      case "TuNgay":
        _active.Type = 2;
        _active.Date.TuNgay = new Date(v);
        break;
      case "DenNgay":
        _active.Type = 2;
        _active.Date.DenNgay = new Date(v);
        break;
      case "HomNay":
        _active.Type = 1;
        _active.Date.Name = v;
        _active.Date.TuNgay = new Date();
        _active.Date.DenNgay = new Date();
        break;
      case "HomQua":
        _active.Type = 1;
        _active.Date.Name = v;
        _active.Date.TuNgay = new Date(
          new Date().setDate(new Date().getDate() - 1)
        );
        _active.Date.DenNgay = new Date(
          new Date().setDate(new Date().getDate() - 1)
        );
        break;
      case "TuanNay":
        _active.Type = 1;
        _active.Date.Name = v;
        var curr = new Date(); // get current date
        var first = curr.getDate() - curr.getDay() + 1; // First day is the day of the month - the day of the week
        var last = first + 6; // last day is the first day + 6
        var firstday = new Date(curr.setDate(first));
        var lastday = new Date(curr.setDate(last));
        _active.Date.TuNgay = firstday;
        _active.Date.DenNgay = lastday;
        break;
      case "TuanTruoc":
        _active.Type = 1;
        _active.Date.Name = v;
        var d = new Date();
        var to = d.setTime(
          d.getTime() - (d.getDay() ? d.getDay() : 7) * 24 * 60 * 60 * 1000
        );
        var from = d.setTime(d.getTime() - 6 * 24 * 60 * 60 * 1000);
        _active.Date.TuNgay = new Date(from);
        _active.Date.DenNgay = new Date(to);
        break;
      case "7NgayQua":
        _active.Type = 1;
        _active.Date.Name = v;
        _active.Date.DenNgay = new Date();
        _active.Date.TuNgay = new Date(
          new Date().setDate(new Date().getDate() - 6)
        );
        break;
      case "ThangNay":
        _active.Type = 1;
        _active.Date.Name = v;
        var date = new Date(),
          y = date.getFullYear(),
          m = date.getMonth();
        var firstDay = new Date(y, m, 1);
        var lastDay = new Date(y, m + 1, 0);
        _active.Date.TuNgay = new Date(firstDay);
        _active.Date.DenNgay = new Date(lastDay);
        break;
      case "ThangTruoc":
        _active.Type = 1;
        _active.Date.Name = v;
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
        _active.Date.TuNgay = new Date(firstDay);
        _active.Date.DenNgay = new Date(lastDay);
        break;
      case "30NgayQua":
        _active.Type = 1;
        _active.Date.Name = v;
        _active.Date.TuNgay = new Date(
          new Date().setDate(new Date().getDate() - 29)
        );
        _active.Date.DenNgay = new Date();
        break;
      case "QuyNay":
        _active.Type = 1;
        _active.Date.Name = v;
        const today = new Date();
        const quarter = Math.floor(today.getMonth() / 3);
        const startFullQuarter = new Date(today.getFullYear(), quarter * 3, 1);
        const endFullQuarter = new Date(
          startFullQuarter.getFullYear(),
          startFullQuarter.getMonth() + 3,
          0
        );
        _active.Date.TuNgay = startFullQuarter;
        _active.Date.DenNgay = endFullQuarter;
        break;
      case "QuyTruoc":
        _active.Type = 1;
        _active.Date.Name = v;
        const today1 = new Date();
        const quarter1 = Math.floor(today1.getMonth() / 3);
        const startFullQuarter1 = new Date(
          today1.getFullYear(),
          quarter1 * 3 - 3,
          1
        );
        const endFullQuarter1 = new Date(
          startFullQuarter1.getFullYear(),
          startFullQuarter1.getMonth() + 3,
          0
        );
        _active.Date.TuNgay = startFullQuarter1;
        _active.Date.DenNgay = endFullQuarter1;
        break;
      case "NamNay":
        _active.Type = 1;
        _active.Date.Name = v;
        var currentDate = new Date();
        var theFirst = new Date(currentDate.getFullYear(), 0, 1);
        var theLast = new Date(currentDate.getFullYear(), 11, 31);
        _active.Date.TuNgay = theFirst;
        _active.Date.DenNgay = theLast;
        break;
      case "NamTruoc":
        _active.Type = 1;
        _active.Date.Name = v;
        var currentDate2 = new Date();
        var theFirst2 = new Date(currentDate2.getFullYear() - 1, 0, 1);
        var theLast2 = new Date(currentDate2.getFullYear() - 1, 11, 31);
        _active.Date.TuNgay = theFirst2;
        _active.Date.DenNgay = theLast2;
        break;
      default:
        break;
    }

    const date9 = DateTime.fromJSDate(_active.Date.TuNgay, { zone: "utc" });
    const isoString = date9.setZone("Asia/Ho_Chi_Minh").toISO();
    const date8 = DateTime.fromJSDate(_active.Date.DenNgay, { zone: "utc" });
    const isoString8 = date8.setZone("Asia/Ho_Chi_Minh").toISO();

    console.log(isoString8);
    console.log(isoString8);

    setOpen(false);
    setActive(_active);
    onChangeDate({
      TuNgay: isoString,
      DenNgay: isoString8 ? isoString8 : _active.Date.DenNgay,
    });
  };
  const content = (
    <Row style={{ width: 500, padding: 6 }} gutter={[10, 10]}>
      <Col span={4}>
        <p style={{ fontSize: 12, fontWeight: "bold", paddingBottom: 10 }}>
          Theo ngày
        </p>
        <p
          style={{ paddingBottom: 10, color: "#0070f4", cursor: "pointer" }}
          onClick={() => applyChangeFilter("HomNay", "Hôm nay")}
        >
          Hôm nay
        </p>
        <p
          style={{ color: "#0070f4", cursor: "pointer" }}
          onClick={() => applyChangeFilter("HomQua", "Hôm qua")}
        >
          Hôm qua
        </p>
      </Col>
      <Col span={5}>
        <p style={{ fontSize: 12, fontWeight: "bold", paddingBottom: 10 }}>
          Theo tuần
        </p>
        <p
          style={{ paddingBottom: 10, color: "#0070f4", cursor: "pointer" }}
          onClick={() => applyChangeFilter("TuanNay", "Tuần này")}
        >
          Tuần này
        </p>
        <p
          style={{ paddingBottom: 10, color: "#0070f4", cursor: "pointer" }}
          onClick={() => applyChangeFilter("TuanTruoc", "Tuần trước")}
        >
          Tuần trước
        </p>
        <p
          style={{ color: "#0070f4", cursor: "pointer" }}
          onClick={() => applyChangeFilter("7NgayQua", "7 Ngày qua")}
        >
          7 ngày qua
        </p>
      </Col>
      <Col span={5}>
        <p style={{ fontSize: 12, fontWeight: "bold", paddingBottom: 10 }}>
          Theo tháng
        </p>
        <p
          style={{ paddingBottom: 10, color: "#0070f4", cursor: "pointer" }}
          onClick={() => applyChangeFilter("ThangNay", "Tháng này")}
        >
          Tháng này
        </p>
        <p
          style={{ paddingBottom: 10, color: "#0070f4", cursor: "pointer" }}
          onClick={() => applyChangeFilter("ThangTruoc", "Tháng trước")}
        >
          Tháng trước
        </p>
        <p
          style={{ color: "#0070f4", cursor: "pointer" }}
          onClick={() => applyChangeFilter("30NgayQua", "30 ngày qua")}
        >
          30 ngày qua
        </p>
      </Col>
      <Col span={5}>
        <p style={{ fontSize: 12, fontWeight: "bold", paddingBottom: 10 }}>
          Theo quý
        </p>
        <p
          style={{ paddingBottom: 10, color: "#0070f4", cursor: "pointer" }}
          onClick={() => applyChangeFilter("QuyNay", "Quý này")}
        >
          Quý này
        </p>
        <p
          style={{ color: "#0070f4", cursor: "pointer" }}
          onClick={() => applyChangeFilter("QuyTruoc", "Quý trước")}
        >
          Quý trước
        </p>
      </Col>
      <Col span={5}>
        <p style={{ fontSize: 12, fontWeight: "bold", paddingBottom: 10 }}>
          Theo năm
        </p>
        <p
          style={{ paddingBottom: 10, color: "#0070f4", cursor: "pointer" }}
          onClick={() => applyChangeFilter("NamNay", "Năm nay")}
        >
          Năm này
        </p>
        <p
          style={{ color: "#0070f4", cursor: "pointer" }}
          onClick={() => applyChangeFilter("NamTruoc", "Năm trước")}
        >
          Năm trước
        </p>
      </Col>
    </Row>
  );

  return (
    <div className="box-search">
      {/* <p className="label">Thời gian</p> */}
      <Flex gap={10} align="center">
        <Checkbox
          style={{ borderRadius: "50%" }}
          checked={active?.Type === 1}
          onChange={(v) => applyChangeFilter("Check", v.target.checked)}
        />
        <Popover
          content={content}
          placement="right"
          trigger="click"
          open={open}
          onVisibleChange={setOpen}
        >
          <Flex
            style={{
              borderBottom: "1px solid #d9d9d9",
              width: "90%",
              padding: "3px 0px",
            }}
            align="center"
            justify="space-between"
          >
            <p>{active?.Date?.Name}</p>
            <FaSort color="#d9d9d9" />
          </Flex>
        </Popover>
      </Flex>
      <Flex gap={10} align="center">
        <Checkbox
          checked={active?.Type === 2}
          style={{ borderRadius: "50%" }}
          onChange={(v) => applyChangeFilter("CheckOther", v.target.checked)}
        />
        <p>Tuỳ chọn</p>
      </Flex>
      {active.Type === 2 && (
        <>
          {/* <DatePicker
            format="DD-MM-YYYY"
            style={{ width: "90%", marginLeft: "10%" }}
            value={dayjs(active.Date.TuNgay)}
            placeholder="Từ ngày"
            onChange={(v) => applyChangeFilter("TuNgay", v)}
            allowClear={false}
          /> */}
          <div style={{ marginLeft: "10px" }}>
            <DatePickerCustomFilter
              // label="Từ ngày:"
              applyChange={applyChangeFilter}
              prop={"TuNgay"}
              value={dayjs(active.Date.TuNgay)}
              placeholder={"Chọn ngày nhập"}
            />
          </div>
          

          {/* <DatePicker
            format="DD-MM-YYYY"
            style={{ width: "90%", marginLeft: "10%" }}
            placeholder="Đến ngày"
            value={dayjs(active.Date.DenNgay)}
            onChange={(v) => applyChangeFilter("DenNgay", v)}
            allowClear={false}
          /> */}
          <div style={{ marginLeft: "10px" }}>
            <DatePickerCustomFilter
              // label="Đến ngày:"
              applyChange={applyChangeFilter}
              prop={"DenNgay"}
              value={dayjs(active.Date.DenNgay)}
              placeholder={"Chọn ngày nhập"}
            />
          </div>
        </>
      )}
    </div>
  );
}
export default FilterDate;
