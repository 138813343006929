import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Tabs,
  Checkbox,
  Flex,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { DatePicker } from "antd/lib";
import vi from "dayjs/locale/vi";
import { SoQuyService } from "services/SoQuyService";
import TabPane from "antd/es/tabs/TabPane";
import { ToaNhaService } from "services/ToaNhaService";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import SelectCustom from "components/Forms/SelectCustom";
import InputCustom from "components/Forms/InputCustom";
import DebounceSelect from "components/DebounceSelect";
import FormDrawerUser from "components/FormMatBang";
import { EditOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { CategoryService } from "services/CategoryService";
const { TextArea } = Input;

const FormAdd = (props, ref) => {
  const { onAfterSubmit, toanha, filterCondition } = props;

  const [api, contextHolder] = notification.useNotification();
  const defaulData = {
    NgayThu: new Date(),
  };
  const [data, setData] = useState(defaulData);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const defaultErrors = {
    ThanhTien: null,
  };
  const [errors, setErrors] = useState(defaultErrors);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      setData((prevData) => ({ ...prevData, MaTN: filterCondition.MaTN }));
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);

  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    _data[p] = v;
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };

  const onSubmit = async (close) => {
    setLoading(true);
    // let valid = validate();

    // if (valid.length > 0) {
    //   setLoading(false);
    //   return;
    // }

    let _data = _.cloneDeep(data);
    let _res = await CategoryService.DichVu.Xe.KetNoiBaiXe.add(_data);
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} loại xe thành công!`
      );
      setIsUpdate(true);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const renderThongTinChung = () => {
    return (
      <>
        <Row style={{ marginTop: "10px" }} gutter={[20, 12]}>
          <Col span={24}>
            <SelectCustom
              disabled={true}
              label="Tòa nhà:"
              applyChange={applyChange}
              prop="MaTN"
              value={data?.MaTN}
              placeholder="Chọn tòa nhà"
              options={toanha}
              fieldNames={{ label: "TenTN", value: "MaTN" }}
              errors={errors}
            />
          </Col>
          <Col span={12}>
            <InputCustom
              label="Server:"
              applyChange={applyChange}
              prop="Server"
              value={data?.Server}
              placeholder="Nhập server"
            />
          </Col>
          <Col span={12}>
            <InputCustom
              label="Username:"
              applyChange={applyChange}
              prop="Username"
              value={data?.Username}
              placeholder="Nhập username"
            />
          </Col>
          <Col span={12}>
            <InputCustom
              label="Password:"
              applyChange={applyChange}
              prop="Password"
              value={data?.Password}
              placeholder="Nhập password"
            />
          </Col>
          <Col span={12}>
            <InputCustom
              label="Database MPARKING:"
              applyChange={applyChange}
              prop="Mparking"
              value={data?.Mparking}
              placeholder="Nhập database MPARKING"
            />
          </Col>
          <Col span={24}>
            <InputCustom
              label="Database MPARKINGEVENT:"
              applyChange={applyChange}
              prop="MparkingEvent"
              value={data?.MparkingEvent}
              placeholder="Nhập database MPARKINGEVENT"
            />
          </Col>
        </Row>
      </>
    );
  };
  return (
    <Drawer
      title={isupdate ? "Sửa kết nối bãi xe" : "Thêm kết nối bãi xe"}
      width={920}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined />Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      {renderThongTinChung()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
