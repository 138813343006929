import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Button,
  Popconfirm,
  notification,
  Tooltip,
  Badge,
  Row,
  Col,
  Flex,
  Tag,
  Input,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { CiTrash } from "react-icons/ci";
import Toolbar from "components/Toolbar";
import { MdEdit, MdOutlinePendingActions } from "react-icons/md";
import FormDetail from "./FormDetail";
import { YeuCauService } from "services/YeuCauService";
import { NhanVienService } from "services/NhanVienService";
import { ToaNhaService } from "services/ToaNhaService";
import FormView from "./FormView";
import { Format_Datetime } from "layouts/ultis/common";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { EllipsisWithTooltip } from "layouts/ultis/tooltip";
import { ExportOutlined, PlusOutlined } from "@ant-design/icons";
import { AiOutlineMenuFold } from "react-icons/ai";

export default function Index() {
  const [dataAll, setDataAll] = useState([]);
  const [data, setData] = useState([]);
  const [stateActive, setStateActive] = useState({
    MaTT: 0,
    TenTT: "Tất cả",
    Color: "gray",
  });
  const [api, contextHolder] = notification.useNotification();
  const refDetail = useRef(null);
  const refDetailView = useRef(null);
  const refAdd = useRef(null);
  const [loading, setLoading] = useState(false);
  const [filterCondition, setFilterCondition] = useState({
    MaTT: 0,
    MaBP: null,
    MaLYC: null,
    TuNgay: new Date(new Date().setDate(new Date().getDate() - 6)),
    DenNgay: new Date(),
    HinhThuc: ",,",
    DoUuTien: ",,",
    NhanVien: ",,",
    MaTN: null,
    InputSearch: "",
  });
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("filter") === "true"
  );
  const [mucdo, setMucdo] = useState([]);
  const [hinhthuc, setHinhthuc] = useState([]);
  const [nhanvien, setNhanvien] = useState([]);
  const [trangthai, setTrangthai] = useState([]);
  const [phongban, setPhongban] = useState([]);
  const [toanha, setToanha] = useState([]);

  useEffect(() => {
    loadCategories();
    // load(filterCondition);
  }, []);

  const load = async (_filter) => {
    setLoading(true);
    let _res = await YeuCauService.getYeuCau(_filter);
    let _tt = await YeuCauService.Categories.getTrangThai({
      MaTN: _filter.MaTN,
    });
    let status = [
      { MaTT: 0, TenTT: "Tất cả", Color: "#cccccc" },
      ..._tt.data?.filter((v) => v.isViewApp),
    ];
    status.map((item) => {
      if (item.MaTT === 0) {
        item.Total = _res.data?.length;
      } else {
        item.Total = _res?.data?.filter((v) => v.MaTT === item.MaTT)?.length;
      }
    });
    setTrangthai(status);
    setDataAll(_res.data);
    setData(_res.data);
    setLoading(false);
  };
  const loadCategories = async () => {
    let _ht = await YeuCauService.Categories.getHinhThuc();
    setHinhthuc(_ht.data);
    let _md = await YeuCauService.Categories.getMucDo();
    setMucdo(_md.data);

    let _tn = await ToaNhaService.getToaNhaByNV();
    setToanha(_tn.data);
    let _filter = _.cloneDeep(filterCondition);
    _filter.MaTN = _tn.data[0]?.MaTN;
    setFilterCondition(_filter);
    loadcategoriesByMaTN(_tn.data[0]?.MaTN);
    load(_filter);
  };
  const loadcategoriesByMaTN = async (matn) => {
    let _pb = await NhanVienService.Categories.getPhongBan({
      MaTN: matn,
    });
    setPhongban(_pb.data);
    let _nv = await NhanVienService.getNhanVienByMaTN({ MaTN: matn });
    setNhanvien(_nv.data);
  };
  const columns = [
    {
      title: "Mã yêu cầu",
      render: (item) => (
        <p
          style={{ fontWeight: "bold", color: "cadetblue", cursor: "pointer" }}
          onClick={() => refDetailView.current.show(item.ID)}
        >
          {item.MaYC}
        </p>
      ),
    },
    {
      title: "Trạng thái",
      dataIndex: "MaTT",
      ellipsis: true,
      render: (item) => (
        <Tag color={trangthai?.find((v) => v.MaTT === item)?.Color}>
          {trangthai?.find((v) => v.MaTT === item)?.TenTT}
        </Tag>
      ),
    },
    {
      title: "Mã mặt bằng",
      dataIndex: "MaSoMB",
      ellipsis: true,
    },
    {
      title: "Loại yêu cầu",
      dataIndex: "TenLYC",
      ellipsis: true,
    },
    {
      title: "Nội dung",
      dataIndex: "NoiDung",
      render: (text) => <EllipsisWithTooltip text={text} />,
    },
    {
      title: "Điện thoại",
      dataIndex: "SoDienThoaiNguoiGui",
      ellipsis: true,
    },
    {
      title: "Cư dân",
      dataIndex: "NguoiGui",
      ellipsis: true,
    },

    {
      title: "Tiêu đề",
      dataIndex: "TieuDe",
      ellipsis: true,
    },

    {
      title: "Độ ưu tiên",
      dataIndex: "MaDoUuTien",
      ellipsis: true,
      render: (item) => mucdo?.find((v) => v.MaDoUuTien === item)?.TenDoUuTien,
    },
    {
      title: "Ngày yêu cầu",
      dataIndex: "NgayYC",
      ellipsis: true,
      render: (item) => Format_Datetime(item),
    },
    {
      title: "Ngày hoàn thành",
      dataIndex: "NgayHoanThanh",
      ellipsis: true,
    },
    {
      title: "Nhân viên xử lý",
      dataIndex: "MaNTN",
      ellipsis: true,
      render: (item) => nhanvien?.find((v) => v.MaNV === item)?.HoTenNV,
    },
    {
      title: "Bộ phận",
      dataIndex: "MaBP",
      ellipsis: true,
      render: (item) => phongban?.find((v) => v.MaPB === item)?.TenPB,
    },
    {
      title: "",
      fixed: "right",
      render: (item) => {
        return (
          <Flex gap={10} justify="center">
            <Tooltip placement="topRight" title="Phản hồi">
              <Button
                type="primary"
                ghost
                icon={<MdOutlinePendingActions />}
                onClick={() => refDetail.current.show(item)}
              />
            </Tooltip>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refAdd.current.update(item)}
              />
            </Tooltip>
            <Popconfirm
              title="Xoá yêu cầu"
              description="Bạn có chắc chắn muốn xoá hay không?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item)}
            >
              <Button danger ghost icon={<CiTrash />} />
            </Popconfirm>
          </Flex>
        );
      },
    },
  ];
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const remove = async (item) => {
    let _res = await YeuCauService.deleteYeuCau({
      ID: item.ID,
    });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.ID !== Number(item.ID));
      setData(fil);
      openNotification("success", "topRight", "Xoá  thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const onAfterSubmit = () => {
    load(filterCondition);
  };
  const onChangeFilter = (_filter) => {
    load(_filter);
    setFilterCondition(_filter);
    loadcategoriesByMaTN(_filter.MaTN);
  };
  const changeState = (state) => {
    if (state === 0) {
      setData(dataAll);
    } else {
      let _data = dataAll.filter((v) => v.MaTT === state);
      setData(_data);
    }
  };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = () => {
    const fieldsToExport = columns.map((col) => col.dataIndex);
    const filteredData = data.map((item) => {
      let filteredItem = {};
      fieldsToExport.forEach((field) => {
        filteredItem[field] = item[field];
      });
      return filteredItem;
    });
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "Danh sách yêu cầu" + fileExtension);
  };
  const onHandleHide = () => {
    setCollapsed(!collapsed);
    localStorage.setItem("filter", !collapsed);
  };
  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        if (filterCondition.MaTN) load(filterCondition);
      }, 500);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  return (
    <Row gutter={[10, 10]}>
      <Col span={collapsed ? 0 : 4} style={{ transition: "all 0.3s ease" }}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
          options={{
            PhongBan: phongban,
            NhanVien: nhanvien,
            MucDo: mucdo,
            HinhThuc: hinhthuc,
            ToaNha: toanha,
          }}
        />
      </Col>
      <Col span={collapsed ? 24 : 20}>
        <Toolbar
          left={() => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                alignItems: "center",
              }}
            >
              <AiOutlineMenuFold
                size={20}
                color="primary"
                className="icon-filter"
                onClick={() => onHandleHide()}
              />
              <Input
                style={{
                  width: "300px",
                  padding: "4px 11px",
                  borderRadius: 10,
                }}
                placeholder="Tìm mã số mặt bằng, điện thoại, tên khách hàng"
                onChange={(e) => onSearch(e.target.value)}
              />
              {trangthai?.map((item) => {
                return (
                  <Badge
                    key={item.MaTT}
                    count={item.Total}
                    onClick={() => {
                      setStateActive(item);
                      changeState(item.MaTT);
                    }}
                  >
                    <div
                      style={{
                        border: `1px solid ${item.Color}`,
                        padding: "6px 15px",
                        cursor: "pointer",
                        borderRadius: 10,
                        background:
                          stateActive.MaTT === item.MaTT ? item.Color : null,
                        color: stateActive.MaTT === item.MaTT ? "white" : null,
                      }}
                    >
                      <p>{item.TenTT}</p>
                    </div>
                  </Badge>
                );
              })}
            </div>
          )}
          right={() => (
            <Flex gap={10}>
              <Button
                type="primary"
                onClick={() => refAdd.current.show(filterCondition?.MaTN)}
              >
                <PlusOutlined style={{ fontSize: "13px", margin: "0 -4px" }} />{" "}
                Thêm
              </Button>
              <Button onClick={() => exportToCSV()}>
                <ExportOutlined
                  style={{ fontSize: "13px", margin: "0 -2px" }}
                />
                Export
              </Button>
            </Flex>
          )}
        />

        <Table
          style={{ height: 100 }}
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 250,
            x: 2000,
          }}
          size="small"
        />
      </Col>

      <FormAdd
        ref={refAdd}
        onAfterSubmit={onAfterSubmit}
        filterCondition={filterCondition}
        options={{
          PhongBan: phongban,
          NhanVien: nhanvien,
          MucDo: mucdo,
          HinhThuc: hinhthuc,
          TrangThai: trangthai,
          ToaNha: toanha,
        }}
      />
      <FormDetail
        ref={refDetail}
        onAfterSubmit={onAfterSubmit}
        options={{
          NhanVien: nhanvien,
          TrangThai: trangthai,
        }}
      />
      <FormView
        ref={refDetailView}
        options={{
          ToaNha: toanha,
          TrangThai: trangthai,
          NhanVien: nhanvien,
          PhongBan: phongban,
        }}
        // options={{
        //   NhanVien: nhanvien,
        //   TrangThai: trangthai,
        // }}
      />
      {contextHolder}
    </Row>
  );
}
