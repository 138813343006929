import { Flex, Input } from "antd";
import React from "react";
import "./index.scss";
const InputCustom = ({
  label,
  applyChange,
  prop,
  value,
  placeholder,
  disabled,
  errors,
  required,
}) => {
  return (
    <div>
      <Flex
        className={required ? "border-required" : "input-custom"}
        align="center"
        justify="flex-start"
      >
        <label
          htmlFor={prop}
          //   className="text-overflow-ellipsis white-space-nowrap overflow-hidden"
          style={{
            fontWeight: "600",
            // textOverflow: "ellipsis",
            // whiteSpace: "nowrap",
            // overflow: "hidden",
            // display: "inline-block",
            width: "35%",
            cursor: "pointer",
          }}
        >
          {label} <span style={{ color: "red" }}> {required ? "*" : ""}</span>
        </label>
        <Input
          id={prop}
          style={{
            minWidth: "65%",
            border: "none",
          }}
          value={value}
          // placeholder={placeholder}
          onChange={(e) =>
            typeof applyChange === "function" &&
            applyChange(prop, e.target.value)
          }
          disabled={disabled}
        />
      </Flex>
      {errors && (
        <p style={{ color: "red", textAlign: "center" }}>
          {errors && errors[prop]}
        </p>
      )}
    </div>
  );
};

export default InputCustom;
