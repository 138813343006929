import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Space,
  Drawer,
  notification,
  Table,
  Upload,
  message,
  Select,
  DatePicker,
  ConfigProvider,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import { UploadOutlined } from "@ant-design/icons";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { DichVuService } from "services/DichVuService";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DateTime } from "luxon";
import vi from "dayjs/locale/vi";
import viVN from "antd/es/locale/vi_VN";

const FormAdd = (props, ref) => {
  const { onAfterSubmit, options, maTN } = props;
  const [api, contextHolder] = notification.useNotification();

  const [data, setData] = useState([]);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = { TenKH: null };
  const [errors, setErrors] = useState(defaultErrors);
  const [sheetExcel, setSheetExcel] = useState();
  const [workbook, setWorkbook] = useState(null);
  const [isDate, setIsDate] = useState(false);
  const [dataNN, setDataNN] = useState({
    // NgayNhap: DateTime.now().toFormat("yyyy-MM-dd"),
    NgayNhap: null,
  });

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData([]);
    setSheetExcel();
  };

  const columns = [
    {
      title: "Mã số MB",
      dataIndex: "MaSoMB",
      key: "MaSoMB",
    },
    {
      title: "Mã đồng hồ",
      dataIndex: "MaDongHo",
      key: "MaDongHo",
    },
    {
      title: "Chỉ số cũ",
      dataIndex: "ChiSoCu",
      key: "ChiSoCu",
    },
    {
      title: "Chỉ số mới",
      dataIndex: "ChiSoMoi",
      key: "ChiSoMoi",
    },
    {
      title: "Số tiêu thụ",
      dataIndex: "SoTieuThu",
      key: "SoTieuThu",
      ellipsis: true,
    },
    {
      title: "Hệ số DC",
      dataIndex: "HeSoDC",
      key: "HeSoDC",
    },
    {
      title: "Số người HDM",
      dataIndex: "SoNguoiHDM",
      key: "SoNguoiHDM",
    },
    {
      title: "Diễn giải",
      dataIndex: "DienGiai",
      key: "DienGiai",
    },
    {
      title: "Nợ đầu kỳ",
      dataIndex: "NoDauKy",
      key: "NoDauKy",
    },
    {
      title: "VAT",
      dataIndex: "VAT",
      key: "VAT",
    },
    {
      title: "Hao hụt",
      dataIndex: "HaoHut",
      key: "HaoHut",
    },
    {
      title: "BVMT",
      dataIndex: "BVMT",
      key: "BVMT",
    },
    {
      title: "Đơn giá phí dịch vụ",
      dataIndex: "PhiDichVu",
      key: "PhiDichVu",
      ellipsis: true,
    },
    {
      title: "Thành tiền",
      dataIndex: "ThanhTien",
      key: "ThanhTien",
    },
    {
      title: "VAT BVMT",
      dataIndex: "VATBVMT",
      key: "VATBVMT",
    },
  ];
  const [col, setCol] = useState(columns);
  const sampleData = [
    {
      MaSoMB: "HH1-0307",

      MaDongHo: "HH1-0303",

      ChiSoCu: 100,
      ChiSoMoi: 150,
      SoTieuThu: 6666,
      HeSoDC: 1.2,
      SoNguoiHDM: 1,
      DienGiai: "Tháng 6",
      NoDauKy: 500000,
      VAT: 10,
      HaoHut: 2,
      BVMT: 1,
      PhiDichVu: 20000,
      ThanhTien: 600000,
      VATBVMT: 5,
    },
    {
      MaSoMB: "HH1-0303",
      MaDongHo: "HH1-0303",
      ChiSoCu: 120,
      ChiSoMoi: 170,
      SoTieuThu: 8888,
      HeSoDC: 1.1,
      SoNguoiHDM: 1,
      DienGiai: "Tháng 6",
      NoDauKy: 300000,
      VAT: 10,
      HaoHut: 1.5,
      BVMT: 0.8,
      PhiDichVu: 18000,
      ThanhTien: 400000,
      VATBVMT: 3,
    },
  ];

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  // const exportToCSV = () => {
  //   const ws = XLSX.utils.json_to_sheet(sampleData);
  //   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //   const dataEX = new Blob([excelBuffer], { type: fileType });
  //   FileSaver.saveAs(dataEX, "File mẫu " + fileExtension);
  // };
  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(sampleData);

    // Define the styles for the header cells
    const headerCellStyle = {
      font: { bold: true, color: { rgb: "FF0000" } }, // Red text
      fill: {
        fgColor: { rgb: "FFFF00" }, // Yellow background
      },
    };
    ws["A1"].s = headerCellStyle;
    ws["B1"].s = headerCellStyle;
    ws["C1"].s = headerCellStyle;
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "File mẫu nước" + fileExtension);
  };
  const loadDataImport = (data) => {
    let res = [];
    data?.map((item, i) => {
      if (i > 0) {
        res.push({
          STT: i + 1,
          MaSoMB: item[0]?.toString() ?? null,
          MaDongHo: item[1]?.toString() ?? null,
          ChiSoCu: item[2] ?? null,
          ChiSoMoi: item[3] ?? null,
          SoTieuThu: item[4] ?? null,
          HeSoDC: item[5] ?? null,
          SoNguoiHDM: item[6] ?? null,
          DienGiai: item[7]?.toString() ?? null,
          NoDauKy: item[8] ?? null,
          VAT: item[9] ?? null,
          HaoHut: item[10] ?? null,
          BVMT: item[11] ?? null,
          PhiDichVu: item[12] ?? null,
          ThanhTien: item[13] ?? null,
          VATBVMT: item[14] ?? null,
        });
      }
    });
    setData(res);
  };
  const onSubmit = async (close) => {
    setLoading(true);

    let _data = _.cloneDeep(data);

    dayjs.extend(customParseFormat);
    if (!dataNN?.NgayNhap) {
      openNotification("error", "topRight", "Vui lòng nhập ngày thanh toán");
      setLoading(false);
      return;
    }
    const formatDate = (dateString) => {
      if (dayjs(dateString, "DD/MM/YYYY", true).isValid()) {
        return dayjs(dateString, "DD/MM/YYYY").format("YYYY-MM-DD");
      } else if (dayjs(dateString, "YYYY/MM/DD", true).isValid()) {
        return dayjs(dateString, "YYYY/MM/DD").format("YYYY-MM-DD");
      } else if (dayjs(dateString, "DD-MM-YYYY", true).isValid()) {
        return dayjs(dateString, "DD-MM-YYYY").format("YYYY-MM-DD");
      } else {
        return dateString;
      }
    };

    let _res = await DichVuService.Nuoc.importExcel({
      MaTN: maTN,
      List: _data,
      NgayNhap: formatDate(dataNN?.NgayNhap),
    });
    setLoading(false);

    if (_res.status !== 2000) {
      openNotification("error", "topRight", _res.message);
    } else if (_res.status === 2000 && _res.data.length === 0) {
      openNotification("success", "topRight", "Thao tác thành công");
      setIsUpdate(true);
      onClose();
    } else {
      //  openNotification("error", "topRight", "Thao tác thành công");
      const pushErrorData = _data.map((item) => {
        let updatedItem = { ...item };
        _res?.data?.forEach((i) => {
          if (item.MaSoMB === i.maSoMB) {
            updatedItem = { ...item, error: i.error };
          }
        });
        return updatedItem;
      });

      setData(pushErrorData.filter((item) => item.error));
      setCol([
        {
          title: "Error",
          dataIndex: "error",
          key: "error",
          width: 180,
          render: (item) => <p style={{ color: "red" }}>{item}</p>,
        },
        ...columns,
      ]);
    }
    onAfterSubmit();
  };
  const handleSheet = (value) => {
    const wsname = workbook.SheetNames[value];
    const ws = workbook.Sheets[wsname];
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
    loadDataImport(data);
    setCol(columns);
  };

  const onChangeDate = (date, dateString) => {
    setDataNN({ NgayNhap: dateString });
    setIsDate(true);
  };
  return (
    <Drawer
      title="Excel"
      width={960}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          {sheetExcel && (
            <Select
              placeholder="Chọn Sheet"
              style={{
                width: 115,
                border: "1px solid #d9d9d9",
                borderBottom: "3px solid #d9d9d",
                borderRadius: "5px",
                padding: "0 2px 0 10px",
              }}
              onChange={handleSheet}
              options={sheetExcel}
            />
          )}
          <ConfigProvider locale={viVN}>
            <DatePicker
              style={{
                border: "1px solid #d9d9d9",
                borderBottom: "3px solid #d9d9d",
                borderRadius: "5px",
                padding: "5px",
              }}
              format="DD-MM-YYYY"
              placeholder="Chọn ngày TT"
              // value={dataTT?.NgayTT}
              onChange={onChangeDate}
              locale={vi}
            />
          </ConfigProvider>

          <Upload
            accept=" .xlsx, .xls"
            showUploadList={false}
            beforeUpload={(file) => {
              setWorkbook(null);
              setSheetExcel(null);
              const reader = new FileReader();
              const rABS = !!reader.readAsBinaryString;
              reader.onload = (e) => {
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
                const sheets = wb.SheetNames.map((item, index) => ({
                  value: index,
                  label: item,
                }));
                if (sheets.length === 1) {
                  const wsname = wb.SheetNames[0];
                  const ws = wb.Sheets[wsname];
                  const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
                  loadDataImport(data);
                  setCol(columns);
                  if (!isDate) {
                    openNotification(
                      "warning",
                      "topRight",
                      "Vui lòng chọn tháng nhập dữ liệu!"
                    );
                  }
                } else {
                  setWorkbook(wb);
                  setSheetExcel(sheets);
                  setData();
                  if (!isDate) {
                    openNotification(
                      "warning",
                      "topRight",
                      "Vui lòng chọn tháng nhập dữ liệu!"
                    );
                  }
                }
              };
              if (rABS) reader.readAsBinaryString(file);
              else reader.readAsArrayBuffer(file);
            }}
          >
            <Button icon={<UploadOutlined />}>Upload File</Button>
          </Upload>
          <Button onClick={() => exportToCSV()}>Tải file mẫu</Button>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Table
        style={{ margin: 0, marginTop: "20px" }}
        dataSource={data}
        columns={col}
        scroll={{
          y: window.innerHeight - 250,
          x: 2000,
        }}
      />
    </Drawer>
  );
};
export default forwardRef(FormAdd);
