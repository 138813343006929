import { Button, Table, Tooltip } from "antd";
import React, { useRef } from "react";
import { MdPreview } from "react-icons/md";
import ViewDashboard from "./ViewDashboard";
import ViewDashboard3 from "./ViewDashboard3";

const Dashboard = () => {
  const refViewDashboard = useRef(null);
  const refViewDashboard3 = useRef(null);
  const dataSource = [
    {
      key: "1",
      nameDashboard: "Mẫu 1",
    },
    {
      key: "2",
      nameDashboard: "Mẫu 2",
    },
  ];

  const columns = [
    {
      title: "Name Dashboard",
      dataIndex: "nameDashboard",
      key: "nameDashboard",
    },

    {
      title: "",
      fixed: "right",
      render: (item) => {
        return (
          <div style={{ textAlign: "center" }}>
            <Tooltip placement="topRight" title="Xem">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdPreview />}
                onClick={() =>
                  item.key === "1"
                    ? refViewDashboard.current.show(item)
                    : refViewDashboard3.current.show(item)
                }
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];
  return (
    <div>
      <Table dataSource={dataSource} columns={columns} pagination={false} />
      <ViewDashboard ref={refViewDashboard} />
      <ViewDashboard3 ref={refViewDashboard3} />
    </div>
  );
};
export default Dashboard;
