import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Button,
  Popconfirm,
  notification,
  Tooltip,
  Badge,
  Row,
  Col,
  Flex,
  Tag,
  Checkbox,
  Input,
  Space,
  Dropdown,
  Menu,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import Toolbar from "components/Toolbar";
import { Format_Date } from "layouts/ultis/common";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";

import FormDetail from "./FormDetail";
import FormAddExcel from "./FormAddExcel";
import { ToaNhaService } from "services/ToaNhaService";
import "../scss/index.scss";
import { Editor } from "primereact/editor";
import { KhachHangService } from "services/KhachHangService";
import {
  DeleteOutlined,
  DownOutlined,
  EditTwoTone,
  ExportOutlined,
  ImportOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import FormAddExcel2 from "./FormAddExcel2";
import FormHistory from "./FormHistory";
import { NhanVienService } from "services/NhanVienService";
import { AiOutlineMenuFold } from "react-icons/ai";

export default function Index() {
  const [dataAll, setDataAll] = useState([]);

  const [data, setData] = useState([]);
  const [toaNha, setToanha] = useState([]);
  const [stateActive, setStateActive] = useState({
    MaTT: 0,
    TenTT: "Tất cả",
    Color: "gray",
  });
  const [api, contextHolder] = notification.useNotification();
  const refDetail = useRef(null);
  const refAdd = useRef(null);
  const refAddExcel = useRef(null);
  const refAddExcel2 = useRef(null);
  const refHistory = useRef(null);
  const [loading, setLoading] = useState(false);
  const [nhanVien, setNhanVien] = useState([]);
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("filter") === "true"
  );
  const [filterCondition, setFilterCondition] = useState({
    InputSearch: "",
  });

  useEffect(() => {
    loadCategories();
    // load(filterCondition);
  }, []);

  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        if (filterCondition.MaTN) load(filterCondition);
      }, 500);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  const load = async (_filter) => {
    setLoading(true);
    let _res = await KhachHangService.KhachHang.get(_filter);
    setDataAll(_res.data);
    setData(_res.data);
    setLoading(false);
  };
  const loadCategories = async () => {
    let _tn = await ToaNhaService.getToaNhaByNV();
    setToanha(_tn.data);
    let _filter = _.cloneDeep(filterCondition);
    _filter.MaTN = _tn.data[0]?.MaTN;
    let _nv = await NhanVienService.getNhanVien({ inputSearch: "" });
    setNhanVien(_nv.data);
    setFilterCondition(_filter);
    load(_filter);
  };

  const columns = [
    // {
    //   title: "STT",
    //   dataIndex: "STT",
    // ellipsis: true,
    // },
    // {
    //   title: "Mã KH",
    //   dataIndex: "MaKH",
    // ellipsis: true,
    // },
    {
      title: "Mã Ký hiệu",
      // ellipsis: true,
      width: "120px",
      render: (item) => (
        <p
          style={{
            fontWeight: "bold",
            color: "cadetblue",
            cursor: "pointer",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            // ellipsis: true,
          }}
          onClick={() => refHistory.current.show(item)}
        >
          {item.KyHieu}
        </p>
      ),
    },
    // {
    //   title: "Mã phụ",
    //   dataIndex: "MaPhu",
    // ellipsis: true,
    // },
    {
      title: "Tên",
      dataIndex: "TenKH",
      width: 150,
      // ellipsis: true,
    },
    {
      title: "Ngày sinh",
      dataIndex: "NgaySinh",
      // ellipsis: true,
      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Ngày cấp",
      dataIndex: "NgayCap",
      // ellipsis: true,
      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Nơi cấp",
      dataIndex: "NoiCap",
      // ellipsis: true,
    },
    {
      title: "CCCD /Passport",
      dataIndex: "CMND",
      // ellipsis: true,
    },
    {
      title: "Mã số thuế",
      dataIndex: "MaSoThue",
      // ellipsis: true,
    },
    {
      title: "Điện thoại",
      dataIndex: "DienThoaiKH",
      // ellipsis: true,
    },
    {
      title: "Điện thoại 2",
      dataIndex: "DienThoai2",
      // ellipsis: true,
    },
    {
      title: "Email",
      dataIndex: "EmailKH",
      // ellipsis: true,
    },
    {
      title: "Email 2",
      dataIndex: "Email2",
      // ellipsis: true,
    },
    {
      title: "Ghi chú",
      dataIndex: "GhiChu",
      width:150,
      // ellipsis: true,
    },
    {
      title: "Địa chỉ liên lạc",
      dataIndex: "DCLL",
      width:150,
      // ellipsis: true,
    },
    // {
    //   title: "Địa chỉ thường trú",
    //   dataIndex: "DiaChiThuongTru",
    // ellipsis: true,
    // },
    // {
    //   title: "Quốc tịch",
    //   dataIndex: "QuocTich",
    // ellipsis: true,
    // },
    // {
    //   title: "Đồng sở hữu",
    //   dataIndex: "ĐongSoHuu",
    // ellipsis: true,
    // },
    {
      title: "Người sửa",
      dataIndex: "MaNV",
      render: (item) => nhanVien?.find((nv) => nv.MaNV === item)?.HoTenNV,
      // ellipsis: true,
    },
    {
      title: "Ngày sửa",
      dataIndex: "NgayCN",
      // ellipsis: true,
      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Tên nhân viên",
      dataIndex: "NguoiNhap",
      render: (item) => nhanVien?.find((nv) => nv.MaNV === item)?.HoTenNV,
      // ellipsis: true,
    },
    {
      title: "Ngày nhập",
      dataIndex: "NgayNhap",
      // ellipsis: true,
      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Giới tính",
      dataIndex: "GioiTinh",
      // ellipsis: true,
      render: (item) => (item ? "Nam" : "Nữ"),
    },
    {
      title: "",
      fixed: "right",
      width: "100px",
      render: (item) => (
        <div style={{ display: "flex" }}>
          <Tooltip placement="topRight" title="Sửa">
            <Button
              style={{ marginLeft: 10 }}
              type="primary"
              ghost
              icon={<EditTwoTone />}
              onClick={() => refAdd.current.update(item)}
            />
          </Tooltip>
          <Popconfirm
            title="Xoá danh mục"
            description="Bạn có chắc chắn muốn xoá danh mục?"
            okText="Đồng ý"
            cancelText="Không"
            onConfirm={() => remove(item.MaKH)}
          >
            <Tooltip placement="topRight" title="Xoá">
              <Button
                style={{ marginLeft: 10 }}
                danger
                ghost
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        </div>
      ),
    },
  ];
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const remove = async (item) => {
    let _res = await KhachHangService.KhachHang.delete({
      ID: item,
    });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.MaKH !== Number(item));
      setData(fil);
      openNotification("success", "topRight", "Xoá  thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const onAfterSubmit = () => {
    load(filterCondition);
  };
  const onChangeFilter = (_filter) => {
    load(_filter);
    setFilterCondition(_filter);
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = () => {
    const fieldsToExport = columns.map((col) => col.dataIndex);
    const filteredData = data.map((item) => {
      let filteredItem = {};
      fieldsToExport.forEach((field) => {
        filteredItem[field] = item[field];
      });
      return filteredItem;
    });
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "Danh sách khách hàng " + fileExtension);
  };
  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  // const handleMenuClick = (e) => {
  //   if (e.key === "1") {
  //     refAddExcel.current.show();
  //   } else {
  //     refAddExcel2.current.show();
  //   }
  // };

  // const menu = (
  //   <Menu onClick={handleMenuClick}>
  //     <Menu.Item key="1">Cá nhân</Menu.Item>
  //     <Menu.Item key="2">Doanh nghiệp</Menu.Item>
  //   </Menu>
  // );

  const onHandleHide = () => {
    setCollapsed(!collapsed);
    localStorage.setItem("filter", !collapsed);
  };
  return (
    <Row gutter={[10, 10]}>
      <Col span={collapsed ? 0 : 4} style={{ transition: "all 0.3s ease" }}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
          options={{ ToaNha: toaNha }}
        />
      </Col>
      <Col span={collapsed ? 24 : 20}>
        <Toolbar
          left={() => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                alignItems: "center",
              }}
            >
              <AiOutlineMenuFold
                size={20}
                color="primary"
                className="icon-filter"
                onClick={() => onHandleHide()}
              />
              <Input
                style={{
                  width: "300px",
                  padding: "4px 11px",
                  borderRadius: 10,
                }}
                placeholder="Tìm mã số mặt bằng, điện thoại, tên khách hàng"
                onChange={(e) => onSearch(e.target.value)}
              />
            </div>
          )}
          right={() => (
            <Flex gap={10}>
              <Button
                type="primary"
                onClick={() => refAdd.current.show(filterCondition?.MaTN)}
              >
                <PlusOutlined style={{ fontSize: "13px", margin: "0 -4px" }} />{" "}
                Thêm
              </Button>
              <Button onClick={() => refAddExcel.current.show()}>
                <ImportOutlined
                  style={{ fontSize: "13px", margin: "0 -2px" }}
                />
                Import
              </Button>
              {/* <Dropdown overlay={menu} trigger={["click"]}>
                <Button>
                  Import
                  <DownOutlined />
                </Button>
              </Dropdown> */}
              <Button onClick={() => exportToCSV()}>
                <ExportOutlined
                  style={{ fontSize: "13px", margin: "0 -2px" }}
                />
                Export
              </Button>
            </Flex>
          )}
        />

        <Table
          style={{ height: 100 }}
          loading={loading}
          dataSource={data}
          columns={columns}
          bordered
          scroll={{
            y: window.innerHeight - 250,
            x: 2100,
          }}
          size="small"
        />
      </Col>

      <FormAdd
        ref={refAdd}
        onAfterSubmit={onAfterSubmit}
        options={{ ToaNha: toaNha }}
        filterCondition={filterCondition}
      />
      <FormDetail
        ref={refDetail}
        onAfterSubmit={onAfterSubmit}
        options={{ ToaNha: toaNha }}
      />

      <FormAddExcel
        ref={refAddExcel}
        maTN={filterCondition.MaTN}
        onAfterSubmit={onAfterSubmit}
      />
      <FormAddExcel2 ref={refAddExcel2} />
      <FormHistory
        ref={refHistory}
        onAfterSubmit={onAfterSubmit}
        options={{ ToaNha: toaNha }}
      />
      {contextHolder}
      <div style={{ display: "none" }}>
        <Editor style={{ height: 0 }} />
      </div>
    </Row>
  );
}
