import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  ColorPicker,
  Flex,
} from "antd";
import _ from "lodash";
import { ToaNhaService } from "services/ToaNhaService";
import InputCustom from "components/Forms/InputCustom";
import { CategoryService } from "services/CategoryService";
import { SaveOutlined } from "@ant-design/icons";

const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const defaulData = {};
  const [data, setData] = useState(defaulData);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = {
    MaCTDK: null,
    TenTrangThai: null,
  };
  const [errors, setErrors] = useState(defaultErrors);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);

  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "TenTrangThai":
        _data.isDefaut = false;
        break;
      default:
        break;
    }
    _data[p] = v;
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(defaultErrors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    let _res = await CategoryService.KhachHang.CuDan.TrangThai.add(_data);
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} phiếu thu thành công!`
      );
      setIsUpdate(true);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const renderThongTinChung = () => {
    return (
      <>
        <Row style={{ marginTop: "10px" }} gutter={[20, 12]}>
          <Col span={24}>
            <InputCustom
              label="Tên trạng thái:"
              applyChange={applyChange}
              prop="TenTrangThai"
              value={data?.TenTrangThai}
              placeholder="Nhập tên trạng thái"
              errors={errors}
            />
          </Col>
          <Col span={24}>
            <InputCustom
              label="MaCTDK:"
              applyChange={applyChange}
              prop="MaCTDK"
              value={data?.MaCTDK}
              placeholder="Nhập MaCTDK"
              errors={errors}
            />
          </Col>
          <Col span={24}>
            <Flex style={{ paddingTop: "20px" }}>
              <p style={{ fontWeight: "600" }}>Màu nền:</p>
              <ColorPicker
                style={{ marginTop: "-15px", marginLeft: "10px" }}
                value={data?.MauNen}
                onChangeComplete={(color) => {
                  applyChange("MauNen", color.toHexString());
                }}
              />
            </Flex>
          </Col>
        </Row>
      </>
    );
  };
  return (
    <Drawer
      title={isupdate ? "Sửa trạng thái" : "Thêm trạng thái"}
      width={720}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined />Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      {renderThongTinChung()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
