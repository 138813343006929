import React, { useEffect, useState } from "react";
import { Select } from "antd";
import _ from "lodash";
import "dayjs/locale/vi";

const FilterKN = ({ ...props }) => {
  const { filterCondition, onChangeFilter, toaNha } = props;

  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "HinhThuc":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        break;
      default:
        _filter[p] = v;
        break;
    }
    onChangeFilter(_filter);
  };

  return (
    <div>
      <div className="box-search">
        <p className="label">Dự án</p>
        <Select
          // mode="multiple"
          placeholder="Chọn dự án"
          onChange={(v) => applyChangeFilter("MaTN", v)}
          style={{
            width: "100%",
          }}
          value={filterCondition.MaTN}
          options={toaNha}
          fieldNames={{ label: "TenTN", value: "MaTN" }}
        />
      </div>
      {/* <div className="box-search">
        <p className="label">Loại thu</p>
        <Select
          mode="multiple"
          placeholder="Chọn loại thu"
          onChange={(v) => applyChangeFilter("LoaiThu", v)}
          style={{
            width: "100%",
          }}
          options={loaiPT}
          fieldNames={{ label: "TenLPT", value: "ID" }}
        />
      </div> */}
    </div>
  );
};
export default FilterKN;
