import React, { useEffect } from "react";
import { DatePicker, Select, ConfigProvider, Row, Col } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import ActiveRoute from "components/ActiveRoute";
import FilterDate from "components/FilterDate";
import { DateTime } from "luxon";
import viVN from "antd/es/locale/vi_VN";

dayjs.locale("vi");
const Filter = ({ ...props }) => {
  const { filterCondition, onChangeFilter, options } = props;
  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "MaTN":
        _filter[p] = v;
        break;

      case "DenNgay":
        const date = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const dateInHoChiMinh = date.setZone("Asia/Ho_Chi_Minh");
        const Month = dateInHoChiMinh.month;
        const Year = dateInHoChiMinh.year;
        _filter.Month = Month;
        _filter.Year = Year;
        break;
      default:
        _filter[p] = v;
        break;
    }
    onChangeFilter(_filter);
  };

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col span={4}>
          <div style={{ display: "flex" }}>
            <p
              className="label"
              style={{
                marginTop: 4,
                paddingRight: "10px",
                fontWeight: "600",
              }}
            >
              Dự án
            </p>
            <Select
              // mode="multiple"
              // defaultValue={localStorage.getItem("MaTN")}
              value={filterCondition?.MaTN}
              placeholder="Chọn dự án"
              onChange={(v) => applyChangeFilter("MaTN", v)}
              style={{ width: "70%" }}
              options={options?.ToaNha}
              fieldNames={{ label: "TenTN", value: "MaTN" }}
            />
          </div>
        </Col>
        <Col span={4}>
          <ConfigProvider locale={viVN}>
            <div style={{ display: "flex" }} className="">
              <p
                className="label"
                style={{
                  marginTop: 4,
                  paddingRight: "10px",
                  fontWeight: "600",
                }}
              >
                Tháng năm
              </p>
              <DatePicker
                picker="month"
                format="MM-YYYY"
                style={{}}
                defaultValue={dayjs(filterCondition?.DenNgay)}
                placeholder="Đến ngày"
                onChange={(v) => applyChangeFilter("DenNgay", v)}
                allowClear={false}
              />
            </div>
          </ConfigProvider>
        </Col>
      </Row>
    </>
  );
};
export default Filter;
