import {
  Button,
  Col,
  Drawer,
  Flex,
  Modal,
  notification,
  Row,
  Space,
} from "antd";
import DebounceSelect from "components/DebounceSelect";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import InputCustom from "components/Forms/InputCustom";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import { DateTime } from "luxon";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { DichVuService } from "services/DichVuService";
import { MatBangService } from "services/MatBangService";
import _ from "lodash";
import SelectCustom from "components/Forms/SelectCustom";
import { KhachHangService } from "services/KhachHangService";
import { CategoryService } from "services/CategoryService";

const FormModal = (props, ref) => {
  const { onAfterSubmit, maTN } = props;
  const [isupdate, setIsUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState({});
  const [keyActive, setKeyActive] = useState();

  const onClose = () => {
    onSubmit();
    setIsModalOpen(false);
    setData({});
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useImperativeHandle(ref, () => ({
    show: async (item, key) => {
      setData(item);
      setKeyActive(key);
      setIsModalOpen(true);
    },
    update: async (data) => {
      setIsModalOpen(true);
    },
  }));
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };

  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let _res;
    if (keyActive === "2") {
      _res = await KhachHangService.TienIch.huy({
        ID: _data.ID,
        MaMB: _data.MaMB,
      });
    } else {
      _res = await KhachHangService.TienIch.duyet({
        ID: _data.ID,
        MaTN: maTN,
        MaMB: _data.MaMB,
        TenKH: _data.TenKH,
      });
    }

    setLoading(false);
    if (_res.status === 2000) {
      openNotification("success", "topRight", ` Đặt lịch thành công!`);
      setIsUpdate(true);

      onAfterSubmit();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  return (
    <div>
      <Modal
        title={keyActive === "2" ? "Hủy lịch" : "Duyệt lịch"}
        open={isModalOpen}
        onOk={onClose}
        onCancel={handleCancel}
        confirmLoading={loading} 
        width={400}
      >
        <p style={{ paddingBottom: "10px" }}>
          Bạn có muốn {keyActive === "2" ? "hủy lịch" : "duyệt lịch"} không?
        </p>
      </Modal>
      {contextHolder}{" "}
    </div>
  );
};

export default forwardRef(FormModal);
