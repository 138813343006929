import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Radio,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { DatePicker } from "antd/lib";
import { ToaNhaService } from "services/ToaNhaService";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import SelectCustom from "components/Forms/SelectCustom";
import InputCustom from "components/Forms/InputCustom";
import { CategoryService } from "services/CategoryService";
import { DateTime } from "luxon";
import { SaveOutlined } from "@ant-design/icons";

const { TextArea } = Input;

const FormAdd = (props, ref) => {
  const { onAfterSubmit, toanha, filterCondition } = props;
  const [api, contextHolder] = notification.useNotification();

  const [data, setData] = useState({});
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = {
    TenLX: null,
    TuNgay: null,
    DenNgay: null,
    MaTN: null,
  };
  const [errors, setErrors] = useState(defaultErrors);
  const [selectedValue, setSelectedValue] = useState(1);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      setData((pr) => ({
        ...pr,
        MaTN: filterCondition.MaTN,
      }));
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
      setSelectedValue(data.MaNX);
    },
  }));

  const onClose = () => {
    setOpen(false);
    setErrors(defaultErrors);
    setData();

    setLoading(false);
  };

  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MaNX":
        setSelectedValue(v);
        _data.MaNX = v;
        break;
      case "TuNgay":
        const date = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString = date.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString;
        break;
      case "DenNgay":
        const date1 = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString1 = date1.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString1;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };

  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };

  const onSubmit = async (close) => {
    setLoading(true);
    let valid = validate();

    if (valid.length > 0) {
      setLoading(false);
      return;
    }

    let _data = _.cloneDeep(data);
    let _res = await CategoryService.DichVu.Xe.LoaiXe.add(_data);
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} loại xe thành công!`
      );
      setIsUpdate(true);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const renderThongTinChung = () => {
    return (
      <>
        <Row style={{ marginTop: "10px" }} gutter={[20, 12]}>
          <Col span={24}>
            <InputCustom
              label="Tên loại xe:"
              applyChange={applyChange}
              prop="TenLX"
              errors={errors}
              value={data?.TenLX}
              placeholder="Nhập tên LX"
            />
          </Col>
          <Col span={12}>
            <InputNumberCustom
              label="Phí làm thẻ:"
              applyChange={applyChange}
              prop={"PhiLamThe"}
              value={data?.PhiLamThe}
              placeholder="0"
            />
          </Col>
          <Col span={12}>
            <SelectCustom
              label="Tòa nhà:"
              applyChange={applyChange}
              prop="MaTN"
              value={data?.MaTN}
              placeholder="Chọn tòa nhà"
              options={toanha}
              fieldNames={{ label: "TenTN", value: "MaTN" }}
              errors={errors}
            />
          </Col>
          <Col span={12}>
            <DatePickerCustom
              label="Từ ngày:"
              applyChange={applyChange}
              prop={"TuNgay"}
              value={data?.TuNgay}
              placeholder={"Chọn từ ngày"}
              errors={errors}
            />
          </Col>
          <Col span={12}>
            <DatePickerCustom
              label="Đến ngày:"
              applyChange={applyChange}
              prop={"DenNgay"}
              value={data?.DenNgay}
              placeholder={"chọn đến ngày"}
              errors={errors}
            />
          </Col>

          <Radio.Group
            value={selectedValue}
            onChange={(e) => applyChange("MaNX", e.target.value)}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "40px",
                marginTop: "10px",
                marginLeft: "8px",
                flexWrap: "wrap",
              }}
            >
              <Radio value={1}>Xe đạp</Radio>
              <Radio value={2}>Xe máy</Radio>
              <Radio value={3}>Ô tô</Radio>
              <Radio value={4}>Xe điện</Radio>
            </div>
          </Radio.Group>
        </Row>
      </>
    );
  };

  return (
    <Drawer
      title={isupdate ? "Sửa loại xe" : "Thêm loại xe"}
      width={920}
      onClose={onClose}
      open={open}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined /> Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      {renderThongTinChung()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
