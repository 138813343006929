import React, {
  useEffect, useState,
} from "react";
import { DatePicker, Select } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import ActiveRoute from "components/ActiveRoute";
import { ToaNhaService } from "services/ToaNhaService";
import { NhanVienService } from "services/NhanVienService";

const FilterLYC = ({ ...props }) => {
  const { filterCondition, onChangeFilter } = props;
  const [toanha, setToanha] = useState([]);
  const [phongban, setPhongban] = useState([]);

  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "HinhThuc":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        break;
      case "MaTN":
        _filter[p] = v;
        _filter.MaPB = null;
        loadPhongBan(v);
        break;
      default:
        _filter[p] = v;
        break;
    }
    onChangeFilter(_filter);
  };
  const loadcategories = async () => {
    let _tn = await ToaNhaService.getToaNhaByNV();
    setToanha(_tn.data ?? []);
  };
  const loadPhongBan = async (maTN) => {
    let _tn = await NhanVienService.Categories.getPhongBan({
      MaTN: maTN,
    });
    setPhongban(_tn.data);
  };
  useEffect(() => {
    loadcategories();
    loadPhongBan(filterCondition?.MaTN);
  }, []);
  return (
    <div>
      <div className="box-search">
        <p className="label">Dự án</p>
        <Select
          // mode="multiple"
          value={filterCondition.MaTN}
          placeholder="Chọn dự án"
          onChange={(v) => applyChangeFilter("MaTN", v)}
          style={{
            width: "100%",
          }}
          options={toanha}
          fieldNames={{ label: "TenTN", value: "MaTN" }}
        />
      </div>
      <div className="box-search">
        <p className="label">Phòng ban</p>
        <Select
          value={filterCondition.MaPB}
          placeholder="Chọn phòng ban"
          onChange={(v) => applyChangeFilter("MaPB", v)}
          style={{
            width: "100%",
          }}
          options={phongban}
          fieldNames={{ label: "TenPB", value: "MaPB" }}
        />
      </div>
    </div>
  );
};
export default FilterLYC;
