import { Tooltip } from 'antd';
import { useRef, useEffect, useState } from 'react';

export const EllipsisWithTooltip = ({ text }) => {
  const [isOverflowed, setIsOverflowed] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    const element = textRef.current;
    if (element) {
      setIsOverflowed(element.scrollHeight > element.clientHeight);
    }
  }, [text]);

  return (
    <Tooltip title={isOverflowed ? text : null}>
      <span
        ref={textRef}
        className="line-clamp"
      >
        {text}
      </span>
    </Tooltip>
  );
};
