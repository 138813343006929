import axiosApi from "./api_helper";
export const YeuCauService = {
  Categories: {
    getTrangThai: async (payload) => {
      return await axiosApi()
        .post("api/yeu-cau/category/get-trangthai", payload)
        .then((res) => res.data);
    },
    addTrangThai: async (payload) => {
      return await axiosApi()
        .post("api/yeu-cau/category/add-trangthai", payload)
        .then((res) => res.data);
    },
    deleteTrangThai: async (payload) => {
      return await axiosApi()
        .post("api/yeu-cau/category/delete-trangthai", payload)
        .then((res) => res.data);
    },

    getMucDo: async () => {
      return await axiosApi()
        .post("api/yeu-cau/category/get-douutien")
        .then((res) => res.data);
    },
    addMucDo: async (payload) => {
      return await axiosApi()
        .post("api/yeu-cau/category/add-douutien", payload)
        .then((res) => res.data);
    },
    deleteMucDo: async (payload) => {
      return await axiosApi()
        .post("api/yeu-cau/category/delete-douutien", payload)
        .then((res) => res.data);
    },

    getHinhThuc: async () => {
      return await axiosApi()
        .post("api/yeu-cau/category/get-hinhthuc")
        .then((res) => res.data);
    },
    addHinhThuc: async (payload) => {
      return await axiosApi()
        .post("api/yeu-cau/category/add-hinhthuc", payload)
        .then((res) => res.data);
    },
    deleteHinhThuc: async (payload) => {
      return await axiosApi()
        .post("api/yeu-cau/category/delete-hinhthuc", payload)
        .then((res) => res.data);
    },

    getLoaiYeuCau: async (payload) => {
      return await axiosApi()
        .post("api/yeu-cau/category/get-loaiyeucau", payload)
        .then((res) => res.data);
    },
    addLoaiYeuCau: async (payload) => {
      return await axiosApi()
        .post("api/yeu-cau/category/add-loaiyeucau", payload)
        .then((res) => res.data);
    },
    deleteLoaiYeuCau: async (payload) => {
      return await axiosApi()
        .post("api/yeu-cau/category/delete-loaiyeucau", payload)
        .then((res) => res.data);
    },
  },
  getYCById: async (payload) => {
    return await axiosApi()
      .post("api/yeu-cau/get-byid", payload)
      .then((res) => res.data);
  },
  getImages: async (payload) => {
    return await axiosApi()
      .post("api/yeu-cau/get-images", payload)
      .then((res) => res.data);
  },
  getYeuCau: async (payload) => {
    return await axiosApi()
      .post("api/yeu-cau/get", payload)
      .then((res) => res.data);
  },
  addYeuCau: async (payload) => {
    return await axiosApi()
      .post("api/yeu-cau/add", payload)
      .then((res) => res.data);
  },
  deleteYeuCau: async (payload) => {
    return await axiosApi()
      .post("api/yeu-cau/delete", payload)
      .then((res) => res.data);
  },
  addPhanHoi: async (payload) => {
    return await axiosApi()
      .post("api/yeu-cau/add-phanhoi", payload)
      .then((res) => res.data);
  },
  getPhanHoi: async (payload) => {
    return await axiosApi()
      .post("api/yeu-cau/get-phanhoi", payload)
      .then((res) => res.data);
  },
  getImg: async (payload) => {
    return await axiosApi()
      .post("api/yeu-cau/get-images", payload)
      .then((res) => res.data);
  },
};
