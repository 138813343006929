import { Drawer, notification, Table, Tabs } from "antd";
import { Format_Datetime2 } from "layouts/ultis/common";
import React, {
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import { DichVuService } from "services/DichVuService";

const FormQLHistory = (props, ref) => {
  const { onAfterSubmit, options } = props;
  const [api, contextHolder] = notification.useNotification();
  const [lichSuCN, setLichSuCN] = useState([]);

  const [payLoad, setPayload] = useState();

  const [visible, setVisible] = useState(false);

  const { TabPane } = Tabs;

  const onClose = () => {
    setVisible(false);
  };

  useImperativeHandle(ref, () => ({
    show: async (item) => {
      setVisible(true);
      setPayload(item?.ID);
      fetchDataForTab("1", item?.ID);
    },
  }));

  const columns = [
    {
      title: "Ngày thao tác",
      dataIndex: "NgayTao",
      key: "NgayTao",
   
      render: (item) => (item ? Format_Datetime2(item) : ""),
    },
    // {
    //   title: "Ngày tính phí",
    //   dataIndex: "NgayTP",
    //   key: "NgayTP",
    //
    //   render: (item) => (item ? Format_Date(item) : ""),
    // },
    // {
    //   title: "Ngày thanh toán",
    //   dataIndex: "NgayTT",
    //   key: "NgayTT",
    //
    //   render: (item) => (item ? Format_Date(item) : ""),
    // },
    {
      title: "Nhân viên",
      dataIndex: "HoTenNV",
      key: "HoTenNV",
   
    },
    // {
    //   title: "Trạng thái",
    //   dataIndex: "TrangThai",
    //   key: "TrangThai",
    //
    // },
    {
      title: "Diễn giải",
      dataIndex: "DienGiai",
      key: "DienGiai",
   
    },
  ];

  const fetchDataForTab = async (key, value) => {
    switch (key) {
      case "1":
        let _res = await DichVuService.Xe.getLichSuTX({
          MaTX: value,
        });
        if (_res.data) setLichSuCN(_res.data);

        break;

      default:
        break;
    }
  };

  const onTabChange = (key) => {
    fetchDataForTab(key, payLoad);
  };

  return (
    <>
      <Drawer
        title="Quản lý lịch sử thẻ xe"
        placement="right"
        closable={true}
        onClose={onClose}
        visible={visible}
        width={1300}
      >
        <Tabs defaultActiveKey="1" onChange={onTabChange}>
          <TabPane tab="Lịch sử cập nhật" key="1">
            <Table
              pagination={false}
              columns={columns}
              dataSource={lichSuCN}
              scroll={{
                y: window.innerHeight - 250,
                x: 1000,
              }}
            />
          </TabPane>
        </Tabs>
      </Drawer>
    </>
  );
};

export default forwardRef(FormQLHistory);
