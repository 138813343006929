import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Button,
  Input,
  Upload,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  message,
  Select,
  Checkbox,
  ColorPicker,
} from "antd";
import { CustomerService } from "services/CustomerService";
import _ from "lodash";
import { ToaNhaService } from "services/ToaNhaService";
import { YeuCauService } from "services/YeuCauService";
import { NhanVienService } from "services/NhanVienService";
import { SaveOutlined } from "@ant-design/icons";
const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("0");
  const [toanha, setToanha] = useState([]);
  const [khoinha, setKhoinha] = useState([]);
  const [phongban, setPhongban] = useState([]);

  const defaultErrors = {
    MaGD: null,
    MaKH: null,
    MaHinhThuc: null,
  };
  const [errors, setErrors] = useState(defaultErrors);

  const loadcategories = async () => {
    let _tn = await ToaNhaService.getToaNhaByNV();
    setToanha(_tn.data);
  };
  const loadPhongBan = async (maTN) => {
    let _tn = await NhanVienService.Categories.getPhongBan({
      MaTN: maTN,
    });
    setPhongban(_tn.data);
  };
  useImperativeHandle(ref, () => ({
    show: async (tab) => {
      setOpen(true);
      loadcategories();
      setActiveTab(tab);
    },
    update: async (data, tab) => {
      setOpen(true);
      loadcategories();
      // loadKhoiNha(data.MaTN);
      loadPhongBan(data.MaTN);
      setData(data);
      setActiveTab(tab);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
  };
  const applyChange = async (p, v) => {
    // console.log(v);
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MaTN":
        loadPhongBan(v);
        _data.MaKN = null;
        _data[p] = v;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    // let valid = validate();
    // if (valid?.length > 0) {
    //   return;
    // }
    let _res = null;
    switch (activeTab) {
      case "0":
        _res = await YeuCauService.Categories.addTrangThai(_data);
        break;
      case "1":
        _res = await YeuCauService.Categories.addMucDo(_data);
        break;
      case "2":
        _res = await YeuCauService.Categories.addHinhThuc(_data);
        break;
      case "3":
        _res = await YeuCauService.Categories.addLoaiYeuCau(_data);
        break;
    }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification("success", "topRight", `Cập nhật thành công!`);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const renderTabTT = () => {
    return (
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <p>Dự án</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaTN}
            placeholder="Chọn dự án"
            onChange={(v) => applyChange("MaTN", v)}
            options={toanha}
            fieldNames={{ label: "TenTN", value: "MaTN" }}
          />
        </Col>
        <Col span={24}>
          <p>Tên trạng thái</p>
          <Input
            value={data?.TenTT}
            placeholder="Nhập tên trạng thái"
            onChange={(e) => applyChange("TenTT", e.target.value)}
          />
        </Col>

        <Col span={12}>
          <Checkbox
            checked={data?.DefautCuDan === true}
            onChange={() => applyChange("DefautCuDan", !data?.DefautCuDan)}
          >
            Mặc định cư dân
          </Checkbox>
        </Col>
        <Col span={12}>
          <Checkbox
            checked={data?.isViewApp === true}
            onChange={() => applyChange("isViewApp", !data?.isViewApp)}
          >
            Hiển thị app bql
          </Checkbox>
        </Col>
        <Col span={12}>
          <Checkbox
            checked={data?.ViewAppCuDan === true}
            onChange={() => applyChange("ViewAppCuDan", !data?.ViewAppCuDan)}
          >
            Hiển thị app cư dân
          </Checkbox>
        </Col>
        <Col span={12}>
          <Checkbox
            checked={data?.isDanhGia === true}
            onChange={() => applyChange("isDanhGia", !data?.isDanhGia)}
          >
            Cư dân đánh giá
          </Checkbox>
        </Col>
        <Col span={12}>
          <Checkbox
            checked={data?.isNgayHoanThanh === true}
            onChange={() =>
              applyChange("isNgayHoanThanh", !data?.isNgayHoanThanh)
            }
          >
            Đánh dấu hoàn thành
          </Checkbox>
        </Col>
        <Col span={12}>
          <ColorPicker
            value={data?.Color}
            size="small"
            showText
            onChange={(v) => applyChange("Color", v.toHexString())}
          />
        </Col>
      </Row>
    );
  };
  const renderMucDo = () => {
    return (
      <Row gutter={[20, 20]}>
        {/* <Col span={24}>
           <p>Dự án</p>
           <Select
             style={{
               width: "100%",
             }}
             value={data?.MaTN}
             placeholder="Chọn dự án"
             onChange={(v) => applyChange("MaTN", v)}
             options={toanha}
             fieldNames={{ label: "TenTN", value: "MaTN" }}
           />
         </Col> */}
        <Col span={24}>
          <p style={{ paddingTop: 20 }}>Tên độ ưu tiên</p>
          <Input
            value={data?.TenDoUuTien}
            placeholder="Nhập tên độ ưu tên"
            onChange={(e) => applyChange("TenDoUuTien", e.target.value)}
          />
        </Col>
        <Col span={12}>
          <p>Màu nền</p>
          <ColorPicker
            value={data?.Color}
            size="small"
            showText
            onChangeComplete={(v) => applyChange("Color", v.toHexString())}
          />
        </Col>
      </Row>
    );
  };
  const renderHinhThuc = () => {
    return (
      <Row gutter={[20, 20]}>
        {/* <Col span={24}>
           <p>Dự án</p>
           <Select
             style={{
               width: "100%",
             }}
             value={data?.MaTN}
             placeholder="Chọn dự án"
             onChange={(v) => applyChange("MaTN", v)}
             options={toanha}
             fieldNames={{ label: "TenTN", value: "MaTN" }}
           />
         </Col> */}
        <Col span={24}>
          <p style={{ paddingTop: 20 }}>Tên hình thức</p>
          <Input
            value={data?.TenHT}
            placeholder="Nhập tên hình thức"
            onChange={(e) => applyChange("TenHT", e.target.value)}
          />
        </Col>
      </Row>
    );
  };
  const renderLoaiYeuCau = () => {
    return (
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <p>Dự án</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaTN}
            placeholder="Chọn dự án"
            onChange={(v) => applyChange("MaTN", v)}
            options={toanha}
            fieldNames={{ label: "TenTN", value: "MaTN" }}
          />
        </Col>
        <Col span={24}>
          <p>Phòng ban</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaPB}
            placeholder="Chọn phòng ban"
            onChange={(v) => applyChange("MaPB", v)}
            options={phongban}
            fieldNames={{ label: "TenPB", value: "MaPB" }}
          />
        </Col>
        <Col span={24}>
          <p>Tên loại yêu cầu</p>
          <Input
            value={data?.TenLYC}
            placeholder="Nhập tên loại yêu cầu"
            onChange={(e) => applyChange("TenLYC", e.target.value)}
          />
        </Col>

        <Col span={12}>
          <Checkbox
            checked={data?.IsViewApCuDan === true}
            onChange={() => applyChange("IsViewApCuDan", !data?.IsViewApCuDan)}
          >
            Hiển thị app cư dân
          </Checkbox>
        </Col>
      </Row>
    );
  };
  return (
    <Drawer
      title="Danh mục"
      width={520}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          {/* <Button loading={loading} onClick={() => onSubmit()}>
            Lưu
          </Button> */}
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined /> Lưu & đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      {activeTab === "0"
        ? renderTabTT()
        : activeTab === "1"
        ? renderMucDo()
        : activeTab === "2"
        ? renderHinhThuc()
        : renderLoaiYeuCau()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
