import axiosApi from "./api_helper";
export const MatBangService = {
  getByTangLaus: async (payload) => {
    return await axiosApi()
      .post("api/mat-bang/get-bymatangs", payload)
      .then((res) => res.data);
  },
  getLoaiTien: async (payload) => {
    return await axiosApi()
      .get("api/cagegory/duan/get-loaitien", payload)
      .then((res) => res.data);
  },
  get: async (payload) => {
    return await axiosApi()
      .post("api/mat-bang/get", payload)
      .then((res) => res.data);
  },

  getMatBang: async (payload) => {
    return await axiosApi()
      .post("api/mat-bang/filter", payload)
      .then((res) => res.data);
  },
  getHinhThucTP: async (payload) => {
    return await axiosApi()
      .post("api/category/duan/get-hinhthucthuphi", payload)
      .then((res) => res.data);
  },
  add: async (payload) => {
    return await axiosApi()
      .post("api/mat-bang/add", payload)
      .then((res) => res.data);
  },
  delete: async (payload) => {
    return await axiosApi()
      .post("api/mat-bang/delete", payload)
      .then((res) => res.data);
  },

  addCongNo: async (payload) => {
    return await axiosApi()
      .post("api/mat-bang/tao-congno", payload)
      .then((res) => res.data);
  },
  addCongNoByTN: async (payload) => {
    return await axiosApi()
      .post("api/mat-bang/tao-congno-bymatn", payload)
      .then((res) => res.data);
  },
  updateDonGia: async (payload) => {
    return await axiosApi()
      .post("api/mat-bang/update-dongia", payload)
      .then((res) => res.data);
  },
  updateVAT: async (payload) => {
    return await axiosApi()
      .post("api/mat-bang/update-vat", payload)
      .then((res) => res.data);
  },
  updateDCH: async (payload) => {
    return await axiosApi()
      .post("api/mat-bang/update-chuho", payload)
      .then((res) => res.data);
  },
  updateCapNhatNgayTT: async (payload) => {
    return await axiosApi()
      .post("api/mat-bang/update-ngaytt", payload)
      .then((res) => res.data);
  },
  importExcel: async (payload) => {
    return await axiosApi()
      .post("api/mat-bang/import", payload)
      .then((res) => res.data);
  },
  updateImportExcel: async (payload) => {
    return await axiosApi()
      .post("api/mat-bang/import-update", payload)
      .then((res) => res.data);
  },

  getLichSuCN: async (payload) => {
    return await axiosApi()
      .post("api/mat-bang/get-lscapnhat", payload)
      .then((res) => res.data);
  },
  getCuDan: async (payload) => {
    return await axiosApi()
      .post("api/mat-bang/get-cudan", payload)
      .then((res) => res.data);
  },
  getTheXe: async (payload) => {
    return await axiosApi()
      .post("api/mat-bang/get-thexe", payload)
      .then((res) => res.data);
  },
  getCongNo: async (payload) => {
    return await axiosApi()
      .post("api/mat-bang/get-congno", payload)
      .then((res) => res.data);
  },
  getLichSuTP: async (payload) => {
    return await axiosApi()
      .post("api/mat-bang/get-phieuthu", payload)
      .then((res) => res.data);
  },
  getLichSuYC: async (payload) => {
    return await axiosApi()
      .post("api/mat-bang/get-lsyeucau", payload)
      .then((res) => res.data);
  },
};
