import React, { useEffect, useState } from "react";
import { DatePicker, Select } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import ActiveRoute from "components/ActiveRoute";
import { ToaNhaService } from "services/ToaNhaService";

const FilterTL = ({ ...props }) => {
  const { filterCondition, onChangeFilter, toaNha } = props;
  // const [toanha, setToanha] = useState([]);
  const [khoinha, setKhoinha] = useState([]);

  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "HinhThuc":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        break;
      case "MaTN":
        _filter[p] = v;
        _filter.MaKN = null;
        loadKhoiNha(v);
        break;
      default:
        _filter[p] = v;
        break;
    }
    onChangeFilter(_filter);
  };
  // const loadcategories = async () => {
  //   let _tn = await ToaNhaService.getToaNhaByNV();
  //   setToanha(_tn.data ?? []);
  // };
  const loadKhoiNha = async (maTN) => {
    let _tn = await ToaNhaService.Categories.getKhoiNha({
      MaTN: maTN,
    });
    setKhoinha(_tn.data);
  };
  useEffect(() => {
    // loadcategories();
    loadKhoiNha(filterCondition?.MaTN);
  }, []);
  return (
    <div>
      <div className="box-search">
        {/* <p className="label">Dự án</p> */}
        <Select
          // mode="multiple"
          value={filterCondition.MaTN}
          placeholder="Chọn dự án"
          onChange={(v) => applyChangeFilter("MaTN", v)}
          style={{
            width: "100%",
          }}
          options={toaNha}
          fieldNames={{ label: "TenTN", value: "MaTN" }}
        />
      </div>
      <div className="box-search">
        <p className="label">Khối nhà</p>
        <Select
          value={filterCondition.MaKN}
          placeholder="Chọn khối nhà"
          onChange={(v) => applyChangeFilter("MaKN", v)}
          style={{
            width: "100%",
          }}
          options={khoinha}
          fieldNames={{ label: "TenKN", value: "MaKN" }}
        />
      </div>
    </div>
  );
};
export default FilterTL;
