import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Select,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  message,
  DatePicker,
} from "antd";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import vi from "dayjs/locale/vi";
import _ from "lodash";
import DebounceSelect from "components/DebounceSelect";
import { MatBangService } from "services/MatBangService";
import { CuDanService } from "services/CuDanService";
import { YeuCauService } from "services/YeuCauService";
import { SaveOutlined } from "@ant-design/icons";

const { TextArea } = Input;
const FormAdd = (props, ref) => {
  const { onAfterSubmit, options } = props;
  const [api, contextHolder] = notification.useNotification();
  const [messageApi, contextHolderMS] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const defaultData = {
    NgayYC: new Date(),
    HanXuLy: new Date(),
    NgayHenKH: new Date(),
  };
  const [data, setData] = useState(defaultData);
  const [open, setOpen] = useState(false);
  const [cudan, setCudan] = useState([]);
  const defaultErrors = {
    MaGD: null,
    MaKH: null,
    MaHinhThuc: null,
  };
  const [errors, setErrors] = useState(defaultErrors);
  const [loaiyeucau, setLoaiyeucau] = useState([]);
  useImperativeHandle(ref, () => ({
    show: async (matn) => {
      setOpen(true);
      setData({ MaTN: matn });
    },
    update: async (data) => {
      setOpen(true);
      data.MatBang = { value: data.MaMB, label: data.MaSoMB };
      let _lyc = await YeuCauService.Categories.getLoaiYeuCau({
        MaPB: data.MaBP,
      });
      setLoaiyeucau(_lyc.data);
      let _res = await CuDanService.getCuDanByMaMB({ MaMB: data.MaMB });
      setCudan(_res.data);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaultData);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MaMB":
        let _res = await CuDanService.getCuDanByMaMB({ MaMB: v });
        setCudan(_res.data);
        _data[p] = v;
        break;
      case "MaBP":
        let _lyc = await YeuCauService.Categories.getLoaiYeuCau({ MaPB: v });
        setLoaiyeucau(_lyc.data);
        _data[p] = v;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async () => {
    let _data = _.cloneDeep(data);
    // let valid = validate();
    // if (valid?.length > 0) {
    //   return;
    // }
    _data.MaMB = _data.MatBang?.value;
    setLoading(true);
    let _res = await YeuCauService.addYeuCau(_data);
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${_data.ID ? "Sửa" : "Thêm"} nhân viên thành công!`
      );
      onClose();
      onAfterSubmit();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  async function fetchMatBang(input) {
    return MatBangService.getMatBang({
      InputSearch: input,
      MaTN: data?.MaTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.MaSoMB}`,
        value: item.MaMB,
        title: JSON.stringify(item),
      }));
    });
  }

  return (
    <Drawer
      title="Yêu cầu khách hàng"
      width={1120}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <p style={{ fontSize: 14, fontWeight: "bold", marginRight: 20 }}>
            {options?.ToaNha?.find((v) => v.MaTN === data?.MaTN)?.TenTN}
          </p>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined /> Lưu & đóng
          </Button>
        </Space>
      }
    >
      {contextHolderMS}
      {contextHolder}
      <Row gutter={[20, 8]} style={{ marginTop: 20 }}>
        <Col span={6}>
          <p style={{ color: "red" }}>Căn hộ</p>
          <DebounceSelect
           open={open}
            value={data?.MatBang}
            placeholder="Tìm theo mã mặt bằng"
            fetchOptions={fetchMatBang}
            onChange={(v) => applyChange("MatBang", v)}
            style={{
              width: "100%",
            }}
          />
        </Col>
        <Col span={6}>
          <p>Cư dân</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaKH}
            placeholder="Chọn cư dân"
            onChange={(v) => applyChange("MaKH", v)}
            options={cudan}
            fieldNames={{ label: "HoTenNK", value: "ID" }}
          />
          {/* <DebounceSelect
            // value={null}
            placeholder="Tìm theo tên, số điện thoại"
            fetchOptions={fetchMatBang}
            // onChange={(newValue) => onAdd(newValue)}
            style={{
              width: "100%",
            }}
          /> */}
        </Col>
        <Col span={6} className="gutter-row">
          <p>Khách hàng phản ánh</p>
          <Input
            value={data?.NguoiGui}
            placeholder="Nhập tên khách phản ánh"
            onChange={(e) => applyChange("NguoiGui", e.target.value)}
          />
        </Col>
        <Col span={6} className="gutter-row">
          <p>Di động</p>
          <Input
            value={data?.SoDienThoaiNguoiGui}
            placeholder="Nhập số điện thoại"
            onChange={(e) => applyChange("SoDienThoaiNguoiGui", e.target.value)}
          />
        </Col>
        <Col span={24} className="gutter-row">
          <p style={{ color: "red" }}>Tiêu đề</p>
          <Input
            value={data?.TieuDe}
            placeholder="Nhập tiêu đề"
            onChange={(e) => applyChange("TieuDe", e.target.value)}
          />
        </Col>

        <Col span={24} className="gutter-row">
          <p style={{ color: "red" }}>Nội dung</p>
          <TextArea
            value={data?.NoiDung}
            placeholder="Nhập nội dung"
            onChange={(e) => applyChange("NoiDung", e.target.value)}
          />
        </Col>
        <Col span={6} className="gutter-row">
          <p style={{ color: "red" }}>Độ ưu tiên</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaDoUuTien}
            placeholder="Chọn độ ưu tiên"
            onChange={(v) => applyChange("MaDoUuTien", v)}
            options={options?.MucDo}
            fieldNames={{ label: "TenDoUuTien", value: "MaDoUuTien" }}
          />
        </Col>
        <Col span={6} className="gutter-row">
          <p>Gửi đến</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaBP}
            placeholder="Chọn phòng ban"
            onChange={(v) => applyChange("MaBP", v)}
            options={options?.PhongBan}
            fieldNames={{ label: "TenPB", value: "MaPB" }}
          />
        </Col>
        <Col span={6} className="gutter-row">
          <p style={{ color: "red" }}>Loại yêu cầu</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaLYC}
            placeholder="Chọn loại yêu cầu"
            onChange={(v) => applyChange("MaLYC", v)}
            options={loaiyeucau}
            fieldNames={{ label: "TenLYC", value: "MaLYC" }}
          />
        </Col>

        <Col span={6} className="gutter-row">
          <p>Hình thức</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaHinhThucNhanYC}
            placeholder="Chọn hình thức"
            onChange={(v) => applyChange("MaHinhThucNhanYC", v)}
            options={options?.HinhThuc}
            fieldNames={{ label: "TenHT", value: "ID" }}
          />
        </Col>
        <Col span={6}>
          <p>Ngày yêu cầu</p>
          <DatePicker
            allowClear={false}
            placeholder="Nhập ngày yêu cầu"
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            value={data?.NgayYC ? dayjs(data?.NgayYC) : null}
            locale={vi}
            onChange={(v) => applyChange("NgayYC", v)}
          />
        </Col>
        <Col span={6}>
          <p>Hạn xử lý</p>
          <DatePicker
            allowClear={false}
            placeholder="Nhập hạn xử lý"
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            value={data?.HanXuLy ? dayjs(data?.HanXuLy) : null}
            locale={vi}
            onChange={(v) => applyChange("HanXuLy", v)}
          />
        </Col>
        <Col span={6}>
          <p>Ngày hẹn khách hàng</p>
          <DatePicker
            allowClear={false}
            placeholder="Nhập ngày hẹn KH"
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            value={data?.NgayHenKH ? dayjs(data?.NgayHenKH) : null}
            locale={vi}
            onChange={(v) => applyChange("NgayHenKH", v)}
          />
        </Col>
        <Col span={6} className="gutter-row">
          <p>Trạng thái</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaTT}
            placeholder="Chọn trạng thái"
            onChange={(v) => applyChange("MaTT", v)}
            options={options?.TrangThai?.filter((v) => v.MaTT !== 0)}
            fieldNames={{ label: "TenTT", value: "MaTT" }}
          />
        </Col>

        <Col span={6} className="gutter-row">
          <p>Nhân viên</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaNTN}
            placeholder="Chọn nhân viên"
            onChange={(v) => applyChange("MaNTN", v)}
            options={options?.NhanVien}
            fieldNames={{ label: "HoTenNV", value: "MaNV" }}
          />
        </Col>
      </Row>
    </Drawer>
  );
};
export default forwardRef(FormAdd);
