import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Flex,
  Input,
  Popconfirm,
  Table,
  Tooltip,
  notification,
} from "antd";
import FormAdd from "./FormAdd";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import { NhanVienService } from "services/NhanVienService";
import { FaUserLock } from "react-icons/fa6";
import { TbPasswordUser } from "react-icons/tb";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  ExportOutlined,
  ImportOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import FormAddExcel from "./FormAddExcel";
export default function Index() {
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterCondition, setFilterCondition] = useState({ InputSearch: "" });
  const refDetail = useRef(null);
  const refAddExcel = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  useEffect(() => {
    filter(filterCondition);
  }, []);
  const filter = async (filter) => {
    setLoading(true);
    let _res = await NhanVienService.getNhanVien(filter);
    if (_res.data) setData(_res.data);
    setLoading(false);
  };

  const onAfterSubmit = () => {
    filter(filterCondition);
  };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = () => {
    const fieldsToExport = columns.map((col) => col.dataIndex);
    const filteredData = data.map((item) => {
      let filteredItem = {};
      fieldsToExport.forEach((field) => {
        filteredItem[field] = item[field];
      });
      return filteredItem;
    });
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "Danh sách nhân viên" + fileExtension);
  };
  const columns = [
    {
      title: "Mã số",
      dataIndex: "MaSoNV",
      // ellipsis: true,
    },
    {
      title: "Họ và tên",
      dataIndex: "HoTenNV",
      // ellipsis: true,
    },
    {
      title: "Ngày sinh",
      dataIndex: "NgaySinh",
      // ellipsis: true,
      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Địa chỉ",
      dataIndex: "DiaChi",
      // ellipsis: true,
    },
    {
      title: "Email",
      dataIndex: "Email",
      // ellipsis: true,
    },
    // {
    //   title: "Chi nhánh",
    //   dataIndex: "ChiNhanh",

    // },
    {
      title: "Khoá",
      dataIndex: "IsLocked",
      // ellipsis: true,
      render: (item) => (item ? "Có" : "Không"),
    },

    {
      title: "",
      fixed: "right",
      width: "170px",
      render: (item) => {
        return (
          <div>
            {/* <Tooltip placement="topRight" title={`${item.Lock ? "Mở" :"Khoá"} tài khoản`}>
              <Button
                style={{ marginLeft: 5 }}
                type="primary"
                ghost
                icon={<FaUserLock />}
                onClick={() => lockUser(item)}
              />
            </Tooltip> */}
            <Tooltip placement="topRight" title="Khôi phục mật khẩu">
              <Button
                style={{ marginLeft: 5 }}
                type="primary"
                ghost
                icon={<TbPasswordUser />}
                onClick={() => restorePass(item.MaNV)}
              />
            </Tooltip>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 5 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá nhân viên"
              description="Bạn có chắc chắn muốn xoá nhân viên này?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.MaNV)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 5 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const lockUser = async (value) => {
    let _res = await NhanVienService.updateNhanVien({
      ...value,
      Lock: !value.Lock,
    });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let item = _data.find((v) => v.MaNV === Number(value.MaNV));
      item.Lock = !item.Lock;
      setData(_data);
      openNotification("success", "topRight", "Thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const restorePass = async (value) => {
    let _res = await NhanVienService.khoiPhucPass({
      ID: value,
    });
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        "Khôi phục mật khẩu thành công. Mật khẩu mới: 123456"
      );
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const remove = async (value) => {
    let _res = await NhanVienService.deleteNhanVien({ ID: Number(value) });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.MaNV !== Number(value));
      setData(fil);
      openNotification("success", "topRight", "Xoá nhân viên thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        filter(filterCondition);
      }, 500);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  return (
    <Box>
      <Toolbar
        left={() => (
          <Flex align="center" gap={10}>
            <Input
              style={{
                width: "300px",
                padding: "4px 11px",
                borderRadius: 10,
              }}
              placeholder="Tìm mã số mặt bằng, điện thoại, tên khách hàng"
              onChange={(e) => onSearch(e.target.value)}
            />
          </Flex>
        )}
        right={() => (
          <Flex gap={10}>
            <Button type="primary" onClick={() => refDetail.current.show()}>
              <PlusOutlined style={{ fontSize: "13px", margin: "0 -4px" }} />{" "}
              Thêm
            </Button>
            {/* <Button onClick={() => refAddExcel.current.show()}>
              <ImportOutlined style={{ fontSize: "13px", margin: "0 -2px" }} />
              Import
            </Button> */}
            <Button onClick={() => exportToCSV()}>
              {" "}
              <ExportOutlined style={{ fontSize: "13px", margin: "0 -2px" }} />
              Export
            </Button>
          </Flex>
        )}
      />
      <Table
        style={{ height: 100 }}
        loading={loading}
        dataSource={data}
        bordered
        columns={columns}
        scroll={{
          y: window.innerHeight - 250,
          // x: 2500
        }}
        size="small"
      />
      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
      {/* <FormAddExcel
        ref={refAddExcel}
        onAfterSubmit={onAfterSubmit}
        filterCondition={filterCondition}
      /> */}
      {contextHolder}
    </Box>
  );
}
