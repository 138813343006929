import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Space,
  Drawer,
  notification,
  Table,
  Upload,
  message,
  Select,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import { UploadOutlined } from "@ant-design/icons";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { ConvertExcelDateToJSDate } from "layouts/ultis/common";
const props2 = {
  name: "file",
  action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const FormAdd = (props, ref) => {
  const { onAfterSubmit, options, filterCondition } = props;
  const [api, contextHolder] = notification.useNotification();

  const [data, setData] = useState([]);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = { TenKH: null };
  const [errors, setErrors] = useState(defaultErrors);
  const [sheetExcel, setSheetExcel] = useState();
  const [workbook, setWorkbook] = useState(null);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData([]);
    setSheetExcel();
  };
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const columns = [
    { title: "Mã MB", dataIndex: "MaMB", key: "MaMB", ellipsis: true },
    {
      title: "Họ Tên NK",
      dataIndex: "HoTenNK",
      key: "HoTenNK",
      ellipsis: true,
    },
    { title: "Địa Chỉ TT", dataIndex: "DCTT", key: "DCTT", ellipsis: true },
    {
      title: "Giới Tính",
      dataIndex: "GioiTinh",
      key: "GioiTinh",
      ellipsis: true,
    },
    {
      title: "Ngày Sinh",
      dataIndex: "NgaySinh",
      key: "NgaySinh",
      ellipsis: true,
    },
    { title: "CMND", dataIndex: "CMND", key: "CMND", ellipsis: true },
    { title: "Ngày Cấp", dataIndex: "NgayCap", key: "NgayCap", ellipsis: true },
    { title: "Nơi Cấp", dataIndex: "NoiCap", key: "NoiCap", ellipsis: true },
    {
      title: "Điện Thoại",
      dataIndex: "DienThoai",
      key: "DienThoai",
      ellipsis: true,
    },
    { title: "Email", dataIndex: "Email", key: "Email", ellipsis: true },
    {
      title: "Ngày Hết Hạn DKTT",
      dataIndex: "NgayHetHanDKTT",
      key: "NgayHetHanDKTT",
      ellipsis: true,
    },
    {
      title: "Trạng Thái",
      dataIndex: "TrangThai",
      key: "TrangThai",
      ellipsis: true,
    },
    {
      title: "Quốc Tịch",
      dataIndex: "QuocTich",
      key: "QuocTich",
      ellipsis: true,
    },
    { title: "Quan Hệ", dataIndex: "QuanHe", key: "QuanHe", ellipsis: true },
    {
      title: "Diễn Giải",
      dataIndex: "DienGiai",
      key: "DienGiai",
      ellipsis: true,
    },
  ];
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const sampleData = [
    {
      MaMB: "MB001",
      HoTenNK: "Nguyễn Văn A",
      DCTT: "123 Đường ABC, Quận 1, TP.HCM",
      GioiTinh: "Nam",
      NgaySinh: "1985-05-15",
      CMND: "123456789",
      NgayCap: "2010-01-01",
      NoiCap: "Công an TP.HCM",
      DienThoai: "0901234567",
      Email: "nguyenvana@example.com",
      NgayHetHanDKTT: "2025-05-15",
      TrangThai: "Đang hoạt động",
      QuocTich: "Việt Nam",
      QuanHe: "Chủ hộ",
      DienGiai: "Khách hàng VIP",
    },
    {
      MaMB: "MB002",
      HoTenNK: "Trần Thị B",
      DCTT: "456 Đường XYZ, Quận 3, TP.HCM",
      GioiTinh: "Nữ",
      NgaySinh: "1990-07-20",
      CMND: "987654321",
      NgayCap: "2015-06-15",
      NoiCap: "Công an TP.HCM",
      DienThoai: "0912345678",
      Email: "tranthib@example.com",
      NgayHetHanDKTT: "2024-07-20",
      TrangThai: "Ngừng hoạt động",
      QuocTich: "Việt Nam",
      QuanHe: "Người phụ thuộc",
      DienGiai: "Khách hàng thường",
    },
  ];
  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(sampleData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "File mẫu " + fileExtension);
  };
  const loadDataImport = (data) => {
    let res = [];
    data?.map((item, i) => {
      if (i > 0) {
        res.push({
          STT: i + 1,
          MaMB: item[0],
          HoTenNK: item[1],
          DCTT: item[2],
          GioiTinh: item[3],
          NgaySinh:  typeof item[4] === "number"
          ? ConvertExcelDateToJSDate(item[4])
          : item[4],
          CMND: item[5],
          NgayCap:  typeof item[6] === "number"
          ? ConvertExcelDateToJSDate(item[6])
          : item[6],
          NoiCap: item[7],
          DienThoai: item[8],
          Email: item[9],
          NgayHetHanDKTT:  typeof item[10] === "number"
          ? ConvertExcelDateToJSDate(item[10])
          : item[10],
          TrangThai: item[11],
          QuocTich: item[12],
          QuanHe: item[13],
          DienGiai: item[14],
        });
      }
    });
    setData(res);
  };
  const handleSheet = (value) => {
    const wsname = workbook.SheetNames[value];
    const ws = workbook.Sheets[wsname];
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
    loadDataImport(data);
  };
  return (
    <Drawer
      title="Excel"
      width={960}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          {sheetExcel && (
            <Select
              placeholder="Chọn Sheet"
              style={{
                width: 115,
                border: "1px solid #d9d9d9",
                borderBottom: "3px solid #d9d9d",
                borderRadius: "5px",
                padding: "0 2px 0 10px",
              }}
              onChange={handleSheet}
              options={sheetExcel}
            />
          )}
          <Upload
            accept=".xlsx, .xls"
            showUploadList={false}
            beforeUpload={(file) => {
              setWorkbook(null);
              setSheetExcel(null);
              const reader = new FileReader();
              const rABS = !!reader.readAsBinaryString;
              reader.onload = (e) => {
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
                const sheets = wb.SheetNames.map((item, index) => ({
                  value: index,
                  label: item,
                }));
                if (sheets.length === 1) {
                  const wsname = wb.SheetNames[0];
                  const ws = wb.Sheets[wsname];
                  const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
                  loadDataImport(data);
                } else {
                  setWorkbook(wb);
                  setSheetExcel(sheets);
                  setData();
                }
              };
              if (rABS) reader.readAsBinaryString(file);
              else reader.readAsArrayBuffer(file);
            }}
          >
            <Button icon={<UploadOutlined />}>Upload File</Button>
          </Upload>
          <Button onClick={() => exportToCSV()}>Tải file mẫu</Button>
          <Button danger onClick={() => setData([])}>
            Xóa
          </Button>
          <Button
            loading={loading}
            // onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Table
        style={{ margin: 0, marginTop: "20px" }}
        dataSource={data}
        columns={columns}
        scroll={{ x: 1500 }}
      />
    </Drawer>
  );
};
export default forwardRef(FormAdd);
