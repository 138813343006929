import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Tabs,
  Checkbox,
  Flex,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { DatePicker } from "antd/lib";
import vi from "dayjs/locale/vi";
import { SoQuyService } from "services/SoQuyService";
import TabPane from "antd/es/tabs/TabPane";
import { ToaNhaService } from "services/ToaNhaService";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import SelectCustom from "components/Forms/SelectCustom";
import InputCustom from "components/Forms/InputCustom";
import DebounceSelect from "components/DebounceSelect";
import FormDrawerUser from "components/FormMatBang";
import { EditOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";
import { MatBangService } from "services/MatBangService";
import { DichVuService } from "services/DichVuService";
import { HeThongService } from "services/HeThongService";
import UploadComponent from "components/UploadComponent";
import { URL_UPLOAD } from "services/UploadService";
const { TextArea } = Input;

const FormAdd = (props, ref) => {
  const { onAfterSubmit, maTN, loaiBM } = props;
  const [api, contextHolder] = notification.useNotification();
  const defaulData = {
    VAT: 0,
    HeSoDC: 1,
    HaoHut: 0,
  };
  const [data, setData] = useState(defaulData);
  const [congTo, setCongTo] = useState([]);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const defaultErrors = {
    TenBM: null,
    MaLBM: null,
  };
  const [errors, setErrors] = useState(defaultErrors);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      setErrors(defaultErrors);
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
    onAfterSubmit();
  };

  const affterUpload = async (file) => {
    let _data = _.cloneDeep(data);
    if (file.length > 0) {
      _data.Link = URL_UPLOAD + file[0];
      setData(_data);
    }
  };

  const onRemove = async (file) => {
    // let _file = files.find((v) => v.Link === file.url);
    // await VisaService.HoSo.deleteTaiLieu({
    //   ID: _file?.ID,
    // });
  };

  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "IsLock":
        _data[p] = !v;
        break;

      default:
        _data[p] = v;
        break;
    }

    // Call sumTongTien with the modified _data before updating the state

    // Update the state
    setData(_data);

    // Optionally validate data
    validate(_data);
  };

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = (_data) => {
    let _errors = _.cloneDeep(errors);
    let _dtcheck = _data || data;
    Object.keys(_errors)?.map((item) => {
      _errors[item] = _dtcheck[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    const payLoad = { ..._data, MaTN: maTN };
    let _res = await HeThongService.BieuMau.add(payLoad);

    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} thông tin điện!`
      );
      setIsUpdate(true);
      if (close) onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  async function fetchMatBang(input) {
    return MatBangService.getMatBang({
      InputSearch: input,
      MaTN: maTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.MaSoMB}`,
        value: item.MaMB,
        title: JSON.stringify(item),
      }));
    });
  }

  const renderThongTinChung = () => {
    return (
      <>
        <Row style={{ marginTop: "15px" }} gutter={[20, 12]}>
          <Col span={18}>
            <Row gutter={[20, 12]}>
              <Col style={{ marginBottom: "5px" }} span={24}>
                <InputCustom
                  label="Tên biểu mẫu:"
                  applyChange={applyChange}
                  prop="TenBM"
                  value={data?.TenBM}
                  placeholder="Nhập di mã thẻ"
                />
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["TenBM"]}
                  </p>
                )}
              </Col>
              <Col span={24}>
                <SelectCustom
                  label="Loại biểu mẫu:"
                  applyChange={applyChange}
                  prop="MaLBM"
                  value={data?.MaLBM}
                  placeholder="Chọn biểu mẫu"
                  options={loaiBM}
                  fieldNames={{ label: "TenLBM", value: "MaLBM" }}
                />
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["MaLBM"]}
                  </p>
                )}
              </Col>
              <Col span={24}>
                <p style={{ fontWeight: "600", width: "13%" }}>Diễn giải:</p>
                <TextArea
                  value={data?.Description}
                  onChange={(e) => applyChange("Description", e.target.value)}
                />
                {/* </Flex> */}
              </Col>
              <Col span={12}>
                <p style={{ paddingBottom: "10px" }}>Upload file</p>
                <UploadComponent
                  files={data?.Link ? [data?.Link] : []}
                  multiple={false}
                  affterUpload={affterUpload}
                  onRemove={onRemove}
                />
              </Col>
              <Col span={12}>
                <Checkbox
                  checked={data?.IsLock}
                  value={data?.IsLock}
                  onChange={(e) => applyChange("IsLock", e.target.value)}
                >
                  Khóa biểu mẫu
                </Checkbox>
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Row gutter={[20, 12]}>
              {" "}
              <Col span={24}>
                <Checkbox
                  checked={data?.isPQL}
                  value={data?.isPQL}
                  onChange={(e) => applyChange("isPQL", !e.target.value)}
                >
                  Phí quản lý
                </Checkbox>
              </Col>{" "}
              <Col span={24}>
                <Checkbox
                  checked={data?.isTheXe}
                  value={data?.isTheXe}
                  onChange={(e) => applyChange("isTheXe", !e.target.value)}
                >
                  Phí thẻ xe
                </Checkbox>
              </Col>{" "}
              <Col span={24}>
                <Checkbox
                  checked={data?.isDien}
                  value={data?.isDien}
                  onChange={(e) => applyChange("isDien", !e.target.value)}
                >
                  Phí điện
                </Checkbox>
              </Col>{" "}
              <Col span={24}>
                <Checkbox
                  checked={data?.isDienDieuHoa}
                  value={data?.isDienDieuHoa}
                  onChange={(e) =>
                    applyChange("isDienDieuHoa", !e.target.value)
                  }
                >
                  Phí điện điều hòa
                </Checkbox>
              </Col>{" "}
              <Col span={24}>
                <Checkbox
                  checked={data?.isNuoc}
                  value={data?.isNuoc}
                  onChange={(e) => applyChange("isNuoc", !e.target.value)}
                >
                  Phí nước
                </Checkbox>
              </Col>{" "}
              <Col span={24}>
                <Checkbox
                  checked={data?.isGas}
                  value={data?.isGas}
                  onChange={(e) => applyChange("isGas", !e.target.value)}
                >
                  Phí GAS
                </Checkbox>
              </Col>{" "}
              <Col span={24}>
                <Checkbox
                  checked={data?.isPhiKhac}
                  value={data?.isPhiKhac}
                  onChange={(e) => applyChange("isPhiKhac", !e.target.value)}
                >
                  Phí khác
                </Checkbox>
              </Col>{" "}
              <Col span={24}>
                <Checkbox
                  checked={data?.isCongNo}
                  value={data?.isCongNo}
                  onChange={(e) => applyChange("isCongNo", !e.target.value)}
                >
                  Công nợ
                </Checkbox>
              </Col>{" "}
              <Col span={24}>
                <Checkbox
                  checked={data?.isChoThue}
                  value={data?.isChoThue}
                  onChange={(e) => applyChange("isChoThue", !e.target.value)}
                >
                  Cho thuê
                </Checkbox>
              </Col>{" "}
            </Row>
          </Col>
        </Row>
      </>
    );
  };
  return (
    <Drawer
      title="Thêm biểu mẫu"
      width={820}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          {/* <Button> Tải file </Button> */}
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined /> Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      {renderThongTinChung()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
