import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Select,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  message,
  DatePicker,
  Table,
} from "antd";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import vi from "dayjs/locale/vi";
import _ from "lodash";
import DebounceSelect from "components/DebounceSelect";
import { MatBangService } from "services/MatBangService";
import { CuDanService } from "services/CuDanService";
import { YeuCauService } from "services/YeuCauService";
import { SaveOutlined } from "@ant-design/icons";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import InputCustom from "components/Forms/InputCustom";
import { Editor } from "primereact/editor";
import { CongNoService } from "services/CongNoService";
import { HeThongService } from "services/HeThongService";
import SelectCustom from "components/Forms/SelectCustom";

const { TextArea } = Input;
const FormDetailMail = (props, ref) => {
  const { onAfterSubmit, options, setIsModalVisible } = props;
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [taiKhoanMail, setTaiKhoanMail] = useState([]);
  const defaultErrors = {};
  const [errors, setErrors] = useState(defaultErrors);
  const [text, setText] = useState(null);
  useImperativeHandle(ref, () => ({
    show: async (data) => {
      setData(data);
      setOpen(true);
      let _res = await HeThongService.CauHinh.taiKhoanMail.get();
      if (_res?.data) setTaiKhoanMail(_res.data);
    },
  }));
  const onClose = (value) => {
    setOpen(false);
    setIsModalVisible(value === true ? false : true);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MaMB":
        _data[p] = v;
        break;
      case "TenKH":
        _data[p] = v;
        break;
      case "MaSoMB":
        _data[p] = v;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async () => {
    let _data = _.cloneDeep(data);
    // let valid = validate();
    // if (valid?.length > 0) {
    //   return;
    // }
    _data.NoiDung = text;
    setLoading(true);
    let _res = await CongNoService.TongHop.thaoTacThongBao(_data);
    setLoading(false);
    if (_res.status === 2000) {
      openNotification("warning", "topRight", `Đang xử lý thông báo!`);
      onClose(true);
      setData({});
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  async function fetchMatBang(input) {
    return MatBangService.getMatBang({
      InputSearch: input,
      MaTN: data?.MaTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.MaSoMB}`,
        value: item.MaMB,
        title: JSON.stringify(item),
      }));
    });
  }

  const columns = [
    {
      title: "Mặt bằng",
      dataIndex: "MaSoMB",
      key: "MaSoMB",
    },
    {
      title: "Tên khách hàng",
      dataIndex: "TenKH",
      key: "TenKH",
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
    },
  ];

  return (
    <Drawer
      title="Thông báo giửi mail"
      width={1120}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <p style={{ fontSize: 14, fontWeight: "bold", marginRight: 20 }}>
            {options?.ToaNha?.find((v) => v.MaTN === data?.MaTN)?.TenTN}
          </p>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined /> Lưu & đóng
          </Button>
        </Space>
      }
    >
      <Row gutter={[20, 8]} style={{ marginTop: 20 }}>
        <Col span={13}>
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <Table columns={columns} dataSource={data?.MatBang} />
            </Col>{" "}
          </Row>
        </Col>
        <Col span={11}>
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <DatePickerCustom
                label="Thời gian:"
                applyChange={applyChange}
                prop={"ThoiGianGui"}
                value={data?.ThoiGianGui}
                placeholder={""}
              />
            </Col>
            <Col span={24}>
              {" "}
              <InputCustom
                label="Tiêu đề:"
                applyChange={applyChange}
                prop="TieuDe"
                value={data?.TieuDe}
              />
            </Col>{" "}
            <Col span={24}>
              <SelectCustom
                label="Tài khoản mail:"
                applyChange={applyChange}
                prop="MaTK"
                value={data?.MaTK}
                placeholder="Chọn tài khoản mail"
                options={taiKhoanMail}
                fieldNames={{ label: "DiaChi", value: "ID" }}
              />
            </Col>{" "}
            <Col span={24}>
              <p style={{ fontWeight: "600",paddingBottom:'5px' }}>Nội dung</p>
              <Editor
                value={text}
                onTextChange={(e) => setText(e.htmlValue)}
                style={{ minHeight: "420px" }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {contextHolder}
    </Drawer>
  );
};
export default forwardRef(FormDetailMail);
