import React, { useEffect, useRef, useState } from "react";
import { Format_Time } from "layouts/ultis/common";
import {
  notification,
  Row,
  Col,
  Flex,
  Select,
  Skeleton,
  Input,
  Dropdown,
  Button,
  Modal,
} from "antd";
import { RiFileExcel2Fill, RiSendPlaneFill } from "react-icons/ri";
import { ImAttachment } from "react-icons/im";
import { IoMdTrash } from "react-icons/io";
import _ from "lodash";
import { FaFilePdf, FaFileWord } from "react-icons/fa6";
const { TextArea } = Input;

const MessageInput = ({
  typing,
  loadingSend,
  socket,
  addMessage,
  members,
  addMessageFile,
}) => {
  const [textSend, setTextSend] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isImage,setIsImage] = useState(true);
  const [files, setFiles] = useState([]);

  const handleKeyDown = (e) => {
    // if (e.key === "Enter" && !e.shiftKey) {
    //   e.preventDefault(); // Ngăn chặn sự kiện mặc định của Enter
    //   // Gửi tin nhắn
    //   if (textSend.trim() !== "") {
    //     send();
    //     // addMessage(textSend);
    //     // setTextSend("");
    //   }
    // }
  };
  const send = () => {
    addMessage(textSend);
    setTextSend("");
  };
  const onChangeTextSend = (value) => {
    setTextSend(value);
    let _users = members.filter(
      (v) => v.user_id !== JSON.parse(localStorage.getItem("NhanVien"))?.maNV
    );
    _users?.map((item) => {
      socket.emit("typing", { data: true, userId: item.user_id });
    });
  };
  useEffect(() => {
    let time;
    if (!time) {
      time = setTimeout(() => {
        let _users = members.filter(
          (v) =>
            v.user_id !== JSON.parse(localStorage.getItem("NhanVien"))?.maNV
        );
        _users?.map((item) => {
          socket.emit("typing", { data: false, userId: item.user_id });
        });
      }, 3000);
    }
    return () => clearTimeout(time);
  }, [textSend]);
  const items = [
    {
      key: "1",
      label: <p>Ảnh/ video</p>,
      onClick: () => handleUploadImg(),
    },
    {
      key: "2",
      label: <p>Tài liệu</p>,
      onClick: () => handleUploadFile(),
    },
  ];
  const handleUploadImg = () => {
    let a = document.getElementById("imgupload");
    a?.click();
    setIsImage(true);
  };
  const handleUploadFile = () => {
    let a = document.getElementById("fileupload");
    a?.click();
    setIsImage(false);
  };
  useEffect(() => {
    const inputElement = document.getElementById("imgupload");
    inputElement.addEventListener("change", handleFiles, false);
    function handleFiles() {
      setIsModalOpen(true);
      setFiles(this.files);
    }
    const inputElement2 = document.getElementById("fileupload");
    inputElement2.addEventListener("change", handleFiles2, false);
    function handleFiles2() {
      setIsModalOpen(true);
      
      setFiles(this.files);
    }
  }, [files]);

  const renderModal = () => {
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };
    const removeImage = (index) => {
      let _f = _.cloneDeep([...files]);
      _f.splice(index, 1);
      setFiles(_f);
      if (_f?.length === 0) {
        setIsModalOpen(false);
      }
    };
    const sendFile = () => {
      addMessageFile(files,isImage);
    };
    const GetImageFile = (item) => {
      let index = item?.name?.lastIndexOf(".");
      let type = item?.name?.slice(index);
      switch (type) {
        case ".pdf":
          return <FaFilePdf size={35} />
        case ".doc":
        case ".docx":
          return <FaFileWord size={35} />;
        case ".xls":
        case ".xlsx":
          return <RiFileExcel2Fill size={35} />;
        default:
          return (
            <img
              src={URL.createObjectURL(item)}
              width={60}
              style={{
                height: "60px",
              }}
            />
          );
      }
    };
    return (
      <Modal
        title={`Gửi ` + files?.length + " file"}
        open={isModalOpen}
        onOk={handleOk}
        width={400}
        onCancel={handleCancel}
        footer={
          <Flex gap={10} justify="flex-end">
            {/* <Button onClick={() => isImage ? handleUploadImg() : handleUploadFile()}>Tải thêm file</Button> */}
            <Button
              type="primary"
              onClick={() => sendFile()}
              loading={loadingSend}
            >
              Gửi
            </Button>
          </Flex>
        }
      >
        {[...files]?.map((item, index) => {
          return (
            <Flex
              justify="space-between"
              align="center"
              gap={8}
              style={{ marginBottom: 10 }}
            >
              <Flex align="center" gap={8} style={{ width: "80%" }}>
                {GetImageFile(item)}
                <div>
                  <p
                    style={{ fontSize: 14, fontWeight: "bold", width: "100%" }}
                  >
                    {item.name}
                  </p>
                  <p style={{ color: "rgb(112,117,121)", fontSize: 12 }}>
                    {item.size} kb
                  </p>
                </div>
              </Flex>
              <Button
                type="text"
                onClick={() => removeImage(index)}
                icon={<IoMdTrash size={20} color="gray" />}
              ></Button>
            </Flex>
          );
        })}
      </Modal>
    );
  };
  return (
    <div className="content-bottom">
      {typing === true && (
        <Flex gap={10} justify="center" style={{ paddingBottom: 2 }}>
          <div style={{ width: "60%" }}>
            <div className="chat-bubble">
              <div className="typing">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            </div>
          </div>
        </Flex>
      )}
      <Flex gap={10} justify="center">
        <Flex
          align="center"
          style={{
            background: "white",
            width: "60%",
            padding: "6px 10px 6px 20px",
            borderRadius: 20,
          }}
        >
          <TextArea
            className="input"
            placeholder="Tin nhắn"
            autoSize
            value={textSend}
            onKeyDown={handleKeyDown}
            onChange={(v) => onChangeTextSend(v.target.value)}
          />
          <Dropdown
            menu={{
              items,
            }}
            placement="topRight"
          >
            <Button
              type="text"
              icon={<ImAttachment size={20} color="gray" />}
            />
          </Dropdown>
        </Flex>
        {textSend?.length > 0 && (
          <Button
            className="button-send"
            onClick={() => send()}
            loading={loadingSend}
            icon={<RiSendPlaneFill size={20} color="rgb(51,144,236)" />}
          ></Button>
        )}
      </Flex>
      <input
        accept="image/*"
        type="file"
        multiple
        id="imgupload"
        style={{ display: "none" }}
      />
      <input
        accept=".pdf,.doc,.docx,.xlsx,.xls"
        type="file"
        multiple
        id="fileupload"
        style={{ display: "none" }}
      />
      {renderModal()}
    </div>
  );
};

export default React.memo(MessageInput);
