import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  notification,
  Row,
  Col,
  Flex,
  Select,
  Skeleton,
  Input,
  Image,
  Button,
} from "antd";
import _ from "lodash";
import "./index.scss";
import { ChatsService } from "services/ChatsService";
import { FaFilePdf, FaFileWord, FaUserGroup } from "react-icons/fa6";
import { RiFileExcel2Fill } from "react-icons/ri";
import ListMember from "./ListMember";
const BoxRight = (props, ref) => {
  const { members, images } = props;
  const refListMember = useRef(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
  }, []);
  useImperativeHandle(ref, () => ({
    show: async () => {
      filterMemberGroup(members);
    },
  }));
  const filterMemberGroup = async (memberReq) => {
    // let _members = memberReq || members;
    let mem = [];
    await Promise.all(
      memberReq?.map(async (item) => {
        if (item.is_admin) {
          let _user = await ChatsService.User.getById(item.user_id);
          mem.push({
            ...item,
            // user_id: _user?.data?.MaNV,
            name: _user?.data?.HoTenNV,
            //is_admin: true,
          });
        } else {
          let _user = await ChatsService.MatBang.getById(item.user_id);
          mem.push({
            ...item,
            // user_id: _user?.data?.MaMB,
            name: _user?.data?.MaSoMB,
            // is_admin: false,
          });
        }
      })
    );
    // setMembers(mem);
  };
  const IsImage = (url) => {
    let index = url.lastIndexOf(".");
    let type = url.slice(index);
    switch (type) {
      case ".pdf":
      case ".doc":
      case ".docx":
      case ".xls":
      case ".xlsx":
        return false;
      default:
        return true;
    }
  };
  const GetInfoFile = (url) => {
    let index = url.lastIndexOf(".");
    let type = url.slice(index);
    let startindex = url.lastIndexOf("/");
    let endindex = url.indexOf(type);
    let name = url.slice(startindex + 1, endindex);
    switch (type) {
      case ".pdf":
        return (
          <Flex align="center" gap={5}>
            <a href={url} target="_blank">
              <FaFilePdf size={35} />
            </a>
            <p style={{ fontSize: 12, fontWeight: "bold" }}>
              {name}
              {type}
            </p>
          </Flex>
        );
      case ".doc":
      case ".docx":
        return (
          <Flex align="center" gap={5}>
            <a href={url} target="_blank">
              <FaFileWord size={35} />
            </a>
            <p style={{ fontSize: 12, fontWeight: "bold" }}>
              {name}
              {type}
            </p>
          </Flex>
        );
      case ".xls":
      case ".xlsx":
        return (
          <Flex align="center" gap={5}>
            <a href={url} target="_blank">
              <RiFileExcel2Fill size={35} />
            </a>
            <p style={{ fontSize: 12, fontWeight: "bold" }}>
              {name}
              {type}
            </p>
          </Flex>
        );
    }
  };
  return (
    <Col span={6} className="right-chats">
      <Flex
        align="center"
        style={{
          height: "47px",
          boxShadow: "0 2px 2px rgb(114,114,114,0.169)",
          padding: "0px 10px",
        }}
      >
        <p style={{ fontSize: 15, fontWeight: "bold" }}>Thông tin</p>
      </Flex>
      <div className="detail">
        <p style={{ fontWeight: "bold", marginBottom: 5 }}>Thành viên nhóm</p>
        <Flex gap={10} onClick={() => refListMember.current.show(members)} style={{cursor:'pointer'}}>
          <FaUserGroup size={20} color="gray" />
          <p>{members?.length} thành viên</p>
        </Flex>
        <p style={{ fontWeight: "bold", marginBottom: 5, marginTop: 15 }}>
          Ảnh/ video
        </p>
        <Image.PreviewGroup>
          <Row>
            {images?.map((item) => {
              return JSON.parse(item.message)?.map((url, index) => {
                return (
                  IsImage(url) && (
                    <Col span={8}>
                      <Image
                        key={index}
                        style={{ paddingRight: 10 }}
                        width="100%"
                        height={100}
                        src={url}
                      />
                    </Col>
                  )
                );
              });
            })}
          </Row>
        </Image.PreviewGroup>

        <p style={{ fontWeight: "bold", marginBottom: 5, marginTop: 15 }}>
          File
        </p>
        <Row>
          {images?.map((item) => {
            return JSON.parse(item.message)?.map((url, index) => {
              return (
                <div style={{ marginBottom: 10 }}>
                  {!IsImage(url) && <Col span={24}>{GetInfoFile(url)}</Col>}
                </div>
              );
            });
          })}
        </Row>
        <p style={{ fontWeight: "bold", marginBottom: 5, marginTop: 15 }}>
          Thiết lập bảo mật
        </p>
        <div>
          <Button type="text" danger>
            Xoá nhóm
          </Button>
          <Button type="text" danger>
            Rời nhóm
          </Button>
        </div>
      </div>
      <ListMember ref={refListMember} />
    </Col>
  );
};
export default React.memo(forwardRef(BoxRight));
