import { Box, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { HomeService } from "services/HomeService";
import {
  Button,
  Col,
  Popconfirm,
  Row,
  Table,
  Tabs,
  Tooltip,
  notification,
} from "antd";
import FormAdd from "./FormAdd";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import { IoEyeSharp, IoSettingsOutline } from "react-icons/io5";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import { CongTyService } from "services/CongTyService";
import { TabView, TabPanel } from "primereact/tabview";
import TabPane from "antd/es/tabs/TabPane";
import { NhanVienService } from "services/NhanVienService";
import FilterPB from "./FilterPB";
import { ToaNhaService } from "services/ToaNhaService";
import { PlusOutlined } from "@ant-design/icons";
import { BiSolidUserPlus } from "react-icons/bi";
import FormAddNV from "./FormAddNV";

export default function Settings() {
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toaNha, setToaNha] = useState([]);
  const [filterCondition, setFilterCondition] = useState({
    MaTN: null,
  });
  const refDetail = useRef(null);
  const refAddNV = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const tabs = [
    {
      title: "Chức vụ",
      key: "0",
    },
    {
      title: "Phòng ban",
      key: "1",
    },
  ];
  const [activeTab, setActiveTab] = useState("0");
  useEffect(() => {
    ToaNhaService.getToaNhaByNV().then((res) => {
      let _filter = _.cloneDeep(filterCondition);
      _filter.MaTN = res.data[0].MaTN;
      if (res.data) {
        setToaNha(res.data);
      }
      filter(activeTab, _filter);
      setFilterCondition(_filter);
    });
  }, []);

  const filter = async (tab, _filter) => {
    setLoading(true);
    if (tab === "0") {
      let _res = await NhanVienService.Categories.getChucVu();
      if (_res.data) setData(_res.data);
    } else if (tab === "1") {
      let _res = await NhanVienService.Categories.getPhongBan(_filter);
      if (_res.data) setData(_res.data);
    }

    setLoading(false);
  };
  const onAfterSubmit = () => {
    filter(activeTab, filterCondition);
  };
  const columns = [
    {
      title: "Tên chức vụ",
      dataIndex: "TenCV",
    },
    {
      title: (
        <div style={{ textAlign: "center" }}>
          <Button
            type="primary"
            onClick={() => refDetail.current.show(activeTab, filterCondition)}
          >
            <PlusOutlined style={{ fontSize: "13px", margin: "0 -4px" }} /> Thêm
          </Button>
        </div>
      ),
      fixed: "right",
      width: "120px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item, activeTab)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá danh mục"
              description="Bạn có chắc chắn muốn xoá danh mục?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.MaCV)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const columns2 = [
    {
      title: "Tên phòng ban",
      dataIndex: "TenPB",
    },
    {
      title: (
        <div style={{ textAlign: "center" }}>
          <Button
            type="primary"
            onClick={() => refDetail.current.show(activeTab, filterCondition)}
          >
            <PlusOutlined style={{ fontSize: "13px", margin: "0 -4px" }} /> Thêm
          </Button>
        </div>
      ),
      fixed: "right",
      width: "150px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Thêm nhân viên">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<BiSolidUserPlus />}
                onClick={() => refAddNV.current.show(item)}
              />
            </Tooltip>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item, activeTab)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá danh mục"
              description="Bạn có chắc chắn muốn xoá danh mục?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.MaPB)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = null;
    switch (activeTab) {
      case "0":
        _res = await NhanVienService.Categories.deleteChucVu({
          ID: Number(value),
        });
        if (_res.status === 2000) {
          let _data = _.cloneDeep(data);
          let fil = _data.filter((v) => v.MaCV !== Number(value));
          setData(fil);
          openNotification("success", "topRight", "Xoá danh mục thành công");
        } else {
          openNotification("error", "topRight", _res?.message);
        }
        break;
      case "1":
        _res = await NhanVienService.Categories.deletePhongBan({
          ID: Number(value),
        });
        if (_res.status === 2000) {
          let _data = _.cloneDeep(data);
          let fil = _data.filter((v) => v.MaPB !== Number(value));
          setData(fil);
          openNotification("success", "topRight", "Xoá danh mục thành công");
        } else {
          openNotification("error", "topRight", _res?.message);
        }
        break;
      default:
        break;
    }
  };
  const onChange = (newActiveKey) => {
    setActiveTab(newActiveKey);
    filter(newActiveKey, filterCondition);
  };
  const onChangeFilter = (_filter) => {
    filter(activeTab, _filter);
    setFilterCondition(_filter);
  };
  return (
    <Box>
      {/* <Toolbar
        left={() => (
          <Button
            type="primary"
            onClick={() => refDetail.current.show(activeTab)}
          >
            Thêm
          </Button>
        )}
      /> */}
      <Tabs onChange={onChange} activeKey={activeTab}>
        {tabs?.map((item) => {
          return (
            <TabPane tab={item.title} key={item.key}>
              {activeTab === "0" ? (
                <Table
                  loading={loading}
                  dataSource={data}
                  data
                  columns={columns}
                  scroll={{
                    y: window.innerHeight - 350,
                    // x: 2500
                  }}
                  size="small"
                  bordered
                />
              ) : (
                <Row gutter={[10, 10]}>
                  <Col span={4}>
                    <FilterPB
                      filterCondition={filterCondition}
                      onChangeFilter={onChangeFilter}
                    />
                  </Col>
                  <Col span={20}>
                    <Table
                      loading={loading}
                      dataSource={data}
                      columns={columns2}
                      scroll={{
                        y: window.innerHeight - 350,
                        // x: 250
                      }}
                      size="small"
                      bordered
                    />
                    :
                  </Col>
                </Row>
              )}
            </TabPane>
          );
        })}
      </Tabs>

      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} toaNha={toaNha} />
      <FormAddNV ref={refAddNV} />
      {contextHolder}
    </Box>
  );
}
