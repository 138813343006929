import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Space,
  Drawer,
  notification,
  Table,
  Upload,
  message,
  Select,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import { SaveOutlined, UploadOutlined } from "@ant-design/icons";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import { CategoryService } from "services/CategoryService";
import { ConvertExcelDateToJSDate } from "layouts/ultis/common";
const props2 = {
  name: "file",
  action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const FormAdd = (props, ref) => {
  const { onAfterSubmit, options, maTN } = props;
  const [api, contextHolder] = notification.useNotification();

  const [data, setData] = useState([]);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = { TenKH: null };
  const [errors, setErrors] = useState(defaultErrors);
  const [sheetExcel, setSheetExcel] = useState();
  const [workbook, setWorkbook] = useState(null);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData([]);
    setWorkbook();
    setCol(columns);
  };

  const columns = [
    {
      title: "Mã số MB",
      dataIndex: "MaSoMB",
      key: "MaSoMB",
      ellipsis: true,
    },
    {
      title: "Mã số KH",
      dataIndex: "MaSoKH",
      key: "MaSoKH",
      ellipsis: true,
    },
    {
      title: "Tên KH",
      dataIndex: "TenKH",
      key: "TenKH",
      ellipsis: true,
    },
    {
      title: "Số phiếu",
      dataIndex: "SoPhieu",
      key: "SoPhieu",
      ellipsis: true,
    },
    {
      title: "Ngày đăng kí",
      dataIndex: "NgayDK",
      key: "NgayDK",
      ellipsis: true,
    },
    {
      title: "Loại đồng hồ",
      dataIndex: "LoaiDongHo",
      key: "LoaiDongHo",
      ellipsis: true,
    },
    {
      title: "Mã đồng hồ",
      dataIndex: "MaDongHo",
      key: "MaDongHo",
      ellipsis: true,
    },
    {
      title: "Nhãn hiệu",
      dataIndex: "NhanHieu",
      key: "NhanHieu",
      ellipsis: true,
    },
    {
      title: "Tên đồng hồ",
      dataIndex: "TenDongHo",
      key: "TenDongHo",
      ellipsis: true,
    },
    {
      title: "Diễn giải",
      dataIndex: "DienGiai",
      key: "DienGiai",
      ellipsis: true,
    },
  ];
  const [col, setCol] = useState(columns);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const sampleData = [
    {
      MaSoMB: "A0302",
      MaSoKH: "A0302",
      TenKH: "Nguyễn Văn A",
      SoPhieu: "A0302",
      NgayDK: "2024-01-16",
      LoaiDongHo: "Đồng hồ đo nước sinh hoạt",
      MaDongHo: "A1111",
      TenDongHo: "A&777",
      DienGiai: "",
    },
    {
      MaSoMB: "A0302",
      MaSoKH: "A0302",
      TenKH: "Nguyễn Văn A",
      SoPhieu: "A0302",
      NgayDK: "2024-01-16",
      LoaiDongHo: "Đồng hồ đo nước sinh hoạt",
      MaDongHo: "A00002",
      TenDongHo: "A*888",
      DienGiai: "",
    },
  ];
  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(sampleData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "File mẫu " + fileExtension);
  };
  const loadDataImport = (data) => {
    let res = [];
    data?.map((item, i) => {
      if (i > 0) {
        res.push({
         
          MaSoMB: item[0]?.toString() ?? null,
          MaSoKH: item[1]?.toString() ?? null,
          TenKH: item[2]?.toString() ?? null,
          SoPhieu: item[3]?.toString() ?? null,
          NgayDK:  typeof item[4] === "number"
          ? ConvertExcelDateToJSDate(item[4])
          : item[4],
          LoaiDongHo: item[5]?.toString() ?? null,
          MaDongHo: item[6]?.toString() ?? null,
          TenDongHo: item[7]?.toString() ?? null,
          DienGiai: item[8]?.toString() ?? null,
        });
      }
    });
    setData(res);
  };
  const handleSheet = (value) => {
    const wsname = workbook.SheetNames[value];
    const ws = workbook.Sheets[wsname];
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
    loadDataImport(data);
    setCol(columns);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const onSubmit = async () => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    if (!_data) {
      setLoading(false);
      return;
    }
    const formatDate = (dateString) => {
      if (dayjs(dateString, "DD/MM/YYYY", true).isValid()) {
        return dayjs(dateString, "DD/MM/YYYY").format("YYYY-MM-DD");
      } else if (dayjs(dateString, "YYYY/MM/DD", true).isValid()) {
        return dayjs(dateString, "YYYY/MM/DD").format("YYYY-MM-DD");
      } else if (dayjs(dateString, "DD-MM-YYYY", true).isValid()) {
        return dayjs(dateString, "DD-MM-YYYY").format("YYYY-MM-DD");
      } else {
        return dateString;
      }
    };
    const filterData = _data.map((item) => {
      return {
        ...item,
        NgayDK: formatDate(item?.NgayDK),
      };
    });
    let _res;
    if (isupdate) {
      // _res = await KhachHangService.KhachHang.updateImport({
      //   MaTN: maTN,
      //   List: filterData,
      // });
    } else {
      _res = await CategoryService.DichVu.Nuoc.DongHo.import({
        MaTN: maTN,
        List: filterData,
      });
    }

    setLoading(false);
    onAfterSubmit();
    if (_res.status === 2000 && _res.data.length === 0) {
      openNotification("success", "topRight", "Thao tác thành công");

      onClose();
    } else {
      const pushErrorData = _data.map((item) => {
        let updatedItem = { ...item };
        _res?.data.forEach((i) => {
          if (item?.MaSoMB === i?.maSoMB) {
            updatedItem = { ...item, error: i.error };
          }
        });
        return updatedItem;
      });
      setData(pushErrorData.filter((item) => item.error));
      setCol([
        {
          title: "Error",
          dataIndex: "error",
          key: "error",
          width: 180,
          render: (item) => <p style={{ color: "red" }}>{item}</p>,
        },
        ...columns,
      ]);
      // onClose()
      openNotification("success", "topRight", "Thao tác thành công");
    }
  };
  return (
    <Drawer
      title="Excel"
      width={960}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          {sheetExcel && (
            <Select
              placeholder="Chọn Sheet"
              style={{
                width: 115,
                border: "1px solid #d9d9d9",
                borderBottom: "3px solid #d9d9d",
                borderRadius: "5px",
                padding: "0 2px 0 10px",
              }}
              onChange={handleSheet}
              options={sheetExcel}
            />
          )}
          <Upload
            accept=".txt, .csv, .xlsx, .xls"
            showUploadList={false}
            beforeUpload={(file) => {
              setWorkbook(null);
              setSheetExcel(null);
              const reader = new FileReader();
              const rABS = !!reader.readAsBinaryString;
              reader.onload = (e) => {
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
                const sheets = wb.SheetNames.map((item, index) => ({
                  value: index,
                  label: item,
                }));
                if (sheets.length === 1) {
                  const wsname = wb.SheetNames[0];
                  const ws = wb.Sheets[wsname];
                  const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
                  loadDataImport(data);
                  setCol(columns);
                } else {
                  setWorkbook(wb);
                  setSheetExcel(sheets);
                  setData();
                }
              };
              if (rABS) reader.readAsBinaryString(file);
              else reader.readAsArrayBuffer(file);
            }}
          >
            <Button icon={<UploadOutlined />}>Upload File</Button>
          </Upload>
          <Button onClick={() => exportToCSV()}>Tải file mẫu</Button>
          <Button loading={loading} onClick={() => onSubmit()} type="primary">
            <SaveOutlined /> Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Table
        style={{ margin: 0, marginTop: "20px" }}
        dataSource={data}
        columns={col}
        scroll={{
          y: window.innerHeight - 250,
          x: 1300,
        }}
      />
    </Drawer>
  );
};
export default forwardRef(FormAdd);
