import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Tabs,
  Checkbox,
  Flex,
  Radio,
  Upload,
} from "antd";
import {
  SearchOutlined,
  PlusOutlined,
  EditOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import _, { cloneDeep } from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { DatePicker } from "antd/lib";
import vi from "dayjs/locale/vi";
import { SoQuyService } from "services/SoQuyService";
import TabPane from "antd/es/tabs/TabPane";
import { ToaNhaService } from "services/ToaNhaService";
import SelectCustom from "components/Forms/SelectCustom";
import InputCustom from "components/Forms/InputCustom";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import DebounceSelect from "components/DebounceSelect";
import DatePickerCustom from "components/Forms/DatePickerCustom";

import FormDrawerUser from "components/FormMatBang";
import { DateTime } from "luxon";
import { MatBangService } from "services/MatBangService";
import { KhachHangService } from "services/KhachHangService";
import { CategoryService } from "services/CategoryService";
import UploadComponent from "components/UploadComponent";
import { URL_UPLOAD } from "services/UploadService";
const { TextArea } = Input;

const FormAdd = (props, ref) => {
  const { onAfterSubmit, filterCondition } = props;
  const [api, contextHolder] = notification.useNotification();
  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");
  const defaulData = {
    NgayDKTTMoi: dateInHoChiMinh.toISO(),
    NgayDKTTCu: dateInHoChiMinh.toISO(),
    NgayDK: dateInHoChiMinh.toISO(),
    NgayChuyenDen: dateInHoChiMinh.toISO(),
    NgayChuyenDi: dateInHoChiMinh.toISO(),
    NgayCap: dateInHoChiMinh.toISO(),
    NgaySinh: dateInHoChiMinh.toISO(),
    NgayHetHanDKTT: dateInHoChiMinh.toISO(),
    NhanSMS: false,
    NhanEmail: false,
    DangKyDinhMuc: false,
    DaDKTT: false,
  };

  const [data, setData] = useState(defaulData);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState(1);
  const [fileList, setFileList] = useState([]);
  const [trangThai, setTrangThai] = useState([]);
  const [quanHe, setQuanHe] = useState([]);
  const defaultErrors = {
    // ThanhTien: null,
  };
  const [errors, setErrors] = useState(defaultErrors);
  const affterUpload = async (file) => {
    let _data = _.cloneDeep(data);
    if (file.length > 0) {
      _data.Avatar = URL_UPLOAD + file[0];
      setData(_data);
    }
  };
  const onRemove = async (file) => {
    // let _file = files.find((v) => v.Link === file.url);
    // await VisaService.HoSo.deleteTaiLieu({
    //   ID: _file?.ID,
    // });
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      const _filter = _.cloneDeep(filterCondition);
      setOpen(true);
      setIsUpdate(false);
      let _trangThai = await CategoryService.KhachHang.CuDan.TrangThai.get(
        _filter
      );
      if (_trangThai?.data) setTrangThai(_trangThai?.data);
      let _quanHe = await CategoryService.KhachHang.CuDan.QuanHe.get(_filter);
      if (_quanHe?.data) setQuanHe(_quanHe?.data);
    },
    update: async (data) => {
      const _filter = _.cloneDeep(filterCondition);
      setOpen(true);
      setIsUpdate(true);
      setData(data);
      let _trangThai = await CategoryService.KhachHang.CuDan.TrangThai.get(
        _filter
      );
      if (_trangThai?.data) setTrangThai(_trangThai?.data);
      let _quanHe = await CategoryService.KhachHang.CuDan.QuanHe.get(_filter);
      if (_quanHe?.data) setQuanHe(_quanHe?.data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MaSoMB":
        const KH = JSON.parse(v.title);
        _data.TenKH = KH.TenKH;
        _data.MaKH = KH.MaKH;
        _data.ParentID = KH.MaKH;
        _data.MaSoMB = KH.MaSoMB;
        _data.MaMB = KH.MaMB;
        _data.MaTN = KH.MaTN;
        break;
      case "NhanSMS":
      case "NhanEmail":
      case "DangKyDinhMuc":
      case "DaDKTT":
        _data[p] = !v;
        break;
      case "GioiTinh":
        setSelectedValue(v);
        _data.GioiTinh = v === 1 ? true : false;
        break;
      case "NgaySinh":
        const date = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString = date.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString;
        break;
      case "NgayHetHanDKTT":
        const date99 = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString99 = date99.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString99;
        break;
      case "NgayCap":
        const date2 = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString2 = date2.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString2;
        break;
      case "NgayChuyenDi":
        const date3 = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString3 = date3.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString3;
        break;
      case "NgayChuyenDen":
        const date4 = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString4 = date4.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString4;
        break;
      case "NgayDK":
        const date5 = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString5 = date5.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString5;
        break;
      case "NgayDKTTCu":
        const date6 = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString6 = date6.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString6;
        break;
      case "NgayDKTTMoi":
        const date7 = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString7 = date7.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString7;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  // C2 applyChanges
  // const applyChange = async (p, v) => {
  //   let _data = _.cloneDeep(data);

  //   const convertDate = (date) => {
  //     return DateTime.fromJSDate(date, { zone: "utc" })
  //       .setZone("Asia/Ho_Chi_Minh")
  //       .toISO();
  //   };

  //   switch (p) {
  //     case "GioiTinh":
  //       setSelectedValue(v);
  //       _data.GioiTinh = v;
  //       break;
  //     case "NgaySinh":
  //     case "NgayCap":
  //     case "NgayChuyenDi":
  //     case "NgayChuyenDen":
  //     case "NgayDK":
  //     case "NgayDKTTCu":
  //     case "NgayDKTTMoi":
  //       _data[p] = convertDate(v.$d);
  //       break;
  //     default:
  //       _data[p] = v;
  //       break;
  //   }

  //   setData(_data);
  // };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    // let _res = await KhachHangService.CuDan.add(_data);
    // let _res = await KhachHangService.CuDan.add({
    //   NgayDKTTMoi: "2024-08-02T17:21:35.630+07:00",
    //   NgayDKTTCu: "2024-08-02T17:21:35.630+07:00",
    //   NgayDK: "2024-08-02T17:21:35.630+07:00",
    //   NgayChuyenDen: "2024-08-02T17:21:35.630+07:00",
    //   NgayChuyenDi: "2024-08-02T17:21:35.630+07:00",
    //   NgayCap: "2024-08-02T17:21:35.630+07:00",
    //   NgaySinh: "2024-08-02T17:21:35.630+07:00",
    //   NgayHetHanDKTT: "2024-08-02T17:21:35.630+07:00",
    //   NhanSMS: true,
    //   NhanEmail: true,
    //   DangKyDinhMuc: true,
    //   DaDKTT: true,
    //   HoTenNK: "k",
    //   QuanHeID: 169,
    //   CMND: "k",
    //   NguyenQuan: "k",
    //   NoiCap: "k",
    //   DCTT: "k",
    //   QuocTich: "k",
    //   DanToc: "k",
    //   TonGiao: "k",
    //   DienThoai: "k",
    //   NgheNghiep: "k",
    //   Email: "k",
    //   NoiLamViec: "k",
    //   TenKH: "Trần Quốc Dũng",
    //   MaKH: 61850,
    //   ParentID: 61850,
    //   MaSoMB: "203A",
    //   MaMB: 79580,
    //   MaTN: 1123,
    //   MaTT: 51,
    //   MaTheRaVao: "k",
    //   DienGiai: "k",
    //   Avatar:
    //     "https://upload.beesky.vn/upload//bc6353e2a3419e95583037b80a0325b8.jpg_638582162843330433.jpg",
    // });

    let _res = await KhachHangService.CuDan.add({
      // ID: 29655,
      CodeID: null,
      MaMB: 79584,
      MaKH: 61855,
      NgayDK: "2024-08-02T16:31:59.937",
      HoTenNK: "h",
      QuocTich: "h",
      // GioiTinh: "Nam",
      NgaySinh: "2024-08-02T16:31:59.937",
      CMND: "h",
      NgayCap: "2024-08-02T16:31:59.937",
      NoiCap: "h",
      DCTT: "h",
      DienThoai: "h",
      Email: "h",
      DienGiai: "h",
      DaDKTT: true,
      MaNV: 338,
      MaTT: 51,
      NgayHetHanDKTT: "2024-08-02T16:31:59.937",
      DangKyDinhMuc: true,
      ParentID: 61855,
      QuanHeID: null,
      DanToc: "h",
      TonGiao: "h",
      DienThoai2: null,
      Email2: null,
      SoThiThucVISA: null,
      HanThiThucVISA: null,
      NgheNghiep: "h",
      NoiLamViec: "h",
      MaTheCuDan: "h",
      MaTheRaVao: "h",
      Avatar:
        "https://upload.beesky.vn/upload//Hình-Lisa-cute.jpg_638582131812484160.jpg",
      NgayChuyenDen: "2024-08-02T16:31:59.937",
      NgayChuyenDi: "2024-08-02T16:31:59.937",
      NhanEmail: true,
      NhanSMS: true,
      MaTN: 1123,
      MatKhau: null,
      NguyenQuan: "h",
      Status: true,
      NgayDKTTCu: "2024-08-02T16:31:59.937",
      NgayDKTTMoi: "2024-08-02T16:31:59.937",
      NgayConLai: "0",
      NgayConDKTT: 0,
      TenKH: "Anh Việt",
      MauNen: null,
      TenTrangThai: "Mới",
      HoTenNV: "admin",
      MaSoMB: "208A",
    });

    // if (!_data?.MaTN) {
    //   setData((pre) => {
    //     return { ...pre, MaTN: _res.data };
    //   });
    // }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} phiếu thu thành công!`
      );
      setIsUpdate(true);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  async function fetchMatBang(input) {
    return MatBangService.getMatBang({
      InputSearch: input,
      MaTN: filterCondition?.MaTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.MaSoMB}`,
        value: item.MaMB,
        title: JSON.stringify(item),
      }));
    });
  }

  const renderThongTinChung = () => {
    return (
      <>
        <Row gutter={[20, 12]}>
          <Col span={12} style={{ marginTop: "20px" }}>
            <Row gutter={[20, 12]}>
              <Col span={12}>
                <InputCustom
                  label="Họ tên:"
                  applyChange={applyChange}
                  prop="HoTenNK"
                  value={data?.HoTenNK}
                  placeholder="Nhập họ và tên"
                  errors={errors}
                />
              </Col>
              <Col span={12}>
                <DatePickerCustom
                  label="Ngày sinh:"
                  applyChange={applyChange}
                  prop={"NgaySinh"}
                  value={data?.NgaySinh}
                  placeholder={"Chọn ngày sinh"}
                />
              </Col>
              <Col span={12}>
                {" "}
                <SelectCustom
                  label="Quan hệ:"
                  applyChange={applyChange}
                  prop="QuanHeID"
                  value={data?.QuanHeID}
                  placeholder="Chọn quan hệ"
                  options={quanHe}
                  fieldNames={{ label: "Name", value: "ID" }}
                />
              </Col>
              <Col span={12}>
                <InputCustom
                  label="CCCD/Passport:"
                  applyChange={applyChange}
                  prop={"CMND"}
                  value={data?.CMND}
                  placeholder="Nhập cccd hoặc passport"
                />
              </Col>
              <Col span={12}>
                {" "}
                <InputCustom
                  label="Quốc tịch:"
                  applyChange={applyChange}
                  prop="QuocTich"
                  value={data?.QuocTich}
                  placeholder="Nhập quốc tịch"
                />
              </Col>
              <Col span={12}>
                {" "}
                <InputCustom
                  label="Dân tộc:"
                  applyChange={applyChange}
                  prop="DanToc"
                  value={data?.DanToc}
                  placeholder="Nhập dân tộc"
                />
              </Col>
              <Col span={12}>
                <InputCustom
                  label="Điện thoại:"
                  applyChange={applyChange}
                  prop={"DienThoai"}
                  value={data?.DienThoai}
                  placeholder="Nhập số điện thoại "
                />
              </Col>
              <Col span={12}>
                <InputCustom
                  label="Nghề nghiệp:"
                  applyChange={applyChange}
                  prop="NgheNghiep"
                  value={data?.NgheNghiep}
                  placeholder="Nhập nghề nghiệp"
                />
              </Col>
              <Col span={12}>
                <InputCustom
                  label="Nơi làm việc:"
                  applyChange={applyChange}
                  prop="NoiLamViec"
                  value={data?.NoiLamViec}
                  placeholder="Nhập nơi làm việc"
                />
              </Col>

              <Col span={12}>
                <Flex style={{ borderBottom: "1px solid #d9d9d9" }}>
                  <p
                    style={{
                      fontWeight: "600",
                      width: "30% ",
                    }}
                  >
                    Mặt bằng:
                  </p>
                  <DebounceSelect
                   open={open}   
                    value={data?.MaSoMB}
                    placeholder="Tìm theo mã mặt bằng"
                    fetchOptions={fetchMatBang}
                    onChange={(v) => applyChange("MaSoMB", v)}
                    style={{
                      width: "100%",
                    }}
                  />
                </Flex>
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["MaSoMB"]}
                  </p>
                )}
              </Col>
              <Col span={12}>
                <SelectCustom
                  label="Chủ hộ:"
                  applyChange={applyChange}
                  prop="TenKH"
                  value={data?.TenKH}
                  placeholder="Chọn chủ hộ"
                  // options={[]}
                  // fieldNames={{ label: "TenLMB", value: "MaLMB" }}
                  errors={errors}
                  disabled={true}
                />
              </Col>
              <Col span={12}>
                <InputCustom
                  label="Mã thẻ cư dân:"
                  applyChange={applyChange}
                  prop={"MaTheCuDan"}
                  value={data?.MaTheCuDan}
                  placeholder="Nhập mã thẻ cư dân"
                />
              </Col>
              <Col span={12}>
                <InputCustom
                  label="Mã thẻ ra vào:"
                  applyChange={applyChange}
                  prop={"MaTheRaVao"}
                  value={data?.MaTheRaVao}
                  placeholder="Nhập mã thẻ ra vào"
                />
              </Col>
              <Col span={12}>
                <SelectCustom
                  label="Trạng thái:"
                  applyChange={applyChange}
                  prop="MaTT"
                  value={data?.MaTT}
                  placeholder="Chọn trạng thái"
                  options={trangThai}
                  fieldNames={{ label: "TenTrangThai", value: "MaTT" }}
                  errors={errors}
                />
              </Col>
              <Col span={12}>
                <DatePickerCustom
                  label="Ngày hết hạn DKTT:"
                  applyChange={applyChange}
                  prop={"NgayHetHanDKTT"}
                  value={data?.NgayHetHanDKTT}
                  placeholder={"Chọn ngày hết hạn DKTT"}
                />
              </Col>
              <Col span={24}>
                <Flex>
                  <p
                    style={{
                      fontWeight: "600",
                      width: "20%",
                      borderBottom: "1px solid #d9d9d9",
                    }}
                  >
                    Diễn giải:
                  </p>
                  <TextArea
                    value={data?.DienGiai}
                    onChange={(e) => applyChange("DienGiai", e.target.value)}
                  />
                </Flex>
              </Col>
              <Col span={24}>
                <Flex>
                  <p
                    style={{
                      fontWeight: "600",
                      // width: "20%",
                      marginRight: "20px",
                    }}
                  >
                    Tùy chọn:
                  </p>
                  <Checkbox
                    value={data?.DaDKTT}
                    checked={data?.DaDKTT}
                    onChange={(e) => applyChange("DaDKTT", e.target.value)}
                  >
                    Đã đăng ký tạm trú
                  </Checkbox>
                  <Checkbox
                    value={data?.DangKyDinhMuc}
                    checked={data?.DangKyDinhMuc}
                    onChange={(e) =>
                      applyChange("DangKyDinhMuc", e.target.value)
                    }
                  >
                    Đã đăng ký định mức
                  </Checkbox>
                  <Checkbox
                    checked={data?.NhanEmail}
                    value={data?.NhanEmail}
                    onChange={(e) => applyChange("NhanEmail", e.target.value)}
                  >
                    Nhận Email
                  </Checkbox>
                  <Checkbox
                    checked={data?.NhanSMS}
                    value={data?.NhanSMS}
                    onChange={(e) => applyChange("NhanSMS", e.target.value)}
                  >
                    Nhận SMS
                  </Checkbox>
                </Flex>
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ marginTop: "20px" }}>
            <Row gutter={[20, 12]}>
              <Col span={12}>
                <Flex style={{ borderBottom: "1px solid #d9d9d9" }}>
                  <p
                    style={{
                      fontWeight: "600",
                      // width: "20%",
                      // marginRight: "20px",
                    }}
                  >
                    Giới tính:
                  </p>
                  <Radio.Group
                    value={selectedValue}
                    onChange={(e) => applyChange("GioiTinh", e.target.value)}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        // gap: "40px",
                        marginLeft: "25px",
                        flexWrap: "wrap",
                      }}
                    >
                      <Radio value={1}>Nam</Radio>
                      <Radio value={2}>Nữ</Radio>
                    </div>
                  </Radio.Group>
                </Flex>
              </Col>

              <Col span={12}>
                <InputCustom
                  label="Nguyên quán:"
                  applyChange={applyChange}
                  prop="NguyenQuan"
                  value={data?.NguyenQuan}
                  placeholder="Nhập nguyên quán"
                />
              </Col>
              <Col span={12}>
                <DatePickerCustom
                  label="Ngày cấp:"
                  applyChange={applyChange}
                  prop={"NgayCap"}
                  value={data?.NgayCap}
                  placeholder={"Chọn ngày cấp"}
                />
              </Col>
              <Col span={12}>
                <InputCustom
                  label="Nơi cấp:"
                  applyChange={applyChange}
                  prop="NoiCap"
                  value={data?.NoiCap}
                  placeholder="Nhập nơi cấp"
                />
              </Col>
              <Col span={12}>
                <InputCustom
                  label="Tôn giáo:"
                  applyChange={applyChange}
                  prop="TonGiao"
                  value={data?.TonGiao}
                  placeholder="Nhập tôn giáo"
                />
              </Col>
              <Col span={12}>
                <InputCustom
                  label="Địa chỉ TT:"
                  applyChange={applyChange}
                  prop="DCTT"
                  value={data?.DCTT}
                  placeholder="Nhập địa chỉ thường trú"
                />
              </Col>
              <Col span={12}>
                <InputCustom
                  label="Email:"
                  applyChange={applyChange}
                  prop="Email"
                  value={data?.Email}
                  placeholder="Nhập email"
                />
              </Col>
              <Col span={12}>
                <DatePickerCustom
                  label="Ngày chuyển đến:"
                  applyChange={applyChange}
                  prop={"NgayChuyenDen"}
                  value={data?.NgayChuyenDen}
                  placeholder={"Chọn chuyển đến"}
                />
              </Col>
              <Col span={12}>
                <DatePickerCustom
                  label="Ngày chuyển đi:"
                  applyChange={applyChange}
                  prop={"NgayChuyenDi"}
                  value={data?.NgayChuyenDi}
                  placeholder={"Chọn ngày chuyển đi"}
                />
              </Col>
              <Col span={12}>
                <DatePickerCustom
                  label="Ngày đăng ký:"
                  applyChange={applyChange}
                  prop={"NgayDK"}
                  value={data?.NgayDK}
                  placeholder={"Chọn đăng ký"}
                />
              </Col>
              {/* <Col span={12}>
                <SelectCustom
                  label="Nhân viên:"
                  applyChange={applyChange}
                  prop="HoTenNV"
                  value={data?.HoTenNV}
                  placeholder="Chọn nhân viên"
                  options={[]}
                  // fieldNames={{ label: "TenLMB", value: "MaLMB" }}
                  errors={errors}
                />
              </Col> */}
              <Col span={12}>
                <DatePickerCustom
                  label="Ngày ĐKTT cũ:"
                  applyChange={applyChange}
                  prop={"NgayDKTTCu"}
                  value={data?.NgayDKTTCu}
                  placeholder={"Chọn ngày ĐKTT cũ"}
                />
              </Col>
              <Col span={12}>
                <DatePickerCustom
                  label="Ngày ĐKTT mới:"
                  applyChange={applyChange}
                  prop={"NgayDKTTMoi"}
                  value={data?.NgayDKTTMoi}
                  placeholder={"Chọn ngày ĐKTT mới"}
                />
              </Col>
              <Col span={24}>
                <UploadComponent
                  files={data?.Avatar ? [data?.Avatar] : []}
                  multiple={false}
                  affterUpload={affterUpload}
                  onRemove={onRemove}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };
  return (
    <Drawer
      title="Cư dân"
      width={1380}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined />
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {/* <FormDrawerUser
        className="vh-100"
        isEdit={isEdit}
        isOpen={modal}
        toggle={toggle}
        detail={staffForm}
      /> */}
      {contextHolder}
      {renderThongTinChung()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
