import React, { useEffect, useMemo, useRef, useState } from "react";
import debounce from "lodash/debounce";
import { Select, Spin } from "antd";
import "./index.scss";
function DebounceSelect({
  fetchOptions,
  debounceTimeout = 500,
  onChange,
  open,
  ...props
}) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    setOptions([]);
  }, [open]);

  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <div className="debounceSelect">
      <Select
        labelInValue
        showSearch
        filterOption={false}
        onSearch={debounceFetcher}
        onChange={onChange}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        {...props}
        options={options}
        // mode="multiple"
      />
    </div>
  );
}
export default DebounceSelect;
