import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Tabs,
  Checkbox,
  Flex,
} from "antd";
import {
  SearchOutlined,
  PlusOutlined,
  EditOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { DatePicker } from "antd/lib";
import vi from "dayjs/locale/vi";
import { SoQuyService } from "services/SoQuyService";
import TabPane from "antd/es/tabs/TabPane";
import { ToaNhaService } from "services/ToaNhaService";
import SelectCustom from "components/Forms/SelectCustom";
import InputCustom from "components/Forms/InputCustom";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import DebounceSelect from "components/DebounceSelect";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import FormDrawerUser from "components/FormMatBang";
import { DateTime } from "luxon";
import { MatBangService } from "services/MatBangService";
import { DichVuService } from "services/DichVuService";
import { Format_Currency } from "layouts/ultis/common";
const { TextArea } = Input;

const FormAdd = (props, ref) => {
  const { onAfterSubmit, maTN } = props;
  const [api, contextHolder] = notification.useNotification();
  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");
  const oneMonthAgo = dateInHoChiMinh.minus({ months: 1 });
  const defaulData = {
    HeSoDC: 1,
    NgayNhap: oneMonthAgo.toISO(),
  };

  const [data, setData] = useState(defaulData);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [onOffDM, setOnOffDM] = useState(true);
  const [dongHoNuoc, setDongHoNuoc] = useState();
  const defaultErrors = {
    MatBang: null,
    MaDongHo: null,
    NgayNhap: null,
    ChiSoMoi: null,
  };
  const [errors, setErrors] = useState(defaultErrors);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      setErrors(defaultErrors);
    },
    update: async (data) => {
      filterDongHo(data?.MaKH);
      setOpen(true);
      setIsUpdate(true);
      setData({
        ...data,
        MatBang: {
          value: data?.MaMB,
          label: data?.MaSoMB,
        },
      });
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
    setDongHoNuoc([]);
    setOnOffDM(true);
  };

  const filterDongHo = async (data, maKH) => {
    let _res = await DichVuService.Nuoc.getDongHo({
      MaKH: maKH,
    });
    if (_res.status === 2000) {
      setDongHoNuoc(_res.data);
    }
    await filterChiSo(data, _res.data[0]?.ID);
    data.MaDongHo = _res.data[0]?.ID;
  };
  const filterChiSo = async (data, maDH) => {
    let _res = await DichVuService.Nuoc.getChiSoNuoc({
      MaMB: data.MaMB,
      MaDongHo: maDH,
    });
    if (_res.status === 2000) {
      data.ChiSoCu = _res.data?.ChiSoCu;
    }
  };
  const filterTienNuoc = async (data) => {
    let _res = await DichVuService.Nuoc.getTienNuoc({
      MaMB: data?.MaMB,
      SoTieuThu: data?.SoTieuThu ? data?.SoTieuThu : 0,
      MaTN: maTN,
      MaLMB: data?.MaLMB,
      NgayNhap: data?.NgayNhap,
    });

    if (_res.status === 2000) {
      setData((prevData) => {
        const updatedData = {
          ...prevData,
          TienNuoc: _res.data?.tienNuoc,
          TienHaoHutDefault: _res.data?.tienHaoHut,
          TienBVMTDefault: _res.data?.tienBVMT,
          TienBVMT: (_res.data?.tienNuoc * (prevData?.BVMT ?? 0)) / 100,
          ThueVAT: (_res.data?.tienNuoc * (prevData?.VAT ?? 0)) / 100,
          TienVATBVMT: _res.data?.tienBVMT * (prevData?.VATBVMT ?? 0),
        };
        sumHaoHut(updatedData);
        sumTongTien(updatedData);

        return updatedData;
      });
    }
  };

  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        if (data) filterTienNuoc(data);
      }, 500);
    }
    return () => clearTimeout(time);
  }, [data?.ChiSoMoi, data?.NgayNhap, data?.MatBang]);

  const sumTongTien = (data) => {
    data.TongTien =
      (data?.TienNuoc ?? 0) +
      (data?.ThueVAT ?? 0) +
      (data?.TienHaoHut ?? 0) +
      (data?.TienBVMT ?? 0) +
      (data?.TienVATBVMT ?? 0) -
      (data?.NoDauKy ?? 0);
  };

  const sumHaoHut = (data) => {
    data.TienHaoHut =
      ((data?.TienNuoc ?? 0) * (data?.HaoHut ?? 0)) / 100 +
      (data?.PhiDichVu ?? 0) * ((data?.ChiSoMoi ?? 0) - (data?.ChiSoCu ?? 0)) +
      (data?.TienHaoHutDefault ?? 0);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "NgayNhap":

        const date = DateTime.fromJSDate(v.$d, { zone: "utc" });
        const isoString = date.setZone("Asia/Ho_Chi_Minh").toISO();
        _data[p] = isoString;

        break;
      case "MatBang":
        const KH = JSON.parse(v?.title);
        filterDongHo(_data, KH?.MaKH);
        _data.ChiSoMoi = null;
        _data.MaTN = maTN;
        _data.MaKH = KH?.MaKH;
        _data.KhachHang = KH?.TenKH;
        _data.MaMB = KH?.MaMB;
        _data.MaLMB = KH?.MaLMB;
        _data[p] = v;

        break;
      case "MaDongHo":
        await filterChiSo(_data, v);
        _data[p] = v;
        break;
      case "ChiSoMoi":
        _data.SoTieuThu = v - _data?.ChiSoCu;
        _data[p] = v;

        break;
      case "PhiDichVu":
        _data[p] = v;
        sumHaoHut(_data);
        sumTongTien(_data);
        break;
      case "HaoHut":
        _data[p] = v;
        sumHaoHut(_data);
        sumTongTien(_data);
        break;

      case "KhongTheoDM":
        setOnOffDM(!v);
        _data.BVMT = 0;
        _data.TienBVMT = 0;
        sumTongTien(_data);
        break;
      case "BVMT":
        _data[p] = v;
        _data.TienBVMT = (v * _data?.TienNuoc) / 100;
        sumTongTien(_data);
        break;
      case "VATBVMT":
        _data[p] = v;
        _data.TienVATBVMT = v * _data?.TienBVMTDefault;
        sumTongTien(_data);
        break;
      case "VAT":
        _data.ThueVAT = (v * _data?.TienNuoc) / 100;
        _data[p] = v;
        sumTongTien(_data);
        break;

      case "NoDauKy":
        _data[p] = v;
        sumTongTien(_data);
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    let _res = await DichVuService.Nuoc.add(_data);

    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} dịch vụ nước thành công!`
      );
      setIsUpdate(true);
      onClose();
      onAfterSubmit();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  async function fetchMatBang(input) {
    return MatBangService.getMatBang({
      InputSearch: input,
      MaTN: maTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.MaSoMB}`,
        value: item.MaMB,
        title: JSON.stringify(item),
      }));
    });
  }

  const renderThongTinChung = () => {
    return (
      <>
        <Row gutter={[20, 12]}>
          <Col span={12}>
            <Row gutter={[20, 12]}>
              <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
                Thông tin chung
              </p>

              <Col span={24}>
                <Flex style={{ borderBottom: "1px solid #ff9087" }}>
                  <p
                    style={{
                      fontWeight: "600",
                      width: "30% ",
                    }}
                  >
                    Mặt bằng:<span color="red">*</span>
                  </p>
                  <DebounceSelect
                    open={open}
                    value={data?.MatBang}
                    placeholder="Tìm theo mã mặt bằng"
                    fetchOptions={fetchMatBang}
                    onChange={(v) => applyChange("MatBang", v)}
                    style={{
                      width: "100%",
                    }}
                  />
                </Flex>
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["MatBang"]}
                  </p>
                )}
              </Col>
              <Col span={24}>
                <InputCustom
                  label="Khách hàng:"
                  applyChange={applyChange}
                  prop="KhachHang"
                  value={data?.KhachHang}
                  placeholder=""
                  disabled={true}
                />
                {/* {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["KhachHang"]}
                  </p>
                )} */}
              </Col>
              <Col span={24}>
                {" "}
                <SelectCustom
                  required={true}
                  label="Đồng hồ nước:"
                  applyChange={applyChange}
                  prop="MaDongHo"
                  value={data?.MaDongHo}
                  placeholder="Chọn đồng hồ nước"
                  options={dongHoNuoc}
                  fieldNames={{ label: "MaDongHo", value: "ID" }}
                />
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["MaDongHo"]}
                  </p>
                )}
              </Col>
              <Col span={24}>
                <DatePickerCustom
                  required={true}
                  label="Ngày nhập:"
                  applyChange={applyChange}
                  prop={"NgayNhap"}
                  value={data?.NgayNhap}
                  placeholder={"Chọn ngày nhập"}
                />
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["NgayNhap"]}
                  </p>
                )}
              </Col>
              <Col span={24}>
                <Flex>
                  <p
                    style={{
                      fontWeight: "600",
                      width: "20%",
                      borderBottom: "1px solid #d9d9d9",
                    }}
                  >
                    Diễn giải:
                  </p>
                  <TextArea
                    value={data?.DienGiai}
                    onChange={(e) => applyChange("DienGiai", e.target.value)}
                  />
                </Flex>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={[20, 12]}>
              <Col span={24}>
                <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
                  Chỉ số nước
                </p>
              </Col>
              <Col span={12}>
                <InputNumberCustom
                  label="Chỉ số cũ:"
                  applyChange={applyChange}
                  prop={"ChiSoCu"}
                  value={data?.ChiSoCu}
                  placeholder="0"
                />
              </Col>
              <Col span={12}>
                <InputNumberCustom
                  label="Hệ số:"
                  applyChange={applyChange}
                  prop={"HeSoDC"}
                  value={data?.HeSoDC}
                  placeholder=""
                />
              </Col>
              <Col span={12}>
                <InputNumberCustom
                  required={true}
                  label="Chỉ số mới:"
                  applyChange={applyChange}
                  prop={"ChiSoMoi"}
                  value={data?.ChiSoMoi}
                  placeholder="0"
                />
                {errors && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors && errors["ChiSoMoi"]}
                  </p>
                )}
              </Col>
              <Col span={10}>
                <Flex justify="space-between">
                  <p>Tiêu thụ:</p>
                  <p>{Format_Currency(data?.SoTieuThu)}</p>
                </Flex>
              </Col>
              <Col span={12}>
                <InputNumberCustom
                  label="Tiền nước giảm:"
                  applyChange={applyChange}
                  prop={"NoDauKy"}
                  value={data?.NoDauKy}
                  placeholder="0"
                />
              </Col>
              <Col span={10}>
                <Flex justify="space-between">
                  <p>Tiền:</p>
                  <p>{Format_Currency(data?.TienNuoc)}</p>
                </Flex>
              </Col>
              <Col span={12}>
                <InputNumberCustom
                  label="Phí dịch vụ:"
                  applyChange={applyChange}
                  prop={"PhiDichVu"}
                  value={data?.PhiDichVu}
                  placeholder="0"
                />
              </Col>
              <Col span={12}></Col>
              <Col span={12}>
                <InputNumberCustom
                  label="% VAT:"
                  applyChange={applyChange}
                  prop={"VAT"}
                  value={data?.VAT}
                  placeholder="0"
                  max={100}
                />
              </Col>
              <Col span={10}>
                <p style={{ textAlign: "right" }}>
                  {" "}
                  {Format_Currency(data?.ThueVAT)}{" "}
                </p>
              </Col>
              <Col span={12}>
                <InputNumberCustom
                  label="% Hao hụt:"
                  applyChange={applyChange}
                  prop={"HaoHut"}
                  value={data?.HaoHut}
                  placeholder="0"
                  max={100}
                />
              </Col>
              <Col span={10}>
                <p style={{ textAlign: "right" }}>
                  {" "}
                  {Format_Currency(data?.TienHaoHut)}
                </p>
              </Col>
              <Col span={12}>
                <InputNumberCustom
                  label="% BVMT:"
                  applyChange={applyChange}
                  prop={"BVMT"}
                  value={data?.BVMT}
                  placeholder="0"
                  disabled={!onOffDM}
                  max={100}
                />
              </Col>
              <Col span={10}>
                <p style={{ textAlign: "right" }}>
                  {" "}
                  {Format_Currency(data?.TienBVMT)}
                </p>
              </Col>
              {!onOffDM && (
                <>
                  <Col span={12}>
                    <InputNumberCustom
                      label="VAT BVMT:"
                      applyChange={applyChange}
                      prop={"VATBVMT"}
                      value={data?.VATBVMT}
                      placeholder=""
                    />
                  </Col>
                  <Col span={10}>
                    <p style={{ textAlign: "right" }}>
                      {" "}
                      {Format_Currency(data?.TienVATBVMT)}
                    </p>
                  </Col>
                </>
              )}

              <Col span={24}>
                <Checkbox
                  checked={onOffDM}
                  value={onOffDM}
                  onChange={(e) => applyChange("KhongTheoDM", e.target.value)}
                >
                  Không theo ĐM
                </Checkbox>
              </Col>
              <Col span={12}>
                <p style={{ fontWeight: "600" }}>Tổng tiền:</p>
              </Col>
              <Col span={10}>
                <p style={{ textAlign: "right" }}>
                  {" "}
                  {Format_Currency(data?.TongTien)}{" "}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };
  return (
    <Drawer
      title="Thêm: nước"
      width={960}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            <SaveOutlined /> Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {/* <FormDrawerUser
        className="vh-100"
        isEdit={isEdit}
        isOpen={modal}
        toggle={toggle}
        detail={staffForm}
      /> */}
      {contextHolder}
      {renderThongTinChung()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
