import React, { useState, forwardRef, useEffect } from "react";
import { notification, Modal, Col, Row, Input } from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import TextArea from "antd/es/input/TextArea";
import InputCustom from "components/Forms/InputCustom";
import { DichVuService } from "services/DichVuService";

const FormPhieuHuy = (props, ref) => {
  const {
    handleCancel,
    isModalOpen,
    setIsModalOpen,
    onAfterSubmit,
    maTN,
    dataFilterSelect,
  } = props;
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (dataFilterSelect && dataFilterSelect.length > 0) {
      setData({ ...dataFilterSelect[0], DienGiai: null });
    } else {
      setData({ DienGiai: null });
    }
  }, [isModalOpen, dataFilterSelect]);
  const [errors, setErrors] = useState({ TuNgay: null });
  const [data, setData] = useState(
    dataFilterSelect && dataFilterSelect.length > 0
      ? { ...dataFilterSelect[0], DienGiai: null }
      : { DienGiai: null }
  );

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "TuNgay":
        _data[p] = v;
        _data.DenNgay = v?.endOf("month");
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const handleSubmit = async () => {
    setLoading(true);
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    let _res;
    const valueTX2 = dataFilterSelect.map((item) => ({
      ID: item.ID,
      SoThe: item.SoThe,
    }));
    _res = await DichVuService.Xe.addPhieuHuy({
      MaTN: maTN,
      DienGiai: data?.DienGiai,
      TheXe: valueTX2,
      TuNgay: data?.TuNgay,
      DenNgay: data?.DenNgay,
    });
    setLoading(false);
    if (_res?.status === 2000) {
      openNotification("success", "topRight", `Cập nhật thành công!`);
      setIsModalOpen(false);
      setData({});
      setErrors({ TuNgay: null });
      onAfterSubmit();
    } else {
      openNotification(
        "error",
        "topRight",
        _res?.message || "Cập nhật thất bại."
      );
    }
  };

  return (
    <Modal
      width={`${"600px"}`}
      title={"Tạo phiếu hủy thẻ xe"}
      open={isModalOpen}
      onOk={handleSubmit}
      onCancel={() => {
        handleCancel();
        setData();
      }}
      confirmLoading={loading}
    >
      <Row
        style={{ marginTop: "25px", marginBottom: "20px" }}
        gutter={[24, 20]}
      >
        <Col span={12}>
          <InputCustom
            disabled={true}
            label="Số thẻ xe:"
            applyChange={applyChange}
            prop="SoThe"
            value={data?.SoThe}
            placeholder=""
          />
        </Col>
        <Col span={12}>
          <DatePickerCustom
            disabled={true}
            label="Ngày đã TT:"
            applyChange={applyChange}
            prop={"NgayTT"}
            value={data?.NgayTT}
            placeholder={""}
          />
        </Col>
        <Col span={12}>
          <InputCustom
            disabled={true}
            label="Mặt bằng:"
            applyChange={applyChange}
            prop="MaSoMB"
            value={data?.MaSoMB}
            placeholder=""
          />
        </Col>
        <Col span={12}>
          <InputCustom
            disabled={true}
            label="Khách hàng:"
            applyChange={applyChange}
            prop="ChuThe"
            value={data?.ChuThe}
            placeholder=""
          />
        </Col>
        <Col span={12}>
          <DatePickerCustom
            label="Hủy từ ngày:"
            applyChange={applyChange}
            prop={"TuNgay"}
            value={data?.TuNgay}
            placeholder={""}
          />
          {errors && (
            <p style={{ color: "red", textAlign: "center" }}>
              {errors && errors["TuNgay"]}
            </p>
          )}
        </Col>
        <Col span={12}>
          <DatePickerCustom
            disabled={true}
            label="Đến ngày:"
            applyChange={applyChange}
            prop={"DenNgay"}
            value={data?.DenNgay}
            placeholder={""}
          />
        </Col>
        <Col span={24}>
          <p
            style={{
              fontWeight: "600",
            }}
          >
            Diễn giải:
          </p>
          <TextArea
            value={data?.DienGiai}
            onChange={(e) => applyChange("DienGiai", e.target.value)}
          />
          {/* {errors && (
              <p style={{ textAlign: "center", color: "red" }}>
                {errors && errors["DienGiai"]}
              </p>
            )} */}
        </Col>
      </Row>

      {contextHolder}
    </Modal>
  );
};
export default forwardRef(FormPhieuHuy);
