import React, { useEffect, useState } from "react";
import { DatePicker, Select } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import ActiveRoute from "components/ActiveRoute";
import FilterDate from "components/FilterDate";
import DatePickerCustom from "components/Forms/DatePickerCustom";

const FilterModal = ({ ...props }) => {
  const { filterCondition, onChangeFilter, options, loadcategoriesByMaTN } =
    props;
  const [activeType, setActiveType] = useState(true);
  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "MaTN":
        loadcategoriesByMaTN(v);
        _filter.MaKN = 0;
        _filter[p] = v;
        break;
      case "DateType":
        _filter.MaKN = 0;
        _filter[p] = v;
        break;
      case "NgayTT":
        _filter.MaKN = 0;
        _filter[p] = v;
        break;
      case "LoaiXe":
      case "TrangThai":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        break;

      case "MaKN":
        if (v === undefined) {
          _filter.MaKN = 0;
          break;
        }
        _filter[p] = v;
        break;
      // case "MaNX":
      //   if (v === undefined) {
      //     _filter.MaNX = 0;
      //     break;
      //   }
      //   _filter[p] = v;
      //   break;

      default:
        _filter[p] = v;
        break;
    }
    onChangeFilter(_filter);
  };

  const onChangeDate = (v) => {
    let _filter = _.cloneDeep(filterCondition);
    _filter.TuNgay = v.TuNgay;
    _filter.DenNgay = v.DenNgay;
    onChangeFilter(_filter);
  };

  const defaultDate = {
    Name: "Hôm nay",
    TuNgay: filterCondition.TuNgay,
    DenNgay: filterCondition.DenNgay,
  };
  return (
    <div>
      <ActiveRoute />
      <div className="box-search">
        {/* <p className="label">Loại ngày</p> */}
        <Select
          // mode="multiple"
          // defaultValue={localStorage.getItem("MaTN")}
          value={filterCondition?.DateType}
          placeholder="Chọn loại ngày"
          onChange={(v) => applyChangeFilter("DateType", v)}
          style={{
            width: "100%",
          }}
          allowClear
          options={[
            { TenLN: "Ngày đăng kí", MaLN: "NgayDK" },
            { TenLN: "Ngày thanh toán", MaLN: "NgayTT" },
          ]}
          fieldNames={{ label: "TenLN", value: "MaLN" }}
        />
      </div>

      <FilterDate
        onChangeDate={onChangeDate}
        setActiveProp={activeType}
        defaultDateXe={defaultDate}
      />
      <div className="box-search">
        {/* <p className="label">Dự án</p> */}
        <Select
          // mode="multiple"
          // defaultValue={localStorage.getItem("MaTN")}
          value={filterCondition.MaTN}
          placeholder="Chọn dự án"
          onChange={(v) => applyChangeFilter("MaTN", v)}
          style={{
            width: "100%",
          }}
          options={options?.ToaNha}
          fieldNames={{ label: "TenTN", value: "MaTN" }}
        />
        {filterCondition?.MaTN && (
          <Select
            // mode="multiple"
            value={filterCondition.MaKN === 0 ? null : filterCondition.MaKN}
            placeholder="Chọn khối nhà"
            onChange={(v) => applyChangeFilter("MaKN", v)}
            style={{
              width: "100%",
            }}
            allowClear
            options={options?.KhoiNha}
            fieldNames={{ label: "TenKN", value: "MaKN" }}
          />
        )}
        <Select
          mode="multiple"
          // defaultValue={localStorage.getItem("MaTN")}
          // value={filterCondition.MaTT}
          placeholder="Chọn trạng thái"
          onChange={(v) => applyChangeFilter("TrangThai", v)}
          style={{
            width: "100%",
          }}
          allowClear
          options={options?.TrangThai}
          fieldNames={{ label: "TenTrangThai", value: "MaTrangThai" }}
        />
        <Select
          mode="multiple"
          // defaultValue={localStorage.getItem("MaTN")}
          // value={filterCondition.MaTT}
          placeholder="Chọn loại xe"
          onChange={(v) => applyChangeFilter("LoaiXe", v)}
          style={{
            width: "100%",
          }}
          allowClear
          options={options?.LoaiXe}
          fieldNames={{ label: "TenLX", value: "MaLX" }}
        />
      </div>
      {/* <div className="box-search">
        <p className="label">Trạng thái</p>
        <Select
          mode="multiple"
          
          placeholder="Chọn dự án"
          onChange={(v) => applyChangeFilter("TrangThai", v)}
          style={{
            width: "100%",
          }}
          allowClear
          options={options?.TrangThai}
          fieldNames={{ label: "TenTrangThai", value: "MaTrangThai" }}
        />
         <Select
          mode="multiple"
       
          placeholder="Chọn loại xe"
          onChange={(v) => applyChangeFilter("LoaiXe", v)}
          style={{
            width: "100%",
          }}
          allowClear
          options={options?.LoaiXe}
          fieldNames={{ label: "TenLX", value: "MaLX" }}
        />
      </div> */}
      {/* <div className="box-search">
        <p className="label">Loại xe</p>
        <Select
          mode="multiple"
          // defaultValue={localStorage.getItem("MaTN")}
          // value={filterCondition.MaTT}
          placeholder="Chọn loại xe"
          onChange={(v) => applyChangeFilter("LoaiXe", v)}
          style={{
            width: "100%",
          }}
          allowClear
          options={options?.LoaiXe}
          fieldNames={{ label: "TenLX", value: "MaLX" }}
        />
      </div> */}
    </div>
  );
};
export default FilterModal;
