import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Flex,
  Input,
  Menu,
  Modal,
  Pagination,
  Popconfirm,
  Popover,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  notification,
} from "antd";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { CiChat2, CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import { ToaNhaService } from "services/ToaNhaService";
import { MatBangService } from "services/MatBangService";
import { AiOutlineMenuFold } from "react-icons/ai";
import { MSBService } from "services/MSBService";
import { DichVuService } from "services/DichVuService";
import { DateTime } from "luxon";
import FormAddExcel from "./FormAddExcel";
import FormQLHistory from "./FormQLHistory";
import {
  DownOutlined,
  ExportOutlined,
  ImportOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { NhanVienService } from "services/NhanVienService";
import { BsMotherboard } from "react-icons/bs";
import { EllipsisWithTooltip } from "layouts/ultis/tooltip";
import { BiDetail } from "react-icons/bi";
import { FaFilter } from "react-icons/fa6";
export default function Index() {
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("filter") === "true"
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toanha, setToanha] = useState([]);
  const [khoinha, setKhoinha] = useState([]);
  const [tanglau, setTanglau] = useState([]);
  const [nhanVien, setNhanVien] = useState([]);
  const [dataDetailDM, setDataDetailDM] = useState([]);
  const [lMB, setLMB] = useState([]);
  const [totalTieuThu, setTotalTieuThu] = useState(0);
  const [sumTongTien, setSumTongTien] = useState(0);
  const [sumTienNuoc, setSumTienNuoc] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = async (item) => {
    let _res = await DichVuService.Nuoc.getDetailDM({ ID: item?.ID });
    if (_res.data) setDataDetailDM(_res.data);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const currentDate = DateTime.now();
  const previousMonth = currentDate.minus({ months: 1 }).month;

  const [filterCondition, setFilterCondition] = useState({
    Month: previousMonth,
    Year: currentDate.year,
    MaTN: null,
    MaKN: 0,
    PageIndex: 1,
    PageSize: 20,
    InputSearch: "",
    SoTieuThu: {
      Type: "Empty", //LonHonBang,BeHonBang,Bang,TuDen
      Value: 0,
    },
  });
  const refDetail = useRef(null);
  const refAddExcel = useRef(null);
  const refHistory = useRef(null);

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const loadCategories = async () => {
    let _tn = await ToaNhaService.getToaNhaByNV();
    setToanha(_tn.data);
    let _filter = _.cloneDeep(filterCondition);
    _filter.MaTN = _tn.data[0]?.MaTN;
    setFilterCondition(_filter);
    loadcategoriesByMaTN(_tn.data[0]?.MaTN);
    filter(_filter);
  };
  const loadcategoriesByMaTN = async (matn) => {
    let _tn = await ToaNhaService.Categories.getKhoiNha({
      MaTN: matn,
    });
    setKhoinha(_tn.data);
  };
  useEffect(() => {
    loadCategories();
  }, []);
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        if (filterCondition.MaTN) filter(filterCondition);
      }, 500);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  const filter = async (_filter) => {
    setLoading(true);
    const _resMaLMB = await ToaNhaService.Categories.getLMB(_filter);
    if (_resMaLMB.data) setLMB(_resMaLMB.data);
    let _resNV = await NhanVienService.getNhanVien({ InputSearch: "" });
    if (_resNV.data) setNhanVien(_resNV.data);
    let _res = await DichVuService.Nuoc.get(_filter);
    if (_res.data) {
      let totalTT = 0;
      let totalTongT = 0;
      let totalTN = 0;

      _res.data.forEach((item) => {
        totalTT += item.SoTieuThu;
        totalTongT += item.TongCong;
        totalTN += item.TienNuoc;
      });
      setData(_res.data);
      setTotalTieuThu(totalTT);
      setSumTienNuoc(totalTN);
      setSumTongTien(totalTongT);
    }
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () => {
    filter(filterCondition);
  };

  // Định nghĩa cột
  const columnsDetailtDM = [
    {
      title: "Chỉ số tiêu thụ",
      dataIndex: "ChiSoTieuThu",
      key: "ChiSoTieuThu",
    },
    {
      title: "Số tiền",
      dataIndex: "ThanhTien",
      key: "ThanhTien",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Đơn giá",
      dataIndex: "DonGia",
      key: "DonGia",
      render: (item) => Format_Currency(item),
    },
  ];

  const columns = [
    {
      title: "Mặt bằng",
      // ellipsis: true,
      fixed: "left",
      render: (item) => (
        <p
          style={{
            fontWeight: "bold",
            color: "cadetblue",
            cursor: "pointer",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            // ellipsis: true,
          }}
          onClick={() => refHistory.current.show(item)}
        >
          {item?.MaSoMB}
        </p>
      ),
    },
    {
      title: "Khach hàng",
      dataIndex: "KhachHang",
      // ellipsis: true,
    },
    {
      title: "Mã đồng hồ",
      dataIndex: "TenDongHo",
      // ellipsis: true,
    },
    {
      title: "Loại mặt bằng",
      dataIndex: "MaLMB",
      // ellipsis: true,
      render: (item) => lMB.find((lmb) => lmb.MaLMB === item)?.TenLMB,
    },
    {
      title: "Ngày nhập",
      dataIndex: "NgayNhap",
      // ellipsis: true,
      render: (item) => (item ? Format_Date(item) : ""),
    },
    {
      title: "Chỉ số cũ",
      dataIndex: "ChiSoCu",
      // ellipsis: true,
    },
    {
      title: "Chỉ số mới",
      dataIndex: "ChiSoMoi",
      // ellipsis: true,
    },
    {
      title: "Hệ số DC",
      dataIndex: "HeSoDC",
      // ellipsis: true,
    },
    {
      title: "Tiêu thụ",
      dataIndex: "SoTieuThu",
      // ellipsis: true,
    },
    {
      title: "Tiêu thụ TB",
      dataIndex: "TieuThuTB",
      // ellipsis: true,
    },
    {
      title: "Chênh",
      dataIndex: "SoLuongChenh",
      // ellipsis: true,
    },
    {
      title: "Tiền nước",
      dataIndex: "TienNuoc",
      // ellipsis: true,
      render: (item) => Format_Currency(item),
    },
    {
      title: "VAT",
      dataIndex: "VAT",
      // ellipsis: true,
    },

    {
      title: "Tiền VAT",
      dataIndex: "TienVAT",
      // ellipsis: true,
      render: (item) => Format_Currency(item),
    },
    {
      title: "% BVMT",
      dataIndex: "PTBVMT",
      // ellipsis: true,
    },
    {
      title: "Phí BVMT",
      dataIndex: "TienBVMT",
      // ellipsis: true,
      render: (item) => Format_Currency(item),
    },
    {
      title: "% Hao hụt",
      dataIndex: "PTHaoHut",
      // ellipsis: true,
    },
    {
      title: "Phí hao hụt",
      dataIndex: "TienHaoHut",
      // ellipsis: true,
      render: (item) => Format_Currency(item),
    },
    {
      title: "Diễn giải",
      dataIndex: "DienDai",
      render: (text) => <EllipsisWithTooltip text={text} />,
      // ellipsis: true,
    },
    {
      title: "Nhân viên",
      dataIndex: "MaNV",
      // ellipsis: true,
      render: (item) => nhanVien.find((nv) => nv.MaNV === item)?.HoTenNV,
    },
    {
      title: "Tiền nước giảm",
      dataIndex: "NoDauKy",
      // ellipsis: true,
      render: (item) => Format_Currency(item),
    },
    {
      title: "% VAT BVMT",
      dataIndex: "VATBVMT",
      // ellipsis: true,
    },
    {
      title: "Tiền VAT BVMT",
      dataIndex: "TienVATBVMT",
      // ellipsis: true,
      render: (item) => Format_Currency(item),
    },
    {
      title: "Tiền nước (VAT,BVMT,HH)",
      dataIndex: "TienNuoc",
      width: 120,
      // ellipsis: true,
      render: (item) => Format_Currency(item),
    },
    // {
    //   title: "Đã thanh toán",
    //   dataIndex: "DaThanhToan",

    //   render: (item) => Format_Currency(item),
    // },
    {
      title: "Tổng tiền",
      dataIndex: "TongCong",
      // ellipsis: true,
      render: (item) => Format_Currency(item),
    },
    // {
    //   title: "Trang thái",
    //   dataIndex: "TrangThai",

    //   fixed: "right",
    //   render: (item) => (
    //     <Tag color={item === "Đã thanh toán" ? "green" : "red"}>{item}</Tag>
    //   ),
    // },
    // {
    //   title: "Ngày nhập",
    //   dataIndex: "NgayTao",
    //   render: (item) => (item ? Format_Date(item) : null),
    // },
    {
      title: "",
      fixed: "right",
      width: 70,

      render: (item) => {
        return (
          <div style={{ textAlign: "center" }}>
            <Tooltip placement="topRight" title="Chi tiết theo định mức">
              <Button
                type="primary"
                ghost
                icon={<BiDetail />}
                onClick={() => showModal(item)}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = await DichVuService.Nuoc.delete({ ID: Number(value) });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.ID !== Number(value));
      setData(fil);
      openNotification("success", "topRight", "Xoá thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const onHandleHide = () => {
    setCollapsed(!collapsed);
    localStorage.setItem("filter", !collapsed);
  };
  const onSelectChange = async (newSelectedRowKeys) => {
    if (newSelectedRowKeys?.length > 100) {
      openNotification("error", "topRight", "Số lượng chọn không quá 100");
      return;
    }
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  function removeSpecialCharacters(str) {
    return str.replace(/[_\.\s]/g, "");
  }
  const createAccount = async () => {
    setLoading(true);
    let _data = data?.filter((v) => selectedRowKeys.includes(v.MaMB));
    let _login = await MSBService.Login();
    let _payload = {
      Token: JSON.parse(_login.data)?.access_token,
      ServiceCode: "ADL",
      Rows: [],
    };
    _data?.map((item) => {
      if (item.TenKH) {
        let masomb = removeSpecialCharacters(item.MaSoMB);
        let _row = {
          AccountNumber: "ADL" + masomb,
          Status: "1",
          ReferenceNumber: masomb,
          SuggestAmount: "",
          PayType: "0",
          Name: item.TenKH,
          Detail1: String(item.MaMB),
          Detail2: String(item.MaTN),
          Phone: item.DienThoaiKH,
        };
        _payload.Rows.push(_row);
      }
    });
    let _res = await MSBService.CreateAccount(_payload);
    if (_res.status === 2000) {
      openNotification("success", "topRight", "Tạo tài khoản thành công");
      filter(filterCondition);
    } else {
      openNotification("error", "topRight", _res?.message);
    }
    setLoading(false);
  };

  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = async () => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.PageSize = 5000;
    let _res = await DichVuService.Nuoc.get(_ft);
    const fieldsToExport = columns.map((col) => col.dataIndex);
    const filteredData = _res?.data.map((item) => {
      let filteredItem = {};
      fieldsToExport.forEach((field) => {
        filteredItem[field] = item[field];
      });
      return filteredItem;
    });
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "Danh sách dịch vụ nước" + fileExtension);
  };
  const onChangePage = (page, size) => {
    if (page === 0) {
      page = 1;
    }
    let _ft = _.cloneDeep(filterCondition);
    _ft.PageIndex = page;
    _ft.PageSize = size;
    setFilterCondition(_ft);
    filter(_ft);
  };

  const handleMenuClick2 = (e) => {
    if (e.key !== "1" && e.key !== "2" && selectedRowKeys.length < 1) {
      openNotification(
        "error",
        "topRight",
        "Vui lòng chọn mặt bằng, xin cảm ơn."
      );
      return;
    }
    if (
      (e.key === "3" && selectedRowKeys.length > 1) ||
      (e.key === "7" && selectedRowKeys.length > 1)
    ) {
      openNotification(
        "error",
        "topRight",
        "Vui lòng chỉ chọn 1 mặt bằng, xin cảm ơn."
      );
      return;
    }
    switch (e.key) {
      case "1":
        refAddExcel.current.show();
        break;
      case "2":
        exportToCSV();
        break;

      default:
        break;
    }
  };

  const thaoTacKhac = (
    <Menu onClick={handleMenuClick2}>
      <Menu.Item key="1">
        {" "}
        <ImportOutlined style={{ paddingRight: "8px" }} />
        Import
      </Menu.Item>
      <Menu.Item key="2">
        <ExportOutlined style={{ paddingRight: "8px" }} />
        Export
      </Menu.Item>
    </Menu>
  );

  return (
    <Row gutter={15}>
      <Col span={collapsed ? 0 : 4} style={{ transition: "all 0.3s ease" }}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
          options={{
            ToaNha: toanha,
            KhoiNha: khoinha,
          }}
          loadcategoriesByMaTN={loadcategoriesByMaTN}
        />
      </Col>
      <Col span={collapsed ? 24 : 20}>
        <Toolbar
          left={() => (
            <Flex align="center" gap={10}>
              <AiOutlineMenuFold
                size={20}
                className="icon-filter"
                color="primary"
                onClick={() => onHandleHide()}
              />

              <Input
                style={{
                  width: "300px",
                  padding: "4px 11px",
                  borderRadius: 10,
                }}
                placeholder="Tìm mã số mặt bằng, điện thoại, tên khách hàng"
                onChange={(e) => onSearch(e.target.value)}
              />
            </Flex>
          )}
          right={() => (
            <Flex gap={10}>
              <Button
                loading={loading}
                type="primary"
                onClick={() => refDetail.current.show()}
              >
                <PlusOutlined style={{ fontSize: "13px", margin: "0 -4px" }} />{" "}
                Thêm
              </Button>
              <Dropdown overlay={thaoTacKhac} trigger={["click"]}>
                <Button style={{ display: "flex", alignItems: "center" }}>
                  <BsMotherboard style={{ marginRight: "5px" }} /> Thao tác khác
                  <DownOutlined />
                </Button>
              </Dropdown>
            </Flex>
          )}
        />
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 250,
            x: 3000,
          }}
          rowSelection={{ ...rowSelection, columnWidth: 60 }}
          pagination={false}
          virtual={true}
          size="small"
          bordered
          summary={(pageData) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={2}>
                    <p style={{ fontWeight: "bold" }}>
                      Tổng: {pageData?.length}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={0}
                    colSpan={7}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell index={0} colSpan={1}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(totalTieuThu)}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={0}
                    colSpan={14}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell index={0}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(sumTienNuoc)}
                    </p>
                  </Table.Summary.Cell>
                  {/* <Table.Summary.Cell index={0}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(sumDaThanhToan)}
                    </p>
                  </Table.Summary.Cell> */}
                  <Table.Summary.Cell index={0}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(sumTongTien)}
                    </p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
        <div style={{ textAlign: "right", paddingTop: "15px" }}>
          <Pagination
            onChange={onChangePage}
            showSizeChanger
            total={data[0]?.TotalRows}
            defaultPageSize={20}
            pageSizeOptions={["10", "20", "50", "100", "500", "1000"]}
          />
        </div>
      </Col>
      <Modal
        title="Chi tiết định mức"
        open={isModalOpen}
        onCancel={handleCancel}
        width={700}
        footer={[
          <Button key="close" onClick={handleCancel}>
            Đóng
          </Button>,
          // <Button key="ok" type="primary" onClick={handleOk}>
          //   Đồng ý
          // </Button>,
        ]}
      >
        <Table
          style={{ padding: "15px 0" }}
          columns={columnsDetailtDM}
          dataSource={dataDetailDM}
          pagination={false}
        />
      </Modal>
      <FormAdd
        ref={refDetail}
        onAfterSubmit={onAfterSubmit}
        maTN={filterCondition.MaTN}
      />
      {contextHolder}
      <FormAddExcel
        ref={refAddExcel}
        maTN={filterCondition.MaTN}
        onAfterSubmit={onAfterSubmit}
      />
      <FormQLHistory ref={refHistory} onAfterSubmit={onAfterSubmit} />
    </Row>
  );
}
