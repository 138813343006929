import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Button, Input, Row, Col, Space, Drawer, notification } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { DatePicker } from "antd/lib";
import { MSBService } from "services/MSBService";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";

dayjs.extend(customParseFormat);
dayjs.extend(utc);

const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  useImperativeHandle(ref, () => ({
    update: async (data) => {
      setOpen(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
    onAfterSubmit();
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    _data[p] = v;
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  function removeSpecialCharacters(str) {
    return str.replace(/[_\.\s]/g, "");
  }
  const onSubmit = async () => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let _login = await MSBService.Login();
    // let masomb = removeSpecialCharacters(item.MaSoMB);
    let _payload = {
      Token: JSON.parse(_login.data)?.access_token,
      ServiceCode: "ADL",
      Rows: [
        {
          AccountNumber: _data.AccountNumber,
          Status: _data.Status,
          ReferenceNumber: _data.MaSoMB,
          SuggestAmount: "",
          PayType: _data.PayType,
          Name: _data.Name,
          Detail1: String(_data.MaMB),
          Detail2: String(_data.MaTN),
          Phone: _data.Phone,
          EqualAmount: _data.EqualAmount,
          ExpiryDate: dayjs(_data.ExpiryDate).format("DD/MM/YYYY HH:mm:ss"),
        },
      ],
    };
    let _res = await MSBService.UpdateAccount(_payload);
    setLoading(false);
    if (_res.status === 2000) {
      openNotification("success", "topRight", `Cập nhật thành công!`);
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const renderThongTinChung = () => {
    return (
      <Row gutter={[24, 12]}>
        <Col span={6}>
          <p>Tài khoản</p>
          <Input
            value={data?.AccountNumber}
            onChange={(e) => applyChange("AccountNumber", e.target.value)}
          />
        </Col>
        <Col span={18}>
          <p>Tên tài khoản</p>
          <Input
            value={data?.Name}
            onChange={(e) => applyChange("Name", e.target.value)}
          />
        </Col>
        <Col span={8}>
          <p>Điện thoại</p>
          <Input
            value={data?.Phone}
            onChange={(e) => applyChange("Phone", e.target.value)}
          />
        </Col>
        <Col span={8}>
          <p>Số tiền thanh toán đúng</p>
          <Input value={data?.EqualAmount} disabled />
        </Col>

        <Col span={8}>
          <p>Ngày hết hạn tài khoản</p>
          <DatePicker
            showTime
            allowClear={false}
            format="DD/MM/YYYY HH:mm"
            style={{ width: "100%" }}
            // value={data?.ExpiryDate ? dayjs(data?.ExpiryDate) : null}
            value={
              data?.ExpiryDate
                ? dayjs(data?.ExpiryDate, "DD/MM/YYYY HH:mm")
                : null
            }
            placeholder="Ngày hết hạn"
            onChange={(v) => applyChange("ExpiryDate", v)}
          />
        </Col>
      </Row>
    );
  };
  return (
    <Drawer
      title="Cập nhật tài khoản"
      width={720}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      {renderThongTinChung()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
