import axiosApi from "./api_helper";

export const InfoService = {
  getChucNang: async (payload) => {
    return await axiosApi()
      .post("api/get-chucnangthongbao", payload)
      .then((res) => res.data);
  },
  getCaiDat: async (payload) => {
    return await axiosApi()
      .post("api/thong-bao/get-caidat", payload)
      .then((res) => res.data);
  },
  addCaiDat: async (payload) => {
    return await axiosApi()
      .post("api/thong-bao/add-caidat", payload)
      .then((res) => res.data);
  },
  confirmRead: async (payload) => {
    return await axiosApi()
      .post("/api/hethong/noti/read", payload)
      .then((res) => res.data);
  },
};
