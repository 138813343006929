import axios from "axios";
export const URL_UPLOAD = "https://upload.beesky.vn/";
// const API_URL = 'http://localhost:3000';
const axiosApi = axios.create({
  baseURL: URL_UPLOAD,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});
export const UploadService = {
  upload: async (payload) => {
    return await axiosApi
      .post("api/Upload", payload)
      .then((res) => res.data);
  },
};
