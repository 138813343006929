import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import {
  Button,
  Space,
  Drawer,
  notification,
  Table,
  Upload,
  message,
  Modal,
  Col,
  Row,
  Flex,
  Input,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import { KhachHangService } from "services/KhachHangService";
import DatePickerCustom from "components/Forms/DatePickerCustom";
import InputNumberCustom from "components/Forms/InputNumberCustom";
import DebounceSelect from "components/DebounceSelect";
import { MatBangService } from "services/MatBangService";
import { DatabaseCoreService } from "services/DatabaseCoreService";
import { QuestionCircleOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import InputCustom from "components/Forms/InputCustom";
import { DichVuService } from "services/DichVuService";

const FormModal = (props, ref) => {
  const {
    filterCondition,
    handleCancel,
    isModalOpen,
    caseModal,
    titleModal,
    setIsModalOpen,
    selectedRowKeys,
    onAfterSubmit,
    maTN,
    dataAllXe,
  } = props;
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState({
    CardNo: "",
    SoThe: "",
  });

  useEffect(() => {
    if (isModalOpen) {
      const newData = dataAllXe.filter((item) =>
        selectedRowKeys.includes(item.ID)
      );
      if (newData.length > 0) {
        const { CardNo, SoThe } = newData[0];
        setData({ CardNo, SoThe });
      } else {
        setData({
          CardNo: "",
          SoThe: "",
        });
      }
    }
  }, [isModalOpen, selectedRowKeys, dataAllXe]);
  const [loading, setLoading] = useState(false);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "TuNgay":
        _data[p] = v;
        _data.DenNgay = v?.endOf("month");
        break;
      case "MaMB":
        const valueNewMB = JSON.parse(v?.title);
    

        _data.MaSoMBMoi = valueNewMB?.MaSoMB;
        _data.MaMBMoi = valueNewMB?.MaMB;

        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };

  const handleSubmit = async () => {
    setLoading(true);

    let _res;
    try {
      switch (caseModal) {
        case 3:
          const valueKhoaXe = dataAllXe
            .filter((item) => selectedRowKeys.includes(item.ID))
            .map((item) => ({
              NgayTinhPhi: item.NgayTinhPhi,
              NgayTT: item.NgayTT,
              MaTrangThai: item.MaTrangThai,
            }));
          _res = await DichVuService.Xe.addKhoaThe({
            DienGiai: data?.DienGiai,
            MaTN: maTN,
            ID: selectedRowKeys[0],
            IsLock: valueKhoaXe[0]?.MaTrangThai === 1 ? false : true,

            NgayTinhPhi: valueKhoaXe[0]?.NgayTinhPhi,
            NgayTT: valueKhoaXe[0]?.NgayTT,
          });
          break;

        case 6:
          const payloadDonGia = dataAllXe
            .filter((item) => selectedRowKeys.includes(item.ID))
            .map((item) => ({
              ID: item.ID,
              PTVAT: item.PTVAT,
            }));
          _res = await DichVuService.Xe.addDonGia({
            DonGia: data?.DonGia,
            TheXe: payloadDonGia,
          });
          break;
        case 7:
          _res = await DichVuService.Xe.addMaThe({
            SoThe: data?.SoThe,
            CardNo: data?.CardNo,
            ID: selectedRowKeys[0] ? selectedRowKeys[0] : null,
          });
          break;
        case 8:
          const payloadNgayHH = dataAllXe
            .filter((item) => selectedRowKeys.includes(item.ID))
            .map((item) => ({
              ID: item.ID,
              SoThe: item.SoThe,
            }));
          _res = await DichVuService.Xe.addNgayHH({
            MaTN: maTN,
            NgayHetHan: data?.NgayHH,
            TheXe: payloadNgayHH,
          });
          break;
        case 9:
          const payloadMatBang = dataAllXe
            .filter((item) => selectedRowKeys.includes(item.ID))
            .map((item) => ({
              ID: item.ID,
              MaSoMB: item.MaSoMB,
            }));

          _res = await DichVuService.Xe.addMatBang({
            MaMB: data?.MaMBMoi,
            MaSoMB: data?.MaSoMBMoi,
            DienGiai: data?.DienGiai,
            TheXe: payloadMatBang,
          });
          break;
        default:
          throw new Error("Invalid caseModal value");
      }
      setLoading(false);
      if (_res?.status === 2000) {
        openNotification("success", "topRight", `Cập nhật thành công!`);
        setIsModalOpen(false);
        setData({});
        onAfterSubmit();
      } else {
        openNotification(
          "error",
          "topRight",
          _res?.message || "Cập nhật thất bại."
        );
      }
    } catch (error) {
      openNotification(
        "error",
        "topRight",
        error.message || "Đã có lỗi xảy ra."
      );
    }
  };

  async function fetchMatBang(input) {
    return MatBangService.getMatBang({
      InputSearch: input,
      MaTN: filterCondition?.MaTN,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.MaSoMB}`,
        value: item.MaMB,
        title: JSON.stringify(item),
      }));
    });
  }
  return (
    <Modal
      width={`${caseModal === 3 ? "400px" : "500px"}`}
      title={titleModal}
      open={isModalOpen}
      onOk={handleSubmit}
      onCancel={() => {
        handleCancel();
        setData({});
      }}
      confirmLoading={loading}
    >
      {caseModal === 3 ? (
        <Row
          style={{ marginTop: "25px", marginBottom: "20px" }}
          gutter={[24, 20]}
        >
          <Col span={24}>
            <p
              style={{
                fontWeight: "600",
              }}
            >
              Diễn giải:
            </p>
            <TextArea
              value={data?.DienGiai}
              onChange={(e) => applyChange("DienGiai", e.target.value)}
            />
            {/* {errors && (
              <p style={{ textAlign: "center", color: "red" }}>
                {errors && errors["DienGiai"]}
              </p>
            )} */}
          </Col>
        </Row>
      ) : caseModal === 6 ? (
        <Col style={{ margin: "30px 0" }} span={24}>
          <InputNumberCustom
            label="Đơn giá"
            applyChange={applyChange}
            prop={"DonGia"}
            value={data?.DonGia}
            placeholder="0"
          />
        </Col>
      ) : caseModal === 7 ? (
        <Row
          style={{ marginTop: "25px", marginBottom: "20px" }}
          gutter={[24, 20]}
        >
          <Col span={24}>
            <InputCustom
              label="Mã thẻ:"
              applyChange={applyChange}
              prop="SoThe"
              value={data?.SoThe}
              placeholder=""
            />
          </Col>
          <Col span={24}>
            <InputCustom
              label="CardNo:"
              applyChange={applyChange}
              prop="CardNo"
              value={data?.CardNo}
              placeholder=""
            />
          </Col>
        </Row>
      ) : caseModal === 8 ? (
        <Col span={24}>
          <DatePickerCustom
            label="Ngày hết hạn:"
            applyChange={applyChange}
            prop={"NgayHH"}
            value={data?.NgayHH}
            placeholder={""}
          />
        </Col>
      ) : (
        <Row gutter={[20, 12]} style={{ margin: "30px 0" }}>
          <Col span={24}>
            <Flex style={{ borderBottom: "1px solid #d9d9d9" }}>
              <p
                style={{
                  fontWeight: "600",
                  width: "30% ",
                }}
              >
                Mặt bằng:
              </p>
              <DebounceSelect
               open={isModalOpen}   
                value={data?.MaMB}
                placeholder="Tìm theo mã mặt bằng"
                fetchOptions={fetchMatBang}
                onChange={(v) => applyChange("MaMB", v)}
                style={{
                  width: "100%",
                }}
              />
            </Flex>
          </Col>
          <Col span={24}>
            <p
              style={{
                fontWeight: "600",
              }}
            >
              Diễn giải:
            </p>
            <TextArea
              value={data?.DienGiai}
              onChange={(e) => applyChange("DienGiai", e.target.value)}
            />
            {/* {errors && (
              <p style={{ textAlign: "center", color: "red" }}>
                {errors && errors["DienGiai"]}
              </p>
            )} */}
          </Col>
        </Row>
      )}
      {contextHolder}
    </Modal>
  );
};
export default forwardRef(FormModal);
