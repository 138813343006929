import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Space,
  Drawer,
  notification,
  Table,
  Upload,
  message,
  Menu,
  Dropdown,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import { DownOutlined, UploadOutlined } from "@ant-design/icons";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
const props2 = {
  name: "file",
  action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const FormAdd = (props, ref) => {
  const { onAfterSubmit, options, filterCondition } = props;
  const [api, contextHolder] = notification.useNotification();

  const [data, setData] = useState([]);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = { TenKH: null };
  const [errors, setErrors] = useState(defaultErrors);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData([]);
  };

  const columns = [
    { title: "Mã Số", dataIndex: "MaSo", key: "MaSo", ellipsis: true },
    { title: "Mã Phụ", dataIndex: "MaPhu", key: "MaPhu", ellipsis: true },
    { title: "Tên CTVT", dataIndex: "TenCTVT", key: "TenCTVT", ellipsis: true },
    {
      title: "Tên Công Ty",
      dataIndex: "TenCongTy",
      key: "TenCongTy",
      ellipsis: true,
    },
    {
      title: "Địa Chỉ Công Ty",
      dataIndex: "DiaChiCT",
      key: "DiaChiCT",
      ellipsis: true,
    },
    {
      title: "Điện Thoại Công Ty",
      dataIndex: "DienThoaiCT",
      key: "DienThoaiCT",
      ellipsis: true,
    },
    {
      title: "Mã Số Thuế",
      dataIndex: "MaSoThue",
      key: "MaSoThue",
      ellipsis: true,
    },
    {
      title: "Người Đại Diện",
      dataIndex: "NguoiDaiDien",
      key: "NguoiDaiDien",
      ellipsis: true,
    },
    {
      title: "Ngày ĐKKD",
      dataIndex: "NgayDKKD",
      key: "NgayDKKD",
      ellipsis: true,
    },
    { title: "Nơi ĐKKD", dataIndex: "NoiDKKD", key: "NoiDKKD", ellipsis: true },
    {
      title: "Số Tài Khoản NH",
      dataIndex: "SoTaiKhoanNH",
      key: "SoTaiKhoanNH",
      ellipsis: true,
    },
  ];
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const sampleData = [
    {
      MaSo: "001",
      MaPhu: "A01",
      TenCTVT: "Ngành Xây Dựng",
      TenCongTy: "Công Ty TNHH ABC",
      DiaChiCT: "123 Đường ABC, Quận 1",
      DienThoaiCT: "0123456789",
      MaSoThue: "123456789",
      NguoiDaiDien: "Nguyễn Văn A",
      NgayDKKD: "01/01/2020",
      NoiDKKD: "Sở Kế Hoạch Đầu Tư",
      SoTaiKhoanNH: "123456789",
    },
  ];
  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(sampleData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "File mẫu " + fileExtension);
  };
  const loadDataImport = (data) => {
    let res = [];
    data?.map((item, i) => {
      if (i > 0) {
        res.push({
          STT: i + 1,
          MaSo: item[0],
          MaPhu: item[1],
          TenCTVT: item[2],
          TenCongTy: item[3],
          DiaChiCT: item[4],
          DienThoaiCT: item[5],
          MaSoThue: item[6],
          NguoiDaiDien: item[7],
          NgayDKKD: item[8],
          NoiDKKD: item[9],
          SoTaiKhoanNH: item[10],
        });
      }
    });
    setData(res);
  };

  return (
    <Drawer
      title="Excel"
      width={960}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Upload
            accept=".txt, .csv, .xlsx, .xls"
            showUploadList={false}
            beforeUpload={(file) => {
              const reader = new FileReader();
              const rABS = !!reader.readAsBinaryString;
              reader.onload = (e) => {
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
                loadDataImport(data);
              };
              if (rABS) reader.readAsBinaryString(file);
              else reader.readAsArrayBuffer(file);
            }}
          >
            <Button icon={<UploadOutlined />}>Upload File</Button>
          </Upload>
          <Button onClick={() => exportToCSV()}>Tải file mẫu</Button>
          <Button danger onClick={() => setData()}>
            Xóa
          </Button>
          <Button
            loading={loading}
            // onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Table
        style={{ margin: 0, marginTop: "20px" }}
        dataSource={data}
        columns={columns}
        scroll={{
          x: 1500,
        }}
      />
    </Drawer>
  );
};
export default forwardRef(FormAdd);
