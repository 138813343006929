import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Flex, Row, Col, Drawer, notification, Skeleton, Button } from "antd";
import _ from "lodash";
import { CardService } from "services/CardService";
import { ChatsService } from "services/ChatsService";
import { IoPersonAddOutline } from "react-icons/io5";
import { GetRandomColor } from "layouts/ultis/common";
const ListMember = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const loadCategories = async (members) => {
    // let _members = memberReq || members;
    setLoading(true);
    let mem = [];
    await Promise.all(
      members?.map(async (item) => {
        if (item.is_admin) {
          let _user = await ChatsService.User.getById(item.user_id);
          mem.push({
            ...item,
            name: _user?.data?.HoTenNV,
          });
        } else {
          let _user = await ChatsService.MatBang.getById(item.user_id);
          mem.push({
            ...item,
            name: _user?.data?.MaSoMB,
          });
        }
      })
    );
    setLoading(false);
    setData(mem);
  };
  useImperativeHandle(ref, () => ({
    show: async (members) => {
      setOpen(true);
      loadCategories(members);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData([]);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MaKH":
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };

  const onSubmit = async (close) => {
    let _data = _.cloneDeep(data);
    if (_data.MaKH?.length > 0) {
      _data.MaKH = _data?.MaKH[0].value;
    }
    let _res = await CardService.updateCard(_data);

    if (_res.status === 2000) {
      openNotification("success", "topRight", ` thẻ thành công!`);
      if (close) onClose();
      onAfterSubmit();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  return (
    <Drawer
      title="THÀNH VIÊN"
      width={350}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
    >
      {contextHolder}

      {loading ? (
        <Flex align="flex-start" justify="center" style={{ height: "100%" }}>
          <Skeleton active avatar style={{ width: "100%" }} />
        </Flex>
      ) : (
        <div>
          <Button
            style={{ width: "100%", margin: "20px 0px" }}
            icon={<IoPersonAddOutline />}
          >
            Thêm thành viên
          </Button>
          <Row gutter={[24, 8]}>
            {data?.map((item) => {
              return (
                <Col span={24}>
                  <Flex align="center" gap={10}>
                    <div
                      className="avatar-mess"
                      style={{
                        background: GetRandomColor(item.user_id),
                      }}
                    >
                      {item?.name?.charAt(0)}
                    </div>
                    <div>
                      <p style={{ fontSize: 12, fontWeight: "bold" }}>
                        {item.name}
                      </p>
                      <p style={{ fontSize: 10, color: "gray" }}>
                        {item.is_admin ? "BQL" : "Cư dân"}
                      </p>
                    </div>
                  </Flex>
                </Col>
              );
            })}
          </Row>
        </div>
      )}
    </Drawer>
  );
};
export default forwardRef(ListMember);
