import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Select,
  Checkbox,
} from "antd";
import _ from "lodash";
import { NhanVienService } from "services/NhanVienService";
import { ToaNhaService } from "services/ToaNhaService";
import { SaveOutlined } from "@ant-design/icons";

const FormAdd = (props, ref) => {
  const { onAfterSubmit, toaNha } = props;
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState({ isHienThiCuDan: false });
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("0");

  const defaultErrors = {
    MaGD: null,
    MaKH: null,
    MaHinhThuc: null,
  };
  const [errors, setErrors] = useState(defaultErrors);

  useImperativeHandle(ref, () => ({
    show: async (tab, filterCondition) => {
      setOpen(true);
      setData((pv) => ({ ...pv, MaTN: filterCondition.MaTN }));
      setActiveTab(tab);
    },
    update: async (data, tab) => {
      setOpen(true);
      setData(data);

      setActiveTab(tab);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "isHienThiCuDan":
        _data[p] = !v;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async () => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let _res = null;
    switch (activeTab) {
      case "0":
        _res = await NhanVienService.Categories.addChucVu(_data);
        break;
      case "1":
        _res = await NhanVienService.Categories.addPhongBan(_data);
        break;
      default:
        break;
    }

    setLoading(false);
    if (_res.status === 2000) {
      openNotification("success", "topRight", `Cập nhật thành công!`);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  return (
    <Drawer
      title="Danh mục"
      width={520}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          {/* <Button loading={loading} onClick={() => onSubmit()}>
            Lưu
          </Button> */}
          <Button loading={loading} onClick={() => onSubmit()} type="primary">
            <SaveOutlined /> Lưu & đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      {activeTab === "0" ? (
        <Row gutter={[24, 20]}>
          <Col span={24}>
            <p style={{ fontWeight: "600", marginTop: "15px" }}>Tên chức vụ</p>
            <Input
              value={data?.TenCV}
              placeholder="Nhập tên chức vụ"
              onChange={(e) => applyChange("TenCV", e.target.value)}
            />
          </Col>
        </Row>
      ) : (
        <Row gutter={[24, 20]}>
          <Col span={24}>
            <p style={{ fontWeight: "600", marginTop: "15px" }}>Dự án</p>
            <Select
              placeholder="Chọn dự án"
              onChange={(v) => applyChange("MaTN", v)}
              style={{
                width: "100%",
              }}
              value={data?.MaTN}
              options={toaNha}
              fieldNames={{ label: "TenTN", value: "MaTN" }}
            />
          </Col>
          <Col span={24}>
            <p style={{ fontWeight: "600" }}>Tên phòng ban</p>
            <Input
              value={data?.TenPB}
              placeholder=""
              onChange={(e) => applyChange("TenPB", e.target.value)}
            />
          </Col>
          <Col span={24}>
            <Checkbox
              checked={data?.isHienThiCuDan}
              value={data?.isHienThiCuDan}
              onChange={(e) => applyChange("isHienThiCuDan", e.target.value)}
            >
              Hiển thị app cư dân
            </Checkbox>
          </Col>
        </Row>
      )}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
