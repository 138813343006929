import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  notification,
  Row,
  Col,
  Flex,
  Select,
  Skeleton,
  Input,
  Image,
  Button,
} from "antd";
import _, { isEmpty } from "lodash";
import "dayjs/locale/vi";
import FormAdd from "./FormAdd";
import { CiSearch, CiTrash } from "react-icons/ci";
import FormDetail from "./FormDetail";
import { YeuCauService } from "services/YeuCauService";
import { ToaNhaService } from "services/ToaNhaService";
import FormView from "./FormView";
// import "./index.scss";
import { ChatsService } from "services/ChatsService";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { RiSendPlaneFill } from "react-icons/ri";
import { BsGrid1X2 } from "react-icons/bs";
import { io } from "socket.io-client";
import { Format_Time } from "layouts/ultis/common";
import BoxRight from "./BoxRight";
const { TextArea } = Input;

export default function Index() {
  const [data, setData] = useState([]);
  const [messages, setMessages] = useState([]);
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const [loadingms, setLoadingms] = useState(false);
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [active, setActive] = useState({});
  const [api, contextHolder] = notification.useNotification();
  const refRight = useRef(null);
  const refDetail = useRef(null);
  const refDetailView = useRef(null);
  const refMessageEnd = useRef(null);
  const refAdd = useRef(null);
  const [messageBox, setMessageBox] = useState(null);
  const [filterCondition, setFilterCondition] = useState({
    MaTN: 0,
    InputSearch: "",
  });
  const [toanha, setToanha] = useState([]);
  const [socket, setSocket] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [typing, setTyping] = useState(false);
  const audio = new Audio(require("../../../assets/audio/noti-message.wav"));
  useEffect(() => {
    loadCategories();
    // const newSocket = io("https://api-beesky.onrender.com");
    const newSocket = io("http://localhost:3001");
    setSocket(newSocket);
    return () => newSocket.disconnect();
  }, []);
   useLayoutEffect(() => {
    scrollToBottom();
   });
  useEffect(() => {
    // let _mess = _.cloneDeep(messages);
    if (socket === null) return;
    let manv = Number(JSON.parse(localStorage.getItem("NhanVien"))?.maNV);
    socket.emit("addNewUser", { userId: manv, is_admin: true });
    socket.on("getOnlineUsers", (res) => {
      setOnlineUsers(res);
    });
    socket.on("getMessage", (res) => {
      audio.play();
      setMessages((pre) => {
        return [...pre, res.data];
      });
    });
    socket.on("getInbox", (res) => {
      // console.log("inbox", res);
    });
    socket.on("typing", (res) => {
      // console.log(res);
      setTyping(res.data);
    });
    return () => {
      socket.off("getOnlineUsers");
      socket.off("getMessage");
      socket.off("getInbox");
      socket.off("typing");
    };
  }, [socket]);
  const scrollToBottom = () => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight + 2000
    }
  };
  const load = async (_filter) => {
    setLoading(true);
    let _res = await ChatsService.getInbox(_filter);
    setData(_res.data);
    setLoading(false);
  };
  const loadCategories = async () => {
    let _tn = await ToaNhaService.getToaNhaByNV();
    setToanha(_tn.data);
    let _filter = _.cloneDeep(filterCondition);
    _filter.MaTN = _tn.data[0]?.MaTN;
    setFilterCondition(_filter);
    load(_filter);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const remove = async (item) => {
    let _res = await YeuCauService.deleteYeuCau({
      ID: item.ID,
    });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.ID !== Number(item.ID));
      setData(fil);
      openNotification("success", "topRight", "Xoá  thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const onAfterSubmit = () => {
    load(filterCondition);
  };
  const colorArr = [
    "linear-gradient(-225deg, #7de2fc 0%, #b9b6e5 100%)",
    "linear-gradient(-225deg, #a695e7 0%, #b9b6e5 100%)",
    "linear-gradient(-225deg, #faa774 0%, #b9b6e5 100%)",
    "linear-gradient(-225deg, #7bc862 0%, #b9b6e5 100%)",
    "linear-gradient(-225deg, #6ec9cb 0%, #b9b6e5 100%)",
    "linear-gradient(-225deg, ##ee7aae 0%, #b9b6e5 100%)",
    "linear-gradient(-225deg, ##e17076 0%, #b9b6e5 100%)",
  ];
  const getRandomColor = () => {
    return colorArr[Math.floor(Math.random() * colorArr.length)];
  };
  const applyChange = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    _filter[p] = v;
    setFilterCondition(_filter);
    load(_filter);
  };
  const onClickDetail = async (item) => {
    setLoadingms(true);
    setActive(item);
    let _res = await ChatsService.getMessages(item.id);
    setMessages(_res?.data);
    let _resmb = await ChatsService.getMemberGroup(item.id);
    // filterMemberGroup(_resmb?.data);
    setMembers(_resmb?.data);
    setLoadingms(false);
    refRight.current?.show();
  };
  const filterMemberGroup = async (memberReq) => {
    // let _members = memberReq || members;
    let mem = [];
    await Promise.all(
      memberReq?.map(async (item) => {
        if (item.is_admin) {
          let _user = await ChatsService.User.getById(item.user_id);
          mem.push({
            ...item,
            // user_id: _user?.data?.MaNV,
            name: _user?.data?.HoTenNV,
            //is_admin: true,
          });
        } else {
          let _user = await ChatsService.MatBang.getById(item.user_id);
          mem.push({
            ...item,
            // user_id: _user?.data?.MaMB,
            name: _user?.data?.MaSoMB,
            // is_admin: false,
          });
        }
      })
    );
    setMembers(mem);
    // setMemberGroup(mem);
  };

  function truncate(str, n) {
    return str.length > n ? str.slice(0, n - 1) + "..." : str;
  }
  const addMessage = async () => {
    setLoadingSend(true);
    let nhanvien = JSON.parse(localStorage.getItem("NhanVien"));
    let _res = await ChatsService.sendMessage({
      inbox_id: active?.id,
      message: textSend,
      user_id: nhanvien?.maNV,
      user_name: nhanvien?.tenNV,
      ma_tn: filterCondition?.MaTN,
      is_image: false,
    });
    if (_res.status === 2000) {
      let _users = members.filter((v) => v.user_id !== nhanvien?.maNV);
      if (socket) {
        _users?.map((item) => {
          socket.emit("sendMessage", {
            data: {
              id: _res.data,
              user_id: nhanvien?.maNV,
              message: textSend,
              create_at: new Date(
                new Date().getTime() - new Date().getTimezoneOffset() * 60000
              ).toJSON(),
              inbox_id: active.id,
              is_image: false,
              user_name: nhanvien?.tenNV,
            },
            userId: item.user_id,
          });
          socket.emit("typing", { data: false, userId: item.user_id });
        });
      }
      let _mes = _.cloneDeep(messages);
      _mes.push({
        id: _res.data,
        user_id: nhanvien?.maNV, //token.User?.MaNV,
        message: textSend,
        create_at: new Date(
          new Date().getTime() - new Date().getTimezoneOffset() * 60000
        ).toJSON(),
        is_image: false,
        user_name: nhanvien?.tenNV,
      });
      setMessages(_mes);
      setTextSend("");
    }
    setLoadingSend(false);
  };
  const [textSend, setTextSend] = useState("");
  const onKeyUp = (e) => {
    const { key, value } = e;
    if (key === "Enter") {
      setTextSend("");
      addMessage();
    }
  };
  const onChangeTextSend = (value) => {
    let _users = members.filter(
      (v) => v.user_id !== JSON.parse(localStorage.getItem("NhanVien"))?.maNV
    );
    _users?.map((item) => {
      socket.emit("typing", { data: true, userId: item.user_id });
    });
    setTextSend(value);
  };
  useEffect(() => {
    let time;
    if (!time) {
      time = setTimeout(() => {
        let _users = members.filter(
          (v) =>
            v.user_id !== JSON.parse(localStorage.getItem("NhanVien"))?.maNV
        );
        _users?.map((item) => {
          socket.emit("typing", { data: false, userId: item.user_id });
        });
      }, 3000);
    }
    return () => clearTimeout(time);
  }, [textSend]);
  const scrollbarRef = useRef(null);
  return (
    <Row gutter={[10, 10]} className="chats">
      <Col span={6} className="left-chats">
        <div
          className="box-search"
          style={{ height: window.innerHeight - 82, overflow: "scroll" }}
        >
          <Select
            value={filterCondition.MaTN}
            placeholder="Chọn dự án"
            onChange={(v) => applyChange("MaTN", v)}
            style={{
              width: "100%",
              marginBottom: 10,
            }}
            options={toanha}
            fieldNames={{ label: "TenTN", value: "MaTN" }}
          />
          <div className="search-input">
            <CiSearch size={20} />
            <input
              className="input"
              placeholder="Tìm kiếm"
              onChange={(v) => applyChange("InputSearch", v.target.value)}
            />
          </div>
          {loading && <Skeleton active />}
          {data?.map((item) => {
            return (
              <Flex
                className={
                  item?.id === active?.id
                    ? "ripple-container-active"
                    : "ripple-container"
                }
                align="center"
                gap={10}
                key={item.id}
                onClick={() => onClickDetail(item)}
              >
                <div className="avatar">{item?.name_chat?.charAt(0)}</div>
                <div style={{ width: "85%" }}>
                  <Flex justify="space-between" style={{ width: "100%" }}>
                    <p style={{ fontWeight: "bold" }}>{item.name_chat}</p>
                    {/* <p>{Format_Datetime(item.date_send)}</p> */}
                  </Flex>
                  <p
                    style={{
                      color:
                        item?.id === active?.id ? "white" : "rgb(142,142,146)",
                    }}
                  >
                    {truncate(item.last_message, 30)}
                  </p>
                </div>
              </Flex>
            );
          })}
        </div>
      </Col>
      <Col span={isShowDetail ? 12 : 18} className="right-chats">
        <Flex
          className="box-search"
          style={{ height: 45, padding: "4px 10px" }}
          align="center"
          justify="space-between"
        >
          <Flex gap={10} align="center">
            <Flex
              align="center"
              justify="center"
              style={{
                background: getRandomColor(),
                width: 30,
                height: 30,
                borderRadius: 50,
                fontWeight: "bold",
              }}
            >
              {active?.name_chat?.charAt(0)}
            </Flex>
            <div>
              <p style={{ fontWeight: "bold" }}>{active.name_chat}</p>
              <p style={{ color: "rgb(112,117,121)", fontSize: 12 }}>
                {members?.length} thành viên
              </p>
            </div>
          </Flex>
          <BsGrid1X2
            size={20}
            style={{ cursor: "pointer" }}
            onClick={() => setIsShowDetail(!isShowDetail)}
            color={isShowDetail ? "#6ec9cb" : "black"}
          />
        </Flex>
        <div className="message">
          <PerfectScrollbar containerRef={(ref) => setMessageBox(ref)}>
            {loadingms ? (
              <Flex align="end" justify="center" style={{ height: "100%" }}>
                <Skeleton active avatar style={{ width: "60%" }} />
              </Flex>
            ) : (
              <div className="content">
                {messages?.map((item, i) => {
                  return (
                    <Flex
                      key={item.id}
                      align="center"
                      gap={10}
                      justify={
                        item?.user_id ===
                        Number(
                          JSON.parse(localStorage.getItem("NhanVien"))?.maNV
                        )
                          ? "end"
                          : "start"
                      }
                    >
                      {messages[i - 1]?.user_id !== item.user_id &&
                      item?.user_id !==
                        Number(
                          JSON.parse(localStorage.getItem("NhanVien"))?.maNV
                        ) ? (
                        <div
                          className="avatar-mess"
                          style={{
                            background: getRandomColor(),
                          }}
                        >
                          {item?.user_name?.charAt(0)}
                        </div>
                      ) : (
                        <div style={{ width: "35px" }}></div>
                      )}

                      {item?.is_image ? (
                        <div style={{ marginBottom: 6 }}>
                          {messages[i - 1]?.user_id !== item.user_id && (
                            <p
                              style={{
                                fontWeight: "bold",
                                fontSize: 11,
                                color: "#7bc862",
                              }}
                            >
                              {item.user_name}
                            </p>
                          )}
                          <Image.PreviewGroup>
                            {JSON.parse(item.message)?.map((url, index) => {
                              return (
                                <Image
                                  key={index}
                                  style={{ paddingRight: 10 }}
                                  width={200}
                                  src={url}
                                />
                              );
                            })}
                          </Image.PreviewGroup>
                        </div>
                      ) : (
                        <div
                          className={
                            item?.user_id ===
                            Number(
                              JSON.parse(localStorage.getItem("NhanVien"))?.maNV
                            )
                              ? "message-item-right"
                              : "message-item"
                          }
                        >
                          {messages[i - 1]?.user_id !== item.user_id && (
                            <p
                              style={{
                                fontWeight: "bold",
                                fontSize: 11,
                                color: "#7bc862",
                              }}
                            >
                              {item.user_name}
                            </p>
                          )}
                          <p>
                            {item?.message}
                            <span className="time">
                              {Format_Time(item.create_at)}
                            </span>
                          </p>
                        </div>
                      )}
                    </Flex>
                  );
                })}
              </div>
            )}
            <span ref={refMessageEnd} />
          </PerfectScrollbar>
        </div>

        {typing === true && (
          <Flex align="center" justify="center" style={{ marginBottom: 10 }}>
            <div style={{ width: "60%" }}>
              <div className="chat-bubble">
                <div className="typing">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </div>
            </div>
          </Flex>
        )}
        <Flex className="message-bottom" justify="center" gap={10} align="end">
          <div
            style={{
              background: "white",
              width: "60%",
              padding: "6px 20px",
              borderRadius: 20,
            }}
          >
            <TextArea
              className="input"
              placeholder="Tin nhắn"
              autoSize
              value={textSend}
              onKeyUp={onKeyUp}
              onChange={(v) => onChangeTextSend(v.target.value)}
            />
          </div>
          <Button
            className="button-send"
            onClick={() => addMessage()}
            loading={loadingSend}
          >
            <RiSendPlaneFill size={25} color="rgb(51,144,236)" />
          </Button>
        </Flex>
      </Col>
      {isShowDetail && (
        <BoxRight
          ref={refRight}
          members={members}
          images={messages?.filter((v) => v.is_image)}
        />
        // <Col span={6}>
        //   <div
        //     className="box-search"
        //     style={{ height: window.innerHeight - 80, overflow: "scroll" }}
        //   >
        //     <p style={{ fontWeight: "bold" }}>Thành viên nhóm</p>
        //     {members?.map((item, index) => {
        //       return (
        //         <p key={item.id}>
        //           {index + 1}. {item.name}
        //         </p>
        //       );
        //     })}
        //   </div>
        // </Col>
      )}
      <FormAdd ref={refAdd} onAfterSubmit={onAfterSubmit} />
      <FormDetail ref={refDetail} onAfterSubmit={onAfterSubmit} />
      <FormView ref={refDetailView} />
      {contextHolder}
    </Row>
  );
}
